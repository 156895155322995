import React from 'react';
import { TickIcon } from "@dataplan/react-components/dist/components/icons";

export default class Tick extends React.Component {

    /**
     * Renders the icon component
     *
     * @return {ReactElement} The icon element
     */
    render () {
        return (
            <TickIcon preserveAspectRatio="none" {...this.props} />
        );
    }

}
