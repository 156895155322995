import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'utils/PrivateRoute';
import { paths } from "../../lib";

import {
    CompaniesList,
    CompaniesAdd,
} from '.';

class Companies extends React.Component {

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <Switch>
                <PrivateRoute path={paths.companiesAdd} component={CompaniesAdd} />
                <PrivateRoute path={paths.companies} component={CompaniesList} />
            </Switch>
        );
    }

}

export default Companies;
