import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import api from '../../lib/api';
import { KEY_RETURN, KEY_ENTER, KEY_ESCAPE } from 'keycode-js';
import appState from '../../state/App';
import _ from 'lodash';
import moment from 'moment';
import InputMoment from 'input-moment';
import classNames from 'classnames';
import { checkValidity } from '../../lib/validationHelpers';
import windowScroll from '../../lib/domWindowScroll';

import Icon from '../icons/Icon';

import styles from './InlineEdit.module.scss';

class InlineEdit extends React.Component {

    static propTypes = {
        type: PropTypes.oneOf([
            'string',
            'date',
            'email',
            'mobile',
            'nino',
        ]),
        onChange: PropTypes.func,
        handleRedraw: PropTypes.func,
        children: PropTypes.any.isRequired,
        patchUrl: PropTypes.string.isRequired,
        objectKey: PropTypes.string.isRequired,
        inDataTable: PropTypes.bool,
        emphasizeEmpty: PropTypes.bool,
        history: PropTypes.object.isRequired,
        field: PropTypes.string,
        fullEdit: PropTypes.string,
    }

    static defaultProps = {
        type: 'string',
        onChange: null,
        handleRedraw: null,
        inDataTable: false,
        emphasizeEmpty: false,
        field: "",
        fullEdit: "",
    }

    /**
     * Creates an instance of the inline edit component
     *
     * @param {object} props The edit component properties
     */
    constructor (props) {
        super(props);

        this.dateFormat = "DD-MM-YYYY";

        this.inlineEditContainer = React.createRef();
        this.editButtonRef = React.createRef();
        this.saveButtonRef = React.createRef();
        this.cancelButtonRef = React.createRef();
        this.cellRef = React.createRef();
        this.calendarRef = React.createRef();

        this.state = {
            inputValue: (this.props.type === 'date')
                ? moment()
                : '-',
            prevValue: null,
            editing: false,
            redrawRequired: false,
            error: {
                isValid: true,
                message: '',
            },
        };
    }

    /**
     * Invoked immediately after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        const { type } = this.props;

        document.addEventListener("click", this.handleDocumentClick);

        if (type) {
            this.setInitialValue();
        }
    }

    /**
     * Invoked just before the component is removed from the DOM
     */
    componentWillUnmount = () => {
        const { redrawRequired } = this.state;

        document.removeEventListener("click", this.handleDocumentClick);

        if (redrawRequired) {
            this.props.handleRedraw();
        }
    }

    /**
     * Sets the initial state for the editable value
     *
     * @param {mixed} existingValue An exisiting value to use if set
     *
     * @return {void}
     */
    setInitialValue = (existingValue = null) => {
        const { children, type } = this.props;
        let inputValue;

        if (existingValue !== null) {
            inputValue = existingValue;
        } else if (type === "date") {
            inputValue = moment((children !== '-') ? children : moment(), this.dateFormat);
        } else {
            inputValue = (_.isArray(children)) ? _.head(children) : children;
        }

        this.setState({
            inputValue,
            error: {
                isValid: true,
                message: "",
            },
        });
    }

    /**
     * Check if the click event target is in the current DOM ref
     *
     * @param {object} ref The DOM ref
     * @param {ReactElement} eventTarget The target of the click event
     *
     * @return {bool} If the click was inside the DOM ref
     */
    isCurrentClickTarget = (ref, eventTarget) => {
        return ref.current && ref.current.contains(eventTarget);
    }

    /**
     * Called when there is a click anywhere on the document
     *
     * @param {ClickEvent} event The event
     */
    handleDocumentClick = (event) => {
        if (!this.state.editing) {
            return;
        }

        const eventTarget = event.target;
        const { type } = this.props;

        if (type === "date") {
            this.handleCalendarBlur(eventTarget);
        } else {
            this.handleInputBlur(eventTarget);
        }
    }

    /**
     * Handle a click event that isn't on a calendar component of the inline edit functionality
     *
     * @param {element} eventTarget The click event target
     */
    handleCalendarBlur = (eventTarget) => {
        const clickInCalendar = this.isCurrentClickTarget(this.calendarRef, eventTarget);
        const clickButton = this.isCurrentClickTarget(this.editButtonRef, eventTarget);

        if (!clickInCalendar && !clickButton) {
            this.toggleEditing(false, this.setInitialValue);
        }
    }

    /**
     * Handle a click event that isn't on an input component of the inline edit functionality
     *
     * @param {element} eventTarget The click event target
     */
    handleInputBlur = (eventTarget) => {
        const clickInCell = this.isCurrentClickTarget(this.cellRef, eventTarget);
        const clickButton = this.isCurrentClickTarget(this.editButtonRef, eventTarget);
        const clickSaveButton = this.isCurrentClickTarget(this.saveButtonRef, eventTarget);
        const clickCancelButton = this.isCurrentClickTarget(this.cancelButtonRef, eventTarget);

        if (!clickInCell && !clickButton && !clickSaveButton && !clickCancelButton) {
            this.toggleEditing(false, this.setInitialValue(this.state.prevValue));
        }
    }

    /**
     * Sets the position of the calendar input so it appears next to the edit button
     */
    positionCalendar = () => {
        const cellBbox = this.editButtonRef.current.getBoundingClientRect();

        this.calendarRef.current.style.top = `${cellBbox.top + windowScroll.getY() + cellBbox.height + 10}px`;
        this.calendarRef.current.style.left = `${cellBbox.left + windowScroll.getX() - 10}px`;
    }

    /**
     * Sets if the value is currently being edited
     *
     * @param {boolean} enabled If editing is enabled
     * @param {function} callback Function to call after the state has been updated
     */
    toggleEditing = (enabled, callback = undefined) => {
        this.setState({ editing: enabled }, callback);
    }

    /**
     * Called when the edit button is clicked
     */
    handleEditClick = () => {
        this.setState((prevState) => {
            return {
                prevValue: prevState.inputValue,
            };
        }, () => {
            this.toggleEditing(true, () => {
                if (this.props.type === 'date') {
                    this.positionCalendar();
                } else {
                    this.handleInputSetup(this.cellRef.current);
                }
            });
        });
    }

    /**
     * Sets up the input and it's parent container's width when ready to edit
     *
     * @param {bool} clearPrevious If we want to clear the previous width/maxWidth of the elements
     *
     * @return {void}
     */
    handleInputResize = (clearPrevious = false) => {
        if (clearPrevious) {
            this.inlineEditContainer.current.removeAttribute('style');
            this.editButtonRef.current.removeAttribute('style');
        }

        let container = this.inlineEditContainer.current;
        let content = this.editButtonRef.current;
        let width = content.getBoundingClientRect().width;

        content.style.maxWidth = `${width}px`;
        container.style.width = `${width}px`;
    }

    /**
     * Sets up the input and it's parent container when ready to edit
     *
     * @param {ReactElement} input The input DOM element from the Ref
     *
     * @return {void}
     */
    handleInputSetup = (input) => {
        this.handleInputResize();

        input.focus();
        input.select();
    }

    /**
     * Called when the save button is clicked
     *
     * @param {event} event The native button click event
     * @param {bool} isDate If the save button is in the datepicker or not
     *
     * @return {void}
     */
    handleSaveClick = (event, isDate = false) => {
        if (isDate) {
            this.toggleEditing(false, this.checkValidity);
        } else {
            this.checkValidity();
        }
    }

    /**
     * Called when the cancel button is clicked
     */
    handleCancelClick = () => {
        this.toggleEditing(false, this.setInitialValue(this.state.prevValue));
    }

    /**
     * Called when a key is pressed whilst focused on the text input
     *
     * @param {event} event The key press
     */
    handleKeyUp = (event) => {
        if (event.keyCode === KEY_RETURN || event.keyCode === KEY_ENTER) {
            event.preventDefault();
            this.toggleEditing(false, this.checkValidity);
        } else if (event.keyCode === KEY_ESCAPE) {
            this.toggleEditing(false, this.setInitialValue(this.state.prevValue));
        }
    }

    /**
     * Called when the state of the input changes
     *
     * @param {event} event The input change
     */
    handleInputChange = (event) => {
        if (this.props.onChange) {
            this.props.onChange(event);
        }

        this.setState({ inputValue: event.target.value });
    }

    /**
     * Called when the date is updated
     *
     * @param {object} newDate A moment object of the new date
     */
    handleDateChange = (newDate) => {
        this.setState({
            inputValue: newDate,
        });
    }

    /**
     * Checks the validity of the data entered into the input
     */
    checkValidity = () => {
        let validation = checkValidity(this.state.inputValue, this.props.type);

        if (validation.isValid) {
            this.setState({
                error: {
                    isValid: true,
                    message: "",
                },
            }, () => {
                this.handleSave();
            });
        } else {
            this.setState({
                editing: true,
                error: {
                    isValid: false,
                    message: validation.message,
                },
            }, () => {
                if (this.props.type === 'date') {
                    this.positionCalendar();
                } else {
                    this.cellRef.current.focus();
                }
            });
        }
    }

    /**
     * Handles the saving of a valid input via the API
     */
    handleSave = () => {
        const compareTo = (_.isArray(this.props.children))
            ? (this.state.inputValue === _.head(this.props.children))
            : (this.state.inputValue === this.props.children);

        if (compareTo) {
            return;
        }

        const { patchUrl } = this.props;
        let { objectKey } = this.props;

        api.patch(patchUrl, this.getPayload(objectKey, this.state.inputValue, this.props.type))
            .then((response) => {
                if (response.status === 200) {
                    this.handleEditSuccess(response);
                }
                this.props.history.go();
            })
            .catch((error) => {
                this.handleEditFail(error.response.data);
            });
    }

    /**
     * Formatting helper to format the return value to be patched
     *
     * @param {string} key The textual ID of the field we are editing
     * @param {mixed} value The value we're padding to the API
     * @param {string} type The type of field, used to ensure return shape is correct
     *
     * @return {object} The payload to be passed to the API
     */
    getPayload = (key, value, type) => {
        if (key === "emails") {
            return {
                [key]: {
                    home: value,
                },
            };
        } else if (key === "phone_numbers") {
            return {
                [key]: {
                    mobile: value,
                },
            };
        } else if (type === "date") {
            return {
                [key]: value.format('DD-MM-YYYY'),
            };
        }

        return {
            [key]: value,
        };
    }

    /**
     * If the API call is successful, force a redraw of the HOC if required
     */
    handleEditSuccess = () => {
        this.toggleEditing(false, () => {
            this.setState({
                redrawRequired: true,
            }, () => {
                this.handleInputResize(true);
            });
        });
    }

    /**
     * If the API call fails, pass the error message to a toast notification
     * and reset to initial value
     *
     * @param {object} errorData The error passed from the API
     */
    handleEditFail = (errorData) => {
        _.each(errorData.messages, (messages) => {
            _.each(messages, (message) => {
                appState.addNotification({
                    text: message,
                    type: "error",
                    duration: 5,
                });
            });
        });

        this.toggleEditing(false, this.setInitialValue);
    }

    /**
     * Renders the calendar input if the date is being edited
     *
     * @return {ReactElement} The element or null if not in edit mode
     */
    renderCalendarControl = () => {
        if (!this.state.editing || this.props.type !== "date") {
            return null;
        }

        return (
            <div
                className={styles.calendarWrapper}
                ref={this.calendarRef}
            >
                <InputMoment
                    moment={this.state.inputValue}
                    onChange={this.handleDateChange}
                    onSave={(event) => this.handleSaveClick(event, true)}
                    minStep={0}
                    hourStep={0}
                />
            </div>
        );
    }

    /**
     * Render the value to display based on type and state
     *
     * @return {string} The value to show
     */
    renderValue = () => {
        const { inputValue, redrawRequired } = this.state;
        const { type, field, children, fullEdit } = this.props;

        if (type === 'date') {
            return (children === '-' && !redrawRequired) ? '-' : inputValue.format('DD-MM-YYYY');
        }

        if (field === 'email') {
            return fullEdit;
        }

        return inputValue;
    }

    /**
     * Determine if the display value is "empty" and should be emphasized
     *
     * @return {bool} If the field is considered empty
     */
    isEmpty = () => {
        return !this.state.inputValue.length || this.state.inputValue === "-";
    }

    /**
     * Renders the textual display of the value that can be edited
     *
     * @return {ReactElement} The value to display
     */
    renderDisplay = () => {
        const { editing, error } = this.state;
        const { emphasizeEmpty, inDataTable } = this.props;

        const classNameList = classNames(styles.editableField, {
            [styles.editing]: editing,
            [styles.error]: !error.isValid,
            [styles.emphasizeEmpty]: emphasizeEmpty && this.isEmpty(),
            [styles.inDataTable]: inDataTable,
        });

        return (
            <span
                className={classNameList}
                onClick={this.handleEditClick}
                ref={this.editButtonRef}
            >
                {this.renderValue()}
            </span>
        );
    }

    /**
     * Renders the text input to display when the field is editable
     *
     * @return {ReactElement} The text input
     */
    renderEditable = () => {
        const { inputValue, editing } = this.state;
        const { type, inDataTable } = this.props;

        if (!editing || this.props.type === 'date') {
            return null;
        }

        return (
            <>
                <input
                    className={classNames(styles.inlineEditInput, {
                        [styles.inDataTable]: inDataTable,
                    })}
                    type="text"
                    ref={this.cellRef}
                    onChange={this.handleInputChange}
                    onKeyUp={this.handleKeyUp}
                    value={(type === 'date') ? inputValue.format('DD-MM-YYYY') : inputValue}
                    disabled={(type === 'date')}
                />
                <div className={styles.buttonContainer}>
                    <button
                        ref={this.saveButtonRef}
                        type="button"
                        className={styles.saveButton}
                        onClick={this.handleSaveClick}
                    >
                        <Icon
                            icon="TickSolid"
                            width="15"
                            height="15"
                            className={styles.saveButtonSVG}
                        />
                    </button>
                    <button
                        ref={this.cancelButtonRef}
                        type="button"
                        className={styles.cancelButton}
                        onClick={this.handleCancelClick}
                    >
                        <Icon
                            icon="CloseSolid"
                            width="15"
                            height="15"
                        />
                    </button>
                </div>
            </>
        );
    }

    /**
     * Renders the validation error, if the user input is not valid for it's type
     *
     * @return {ReactElement} The validation error
     */
    renderValidationError = () => {
        const { error } = this.state;

        if (error.isValid) {
            return null;
        }

        return (
            <span className={styles.validationError}>{error.message}</span>
        );
    }

    /**
     * Renders the inline edit component
     *
     * @return {ReactElement} The inline edit component
     */
    render () {
        const { inDataTable } = this.props;
        const classList = classNames(styles.inlineEditContainer, {
            [styles.inDataTable]: inDataTable,
        });

        return (
            <>
                <div className={classList} ref={this.inlineEditContainer}>
                    {this.renderDisplay()}
                    {this.renderEditable()}
                    {this.renderValidationError()}
                </div>
                {ReactDOM.createPortal(
                    this.renderCalendarControl(),
                    document.body
                )}
            </>
        );
    }

}

export default withRouter(InlineEdit);

