import React from 'react';
import PropTypes from 'prop-types';
import api from "../../lib/api";
import _ from "lodash";
import { generatePath, withRouter } from 'react-router-dom';
import classNames from "classnames";

import Routes from './routes';
import { paths } from "../../lib";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import appState from '../../state/App';
import CardSideMenu from "../../components/CardSideMenu";

import styles from './assets/SinglePayroll.module.scss';

class SinglePayroll extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            company: null,
            payroll: null,
            employeeNumber: null,
        };
    }

    /**
     * Called when the component first renders
     *
     * @return {void}
     */
    componentDidMount () {
        api.get("/payrolls")
            .then((payrolls) => {
                this.setCompanyPayrolls(payrolls.data);
            });
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = "EMC";

        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Handles updating the component
     *
     * @param {object} prevProps previous props
     */
    componentDidUpdate (prevProps) {
        const companyId = this.props.match.params.company_id;
        const prevId = prevProps.match.params.company_id;

        if (companyId !== prevId) {
            api.get("/payrolls")
                .then((payrolls) => {
                    this.setCompanyPayrolls(payrolls.data);
                });
        }
    }

    /**
     * Sets the state for company and payrolls
     * @param {object} payrolls the payrolls data
     */
    setCompanyPayrolls (payrolls) {
        const { history, match } = this.props;
        const { companies, employees } = this.props.appState;
        const payrollIdFromURL = _.toNumber(match.params.payroll_id);
        const payroll = _.find(payrolls, {id: payrollIdFromURL});

        if (!payroll) {
            history.replace(paths.forbidden);
        }

        const company = _.find(companies, {id: payroll.company_id});
        // eslint-disable-next-line camelcase
        const employeeNumber = employees.filter(({ payroll_id }) => payroll_id === payroll.id).length;

        this.setState({
            company,
            payroll,
            employeeNumber,
        }, () => {
            this.setPageName(company, payroll);
        });
    }

    /**
     * Set page name
     *
     * @param {object} company the company data
     * @param  {object} payroll the payroll data
     *
     * @return {void}
     */
    setPageName = (company, payroll) => {
        const pageName = `${company.name} - ${payroll.name} Payroll`;
        document.title = pageName;
    }

    /**
     * Gets the links for the side menu
     *
     * @returns {object} the links for the side menu
     */
    getLinkList () {
        const { params } = this.props.match;

        return [
            {
                label: "About",
                // eslint-disable-next-line camelcase
                to: generatePath(paths.singlePayrollAbout, params),
            },
            {
                label: "File Types",
                // eslint-disable-next-line camelcase
                to: generatePath(paths.singlePayrollFileTypes, params),
            },
            {
                label: "Templates",
                // eslint-disable-next-line camelcase
                to: generatePath(paths.singlePayrollTemplates, params),
            },
            {
                label: "Branding",
                // eslint-disable-next-line camelcase
                to: generatePath(paths.singlePayrollBranding, params),
            },
            {
                label: "Activation",
                // eslint-disable-next-line camelcase
                to: generatePath(paths.singlePayrollActivation, params),
            },
        ];
    }

    /**
     * Gets the page content to render
     *
     * @return {ReactElement} The page content
     */
    getPageContent = () => {
        const { company, payroll } = this.state;

        if (!company || !payroll) {
            return <LoadingSpinner className={styles.loadingSpinner} label="Loading" />;
        }

        return (
            <>
                <CardSideMenu links={this.getLinkList()} />
                <PageLayout
                    heading={{
                        text: `${company.name} > ${payroll.name} payroll`,
                        size: "h1",
                    }}
                    pageType="full"
                    maxWidth={this.props.appState.maxWidth}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: false,
                        hasGutter: true,
                    }}
                >
                    <Routes {...this.state} />
                </PageLayout>
            </>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const classList = classNames(
            styles.card,
            styles.shadow,
        );

        return (
            <>
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={200}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    <div className={classList}>
                        {this.getPageContent()}
                    </div>
                </AnimationContainer>
            </>
        );
    }

}

export default withRouter(appState.attachState(SinglePayroll));
