import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DefaultButton from './DefaultButton';

import './PrimaryButton.scss';

class PrimaryButton extends React.Component {

    static propTypes = {
        children: PropTypes.string.isRequired,
        className: PropTypes.string,
        forwardedRef: PropTypes.any,
    }

    static defaultProps = {
        className: null,
        forwardedRef: null,
    }

    /**
     * Renders the button
     *
     * @return {ReactElement} The button
     */
    render () {
        const { className, forwardedRef, ...otherProps } = this.props;

        return (
            <DefaultButton
                className={classNames(["primary-button", className])}
                ref={forwardedRef}
                {...otherProps}
            />
        );
    }

}

// eslint-disable-next-line react/no-multi-comp
export default React.forwardRef((props, ref) => {
    return <PrimaryButton forwardedRef={ref} {...props} />;
});
