import React from 'react';
import styles from './Circle.module.scss';
import PropTypes from "prop-types";

export default class Circle extends React.Component {

    static propTypes = {
        text: PropTypes.string,
    }

    static defaultProps = {
        text: '',
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The non gender icon
     */
    render () {
        const { text } = this.props;

        return (
            <div className={styles.circle}>
                {text}
            </div>
        );
    }

}
