import React from 'react';
import ReactDOM from 'react-dom';
import { generatePath, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import payslipsPropTypes, { payslipsDefaultProps } from '../../../lib/props-schema/payslipsSchema';
import moment from 'moment';
import _ from 'lodash';
import naturalCompare from 'string-natural-compare';

import { formatLongDate, formatMonies, paths, PdfDownloader } from '../../../lib';
import { PageLayoutContext } from '@dataplan/react-components/dist/components/ui/page_layout';
import { getPayslipYears } from '../../../lib/payslipViewHelpers';
import {
    DataTable,
    YearScroller,
    DocumentPdfView,
    DocumentTray,
} from '../../../components';

import styles from './PayslipsListTab.module.scss';
import sharedStyles from './SharedStyles.module.scss';

class PayslipsListTab extends React.Component {

    static propTypes = {
        employeePayslips: payslipsPropTypes,
        match: PropTypes.object.isRequired,
    }

    static defaultProps = {
        employeePayslips: payslipsDefaultProps,
    }

    /**
     * Creates an instance of the payslip section of the employee profile
     *
     * @param {object} props The payslip section properties
     */
    constructor (props) {
        super(props);

        this.selectedPayslip = null;

        this.state = {
            years: [],
            currentTaxYear: moment().set({
                date: 1,
                month: 0,
            }),
            payslips: [],
            dataLoaded: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.parseInitialPayslipData(this.props.employeePayslips);
    }

    /**
     * Split the payslip data into tax years and stores them in state
     *
     * @param {object} payslips The payslip data returned from the API
     *
     * @return {void}
     */
    parseInitialPayslipData = (payslips) => {
        const payslipYears = getPayslipYears(payslips);
        let shapedPayslips = {};

        _.each(payslipYears, (year) => {
            const yearsPayslips = payslips.filter((payslip) => {
                return payslip.year === year;
            });

            shapedPayslips[year] = this.mapDataObject(yearsPayslips);
        });

        this.setState({
            years: payslipYears,
            currentTaxYear: moment().set({
                date: 1,
                month: 0,
                year: _.last(payslipYears),
            }),
            payslips: shapedPayslips,
            dataLoaded: true,
            showPayslipTray: false,
        });
    }

    /**
     * Maps the payslip data to an object shape suitable for the data table
     *
     * @param {array} payslips The payslip data
     *
     * @return {array} The formatted data object
     */
    mapDataObject = (payslips) => {
        const payslipMap = payslips.map((payslip) => {
            return {
                id: payslip.id,
                paydate: {
                    component: this.getPayslipLink(payslip),
                    sortValue: payslip.paydate,
                },
                period: {
                    text: payslip.period_number,
                },
                gross: {
                    text: formatMonies(payslip.gross),
                    alignRight: true,
                },
                deds: {
                    text: formatMonies(payslip.total_deductions),
                    alignRight: true,
                },
                net: {
                    text: formatMonies(payslip.net),
                    alignRight: true,
                },
                status: {
                    text: (payslip.marked_as_read) ? "Read" : "Unread",
                },
            };
        });

        return payslipMap.sort((a, b) => {
            return naturalCompare(b.paydate.sortValue, a.paydate.sortValue);
        });
    }

    /**
     * Creates a clickable element to run function on click
     *
     * @param {object} payslip The payslip detailed in the row
     *
     * @return {ReactElement} The clickable link
     */
    getPayslipLink = (payslip) => {
        return (
            <button
                className={sharedStyles.buttonLink}
                onClick={() => this.showPayslipPreview(payslip)}
                type="button"
            >
                {formatLongDate(payslip.paydate)}
            </button>
        );
    }

    /**
     * Handles showing the payslip tray on link click
     *
     * @param {object} payslip The payslip detailed in the row
     *
     * @return {void}
     */
    showPayslipPreview = (payslip) => {
        this.selectedPayslip = {
            id: payslip.id,
            title: `Payslip for ${moment(payslip.paydate).format("DD MMMM YYYY")}`,
        };

        this.setState({
            showPayslipTray: true,
        });
    }

    /**
     * Creates a data table using the payslip data
     *
     * @return {ReactElement} The payslip data table
     */
    getPayslipsTable = () => {
        const { years, currentTaxYear, payslips, dataLoaded } = this.state;
        const activeYear = currentTaxYear.year();

        if (!dataLoaded) {
            return null;
        }

        const colOrder = [
            "paydate",
            "period",
            "gross",
            "deds",
            "net",
            "status",
        ];

        const headings = {
            paydate: "Paydate",
            period: "Period",
            gross: {
                text: "Gross",
                alignRight: true,
            },
            deds: {
                text: "Deductions",
                alignRight: true,
            },
            net: {
                text: "Net",
                alignRight: true,
            },
            status: "Status",
        };

        const actions = {
            actions: [
                {
                    label: "Download",
                    callback: this.handleDownload,
                },
            ],
        };

        return (
            <div className={styles.payslipTable}>
                <DataTable
                    key={(years.length > 0) ? activeYear : 'empty-table'}
                    colOrder={colOrder}
                    data={(years.length > 0) ? payslips[activeYear] : []}
                    headings={headings}
                    actions={actions}
                />
            </div>
        );
    }

    /**
     * Downloads the relevant payslip PDF
     *
     * @param {string} payslipId The payslip's ID
     */
    handleDownload = (payslipId) => {
        const { payslips } = this.state;
        const flatArr = _.flatten(Object.values(payslips));
        const payslip = _.find(flatArr, (item) => (item.id === payslipId));

        new PdfDownloader({
            url: `/payslip/${payslipId}/pdf`,
            responseType: "json",
            jsonPdfKey: "payslip",
            title: `Payslip for ${formatLongDate(payslip.paydate.sortValue)}`,
        }).download();
    }

    /**
     * Updates the current visible month
     *
     * @param {string} action The action - either add or subtract
     * @param {number} value The value to modify by
     */
    updateTaxYear = (action, value) => {
        this.setState((prevState) => {
            const newMoment = moment(prevState.currentTaxYear);

            newMoment[action](value, "years");

            return { currentTaxYear: newMoment };
        });
    }

    /**
     * Called when the payslip tray is closed
     *
     * @return {void}
     */
    handleTrayClose = () => {
        this.setState({
            showPayslipTray: false,
        });
    }

    /**
     * Renders the portal for the payslip tray
     *
     * @param {object} context The context from the PageLayoutConsumer
     *
     * @return {?ReactElement} The payslip tray portal
     */
    renderPayslipTray = (context) => {
        const { match } = this.props;
        const { showPayslipTray } = this.state;

        if (!showPayslipTray) {
            return null;
        }

        // eslint-disable-next-line camelcase
        const employeePath = generatePath(paths.employee, match.params);
        const viewMore = `${employeePath}?payslipId=${this.selectedPayslip.id}`;

        return (
            ReactDOM.createPortal(
                (
                    <div className={sharedStyles.previewTray}>
                        <DocumentTray
                            trayTitle={this.selectedPayslip.title}
                            onClose={this.handleTrayClose}
                        >
                            <DocumentPdfView
                                className={sharedStyles.pdfCanvas}
                                key={this.selectedPayslip.title}
                                url={`/payslip/${this.selectedPayslip.id}/pdf`}
                                viewMore={viewMore}
                                responseType="json"
                                jsonPdfKey="payslip"
                                title={this.selectedPayslip.title}
                                responsive
                            />
                        </DocumentTray>
                    </div>
                ), context.pageLayoutTray.current,
            )
        );
    }

    /**
     * Renders the data navigation arrows
     *
     * @return {?ReactElement} The date navigation component
     */
    renderDateNavigation = () => {
        const { years, currentTaxYear } = this.state;

        if (years.length < 1) {
            return null;
        }

        return (
            <YearScroller
                align="center"
                year={currentTaxYear}
                updateTaxYear={this.updateTaxYear}
                maxYear={moment().set({
                    date: 1,
                    month: 0,
                    year: _.last(years),
                })}
                minYear={moment().set({
                    date: 1,
                    month: 0,
                    year: _.head(years),
                })}
            />
        );
    }

    /**
     * Renders the employee payslips list tab
     *
     * @return {ReactElement} The payslips list tab
     */
    render () {
        const { dataLoaded } = this.state;

        if (!dataLoaded) {
            return <>Loading...</>;
        }

        return (
            <>
                <div className={styles.dateNavigation}>
                    {this.renderDateNavigation()}
                </div>
                {this.getPayslipsTable()}
                <PageLayoutContext.Consumer>
                    {(context) => this.renderPayslipTray(context)}
                </PageLayoutContext.Consumer>
            </>
        );
    }

}

export default withRouter(PayslipsListTab);
