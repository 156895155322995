import React from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { paths } from "../../lib";
import { TertiaryButton, PrimaryButton } from "@dataplan/react-components/dist/components/forms/controls";

import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from '../../lib/contrastingColorList';
import { ArrowDropDownIcon } from "@dataplan/react-components/dist/components/icons";

import styles from './Authentication.module.scss';
import _ from "lodash";
import { SecurityCard } from "@dataplan/react-components/dist/components/ui/cards/security_card";

class Authentication extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
    }

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    /**
     * Toggles / sets if the drop down is open
     *
     * @param {boolean} setOpen Optional argument to set open or not
     */
    toggleOpen = (setOpen) => {
        this.setState((prevState) => {
            const open = (_.isBoolean(setOpen))
                ? setOpen
                : !prevState.open;

            return {
                open,
            };
        });
    }

    /**
     * Renders the turn on button
     *
     * @return {ReactElement} The turn on button
     */
    renderTurnOnButton () {
        const { history } = this.props;

        const buttonStyles = {
            backgroundColor: '#652D90',
            color: getMostContrastingColour('#652D90', contrastingColourList),
        };

        return (
            <PrimaryButton
                style={buttonStyles}
                text="Turn On"
                type="submit"
                onClick={() => history.push(paths.myAccountPage)}
            />
        );
    }

    /**
     * Renders the more info description
     *
     * @return {ReactElement} The more info description
     */
    renderMoreInfoDescription () {
        const { open } = this.state;
        const descriptionStyle = (open) ? styles.tertText : styles.tertTextHidden;

        const description = [
            "Multi-Factor Authentication (MFA) is a security system",
            "that verifies a user’s identity by requiring multiple credentials.",
            "It is a critical component of identity and access management (IAM).",
            "Rather than just asking for a username and password, MFA requires you",
            "to also enter a code which will be issued by an authenticator app.",
        ].join(" ");

        return <div className={descriptionStyle}>{description}</div>;
    }

    /**
     * Renders the more info component
     *
     * @return {ReactElement} The more info component
     */
    renderMoreInfo () {
        const { open } = this.state;
        const arrowStyles = (open) ? styles.arrowOpen : styles.arrowClosed;

        return (
            <>
                <TertiaryButton
                    accent={'#652D90'}
                    onClick={this.toggleOpen}
                    text="How does Authentication work?"
                    aria-label="More info"
                />
                <ArrowDropDownIcon width={24} height={24} className={arrowStyles} />
                {this.renderMoreInfoDescription()}
            </>
        );
    }

    /**
     * Renders the buttons in the footer
     *
     * @return {ReactElement} The buttons in the footer
     */
    renderFooterButtons () {
        const { history } = this.props;

        return (
            <div className={styles.footerButtons}>
                <TertiaryButton
                    accent={'#652D90'}
                    onClick={() => history.push(paths.dashboard)}
                    text="Skip"
                    aria-label="Skip"
                    className={styles.skipButton}
                />
            </div>
        );
    }

    /**
     * Renders the card content
     *
     * @return {ReactElement} the card content
     */
    renderContent () {
        return (
            <>
                <h2 className={styles.cardText}>
                    Multi Factor Authentication increases the security of your ePayslips account.
                </h2>
                <div className={styles.secondaryContent}>
                    {this.renderTurnOnButton()}
                </div>
                <div className={styles.tertiaryContent}>
                    {this.renderMoreInfo()}
                </div>
                <div>
                    {this.renderFooterButtons()}
                </div>
            </>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {

        return (
            <AnimationContainer
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <SecurityCard className={styles.card} bodyContent={this.renderContent()} />
            </AnimationContainer>
        );
    }

}

export default withRouter(Authentication);
