import React from 'react';
import PropTypes from 'prop-types';
import reactHtmlParser from 'react-html-parser';

import { parseTemplateTags } from '../';
import { TextAreaRow } from "@dataplan/react-components/dist/components/forms";

import styles from './TemplateTextarea.module.scss';

class TemplateTextarea extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        isPreview: PropTypes.bool,
        showInPreview: PropTypes.bool,
        templateTagValues: PropTypes.object,
    };

    static defaultProps = {
        name: "",
        label: "",
        value: "",
        isPreview: false,
        showInPreview: false,
        templateTagValues: {},
    }

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.state = {
            showTags: false,
        };
    }

    /**
     * Called when the button to hide/show tags is toggled
     *
     * @param {event} event The button onClick event
     *
     * @return {void}
     */
    handleShowTags = (event) => {
        event.preventDefault();

        this.setState((prevState) => {
            return {
                showTags: !prevState.showTags,
            };
        });
    }

    /**
     * Render the template tags as a list
     *
     * @return {ReactElement} The list
     */
    renderTemplateTags () {
        const { templateTagValues } = this.props;
        const { showTags } = this.state;

        if (!showTags || !templateTagValues) {
            return null;
        }

        const templateTagList = Object.keys(templateTagValues);

        return (
            <ul className={styles.templateTagList}>
                {templateTagList.map((tag) => {
                    return (
                        <li key={tag} className={styles.templateTagItem}>
                            <div className={styles.tagContainer} >
                                <div
                                    aria-label={`Copy tag: ${tag}`}
                                    className={styles.tag}
                                    onClick={() => this.copyText(tag)}
                                >
                                    {tag}
                                    <div className={styles.tooltip} >
                                        {`will be replaced by: ${templateTagValues[tag]}`}
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }

    /**
     * Copies text to clipboard
     *
     * @param {string} str The text
     *
     * @return {void}
     */
    copyText = (str) => {
        const textarea = document.createElement('textarea');
        textarea.style.display = "none";
        textarea.value = str;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }

    /**
     * Render the toggle button
     *
     * @return {ReactElement} The button
     */
    renderTemplateTagsButton () {
        const { showTags } = this.state;
        const buttonText = (showTags) ? "Close" : "Template Tags";

        return (
            <button type="button" onClick={this.handleShowTags} className={styles.templateTagsToggle}>
                {buttonText}
            </button>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const {
            value,
            isPreview,
            showInPreview,
            templateTagValues,
            name,
            label,
            onChange,
        } = this.props;

        if ((isPreview && !showInPreview)) {
            return null;
        }

        if (isPreview) {
            let displayValue = parseTemplateTags(value, templateTagValues);

            return <p>{reactHtmlParser(displayValue)}</p>;
        }

        return (
            <>
                <TextAreaRow
                    name={name}
                    value={value}
                    label={label}
                    inputClassName={styles.textArea}
                    onChange={onChange}
                />
                <div className={styles.templateTagWrapper}>
                    {this.renderTemplateTags()}
                    <div className={styles.buttonWrapper}>
                        {this.renderTemplateTagsButton()}
                    </div>
                </div>
            </>
        );
    }

}

export default TemplateTextarea;
