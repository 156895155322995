import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import styles from './MoreButton.module.scss';

class MoreButton extends React.Component {

    static propTypes = {
        onClick: PropTypes.func,
        forwardedRef: PropTypes.any,
    }

    static defaultProps = {
        onClick: _.noop,
        forwardedRef: null,
    }

    /**
     * Gets the more icon SVG.
     *
     * This is wrapped in a method so we can ignore the long line.
     *
     * @return {ReactElement} The SVG element
     */
    getMoreIcon () {
        const path = "M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-"
            + ".22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-"
            + ".49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11"
            + " 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-"
            + "1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.6"
            + "9-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-"
            + "3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z";

        return (
            <svg
                className={styles.icon}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="black"
                width="48px"
                height="48px"
            >
                <path
                    d="M0 0h24v24H0V0z"
                    fill="none"
                />
                <path
                    d={path}
                />
            </svg>
        );
    }

    /**
     * Renders the more nenu button
     *
     * @return {ReactElement} The menu button
     */
    render () {
        return (
            <button
                aria-label="Administration"
                type="button"
                className={styles.button}
                onClick={this.props.onClick}
                ref={this.props.forwardedRef}
            >
                {this.getMoreIcon()}
            </button>
        );
    }

}

// eslint-disable-next-line react/no-multi-comp
export default React.forwardRef((props, ref) => (
    <MoreButton forwardedRef={ref} {...props} />
));
