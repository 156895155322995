import React from 'react';
import PropTypes from 'prop-types';

export default class TabContent extends React.Component {

    static propTypes = {
        children: PropTypes.any,
    }

    static defaultProps = {
        children: null,
    }

    /**
     * Renders the tab content
     *
     * @return {ReactElement} The card content
     */
    render () {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }

}
