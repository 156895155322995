import _ from 'lodash';
import naturalCompare from 'string-natural-compare';

/**
 * Creates an array of tax years to display as tabbed navigation
 *
 * @param {array} payslips A collection of payslips
 *
 * @return {array} The array of tax years to display
 */
export const getPayslipYears = (payslips) => {
    const payslipYears = [];

    _(payslips)
        .uniqBy('year')
        .each((payslip) => payslipYears.push(payslip.year));

    return payslipYears.sort((a, b) => {
        return naturalCompare(b.toString(), a.toString());
    });
};
