import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { paths } from "../../../lib";
import classNames from 'classnames';

import { AddAnnouncementContext, EditAnnouncementContext } from '../';
import {
    DefaultForm,
    TextInputRow,
    TextAreaRow,
    PrimaryButton,
    SecondaryButton,
} from '@dataplan/react-components/dist/components/forms';
import { checkValidity } from 'lib/validationHelpers';

import { defaultAccentColour } from '../../../Colours';

import styles from './AnnouncementAdd.module.scss';

const editingProps = {
    type: PropTypes.bool,
    default: false,
};

/*
 * This component is used in both add and edit announcement components
 * Changes must be checked in both areas!
 */
class AnnouncementAddEditContent extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        context: PropTypes.object.isRequired,
        editing: editingProps.type,
    }

    static defaultProps = {
        editing: editingProps.default,
    }

    /**
     * Creates an instance of the add content for announcements
     *
     * @param {object} props The section properties
     */
    constructor (props) {
        super(props);

        this.state = {
            subject: {
                valid: (props.editing),
                hasChanged: false,
            },
            message: {
                valid: (props.editing),
                hasChanged: false,
            },
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.checkStepOneValidity();
    }

    /**
     * Called when the component is being removed from the DOM
     * Checks if the form has been changed, and if it has, refreshes the content from cache
     *
     * @return {void}
     */
    componentWillUnmount () {
        const hasChanged = this.checkHasFormChanged();
        const { editing } = this.props;
        const { refreshInitialState } = this.props.context;

        if (hasChanged && editing) {
            refreshInitialState();
        }
    }

    /**
     * Sets the state allowing for submission if valid when
     * clicking back to the 1st stage and not making any changes
     *
     * @return {void}
     */
    checkStepOneValidity () {
        const { backToStepOne, announcement } = this.props.context;

        if (backToStepOne) {
            this.setState({
                subject: {
                    valid: (announcement.subject.length > 0),
                },
                message: {
                    valid: (announcement.message.length > 0),
                },
            });
        }
    }

    /**
     * Handler for the standard input change events
     *
     * @param {event} event The input onChange event
     * @param {string} field The field that has been changed
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {void}
     */
    handleInputChange = (event, field, context) => {
        let valueToCheck = event.target.value;

        context.onFieldChange(event, field, () => {
            let valid = checkValidity(valueToCheck, 'requiredInput', field);

            this.setState({
                [field]: {
                    hasChanged: true,
                    valid: valid.isValid,
                },
            });
        });
    }

    /**
     * Renders the subject input section
     *
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {ReactElement} The subject section
     */
    renderAnnouncementSubject (context) {
        const { subject } = context.announcement;
        const { valid, hasChanged } = this.state.subject;
        const classList = classNames({
            [styles.error]: (!valid && hasChanged),
        });
        let subjectError = (!valid && hasChanged) ? "Subject is required" : "";

        return (
            <TextInputRow
                name="subject"
                label="Subject"
                type="text"
                onChange={(event) => this.handleInputChange(event, 'subject', context)}
                value={subject}
                inputClassName={classList}
                errorText={subjectError}
                placeholder="My announcement subject"
            />
        );
    }

    /**
     * Renders the content input section
     *
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {ReactElement} The content section
     */
    renderAnnouncementContent (context) {
        const { message } = context.announcement;
        const { valid, hasChanged } = this.state.message;
        const classList = classNames(styles.textArea, {
            [styles.error]: (!valid && hasChanged),
        });
        let messageError = (!valid && hasChanged) ? "Message is required" : "";

        return (
            <div className={styles.textAreaRow}>
                <TextAreaRow
                    name="message"
                    label="Message"
                    onChange={(event) => this.handleInputChange(event, 'message', context)}
                    value={message}
                    inputClassName={classList}
                    errorText={messageError}
                    placeholder="My announcement content"
                />
            </div>
        );
    }

    /**
     * Check the form inputs are valid
     *
     * @return {boolean} If the form inputs are valid
     */
    checkIsFormValid () {
        const { subject, message } = this.state;

        return (subject.valid && message.valid);
    }

    /**
     * Check if any of the form inputs have changes
     *
     * @return {boolean} If the form inputs have changed
     */
    checkHasFormChanged () {
        const { subject, message } = this.state;

        return (subject.hasChanged || message.hasChanged);
    }

    /**
     * Check if the form can be submitted
     *
     * @param {boolean} checkHasChanged If the form changing is part of the submission criteria
     *
     * @return {boolean} If the form can be submitted
     */
    checkCanFormSubmit (checkHasChanged = true) {
        const isValid = this.checkIsFormValid();
        const hasChanged = this.checkHasFormChanged();

        return (checkHasChanged)
            ? (isValid && hasChanged)
            : (isValid);
    }

    /**
     * Render the form buttons if editing
     *
     * @return {ReactElement} The buttons section
     */
    renderEditButtons () {
        const { context, history } = this.props;
        const { handleAnnouncementContentEdit } = context;
        const cancelAction = () => history.push(paths.announcements);
        const hasChanged = this.checkHasFormChanged();
        const canSubmit = this.checkCanFormSubmit();
        const buttonText = (hasChanged) ? "Cancel" : "Close";

        return (
            <>
                <PrimaryButton onClick={handleAnnouncementContentEdit} disabled={!canSubmit}>
                    Save
                </PrimaryButton>
                <SecondaryButton
                    className={styles.secondaryButton}
                    onClick={cancelAction}
                    accent={defaultAccentColour}
                    text={buttonText}
                    aria-label={buttonText}
                />
            </>
        );
    }

    /**
     * Render the form buttons if adding new
     *
     * @return {ReactElement} The buttons section
     */
    renderAddButtons () {
        const { history } = this.props;
        const canSubmit = this.checkCanFormSubmit(false);

        return (
            <>
                <PrimaryButton type="submit" disabled={!canSubmit}>
                    Next
                </PrimaryButton>
                <SecondaryButton
                    className={styles.secondaryButton}
                    onClick={() => history.push(paths.announcements)}
                    accent={'#682f91'}
                    text={"Cancel"}
                    aria-label={"Cancel"}
                />
            </>
        );
    }

    /**
     * Render the form buttons
     *
     * @return {ReactElement} The buttons section
     */
    renderButtons () {
        if (this.props.editing) {
            return this.renderEditButtons();
        } else {
            return this.renderAddButtons();
        }
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        let context = this.props.context;

        return (
            <DefaultForm onSubmit={context.onContentConfirm}>
                {this.renderAnnouncementSubject(context)}
                {this.renderAnnouncementContent(context)}
                {this.renderButtons()}
            </DefaultForm>
        );
    }

}

/* eslint-disable react/no-multi-comp */
// https://github.com/yannickcr/eslint-plugin-react/issues/2172
const component = React.forwardRef((props, ref) => (
    <AddAnnouncementContext.Consumer>
        {(addAnnouncementContext) => (
            <EditAnnouncementContext.Consumer>
                {(editAnnouncementContext) => (
                    <AnnouncementAddEditContent
                        forwardedRef={ref}
                        context={(props.editing) ? editAnnouncementContext : addAnnouncementContext}
                        {...props}
                    />
                )}
            </EditAnnouncementContext.Consumer>
        )}
    </AddAnnouncementContext.Consumer>
));

component.propTypes = {
    editing: editingProps.type,
};

component.defaultProps = {
    editing: editingProps.default,
};

export default withRouter(component);
/* eslint-enable */
