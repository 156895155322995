/**
 * Helper function to calculate the percentage of a value
 *
 * @param {number} percentageOf The current value to compare
 * @param {number} percentageFor The total to compare
 * @return {number} The amount returned out of 100. Does not return it as a string
 * as the number may need to be further manipulated
 */
export const calculatePercentage = (percentageOf, percentageFor) => {
    return Math.floor((percentageOf / percentageFor) * 100);
};
