import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from 'react-router-dom';
import moment from "moment";
import _ from 'lodash';

import { filterObjectEmptyValues, paths } from 'lib/';
import { EditAnnouncementContext } from '..';
import { DataTable } from 'components/';

class AnnouncementReport extends React.Component {

    static propTypes = {
        context: PropTypes.object.isRequired,
    }

    /**
     * Gets data for the table
     *
     * @returns {array} the map for state
     */
    getTableData () {
        const { announcement } = this.props.context;

        const groupedEmployees = _.chain(announcement.notice_read)
            .groupBy("employeeid")
            .map((data, employeeId) => {
                const { forenames, surname } = data[0].employee;

                const sortedDates = data.map((date) => moment(date.datetimeread).format("YYYY-MM-DD HH:mm:ss"))
                    .sort();

                const firstView = sortedDates[0];
                const latestView = sortedDates[(sortedDates.length - 1)];
                const recipient = `${forenames} ${surname}`;

                return { employeeId, recipient, firstView, latestView };
            })
            .value();

        const stateMap = groupedEmployees.map((data) => {
            const dataMap = {
                id: parseInt(data.employeeId, 10),
                recipient: {
                    text: data.recipient,
                    // eslint-disable-next-line camelcase
                    link: generatePath(paths.employee, { employee_id: data.employeeId }),
                },
                published: {
                    text: moment(announcement.dateadded).format("YYYY-MM-DD HH:mm:ss"),
                },
                author: {
                    text: `${announcement.addedby.forename} ${announcement.addedby.surname}`,
                },
                firstView: {
                    text: data.firstView,
                },
                latestView: {
                    text: data.latestView,
                },
            };

            return filterObjectEmptyValues(dataMap);
        });

        return (stateMap.filter(Boolean));
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        const colOrder = [
            "recipient",
            "published",
            "author",
            "firstView",
            "latestView",
        ];

        const reportTableHeadings = {
            recipient: "Recipient",
            published: "Date published",
            author: "Published by",
            firstView: "First viewed",
            latestView: "Latest viewed",
        };

        return (
            <DataTable
                key="report"
                colOrder={colOrder}
                data={this.getTableData()}
                headings={reportTableHeadings}
                showCheckBoxes={false}
                searchable={true}
                sortable={true}
            />
        );
    }

}

/* eslint-disable react/no-multi-comp */
// https://github.com/yannickcr/eslint-plugin-react/issues/2172
const component = React.forwardRef((props, ref) => (
    <EditAnnouncementContext.Consumer>
        {(context) => (
            <AnnouncementReport forwardedRef={ref} context={context} {...props} />
        )}
    </EditAnnouncementContext.Consumer>
));

export default withRouter(component);
/* eslint-enable */

