import React from 'react';
import PropTypes from 'prop-types';
import { formatLongDate } from '../../../lib/formattingHelpers';
import getFileDownload from '../../../lib/getFileDownload';
import documentsPropTypes, { documentsDefaultProps } from '../../../lib/props-schema/documentsSchema';
import { TertiaryButton } from "@dataplan/react-components/dist/components/forms";
import PageLayoutContext from "@dataplan/react-components/dist/components/ui/page_layout/PageLayoutContext";

import { defaultAccentColour } from '../../../Colours';

import DataTable from '../../../components/tables/data-table/DataTable';

import styles from './LatestDocumentsSection.module.scss';
import sections from '../assets/ProfileSections.module.scss';

class LatestDocumentsSection extends React.Component {

    static propTypes = {
        latestDocuments: documentsPropTypes,
        classNames: PropTypes.string,
        employeeDocuments: documentsPropTypes,
    }

    static defaultProps = {
        classNames: '',
        latestDocuments: documentsDefaultProps,
        employeeDocuments: documentsDefaultProps,
    }

    /**
     * Maps the document data to an object shape suitable for the data table
     *
     * @param {array} latestDocuments The document data
     *
     * @return {array} The array of document data
     */
    mapDataObject = (latestDocuments) => {
        return latestDocuments.map((latestDocument) => {
            return {
                id: latestDocument.id,
                name: {
                    component: this.getDocumentLink(latestDocument),
                },
                date: {
                    text: formatLongDate(latestDocument.datetime_added),
                },
            };
        });
    }

    /**
     * Creates a clickable element to run function on click
     *
     * @param {object} documentData The document detailed in the row
     *
     * @return {ReactElement} The clickable link
     */
    getDocumentLink = (documentData) => {
        return (
            <button
                className={styles.buttonLink}
                onClick={() => getFileDownload(`/file/personalcompany/${documentData.id}/data`, documentData.filename)}
                type="button"
            >
                {documentData.description}
            </button>
        );
    }

    /**
     * Renders the section content depending if there are any documents available
     *
     * @param {object} context Pagelayout context
     *
     * @return {ReactElement} The content to display
     */
    renderContent = (context) => {
        const { latestDocuments, employeeDocuments } = this.props;

        if (!latestDocuments) {
            return <p>No documents to display</p>;
        }

        const colOrder = ["name", "date"];
        const data = this.mapDataObject(latestDocuments);

        return (
            <>
                <DataTable colOrder={colOrder} data={data} rowHeight="inherit" />
                {(employeeDocuments.length > 10) && (
                    <TertiaryButton
                        className={styles.viewAllButton}
                        accent={defaultAccentColour}
                        text={"View all"}
                        aria-label={"View all"}
                        onClick={(event) => {
                            event.preventDefault();
                            context.setCurrentTab(2);
                        }}
                    />
                )}
            </>
        );
    }

    /**
     * Renders a table of the employees latest documents
     *
     * @return {ReactElement} The employees latest documents section
     */
    render () {
        const { classNames } = this.props;

        return (
            <PageLayoutContext.Consumer>
                {(context) => (
                    <div className={classNames}>
                        <h3 className={sections.title}>Latest Documents</h3>
                        {this.renderContent(context)}
                    </div>
                )}
            </PageLayoutContext.Consumer>
        );
    }

}

export default LatestDocumentsSection;
