import React from 'react';
import PropTypes from 'prop-types';
import { TertiaryButton } from "@dataplan/react-components/dist/components/forms/controls";
import { history } from '../../../redux-store';
import { paths } from "../../../lib";

import ScrollingCardRow from '../../../components/cards/ScrollingCardRow';
import PropertiesCard from '../../../components/cards/PropertiesCard';

import { defaultAccentColour } from '../../../Colours';

import styles from './PayRunsSection.module.scss';
import moment from "moment";

export default class PayRunsSection extends React.Component {

    static propTypes = {
        payRuns: PropTypes.arrayOf(PropTypes.shape({
            payDate: PropTypes.string.isRequired,
            paySlipTotal: PropTypes.number,
            payrollName: PropTypes.string,
            action: PropTypes.func,
        })).isRequired,
    }

    /**
     * Renders the show more button
     *
     * @return {ReactElement} Tertiary button
     */
    showMoreButton () {
        return (
            <div className={styles.buttonContainer}>
                <TertiaryButton
                    accent={defaultAccentColour}
                    onClick={() => history.push(paths.payruns)}
                    text="Show more"
                    className={styles.showMore}
                    aria-label="Show more notifications"
                />
            </div>
        );
    }

    /**
     * Sorts the payRuns by paydate
     *
     * @param {array} payRuns used to make a new array
     * @return {array} the array sorted in descending order by payDate
     */
    sortPayRun = (payRuns) => {
        return payRuns.map((payRun) => {
            return {
                ...payRun,
                payDate: moment(payRun.payDate, "YYYY-MM-DD"),
            };
        }).sort((a, b) => b.payDate.valueOf() - a.payDate.valueOf());
    };

    /**
     * Renders the cards for the scrolled container
     *
     * @return {ReactElement} the cards
     */
    renderCards () {
        const payRuns = this.props.payRuns;
        const sortedPayRuns = this.sortPayRun(payRuns).slice(0, 12);

        return sortedPayRuns.map((payRun) => {
            const { id, payroll_id: payrollId } = payRun.period; // eslint-disable-line camelcase

            return (
                <PropertiesCard key={`${payrollId}-${id}`} payRun={payRun} />
            );
        });
    }

    /**
     * Renders the actions section of the dashboard]
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <>
                <ScrollingCardRow>
                    {this.renderCards()}
                </ScrollingCardRow>
                {this.showMoreButton()}
            </>
        );
    }

}
