import React from 'react';

export default class CloseSolid extends React.Component {

    /**
     * Renders the icon component
     *
     * @return {ReactElement} The icon element
     */
    render () {
        return (
            /* eslint-disable max-len */
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.5 24.5" {...this.props}>
                <path d="M12.25 2.042A10.208 10.208 0 1 0 22.459 12.25 10.2 10.2 0 0 0 12.25 2.042zm5.1 13.873l-1.439 1.439-3.665-3.665-3.66 3.665-1.444-1.439 3.665-3.665-3.665-3.664 1.444-1.444 3.665 3.665 3.664-3.665 1.439 1.439-3.665 3.665z" />
            </svg>
            /* eslint-enable */
        );
    }

}
