import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Filter from './assets/Filter';

import styles from './DataTableFilter.module.scss';

export default class DataTableFilter extends React.Component {

    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
        filterable: PropTypes.object,
        filtersSelected: PropTypes.object,
        onFilterSelect: PropTypes.func,
        onFiltersClear: PropTypes.func,
    }

    static defaultProps = {
        filterable: {},
        filtersSelected: {},
        onFilterSelect: null,
        onFiltersClear: null,
    }

    /**
     * Creates an instance of the data table filters
     *
     * @param {object} props The filters properties
     */
    constructor (props) {
        super(props);

        this.state = {
            filterList: {},
        };
    }

    /**
     * Invoked immediately after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        const { filterable: filters, data } = this.props;

        this.generateFilterLists(filters, data);
    }

    /**
     * Invoked when the component updates
     *
     * @param {object} prevProps The components previous properties
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        const { filterable: filters, data } = this.props;

        if (data !== prevProps.data) {
            this.generateFilterLists(filters, data);
        }
    }

    /**
     * Creates a list of filter values based on data and filterable
     * columns
     *
     * @param {object} filters The filters to create
     * @param {object} data The data fed into the data table
     *
     * @return {void}
     */
    generateFilterLists = (filters, data) => {
        let filterList = [];
        let filtersWrapper = _(filters);
        let dataWrapper = _(data);

        filtersWrapper.each((text, filterKey) => {
            let values = dataWrapper
                .map((row) => {
                    let value = _.head(_.filter(row, (cell, cellKey) => {
                        return cellKey === filterKey;
                    }));

                    return (value.filterValues)
                        ? value.filterValues
                        : value.text;
                })
                .flattenDeep()
                .filter((value) => {
                    return value !== "-";
                })
                .uniq()
                .sort();

            filterList.push({
                text,
                filterKey,
                values: values.value(),
            });
        });

        this.setState({
            filterList,
        });
    }

    /**
     * Renders the filter areas to the DOM
     *
     * @return {ReactElement} The filter areas
     */
    render () {
        const { filterable: filters, data, onFilterSelect, filtersSelected, onFiltersClear } = this.props;
        const { filterList } = this.state;

        if (_.isEmpty(filters) || _.isEmpty(data)) {
            return null;
        }

        const activeFilters = (_.isEmpty(filtersSelected))
            ? false
            : _.some(filtersSelected, (filter) => {
                return _.size(_.filter(filter)) > 0;
            });

        return (
            <div className={styles.filterContainer}>
                { activeFilters
                    && (
                        <button
                            type="button"
                            onClick={() => onFiltersClear("all")}
                            className={styles.clearFilters}
                        >
                            Clear Filter(s)
                        </button>
                    )
                }
                {_.map(filterList, (filter) => {
                    if (_.isEmpty(filter.values)) {
                        return null;
                    }

                    return (
                        <Filter
                            key={filter.filterKey}
                            filter={filter}
                            onFilterSelect={onFilterSelect}
                            filtersSelected={filtersSelected[filter.filterKey]}
                            onFiltersClear={onFiltersClear}
                        />
                    );
                })}
            </div>
        );
    }

}
