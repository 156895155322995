import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from 'react-router-dom';
import appState from 'state/App';
import axios from 'axios';
import api from '../../lib/api';
import _ from 'lodash';
import moment from "moment";

import { paths } from "../../lib";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { filterObjectEmptyValues } from 'lib/';
import { DataTable } from 'components/';

class CompanyDocumentReports extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            docDetails: [],
            employeesRead: [],
            dataLoaded: false,
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        const currentDocId = _.toNumber(this.props.match.params.document_id);
        this.handleApiCall(currentDocId);
    }

    /**
     * Called once the component has loaded, queries the API for company docs
     *
     * @param {number} currentDocId the id of the currently shown document
     *
     * @return {void}
     */
    handleApiCall (currentDocId) {
        axios.all([
            api.get(`/file/company/${currentDocId}`),
            api.get(`/file/company/${currentDocId}/hit-log`, {
                params: {
                    aggregate: 1,
                },
            }),
        ]).then(axios.spread((currentDoc, employeeHitLog) => {
            this.setState({
                docDetails: currentDoc.data,
                employeesRead: this.mapEmployeeReadToState(employeeHitLog, currentDoc.data),
                dataLoaded: true,
            });
        }));
    }

    /**
     * Maps the document array to a shape used in the data table
     *
     * @param {array} data An array of employees
     * @param {object} currentDoc An object of the current
     *
     * @return {void}
     */
    mapEmployeeReadToState (data, currentDoc) {
        const stateMap = (data.data).map((hitLogs) => {
            const dataMap = {
                id: parseInt(hitLogs.employee.id, 10),
                recipient: {
                    text: `${hitLogs.employee.forenames} ${hitLogs.employee.surname}`,
                    // eslint-disable-next-line camelcase
                    link: generatePath(paths.employee, { employee_id: hitLogs.employee.id }),
                },
                published: {
                    text: moment(currentDoc.datetime_added).format("YYYY-MM-DD HH:mm:ss"),
                },
                author: {
                    text: `${currentDoc.uploader.forename} ${currentDoc.uploader.surname}`,
                },
                firstView: {
                    text: moment(hitLogs.first_read).format("YYYY-MM-DD HH:mm:ss"),
                },
                latestView: {
                    text: moment(hitLogs.last_read).format("YYYY-MM-DD HH:mm:ss"),
                },
            };

            return filterObjectEmptyValues(dataMap);
        });

        return (stateMap.filter(Boolean));
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        const { docDetails, dataLoaded, employeesRead } = this.state;
        const title = dataLoaded ? docDetails.description : "Document report";

        const colOrder = [
            "recipient",
            "published",
            "author",
            "firstView",
            "latestView",
        ];

        const reportTableHeadings = {
            recipient: "Recipient",
            published: "Date published",
            author: "Published by",
            firstView: "First viewed",
            latestView: "Latest viewed",
        };

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    heading={{
                        text: `${title}`,
                        size: "h1",
                    }}
                    pageType="boxed"
                    maxWidth={1080}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                >
                    <DataTable
                        colOrder={colOrder}
                        key="all-company-docs"
                        data={employeesRead}
                        dataLoaded={dataLoaded}
                        headings={reportTableHeadings}
                        sortable={true}
                        searchable
                    />
                </PageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(CompanyDocumentReports));
