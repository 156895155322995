import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import api from 'lib/api';
import appState from 'state/App';
import _ from 'lodash';
import classNames from 'classnames';

import {
    DefaultForm,
    MultiFileInput,
    TextInputRow,
    TextAreaRow,
    PrimaryButton,
    SecondaryButton,
    SearchInputRow,
} from "@dataplan/react-components/dist/components/forms";

import { defaultAccentColour } from '../../../Colours';

import styles from "./CreateMessage.module.scss";

class CreateMessage extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        closeDrawer: PropTypes.func,
        refreshData: PropTypes.func,
    }

    static defaultProps = {
        closeDrawer: _.noop,
        refreshData: _.noop,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.companyIdRef = props.appState.payrolls.reduce((acc, payroll) => {
            acc[payroll.id] = `${payroll.company_id}`;

            return acc;
        }, {});

        this.state = this.getInitialState();
    }

    /**
     * Called just after the component is added to the DOM
     *
     * @return {object} The initial state
     */
    getInitialState () {
        const { companies } = this.props.appState;

        const initialState = {
            companyId: {
                hasChanged: false,
                valid: false,
                value: null,
            },
            employeeId: {
                hasChanged: false,
                valid: false,
                value: null,
            },
            message: {
                hasChanged: false,
                valid: false,
                value: "",
            },
            subject: {
                hasChanged: false,
                valid: false,
                value: "",
            },
            files: {},
        };

        if (companies.length === 1) {
            initialState.companyId = {
                hasChanged: true,
                valid: true,
                value: `${companies[0].id}`,
            };
        }

        return initialState;
    }

    /**
     * Resets form to intial state
     *
     * @return {void}
     */
    reset = () => {
        const { closeDrawer, refreshData } = this.props;

        this.setState(this.getInitialState(), () => {
            appState.unblockNavigation();
            closeDrawer();
            refreshData();
        });
    }

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The onSubmit event
     *
     * @return {void} submits the form if valid
     */
    handleSubmit = (event) => {
        const attachmentsForm = event.target;
        event.preventDefault();

        const {
            employeeId: { value: employeeId },
            message: { value: message },
            subject: { value: subject },
        } = this.state;

        api.post("/legacy_message", {
            employee_id: employeeId, // eslint-disable-line camelcase
            message,
            subject,
        })
            .then(({ data: { id } }) => this.saveAttachments(id, attachmentsForm))
            .then(() => {
                const { employees } = this.props.appState;
                const employee = _.find(employees, (item) => item.id === parseInt(employeeId, 10));

                appState.addNotification({
                    text: `Message successfully sent to ${employee.name}`,
                    type: "success",
                    duration: 5,
                }, this.reset);
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to create message`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Returns the save attachment promise. Files aren't always attached so need to check before attempting
     * to submit nothing to avoid errors
     *
     * @param {number} id The message id
     * @param {HTMLFormElement} attachmentsForm The html form
     *
     * @return {Promise} Save attachments promise
     */
    saveAttachments = (id, attachmentsForm) => {
        const { files } = this.state;

        return new Promise((resolve, reject) => {
            if (_.isEmpty(files)) {
                resolve();
                return;
            }

            const data = new FormData(attachmentsForm);

            api.post(`/legacy_message/${id}/attachments`, data)
                .then(resolve)
                .catch(reject);
        });
    }

    /**
     * Handles change of input values
     *
     * @param {string} type The type of data changed
     * @param {number} value The new value
     *
     * @return {void}
     */
    handleInputChange = (type, value) => {
        appState.blockNavigation();
        let valid = true;

        if (type === "subject" || type === "message") {
            valid = Boolean(value.length);
        }

        this.setState({
            [type]: {
                hasChanged: true,
                valid,
                value,
            },
        });
    }

    /**
     * Called when a change is made to the list of files
     *
     * @param {array} fileList The new file list
     *
     * @return {void}
     */
    handleFileChange = (fileList) => {
        this.setState({
            files: fileList,
        });
    }

    /**
     * Check if the form can be submitted
     *
     * @return {boolean} If the form can be submitted
     */
    checkCanSubmit () {
        const {
            companyId,
            employeeId,
            message,
            subject,
        } = this.state;

        return (companyId.value && employeeId.value && message.valid && subject.valid);
    }

    /**
     * Renders the company section
     *
     * @return {ReactElement} The company row
     */
    renderCompanySection = () => {
        const { companies } = this.props.appState;
        const { companyId } = this.state;

        if (companies.length <= 1) {
            return null;
        }

        return (
            <SearchInputRow
                array={companies}
                name={"companyId"}
                label={"Organization"}
                onChange={(event) => this.handleInputChange("companyId", event.target.value)}
                value={companyId.value}
                searchable={true}
                branded={true}
            />
        );
    }

    /**
     * Renders the employee section
     *
     * @return {ReactElement} The employee row
     */
    renderEmployeeSection = () => {
        const { employees } = this.props.appState;
        const { companyId, employeeId } = this.state;

        if (!companyId.value) {
            return null;
        }

        const filteredEmployees = employees.filter((item) => {
            return (this.companyIdRef[item.payroll_id] === companyId.value);
        });

        return (
            <SearchInputRow
                array={filteredEmployees}
                name={"employeeId"}
                label={"Employee"}
                onChange={(event) => this.handleInputChange("employeeId", event.target.value)}
                value={employeeId.value}
                searchable={true}
                branded={true}
            />
        );
    }

    /**
     * Renders the subject section
     *
     * @return {ReactElement} The subject row
     */
    renderSubjectSection = () => {
        const { employeeId, subject: { hasChanged, valid, value} } = this.state;

        if (!employeeId.value) {
            return null;
        }

        const classList = classNames(styles.textInput, {
            [styles.error]: (!valid && hasChanged),
        });
        const titleError = (!valid && hasChanged) ? "Title is required" : "";

        return (
            <TextInputRow
                name="subject"
                label="Subject"
                type="text"
                onChange={(event) => this.handleInputChange("subject", event.target.value)}
                value={value}
                inputClassName={classList}
                errorText={titleError}
            />
        );
    }

    /**
     * Renders the message section
     *
     * @return {ReactElement} The message row
     */
    renderMessageSection = () => {
        const { employeeId, message: { hasChanged, valid, value} } = this.state;

        if (!employeeId.value) {
            return null;
        }

        const classList = classNames(styles.textArea, {
            [styles.error]: (!valid && hasChanged),
        });
        let messageError = (!valid && hasChanged) ? "Message is required" : "";

        return (
            <div className={styles.textAreaRow}>
                <TextAreaRow
                    name="message"
                    label="Message"
                    onChange={(event) => this.handleInputChange("message", event.target.value)}
                    value={value}
                    inputClassName={classList}
                    errorText={messageError}
                />
            </div>
        );
    }

    /**
     * Renders the file input
     *
     * @return {ReactElement} The file input row
     */
    renderFileInput () {
        const { employeeId } = this.state;

        if (!employeeId.value) {
            return null;
        }

        return (
            <MultiFileInput
                initialFiles={this.state.files}
                onChange={this.handleFileChange}
                fileListClassName={styles.fileList}
                label={null}
                name="attachments[]"
                multiple
            />
        );
    }

    /**
     * Renders the buttons section
     *
     * @return {ReactElement} The buttons section
     */
    renderButtonsSection = () => {
        const canSubmit = this.checkCanSubmit();

        return (
            <div className={styles.buttons}>
                <PrimaryButton
                    aria-label={"Submit"}
                    className={styles.button}
                    disabled={!canSubmit}
                    type="submit"
                >
                    Create Message
                </PrimaryButton>
                <SecondaryButton
                    accent={defaultAccentColour}
                    aria-label={"Cancel"}
                    className={styles.secondaryButton}
                    onClick={this.props.closeDrawer}
                    text={"Cancel"}
                />
            </div>
        );
    }

    render = () => {
        return (
            <>
                {this.renderCompanySection()}
                {this.renderEmployeeSection()}
                {this.renderSubjectSection()}
                {this.renderMessageSection()}
                <DefaultForm onSubmit={this.handleSubmit}>
                    {this.renderFileInput()}
                    {this.renderButtonsSection()}
                </DefaultForm>
            </>
        );
    }

}

export default withRouter(appState.attachState(CreateMessage));
