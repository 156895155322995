import React from 'react';
import PropTypes from 'prop-types';

import './ContentContainer.scss';

export default class ContentContainer extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
    }

    /**
     * Renders the page container
     *
     * @return {ReactElement} The container
     */
    render () {
        return (
            <div className="content-container">
                {this.props.children}
            </div>
        );
    }

}
