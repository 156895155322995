import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import TabCard from '../../../components/cards/TabCard';
import TabContent from '../../../components/cards/TabContent';

export default class TabsSection extends React.Component {

    /**
     * Renders the tooltip section
     *
     * @return {ReactElement} The section
     */
    render () {
        return (
            <CardRow>
                <CardRowContainer gridWidth={12}>
                    <TabCard
                        heading="Tabs"
                        tabs={[
                            {
                                text: "Paysips",
                                component: <TabContent>Payslip content</TabContent>,
                            },
                            {
                                text: "Roles",
                                component: <TabContent>Contract content</TabContent>,
                            },
                            {
                                text: "Allowances",
                                component: <TabContent>Allowance content</TabContent>,
                            },
                        ]}
                    />
                </CardRowContainer>
            </CardRow>
        );
    }

}
