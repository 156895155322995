import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { paths } from "../lib";
import classNames from "classnames";

import appState from '../state/App';

import AnimateTextUnderline from "./AnimateTextUnderline";

import styles from './MainMenu.module.scss';

class MainMenu extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Creates an instance of the main app menu
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.containerRef = React.createRef();
    }

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        document.addEventListener("click", this.handleDocumentClick);
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    /**
     * Called when a click happens anywhere
     *
     * @param {Event} event The click event
     *
     * @return {void}
     */
    handleClickInMenu (event) {
        const clickInMenu = this.containerRef.current.contains(event.target);

        if (!clickInMenu && this.props.appState.mainMenuOpen) {
            appState.setMainMenuOpen(false);
        }
    }

    /**
     * Called when a click happens anywhere
     *
     * @param {Event} event The click event
     *
     * @return {void}
     */
    handleDocumentClick = (event) => {
        // Do nothing if the menu is not in the DOM
        if (event.defaultPrevented && !this.containerRef.current) {
            return;
        }

        if (document.querySelector("#menuButton").contains(event.target)) {
            return;
        }

        this.handleClickInMenu(event);
    }

    /**
     * Called when one of the navigation links is clicked
     *
     * @return {void}
     */
    handleLinkClick = () => {
        appState.setMainMenuOpen(false);
    }

    /**
     * Get a list of class names to be used in styling the link and underline components
     *
     * @return {object} An object of class names
     */
    getClassLists () {
        const linkClass = classNames({
            [styles.menuLink]: true,
            [styles.unbranded]: true,
        });
        const activeLinkClass = classNames({
            [styles.active]: true,
        });
        const underlineComponentClass = {
            underline: styles.underline,
            underlineFill: styles.underlineFill,
        };

        return {
            linkClass,
            activeLinkClass,
            underlineComponentClass,
        };
    }

    /**
     * Renders a menu link
     *
     * @param {string} to The URL to link to
     * @param {string} label The text to show
     *
     * @return {void}
     */
    renderMenuLink (to, label) {
        const classList = this.getClassLists();

        return (
            <NavLink
                exact to={to}
                className={classList.linkClass}
                activeClassName={classList.activeLinkClass}
                onClick={this.handleLinkClick}
            >
                <AnimateTextUnderline classList={classList.underlineComponentClass}>
                    <div className={styles.underlineText}>{label}</div>
                </AnimateTextUnderline>
            </NavLink>
        );
    }

    /**
     * Renders the main menu
     *
     * @return {ReactElement} The menu element
     */
    render () {
        const containerClass = (this.props.appState.mainMenuOpen)
            ? styles.openMenuContainer
            : styles.closedMenuContainer;

        return (
            <div
                aria-label="Main Menu"
                className={containerClass}
                role="navigation"
                ref={this.containerRef}
            >
                <div className={styles.header}>
                    <span className={styles.welcomeMessage}>Welcome</span>
                </div>
                <nav className={styles.navContainer}>
                    {this.renderMenuLink(paths.dashboard, "Dashboard")}
                    {this.renderMenuLink(paths.payruns, "Pay Runs")}
                    {this.renderMenuLink(paths.employees, "Employees")}
                    {this.renderMenuLink(paths.companyDocuments, "Documents")}
                    {this.renderMenuLink(paths.announcements, "Announcements")}
                    {this.renderMenuLink(paths.messages, "Messages")}
                </nav>
            </div>
        );
    }

}

export default appState.attachState(MainMenu);
