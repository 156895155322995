import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import InputMoment from "input-moment";
import classNames from "classnames";
import windowScroll from '../../lib/domWindowScroll';

import Icon from "../icons/Icon";
import SecondaryButton from "../forms/controls/SecondaryButton";

import styles from "./InlineEditDateCell.module.scss";

export default class InlineEditDateCell extends React.Component {

    static propTypes = {
        children: PropTypes.func.isRequired,
        value: PropTypes.object.isRequired,
        editLabel: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
    }

    static defaultProps = {
        editLabel: "Edit",
        onChange: _.noop,
        className: null,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Input data
     */
    constructor (props) {
        super(props);

        this.cellRef = React.createRef();
        this.calendarRef = React.createRef();

        this.state = {
            editing: false,
        };
    }

    /**
     * Sets the position of the calendar control next to the input cell
     */
    positionCalendar = () => {
        const cellRect = this.cellRef.current.getBoundingClientRect();

        const top = cellRect.top + windowScroll.getY();
        const left = cellRect.left + windowScroll.getX();

        this.calendarRef.current.style.top = `${top}px`;
        this.calendarRef.current.style.left = `${left}px`;
    }

    /**
     * Sets if the value is currently being edited
     *
     * @param {boolean} enabled If editing is enabled
     * @param {function} callback Function to call after the state has been updated
     */
    toggleEditing = (enabled, callback = undefined) => {
        this.setState({ editing: enabled }, callback);
    }

    /**
     * Called when the edit button is clicked
     */
    handleEditClick = () => {
        this.toggleEditing(true, this.positionCalendar);
    }

    /**
     * Called when the close button is clicked
     */
    handleCloseClick = () => {
        this.toggleEditing(false);
    }

    /**
     * Called when the document is clicked
     *
     * @param {Event} event The DOM event
     */
    handleDocumentClick = (event) => {
        const cell = this.cellRef.current;
        const calendar = this.calendarRef.current;

        if (!cell || !calendar) {
            return;
        }

        if (cell.contains(event.target) || calendar.contains(event.target)) {
            return;
        }

        this.toggleEditing(false);
    }

    /**
     * Called when the component is added to the DOM
     */
    componentDidMount () {
        window.addEventListener("click", this.handleDocumentClick);
    }

    /**
     * Called just before the component is removed from the DOM
     */
    componentWillUnmount () {
        window.removeEventListener("click", this.handleDocumentClick);
    }

    /**
     * Renders the edit fornm mask over the cell
     *
     * @return {ReactElement} The cell mask
     */
    renderEditMask () {
        if (!this.state.editing) {
            return null;
        }

        return (
            <div className={styles.editMask}>
                <button
                    type="button"
                    className={styles.closeEditButton}
                    onClick={this.handleCloseClick}
                >
                    <Icon icon="Remove" className={styles.closeIcon} />
                </button>
                {ReactDOM.createPortal(
                    <div ref={this.calendarRef} className={styles.calendarContainer}>
                        <InputMoment
                            moment={this.props.value}
                            onChange={this.props.onChange}
                        />
                    </div>,
                    document.body
                )}
            </div>
        );
    }

    /**
     * Renders the inline edit cell
     *
     * @return {ReactElement} The table cell
     */
    render () {
        const wrapperClass = classNames([
            styles.wrapper,
            this.props.className,
        ]);

        return (
            <td className={styles.cell} ref={this.cellRef}>
                <div className={wrapperClass}>
                    <div className={styles.content}>
                        {this.props.children(this.props.value)}
                    </div>
                    <SecondaryButton
                        className={styles.editButton}
                        onClick={this.handleEditClick}
                    >
                        {this.props.editLabel}
                    </SecondaryButton>
                    {this.renderEditMask()}
                </div>
            </td>
        );
    }

}
