import React from 'react';
import PropTypes from 'prop-types';
import reactHtmlParser from 'react-html-parser';
import classNames from 'classnames';

import styles from './TemplateParagraph.module.scss';

class TemplateParagraph extends React.Component {

    static propTypes = {
        value: PropTypes.string,
        isPreview: PropTypes.bool,
        showInPreview: PropTypes.bool,
        isItalic: PropTypes.bool,
    };

    static defaultProps = {
        value: null,
        isPreview: false,
        showInPreview: false,
        isItalic: false,
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { value, isPreview, showInPreview, isItalic } = this.props;
        const classList = classNames(styles.templateParagraph, [
            isItalic && styles.italic,
        ]);

        if ((isPreview && !showInPreview) || !value) {
            return null;
        }

        return <p className={classList}>{reactHtmlParser(value)}</p>;
    }

}

export default TemplateParagraph;
