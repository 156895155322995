import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Alert.module.scss';

export default class Alert extends React.Component {

    static propTypes = {
        type: PropTypes.oneOf(["warn", "success", "error", "info"]).isRequired,
        message: PropTypes.string,
        onClose: PropTypes.func,
        children: PropTypes.any,
        className: PropTypes.string,
    }

    static defaultProps = {
        onClose: null,
        message: '',
        children: null,
        className: '',
    }

    /**
     * Gets the icon that should be shown on the alert card
     *
     * @param {string} alertType The type of alert
     *
     * @return {ReactElement} The
     */
    getIcon (alertType) {
        switch (alertType) {
        case "success":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16.193">
                    <path d="M16.994 0L6.809 10.19 3 6.383 0 9.388l6.8 6.8.879-.876L20 3z" />
                </svg>
            );
        case "warn":
        case "error":
            /* eslint-disable max-len */
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 20.462">
                    <path d="M11.5 2.34a2.195 2.195 0 0 1 1.807 1.243l6.917 11.53c1 1.656.227 3.01-1.7 3.01H4.48c-1.931 0-2.7-1.356-1.7-3.01l6.917-11.53a2.195 2.195 0 0 1 1.807-1.243m0-2.34a4.49 4.49 0 0 0-3.817 2.376L.77 13.908a4.375 4.375 0 0 0-.275 4.481 4.38 4.38 0 0 0 3.985 2.073h14.04a4.378 4.378 0 0 0 3.986-2.073 4.377 4.377 0 0 0-.277-4.48L15.313 2.38A4.486 4.486 0 0 0 11.5 0z" />
                    <circle cx="11.5" cy="14.612" r="1.521" />
                    <path d="M13.255 7.592a1.755 1.755 0 1 0-3.38.658c.648 1.61 1.625 4.023 1.625 4.023l1.628-4.023a1.8 1.8 0 0 0 .127-.658z" />
                </svg>
            );
            /* eslint-enable */
        case "info":
            /* eslint-disable max-len */
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                    <circle cx="13" cy="13" r="12" />
                    <path d="M10.365 8.184A2.184 2.184 0 1 0 12.549 6a2.183 2.183 0 0 0-2.184 2.184zm0 4.64v4.913h-.682a.682.682 0 1 0 0 1.365h5.728a.682.682 0 1 0 0-1.365h-.682V12.06a.6.6 0 0 0-.6-.6H9.68a.682.682 0 0 0 0 1.365h.682z" />
                </svg>
            );
            /* eslint-enable */
        default:
            return null;
        }
    }

    /**
     * Gets the close icon for the alert
     *
     * This is split from the render method so that long lines
     * in the SVG can be ignored without having to wrap everything.
     *
     * @return {ReactElement} The SVG element
     */
    getCloseIcon () {
        /* eslint-disable max-len */
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.5 24.5">
                <path d="M12.25 2.042A10.208 10.208 0 1 0 22.459 12.25 10.2 10.2 0 0 0 12.25 2.042zm5.1 13.873l-1.439 1.439-3.665-3.665-3.66 3.665-1.444-1.439 3.665-3.665-3.665-3.664 1.444-1.444 3.665 3.665 3.664-3.665 1.439 1.439-3.665 3.665z" />
            </svg>
        );
        /* eslint-enable */
    }

    /**
     * Renders the alert component
     *
     * @return {ReactElement} The alert component
     */
    render () {
        const { message, children, onClose, className } = this.props;
        const classList = classNames([
            styles.container,
            styles[this.props.type],
            className,
        ]);

        return (
            <div className={classList}>
                <div className={styles.icon}>
                    {this.getIcon(this.props.type)}
                </div>
                {onClose && (
                    <button type="button" className={styles.closeButton} onClick={this.props.onClose}>
                        {this.getCloseIcon()}
                    </button>
                )}
                <div className={styles.message}>
                    {message || children}
                </div>
            </div>
        );
    }

}
