import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { ActionMenu, Action, Badge } from '../../';

import styles from './DataTableBulkActions.module.scss';

export default class DataTableBulkActions extends React.Component {

    static propTypes = {
        actions: PropTypes.object.isRequired,
        checked: PropTypes.object,
    }

    static defaultProps = {
        checked: {},
    }

    /**
     * Gets the text to add to the badge for number of employees selected
     *
     * @param {array} checked The array of checked IDs
     *
     * @return {string} The text for the badge
     */
    getBadgeValue = (checked) => {
        return `${checked.length} Selected`;
    }

    /**
     * Renders bulk actions
     *
     * @return {ReactElement} The bulk actions
     */
    renderActions () {
        const { actions, checked } = this.props;
        let checkedArray = [];

        _.forEach(checked, (value, key) => {
            if (value) {
                checkedArray.push(_.toNumber(key));
            }
        });

        const classList = classNames([
            styles.bulkActions,
            styles[(checkedArray.length > 0) ? 'visable' : 'hidden'],
        ]);

        if (typeof actions === 'undefined') {
            return null;
        }

        return (
            <div className={classList}>
                <Badge label="" value={this.getBadgeValue(checkedArray)} />
                <ActionMenu label={actions.label || "Bulk Actions"}>
                    { actions.actions.map((action, i) => {
                        return (
                            <Action
                                key={action.label}
                                label={action.label}
                                action={() => action.callback(checkedArray)}
                            />
                        );
                    })}
                </ActionMenu>
            </div>
        );
    }

    /**
     * Renders bulk actions
     *
     * @return {ReactElement} The bulk actions
     */
    render () {
        const { actions, checked } = this.props;
        let checkedArray = [];

        _.forEach(checked, (value, key) => {
            if (value) {
                checkedArray.push(_.toNumber(key));
            }
        });

        return (actions && checkedArray.length) ? this.renderActions() : null;
    }

}
