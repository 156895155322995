import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

import { FullArrow } from "@dataplan/react-components/dist/components/icons";

import styles from "./YearScroller.module.scss";

class YearScroller extends React.Component {

    static propTypes = {
        align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
        year: PropTypes.object.isRequired,
        updateTaxYear: PropTypes.func.isRequired,
        maxYear: PropTypes.object,
        minYear: PropTypes.object,
    }

    static defaultProps = {
        align: "left",
        maxYear: null,
        minYear: null,
    }

    /**
     * Called when the previous year button is clicked
     */
    handleBackClick = () => {
        this.props.updateTaxYear("subtract", 1);
    }

    /**
     * Called when the next year button is clicked
     */
    handleForwardClick = () => {
        this.props.updateTaxYear("add", 1);
    }

    /**
     * Formats the current tax year to display in the menu
     *
     * @return {string} The formatted year
     */
    formatTaxYear () {
        const year = this.props.year.year();
        const yearStart = year.toString();
        const yearEnd = (year + 1).toString();

        return `${yearStart}/${yearEnd.substr(2)}`;
    }

    /**
     * Renders the month scroller component
     *
     * @return {ReactElement} The element
     */
    render () {
        const { align, minYear, maxYear, year } = this.props;
        const classList = classNames([
            styles.container,
            styles[align],
        ]);

        return (
            <div className={classList}>
                <button
                    type="button"
                    disabled={year.year() <= minYear.year()}
                    className={styles.button}
                    onClick={this.handleBackClick}
                >
                    <FullArrow
                        aria-label="Previous Year"
                        width={24}
                        height={24}
                        classlist={styles.iconLeft}
                    />
                </button>
                <span className={styles.label}>
                    {this.formatTaxYear()}
                </span>
                <button
                    type="button"
                    disabled={year.year() >= maxYear.year()}
                    className={styles.button}
                    onClick={this.handleForwardClick}
                >
                    <FullArrow
                        aria-label="Next Year"
                        width={24}
                        height={24}
                        classlist={styles.iconRight}
                    />
                </button>
            </div>
        );
    }

}

export default withRouter(YearScroller);
