class clientRect {

    /**
     * Gets the X value of getBoundingClientRect, returns the left value
     * if X is not supported in browser
     *
     * @param {object} domClientRect The getBoundingClientRect object
     *
     * @return {number} The X value
     */
    static getX = (domClientRect) => {
        return (domClientRect.x !== undefined)
            ? domClientRect.x
            : domClientRect.left;
    }

    /**
     * Gets the Y value of getBoundingClientRect, returns the top value
     * if Y is not supported in browser
     *
     * @param {object} domClientRect The getBoundingClientRect object
     *
     * @return {number} The Y value
     */
    static getY = (domClientRect) => {
        return (domClientRect.y !== undefined)
            ? domClientRect.y
            : domClientRect.top;
    }

}

export default clientRect;
