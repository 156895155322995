import React from 'react';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';

import PrimaryButton from '../forms/controls/PrimaryButton';

import styles from './DocumentPdfDownload.module.scss';

class DocumentPdfDownload extends React.Component {

    static propTypes = {
        documentPdf: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
    }

    /**
     * Retrieves the raw PDF from the API and returns it to be downloaded by the browser
     *
     * @return {void}
     */
    handleDownload = () => {
        const { documentPdf, title } = this.props;

        fileDownload(documentPdf, `${title}.pdf`, 'application/pdf');
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <PrimaryButton
                className={styles.downloadButton}
                onClick={this.handleDownload}
            >
                Download
            </PrimaryButton>
        );
    }

}

export default DocumentPdfDownload;
