import api from '../api';
import appState from '../../state/App';

/**
 * Handle deleting the file through the API
 *
 * @param {number} docId The ID of the document
 * @param {string} docType The type of document
 * @param {string} docTitle The display name of the file
 * @param {function} redrawCallback The callback from the HOC to redraw page after change to data
 *
 * @return {void}
 *
 * @private
 */
const handleSingleDelete = (docId, docType, docTitle, redrawCallback) => {
    api.delete(`/file/${docType}/${docId}`)
        .then(() => {
            appState.addNotification({
                text: `${docTitle} deleted`,
                type: "success",
                duration: 5,
            }, redrawCallback);
        }).catch(() => {
            appState.addNotification({
                text: `Failed to delete file ${docTitle}`,
                type: "error",
                duration: 0,
            });
        });
};

/**
 * Prompts the user to confirm deleting a file
 *
 * @param {number} docId The ID of the document
 * @param {string} docType The type of document
 * @param {?string} docTitle The title of the document
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 *
 * @private
 */
const confirmDeleteDoc = (docId, docType, docTitle, redrawCallback, createSnackBarConfirmation) => {
    const displayTitle = (docTitle) ? docTitle : "This document";

    createSnackBarConfirmation({
        type: "warn",
        heading: `${displayTitle} will be permanently deleted`,
        message: "Are you sure you want to delete this document?",
        onConfirm: () => handleSingleDelete(docId, docType, displayTitle, redrawCallback),
    });
};

/**
 * Handles deleting a document
 *
 * @param {number} docId The ID of the document
 * @param {string} docType The type of document
 * @param {?string} docTitle The title of the document
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 */
const handleDeletingDocument = (
    docId,
    docType,
    docTitle = null,
    redrawCallback = null,
    createSnackBarConfirmation = null
) => {
    if (!docId || !docType) {
        return;
    }

    confirmDeleteDoc(docId, docType, docTitle, redrawCallback, createSnackBarConfirmation);
};

export default handleDeletingDocument;
