import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../lib/api';
import appState from "../../../state/App";
import employeePropTypes from '../../../lib/props-schema/employeeDetailsSchema';
import { formatShortDate, formatDateTime } from '../../../lib/formattingHelpers';
import _ from 'lodash';
import { getFullPayrollName } from "lib/";
import { StringHelper } from '../../../lib/StringHelper';
import { InlineEdit, StatusIndicator, PrimaryButton } from '../../../components';
import axios from "axios";

import styles from './PersonalDetailsSection.module.scss';
import sections from '../assets/ProfileSections.module.scss';

class PersonalDetailsSection extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        employeeDetails: employeePropTypes.isRequired,
        classNames: PropTypes.string,
        editable: PropTypes.bool,
        handleRedraw: PropTypes.func,
    }

    static defaultProps = {
        classNames: '',
        editable: false,
        handleRedraw: null,
    }

    /**
     * Invoked before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Called when the send welcome email button is clicked
     * Starts the process to call the API to send the email
     *
     * @return {void}
     */
    handleSendingActivationInstructions = () => {
        const { employeeDetails } = this.props;
        const employeeName = new StringHelper(employeeDetails.name).parseName();

        const notificationText = {
            successful: `Welcome email sent to ${employeeName}`,
            alreadySent: `Welcome email already sent to ${employeeName}`,
            failed: `Failed to send welcome email to ${employeeName}`,
        };

        this.handleApiCall(employeeDetails.id, notificationText);
    }

    /**
     * Handle the API call to send the signup instructions email
     *
     * @param {number} employeeId The employees ID
     * @param {object} notificationText The various outcome text to use in toasts
     *
     * @return {void}
     */
    handleApiCall = (employeeId, notificationText) => {
        api.put('/employees/signup_instructions', {
            "employee_ids": [employeeId],
        }, { component: this }).then(({ data }) => {
            if (data.successful.length > 0) {
                appState.addNotification({
                    text: notificationText.successful,
                    type: "success",
                    duration: 5,
                });
            }

            if (data.already_sent.length > 0) {
                appState.addNotification({
                    text: notificationText.alreadySent,
                    type: "info",
                    duration: 5,
                });
            }

            if (data.failed.length > 0) {
                appState.addNotification({
                    text: notificationText.failed,
                    type: "error",
                    duration: 0,
                });
            }
        }).catch((thrown) => {
            if (!axios.isCancel(thrown)) {
                appState.addNotification({
                    text: notificationText.failed,
                    type: "error",
                    duration: 0,
                });
            }
        });
    }

    /**
     * Returns the employees phone numbers encased in HTML for formatting
     *
     * @param {object} employee The employees details returned from the API
     *
     * @return {element} The employees phone numbers
     */
    getPhoneNumbers = (employee) => {
        const numbers = employee.phone_numbers;
        const totalNums = _.size(numbers);

        if (_.isEmpty(numbers)) {
            return "-";
        }

        return _.map(numbers, (line, key) => {
            return this.getLine(line, key, totalNums);
        });
    }

    /**
     * Returns the employees email addresses encased in HTML for formatting
     *
     * @param {object} employee The employees details returned from the API
     *
     * @return {element} The employees email addresses
     */
    getEmails = (employee) => {
        const emails = employee.emails;
        const totalEmails = _.size(emails);

        if (_.isEmpty(emails)) {
            return "-";
        }

        return _.map(emails, (line, key) => {
            return this.getLine(line, key, totalEmails);
        });
    }

    /**
     * Returns the given contact detail line
     *
     * @param {string} line The contact detail line
     * @param {string} key The type of contact detail
     * @param {int} length The number of elements in the contact
     *
     * @return {strig} The formatted string for the contact detail
     */
    getLine = (line, key, length) => {
        if (length <= 1) {
            return line;
        }

        return `${line} (${key})`;
    }

    /**
     * Returns the employees address encased in HTML for formatting
     *
     * @param {object} employee The employees details returned from the API
     *
     * @return {element} The employees address
     */
    getAddress = (employee) => {
        if (_.isEmpty(employee.address)) {
            return "-";
        }

        return _.map(employee.address, (line, key) => {
            return <div key={key}>{line}</div>;
        });
    }

    /**
     * Returns a string to be used in the status indicator based on account status
     *
     * @param {string} accountStatus The account status returned from the API
     *
     * @return {string} The type of indicator to display
     */
    getAccountStatus = (accountStatus) => {
        switch (accountStatus) {
        case "Active":
            return "success";
        case "Account Disabled":
            return "error";
        case "Not Activated":
            return "warn";
        default:
            return null;
        }
    }

    /**
     * Simple wrapper to display the inline edit component for various elements on page
     *
     * @param {string} key The textual ID of the field we are editing
     * @param {mixed} display What we are going to display inside the editable component
     * @param {string} type The type of field, used in validation or adding a date picker
     *
     * @return {ReactElement} The inline edit component
     */
    wrapEditableField = (key, display, type = 'string') => {
        const { editable, employeeDetails } = this.props;

        if (!editable || !type) {
            return display;
        }

        return (
            <InlineEdit
                patchUrl={`/employee/${employeeDetails.id}`}
                objectKey={key}
                handleRedraw={this.props.handleRedraw}
                type={type}
            >
                {display || "-"}
            </InlineEdit>
        );
    }

    /**
     * Renders the payroll section
     *
     * @param {object} employeeDetails The employee details to use
     * @return {ReactElement} The HTML to display
     */
    renderPayrollSection = (employeeDetails) => {
        const { employees, payrolls, companies } = this.props.appState;
        const payrollId = _.find(employees, { id: employeeDetails.id }).payroll_id;

        return (
            <div className={styles.payrollSection}>
                <h2 className={sections.title}>Payroll Details</h2>
                <dl className={styles.container}>
                    <div>
                        <dt>Payroll No.</dt>
                        <dd>{employeeDetails.payroll_ref}</dd>
                    </div>
                    <div>
                        <dt>Payroll Name</dt>
                        <dd>{getFullPayrollName(payrollId, payrolls, companies)}</dd>
                    </div>
                    <div>
                        <dt>NI No.</dt>
                        <dd>{this.wrapEditableField("ni", employeeDetails.ni, 'nino')}</dd>
                    </div>
                    <div>
                        <dt>DOB</dt>
                        <dd>{formatShortDate(employeeDetails.date_of_birth)}</dd>
                    </div>
                    <div>
                        <dt>Department</dt>
                        <dd>{employeeDetails.department || "-"}</dd>
                    </div>
                    <div>
                        <dt>Start date</dt>
                        <dd>{formatShortDate(employeeDetails.start_date)}</dd>
                    </div>
                    <div>
                        <dt>Leave date</dt>
                        <dd>{formatShortDate(employeeDetails.leave_date)}</dd>
                    </div>
                </dl>
            </div>
        );
    }

    /**
     * Renders the contact section
     *
     * @param {object} employeeDetails The employee details to use
     *
     * @return {ReactElement} The HTML to display
     */
    renderContactSection = (employeeDetails) => {
        return (
            <div className={styles.contactSection}>
                <h2 className={sections.title}>Contact</h2>
                <dl className={styles.container}>
                    <div>
                        <dt>Phone</dt>
                        <dd>
                            {this.wrapEditableField("phone_numbers", this.getPhoneNumbers(employeeDetails), 'mobile')}
                        </dd>
                    </div>
                    <div>
                        <dt>Email</dt>
                        <dd>
                            {this.wrapEditableField("emails", this.getEmails(employeeDetails), 'email')}
                        </dd>
                    </div>
                    <div>
                        <dt>Address</dt>
                        <dd>
                            {this.getAddress(employeeDetails)}
                        </dd>
                    </div>
                </dl>
            </div>
        );
    }

    /**
     * Renders the account section
     *
     * @param {object} employeeDetails The employee details to use
     *
     * @return {ReactElement} The HTML to display
     */
    renderAccountSection = (employeeDetails) => {
        return (
            <div className={styles.accountSection}>
                <h2 className={sections.title}>
                    Account
                </h2>
                <dl className={styles.container}>
                    <div>
                        <dt>Last accessed</dt>
                        <dd>
                            {formatDateTime(employeeDetails.last_accessed)}
                        </dd>
                    </div>
                    <div>
                        <dt>Status</dt>
                        <dd>
                            {employeeDetails.account_status}
                            <StatusIndicator type={this.getAccountStatus(employeeDetails.account_status)} />
                        </dd>
                    </div>
                </dl>
            </div>
        );
    }

    /**
     * Renders the send welcome email button if account is not activated
     *
     * @param {object} employeeDetails The employee details to use
     *
     * @return {ReactElement} The HTML to display
     */
    renderActivationButton = (employeeDetails) => {
        if (employeeDetails.account_status !== 'Not Activated') {
            return null;
        }

        return (
            <PrimaryButton onClick={this.handleSendingActivationInstructions}>
                Send Welcome Email
            </PrimaryButton>
        );
    }

    /**
     * Renders the employee personal details section
     *
     * @return {ReactElement} The full personal details section
     */
    render () {
        const { employeeDetails, classNames } = this.props;

        return (
            <div className={classNames}>
                {this.renderPayrollSection(employeeDetails)}
                {this.renderContactSection(employeeDetails)}
                {this.renderAccountSection(employeeDetails)}
                {this.renderActivationButton(employeeDetails)}
            </div>
        );
    }

}

export default appState.attachState(PersonalDetailsSection);
