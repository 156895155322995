import React from 'react';

import appState from '../../state/App';
import PropTypes from 'prop-types';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import PayrollDashboard from './assets/PayrollDashboard';

class Dashboard extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Renders the dashboard page
     *
     * @return {ReactElement} The page component
     */
    render () {
        return (
            <PageLayout
                display={{
                    isDetached: false,
                    isPadded: true,
                    hasBackground: false,
                    hasGutter: false,
                }}
                maxWidth={this.props.appState.maxWidth}
            >
                <PayrollDashboard />
            </PageLayout>
        );
    }

}

export default appState.attachState(Dashboard);
