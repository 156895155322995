import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link, withRouter } from 'react-router-dom';
import { paths } from "../../../lib";

import styles from '../assets/SinglePayroll.module.scss';

const About = (props) => {
    const { employeeNumber, match, payroll } = props;

    return (
        <>
            <div className={styles.bodyContainer}>
                <h2>About</h2>
                <Link
                    // eslint-disable-next-line camelcase
                    to={generatePath(paths.singlePayrollEdit, match.params)}
                >
                    <button
                        type="submit"
                        className={styles.editButton}
                    >
                        {'Edit'}
                    </button>
                </Link>
            </div>
            <dl>
                <dt className={styles.cardItem}>
                    Name
                </dt>
                <dd className={styles.cardValue}>
                    {payroll.name}
                </dd>
                <dt className={styles.cardItem}>
                    Frequency
                </dt>
                <dd className={styles.cardValue}>
                    {payroll.frequency}
                </dd>
                <dt className={styles.cardItem}>
                    Current Employees
                </dt>
                <dd className={styles.cardValue}>
                    {employeeNumber}
                </dd>
            </dl>
        </>
    );
};

About.propTypes = {
    employeeNumber: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired,
    payroll: PropTypes.object.isRequired,
};

export default withRouter(About);
