import React from 'react';
import _ from 'lodash';
import appState from 'state/App';

import {
    PropSchema,
    TemplateLayout,
    TemplateTitle,
    TemplateTextInput,
    TemplateTextarea,
    TemplateParagraph,
} from '../';

class NewSmsGenericNotice extends React.Component {

    static propTypes = PropSchema.getPropTypes;

    static defaultProps = PropSchema.getDefaultProps;

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            valuesLoaded: false,
            canSubmit: {
                content: false,
                settings: false,
            },
        };
    }

    /**
     * Called when the component props have changed
     *
     * @param {object} prevProps The components previous props
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.setInitialValues();
        }
    }

    /**
     * Set the initial values of the inputs from the API into the component state
     *
     * @return {void} Modifies state
     */
    setInitialValues () {
        const { templateValues } = this.props;

        if (_.isEmpty(templateValues)) {
            return;
        }

        const messageKey = (this.props.templateKey === "instructions_sms")
            ? "sms_message"
            : "message";

        this.setState({
            canSubmit: {
                content: false,
                settings: false,
            },
            [messageKey]: {
                value: templateValues[messageKey],
                hasChanged: false,
            },
            valuesLoaded: true,
        });
    }

    /**
     * Called when an input value is changes
     *
     * @param {event} event The onChange event
     * @param {string} key The name of the input
     * @param {string} section If the input is in the content or settings tab
     *
     * @return {void} Modifies state
     */
    handleInputChange = (event, key, section) => {
        event.preventDefault();

        const value = event.target.value;

        this.setState((prevState) => {
            return {
                [key]: {
                    value,
                    hasChanged: true,
                },
                canSubmit: {
                    ...prevState.canSubmit,
                    [section]: true,
                },
            };
        }, appState.blockNavigation());
    }

    /**
     * Get the template elments to render
     *
     * @param {boolean} isPreview If the components are being displayed in the preview tab
     *
     * @return {ReactElement[]} The components to render
     */
    getTemplateContent (isPreview) {
        const { templateContent, templateTags } = this.props;
        const messageKey = (this.props.templateKey === "instructions_sms")
            ? "sms_message"
            : "message";
        const { valuesLoaded, [messageKey]: messageValue } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        return [
            <TemplateTitle
                key={templateContent.desc}
                value={templateContent.desc}
                isPreview={isPreview}
                size="h3"
            />,
            <TemplateTextarea
                key={messageKey}
                name={messageKey}
                label="Message"
                value={messageValue.value}
                onChange={(event) => this.handleInputChange(event, messageKey, "content")}
                isPreview={isPreview}
                showInPreview
                templateTagList={templateContent.template_tags}
                templateTagValues={templateTags}
            />,
            <TemplateParagraph
                key={templateContent.content.outro}
                value={templateContent.content.outro}
                isPreview={isPreview}
            />,
        ];
    }

    /**
     * Get the template elments to render - Setting tab
     *
     * @return {ReactElement[]} The components to render
     */
    getTemplateSettings () {
        const { templateContent, templateValues } = this.props;
        const fromNameKey = (this.props.templateKey === "instructions_sms")
            ? "sms_from_name"
            : "from_name";
        const { valuesLoaded } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        return [
            <TemplateTextInput
                key={fromNameKey}
                name={fromNameKey}
                label="From name"
                value={templateValues[fromNameKey]}
                isPreview={false}
                onChange={_.noop}
                disabled
            />,
            <TemplateParagraph
                key={templateContent.content.from_name}
                value={templateContent.content.from_name}
                isPreview={false}
                isItalic
            />,
        ];
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { onFormSubmit, onComponentUnmount, dataLoaded } = this.props;
        const { canSubmit } = this.state;

        const preview = this.getTemplateContent(true);
        const content = this.getTemplateContent(false);
        const settings = this.getTemplateSettings();
        const rawValues = _.omit(this.state, ["valuesLoaded", "canSubmit"]);

        return (
            <TemplateLayout
                dataLoaded={dataLoaded}
                title={this.props.templateContent.name}
                preview={preview}
                content={content}
                settings={settings}
                rawValues={rawValues}
                canSubmit={canSubmit}
                onFormSubmit={onFormSubmit}
                onComponentUnmount={onComponentUnmount}
            />
        );
    }

}

export default NewSmsGenericNotice;
