import React, { Component } from 'react';
import PropTypes from 'prop-types';
import appState from '../../state/App';
import { withRouter, Link } from 'react-router-dom';
import { matchPath } from "react-router";
import { paths } from "../../lib";
import _ from "lodash";

import styles from "./Breadcrumbs.module.scss";

class Breadcrumbs extends Component {

    static propTypes = {
        appState: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    /**
     * Creates an instance of the class
     *
     * @param {object} props Properties
     */
    constructor (props) {
        super(props);

        // The list of pages that the breadcrumbs should not appear on
        this.excludedPaths = [
            paths.authentication,
            paths.dashboard,
            paths.employee,
            paths.forbidden,
            paths.login,
            paths.logout,
            paths.styleguide,
            paths.styleguideComponents,
            paths.styleguideLayout,
            paths.styleguideToastDemo,
        ];

        // Maximum length of breadcrumbs
        this.maxDepth = 5;

        this.state = {
            crumbs: null,
        };
    }

    /**
     * Called just after the component has been added to the DOM
     */
    componentDidMount = () => {
        this.setBreadcrumbs();
    }

    /**
     * Called if the component is updated
     *
     * @param {object} prevProps The component's previous props
     */
    componentDidUpdate = (prevProps) => {
        const { location } = this.props;

        if (location !== prevProps.location) {
            this.setBreadcrumbs();
        }
    }

    /**
     * Sets array of crumb objects in state based on current pathname and params
     */
    setBreadcrumbs = () => {
        const { pathname } = this.props.location;
        const shouldRender = this.checkShouldRender();

        const crumbs = (shouldRender)
            ? pathname
                .split("/")
                .slice(0, this.maxDepth)
                .reduce(this.reducer, [])
            : null;

        this.setState({
            crumbs,
        });
    };

    /**
     * Checks if the breadcrumbs should render on this page
     * Returns true if the list of excluded paths does not contain the current pathname
     *
     * @return {Boolean} If the breadcrumbs should render
     */
    checkShouldRender = () => {
        const { pathname } = this.props.location;

        return _.find(paths, (path) => {
            const isMatch = matchPath(pathname, { path, exact: true });

            return isMatch && (this.excludedPaths.indexOf(path) === -1);
        });
    };

    /**
     * Reducer function to get the crumb text and url based on path and params
     * If it does not get appropriate text for the crumb, it will skip it
     *
     * @param {Array} acc The accumulator
     * @param {string} str The current crumb text
     * @param {number} index The index of the current crumb
     * @param {Array} arr The array of crumbs
     *
     * @return {Array} The array of crumb objects
     */
    reducer = (acc, str, index, arr) => {
        const url = this.getUrl(index, arr);

        const text = (url.length)
            ? this.getName(str, index, arr)
            : "dashboard";

        if (text) {
            acc.push({
                text,
                url,
            });
        }

        return acc;
    };

    /**
     * Gets the updated text of the given crumb
     * If the given crumb text is not an id, it will return the given crumb text
     * If the given crumb text is an id, it will try to find the name of the relevant object and return that
     * If it does not find a relevant object with a name property, it will return null
     *
     * @param {string} str The given crumb text
     * @param {number} index The index of the given crumb
     * @param {Array} arr The array of crumbs
     *
     * @return {string} The updated crumb text
     */
    getName = (str, index, arr) => {
        const isId = (/^\d+$/).test(str);

        if (!isId) {
            return str.replace(/-/gi, " ");
        }

        const collectionName = arr[index - 1];
        const collection = this.props.appState[collectionName];
        const object = _.find(collection, (obj) => obj.id.toString() === str);

        return (object && object.name)
            ? object.name
            : null;
    }

    /**
     * Gets the url of the given crumb
     * Returns the pathname up to the index of the given crumb
     *
     * @param {number} index The index of the given crumb
     * @param {Array} arr The array of crumbs
     *
     * @return {string} The crumb url
     */
    getUrl = (index, arr) => {
        return arr.slice(0, index + 1).join("/");
    }

    /**
     * Renders the arrow icon
     *
     * @return {ReactElement} The arrow icon
     */
    renderIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="9.047">
                <path data-name="Path 1019" d="M.354.354l4.17 4.17-4.17 4.17" fill="none" stroke="#ccc" />
            </svg>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render = () => {
        const { crumbs } = this.state;

        if (!crumbs) {
            return null;
        }

        return (
            <div className={styles.crumbs}>
                {crumbs.map(({ text, url }, index) => {
                    const isCurrentPage = ((index + 1) === crumbs.length);
                    let content = text;

                    if (!isCurrentPage) {
                        content = (
                            <div className={styles.underline}>
                                <Link to={url}>
                                    {text}
                                    <div className={styles.underlineFill} />
                                </Link>
                            </div>
                        );
                    }

                    return (
                        <div className={styles.crumb} key={text}>
                            {content}
                            {!isCurrentPage && this.renderIcon()}
                        </div>
                    );
                })}
            </div>
        );
    }

}

const BreadcrumbsWithRouter = withRouter(appState.attachState(Breadcrumbs));

// eslint-disable-next-line react/no-multi-comp
export default React.forwardRef((props, ref) => (
    <BreadcrumbsWithRouter forwardedRef={ref} {...props} />
));
