import React from 'react';
import PropTypes from 'prop-types';
import ElementPropTypes from 'react-element-proptypes';
import ContentCard from './ContentCard';
import Tabs from '../tabs/Tabs';
import TabContent from './TabContent';

import styles from './TabCard.module.scss';

export default class TabCard extends React.Component {

    static propTypes = {
        heading: PropTypes.string.isRequired,
        tabs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            component: ElementPropTypes.elementOfType(TabContent).isRequired,
        })).isRequired,
        theme: PropTypes.string,
    }

    static defaultProps = {
        theme: "light",
    }

    /**
     * Creates an instance od the tab card component#
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            currentTabIndex: 0,
        };
    }

    /**
     * Called when a tab is clicked
     *
     * @param {int} tabIndex The index of the currently selected tab
     */
    handleTabChange = (tabIndex) => {
        this.setState({
            currentTabIndex: tabIndex,
        });
    }

    /**
     * Renders the card frame
     *
     * @return {ReactElement} The card element
     */
    render () {
        const currentTab = this.props.tabs[this.state.currentTabIndex];

        return (
            <ContentCard theme={this.props.theme} heading={this.props.heading}>
                <Tabs onTabChange={this.handleTabChange} tabContent={this.props.tabs} />
                <div className={styles.content}>
                    {currentTab.component}
                </div>
            </ContentCard>
        );
    }

}
