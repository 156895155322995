import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { paths } from "../../../lib";
import About from './About';
import Edit from './Edit';
import TemplatesList from './TemplatesList';

const Routes = (props) => {
    const { match, payroll } = props;

    return (
        <Switch>
            <Route exact path={paths.singlePayrollAbout} >
                <About {...props} />
            </Route>
            <Route exact path={paths.singlePayrollEdit} >
                <Edit {...props} />
            </Route>
            <Route exact path={paths.singlePayrollFileTypes} >
                {null}
            </Route>
            <Route exact path={paths.singlePayrollTemplates} >
                <TemplatesList payroll={payroll} />
            </Route>
            <Route exact path={paths.singlePayrollBranding} >
                {null}
            </Route>
            <Route exact path={paths.singlePayrollActivation} >
                {null}
            </Route>
            <Route>
                <Redirect to={generatePath(paths.singlePayrollAbout, match.params)} />
            </Route>
        </Switch>
    );
};

Routes.propTypes = {
    match: PropTypes.object.isRequired,
    payroll: PropTypes.object.isRequired,
};

export default withRouter(Routes);

