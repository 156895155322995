import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import windowScroll from '../../lib/domWindowScroll';

import styles from './FloatingMenu.module.scss';

class FloatingMenu extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
        anchor: PropTypes.shape({ current: PropTypes.any }).isRequired,
        open: PropTypes.bool.isRequired,
        visible: PropTypes.bool.isRequired,
        forwardedRef: PropTypes.any,
        onClickOutside: PropTypes.func,
        className: PropTypes.string,
        anchorPoint: PropTypes.oneOf(['left', 'right']),
    }

    static defaultProps = {
        forwardedRef: null,
        onClickOutside: _.noop,
        className: '',
        anchorPoint: 'right',
    }

    /**
     * Creates an instance of the floating menu
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.menuRef = this.props.forwardedRef || React.createRef();
    }

    /**
     * Handles clicks to the document, closes the menu when clicked off
     *
     * @param {ClickEvent} event The event
     *
     * @return {void}
     */
    handleDocumentClick = (event) => {
        const menuInDom = this.menuRef.current && this.props.anchor.current;

        if (!menuInDom) {
            return;
        }

        // Close the menu if there was a click outside of it
        const clickWithinMenu = this.menuRef.current.contains(event.target);
        const clickWithinAnchor = this.props.anchor.current.contains(event.target);

        if (!clickWithinMenu && !clickWithinAnchor) {
            this.props.onClickOutside(event);
        }
    }

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        document.addEventListener('click', this.handleDocumentClick);
    }

    /**
     * Called just before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    /**
     * Sets the position of the menu to just under the button that opened it
     *
     * @return {void}
     */
    positionMenu () {
        const anchorBbox = this.props.anchor.current.getBoundingClientRect();
        const menuBbox = this.menuRef.current.getBoundingClientRect();
        const anchorPoint = (this.props.anchorPoint === 'right')
            ? (anchorBbox.left - menuBbox.width + anchorBbox.width + windowScroll.getX())
            : (anchorBbox.left + windowScroll.getX());

        this.menuRef.current.style.top = `${anchorBbox.top + anchorBbox.height + windowScroll.getY()}px`;
        this.menuRef.current.style.left = `${anchorPoint}px`;
    }

    /**
     * Renders the menu as a child of the body
     *
     * @return {ReactElement} The menu container
     */
    render () {
        if (!this.props.open) {
            return null;
        }

        const className = classNames([
            (this.props.visible)
                ? styles.visibleMenu
                : styles.hiddenMenu,
            this.props.className,
        ]);

        const children = React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {
                handleCloseMenu: this.props.onClickOutside,
            });
        });

        const menu = (
            <div className={className} ref={this.menuRef}>
                {children}
            </div>
        );

        if (this.props.anchor.current && this.menuRef.current) {
            this.positionMenu();
        }

        return ReactDOM.createPortal(menu, document.body);
    }

}

// eslint-disable-next-line react/no-multi-comp
export default React.forwardRef((props, ref) => (
    <FloatingMenu forwardedRef={ref} {...props} />
));
