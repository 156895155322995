import _ from 'lodash';

/**
 * Trims a company name if over a certain number of characters
 *
 * @param {string} companyName The company name to trim
 *
 * @return {string} The trimmed company name
 */
const trimCompanyName = (companyName) => {
    if (companyName.length <= 15) {
        return companyName;
    }

    return _.truncate(companyName, { length: 15 });
};

/**
 * Returns a string of the payroll name (combined with company name if more than one company)
 *
 * @param {number} payrollId The ID of the payroll the employee belongs to
 * @param {array} payrolls An array of objects containing payroll details
 * @param {array} companies An array of objects containing company details
 *
 * @return {?string} The name of the payroll
 */
export const getPayrollName = (payrollId, payrolls, companies) => {
    if (!payrollId) {
        return "-";
    }

    const matchedPayroll = _.find(payrolls, (payroll) => {
        return payroll.id === payrollId;
    });
    const matchedCompany = _.find(companies, (company) => {
        return company.id === matchedPayroll.company_id;
    });

    if (_.size(payrolls) < 1) {
        return null;
    }

    if (_.size(companies) <= 1 && _.size(payrolls) >= 1) {
        return matchedPayroll.name;
    }

    return `${trimCompanyName(matchedCompany.name)} (${matchedPayroll.name})`;
};

/**
 * Returns a string of the payroll name (combined with company name if more than one company)
 *
 * @param {number} payrollId The ID of the payroll the employee belongs to
 * @param {array} payrolls An array of objects containing payroll details
 * @param {array} companies An array of objects containing company details
 *
 * @return {?string} The name of the payroll
 */
export const getFullPayrollName = (payrollId, payrolls, companies) => {
    if (!payrollId) {
        return "-";
    }

    const matchedPayroll = _.find(payrolls, (payroll) => {
        return payroll.id === payrollId;
    });

    const matchedCompany = _.find(companies, (company) => {
        return company.id === matchedPayroll.company_id;
    });

    if (_.size(payrolls) < 1) {
        return null;
    }

    if (_.size(companies) <= 1 && _.size(payrolls) >= 1) {
        return matchedPayroll.name;
    }

    return `${matchedCompany.name} (${matchedPayroll.name})`;
};

/**
 * Returns a string of the payroll name (combined with company name if more than one company)
 * Used in company documents where the payroll may not be one the user has in the user state
 *
 * @param {object} payroll The payroll details
 * @param {array} companies An array of objects containing company details
 * @param {number} companyId The ID of the company the document belong to
 * @param {boolean} truncate If we want to shorten the company name or not
 *
 * @return {string} The name of the payroll
 */
export const getPayrollNameForDocs = (payroll, companies, companyId, truncate = true) => {
    if (!payroll) {
        return "-";
    }

    if (_.size(companies) <= 1) {
        return payroll.name;
    }

    const matchedCompany = _.find(companies, (company) => {
        return company.id === companyId;
    });

    return (truncate)
        ? `${trimCompanyName(matchedCompany.name)} (${payroll.name})`
        : `${matchedCompany.name} (${payroll.name})`;
};

/**
 * Returns a string of the payroll name without the company name.
 *
 * @param {object} payroll Payroll details
 *
 * @return {string} the payroll name
 */
export const getPayrollNameForCompany = (payroll) => {
    return `${payroll.name} PAYROLL`;
};
