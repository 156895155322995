import React from 'react';
import { withRouter } from 'react-router-dom';
import api from '../../lib/api';
import employeePropTypes from '../../lib/props-schema/employeeDetailsSchema';
import payslipsPropTypes, { payslipsDefaultProps } from '../../lib/props-schema/payslipsSchema';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import appState from '../../state/App';
import PropTypes from 'prop-types';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import {
    PayslipsListTab,
    Ep60ListTab,
    P11dListTab,
} from './payroll';

class PayrollTab extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        employeeDetails: employeePropTypes.isRequired,
        employeePayslips: payslipsPropTypes,
    }

    static defaultProps = {
        employeePayslips: payslipsDefaultProps,
    }

    /**
     * Creates an instance of the payroll tab
     *
     * @param {object} props properties
     */
    constructor (props) {
        super(props);

        this.selectedDoc = null;

        this.state = {
            tabs: [],
            EP60: {
                docs: [],
                dataLoaded: false,
                showDocTray: false,
            },
            P11D: {
                docs: [],
                dataLoaded: false,
                showDocTray: false,
            },
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.getEP60List(this.props.employeeDetails.id);
    }

    /**
     * Called once the component has been torn down
     */
    componentWillUnmount () {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Called once the component has loaded, queries the API for the employees eP60s
     *
     * @param {number} employeeId The employee ID
     *
     * @return {void}
     */
    getEP60List = (employeeId) => {
        if (!employeeId) {
            return;
        }

        api.get(`/documents/${employeeId}/eP60`, { component: this })
            .then((response) => {
                this.setState(() => {
                    return {
                        EP60: {
                            docs: response.data,
                            dataLoaded: true,
                        },
                    };
                });
                this.getP11dList(employeeId);
            })
            .catch(() => {
                // Will catch if axios request is canceled
            });
    }

    /**
     * Called once the component has loaded, queries the API for the employees p11ds
     *
     * @param {number} employeeId The employee ID
     *
     * @return {void}
     */
    getP11dList = (employeeId) => {
        if (!employeeId) {
            return;
        }

        api.get(`/documents/${employeeId}/P11d`, { component: this })
            .then((response) => {
                this.setState(() => {
                    return {
                        P11D: {
                            docs: response.data,
                            dataLoaded: true,
                        },
                    };
                });
                this.getPayrollTabs();
            })
            .catch(() => {
                // Will catch if axios request is canceled
            });
    }

    /**
     * Returns the tab content for each payroll section
     *
     * @return {void}
     */
    getPayrollTabs = () => {
        const { employeePayslips, employeeDetails } = this.props;
        const { EP60, P11D } = this.state;

        this.setState({
            tabs: [
                {
                    text: "Payslips",
                    component: <PayslipsListTab employeeId={employeeDetails.id} employeePayslips={employeePayslips} />,
                    url: "/payslips",
                },
                {
                    text: "P60",
                    component: (
                        <Ep60ListTab
                            employeeDetails={employeeDetails}
                            docs={EP60.docs}
                            dataLoaded={EP60.dataLoaded}
                        />
                    ),
                    url: "/p60",
                    conditional: EP60.docs.length < 1,
                },
                {
                    text: "P11D",
                    component: (
                        <P11dListTab
                            employeeDetails={employeeDetails}
                            docs={P11D.docs}
                            dataLoaded={P11D.dataLoaded}
                        />
                    ),
                    url: "/p11d",
                    conditional: P11D.docs.length < 1,
                },
                {
                    text: "P45",
                    component: null,
                    url: "/p45",
                },
            ],
        });
    }

    /**
     * Renders the employees payroll section
     *
     * @return {ReactElement} The payroll section for a given employee profile
     */
    render () {
        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    isRouted
                    maxWidth={this.props.appState.maxWidth}
                    heading={{
                        text: "Payroll",
                        size: "h2",
                    }}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        isNested: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                    tabContent={this.state.tabs}
                />
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(PayrollTab));
