import React, { Component } from 'react';

import ErrorPage from '../../components/error-page/ErrorPage';

export default class Forbidden extends Component {

    /**
     * Renders the forbidden (403) page
     *
     * @return {ReactElement} The page component
     */
    render () {
        return (
            <ErrorPage
                title="403: Forbidden"
                content="You don&prime;t have permissions to view this page"
                fromErrorBoundary={true}
            />
        );
    }

}
