import React from 'react';

export default class SearchIcon extends React.Component {

    /**
     * Renders the icon component
     *
     * @return {ReactElement} The icon element
     */
    render () {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...this.props}>
                <path d="M11.688 9.75a6.322 6.322 0 1 0-1.9 1.925l.287-.2L14.6
                    16l1.4-1.429-4.521-4.525zm-1.8-6.95a5.009 5.009 0 1 1-3.542-1.467A4.977
                    4.977 0 0 1 9.892 2.8z"
                />
            </svg>
        );
    }

}
