import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from "react-router-dom";
import { paths } from "../../../lib";

import { DataTable } from 'components/';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";

import styles from '../assets/TemplateListTable.module.scss';

class TemplateListTable extends React.Component {

    static propTypes = {
        dataLoaded: PropTypes.bool.isRequired,
        data: PropTypes.shape({
            payroll: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                company_id: PropTypes.number, // eslint-disable-line camelcase
            }),
            templates: PropTypes.arrayOf(PropTypes.shape({
                type: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                long_name: PropTypes.string.isRequired, // eslint-disable-line camelcase
                desc: PropTypes.string.isRequired,
                customised: PropTypes.bool.isRequired,
            })),
        }).isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Get a shaped array of data for the data table from the props
     *
     * @param {object} data The collection of templates
     *
     * @return {array} The data for the data table
     */
    getTableData ({ templateList }) {
        const { match } = this.props;

        return templateList.map((template, index) => {
            return {
                id: index,
                name: {
                    text: template.name,
                    link: generatePath(paths.templatesViewEdit, {
                        ...match.params,
                        template_key: template.type, // eslint-disable-line camelcase
                    }),
                },
                desc: {
                    text: template.desc,
                },
                customised: {
                    text: (template.customised) ? "Yes" : "No",
                },
            };
        });
    }

    /**
     * Get the headings for the data table
     *
     * @return {object} The headings
     */
    getTableHeadings () {
        return {
            name: "Title",
            desc: "Description",
            customised: "Customised",
        };
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { data, dataLoaded } = this.props;

        if (!dataLoaded) {
            return <LoadingSpinner className={styles.loadingSpinner} label="Loading" />;
        }

        const colOrder = [
            "name",
            "desc",
            "customised",
        ];

        return (
            <div className={styles.tableContainer}>
                <DataTable
                    colOrder={colOrder}
                    data={this.getTableData(data)}
                    dataLoaded={dataLoaded}
                    headings={this.getTableHeadings()}
                />
            </div>
        );
    }

}

export default withRouter(TemplateListTable);
