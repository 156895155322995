import api from "../api";
import fileDownload from 'js-file-download';

class PdfDownloader {

    /**
     * Constructor class for handling PDFs
     *
     * @param {object} config PDF config including url, responseType, title and optional jsonPdfKey
     */
    constructor ({ jsonPdfKey, responseType, title, url }) {
        this.title = title;
        this.blob = this.getBlob({ jsonPdfKey, responseType, url });
    }

    /**
     * Gets the pdf blob
     *
     * @param {object} config PDF config including url, responseType and optional jsonPdfKey
     *
     * @return {Promise} The pdf blob
     */
    getBlob = ({ jsonPdfKey, responseType, url }) => {
        return api.get(url, {
            component: this,
            responseType,
        })
            .then(({ data }) => {
                let blob = data;

                if (responseType === 'json') {
                    blob = this.createBlobFromBase64((jsonPdfKey)
                        ? blob[jsonPdfKey]
                        : blob);
                }

                return blob;
            })
            .catch(() => {
                // Will catch if axios request is canceled
            });

    }

    /**
     * Creates a blob if the provided data is a base64 encoded string
     *
     * @param {string} base64 The base64 representation of the document
     *
     * @return {Blob} The pdf blob to be used
     */
    createBlobFromBase64 = (base64) => {
        const raw = window.atob(base64);
        const rawLength = raw.length;
        const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            blobArray[i] = raw.charCodeAt(i);
        }

        return new Blob([blobArray], {type: 'application/pdf'});
    }

    /**
     * Creates the object url of the pdf blob
     *
     * @return {object} The object url
     */
    createObjectURL = () => {
        return new Promise((resolve) => resolve(this.blob))
            .then((blob) => {
                this.objectURL = window.URL.createObjectURL(blob);
                return this.objectURL;
            });
    }

    /**
     * Downloads the pdf to user's PC
     */
    download = () => {
        new Promise((resolve) => resolve(this.blob))
            .then((blob) => {
                fileDownload(blob, `${this.title}.pdf`, 'application/pdf');
            });
    }

    /**
     * Revokes the object url of the pdf blob
     */
    revokeObjectURL = () => {
        if (this.objectURL) {
            this.objectURL = window.URL.revokeObjectURL(this.objectURL);
        }
    }

}

export default PdfDownloader;
