import paths from "./paths.json";

const titleRef = {
    [paths.addPayroll]: "Add A Payroll",
    [paths.announcementAdd]: "Add An Announcement",
    [paths.announcements]: "Announcements",
    [paths.authentication]: "Authentication",
    [paths.companies]: "Companies",
    [paths.companiesAdd]: "Add A Company",
    [paths.companyDocuments]: "Documents",
    [paths.companyDocumentsAdd]: "Upload A Document",
    [paths.companyDocumentsReports]: "Documents",
    [paths.dashboard]: "Dashboard",
    [paths.employees]: "Employees",
    [paths.messages]: "Messages",
    [paths.myAccountPage]: "My Account",
    [paths.payruns]: "Pay Runs",
    [paths.upload]: "Upload Pay Data",
};

/**
 * Sets document title based on path
 *
 * @param {string} path The path
 *
 * @return {Function} Callback to reset document title to "EMC"
 */
export const setDocumentTitle = (path) => {
    const documentTitle = titleRef[path];

    if (documentTitle) {
        document.title = documentTitle;
    }

    return () => {
        document.title = "EMC";
    };
};
