import React, {Component} from 'react';
import styles from "../../SinglePayroll/assets/SinglePayroll.module.scss";
import { generatePath, Link, withRouter } from "react-router-dom";
import { paths } from "../../../lib";
import PropTypes from "prop-types";
import axios from "axios";
import api from "../../../lib/api";

class About extends Component {

    static propTypes = {
        company: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            company: this.props.company,
        };
    }

    /**
     * Called when the component first renders
     *
     * @return {void}
     */
    componentDidMount () {
        const { company } = this.props;
        axios.all([api.get(`/company/${company.id}`)])
            .then((response) => {
                this.setState({
                    company: response[0].data,
                });
            });
    }


    /**
     * Gets the correct address line if available
     *
     * @param {string} name the name of the address line
     * @param {string} lineNo the number of the address line
     * @returns {ReactElement} the address list
     */
    getAddressLine (name, lineNo) {
        return lineNo ? (
            <>
                <dt className={styles.cardItem}>
                    {name}
                </dt>
                <dd className={styles.cardValue}>
                    {lineNo}
                </dd>
            </>
        ) : null;
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        const { match } = this.props;
        const { company } = this.state;
        const { name, address } = company;

        return (
            <>
                <div className={styles.bodyContainer}>
                    <h2>About</h2>
                    <Link to={generatePath(paths.singleCompanyEdit, match.params)} >
                        <button
                            type="submit"
                            className={styles.editButton}
                        >
                            {'Edit'}
                        </button>
                    </Link>
                </div>
                <dl>
                    <dt className={styles.cardItem}>
                        Name
                    </dt>
                    <dd className={styles.cardValue}>
                        {name}
                    </dd>
                </dl>
                <dl>
                    {this.getAddressLine("Address Line 1", address.line_1)}
                    {this.getAddressLine("Address Line 2", address.line_2)}
                    {this.getAddressLine("Address Line 3", address.line_3)}
                    <dt className={styles.cardItem}>
                        Town/City
                    </dt>
                    <dd className={styles.cardValue}>
                        {address.post_town}
                    </dd>
                    <dt className={styles.cardItem}>
                        Postcode
                    </dt>
                    <dd className={styles.cardValue}>
                        {address.post_code}
                    </dd>
                </dl>
            </>
        );
    }

}

export default withRouter(About);
