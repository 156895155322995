import React from 'react';
import PropTypes from "prop-types";
import ReactTimeout from "react-timeout";
import {NavLink} from "react-router-dom";

import FloatingMenu from "@dataplan/react-components/dist/components/ui/floating_menu/FloatingMenu";
import { getTransitionTime } from '../../../lib/animationHelpers';
import { filterObjectEmptyValues } from 'lib/';

import styles from './EmployeeSearchDashboard.module.scss';

class EmployeeSearchDashboard extends React.Component {

    static propTypes = {
        setTimeout: PropTypes.func.isRequired,
        anchorPoint: PropTypes.oneOf(['left', 'right']),
        employees: PropTypes.array,
    }

    static defaultProps = {
        anchorPoint: 'left',
        employees: {},
    }

    /**
     * Creates an instance of the action menu
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            menuOpen: false,
            menuVisible: false,
            queryString: "",
        };

        this.searchInputRef = React.createRef();
        this.menuRef = React.createRef();
    }

    /**
     * Adds the menu to the DOM then makes it visible
     *
     * @return {void}
     */
    openMenu () {
        this.setState({
            menuOpen: true,
            menuVisible: false,
        }, () => {
            this.setState({
                menuVisible: true,
            });
        });
    }

    /**
     * Hides the menu then removes it from the DOM
     *
     * @return {void}
     */
    closeMenu () {
        if (!this.menuRef.current) {
            return;
        }

        const delay = getTransitionTime(this.menuRef.current);

        // Set the menu to hidden
        this.setState({
            menuVisible: false,
        }, () => {
            // Then remove it once the animations completed
            this.props.setTimeout(() => {
                this.setState({
                    menuOpen: false,
                });
            }, delay);
        });
    }

    /**
     * Click handler for any click outside of the menu
     *
     * @return {void}
     */
    handleClickOutside = () => {
        this.closeMenu();
    }

    /**
     * Click handler for the open menu button
     *
     * @return {void}
     */
    handleClick = () => {
        this.openMenu();
    }

    /**
     * Handles users typing in the search input
     *
     * @param {Event} event The onChange event for the input
     *
     * @return {void}
     */
    onSearchInput = (event) => {
        this.setState({
            queryString: event.target.value,
        });
    }

    /**
    * Maps active and archived employee columns to headers.
    * @return {object} Maps list of employees
    */
    mapAllEmployees () {
        const {employees} = this.props;
        return employees.map((employee) => {
            let employeeMap = {
                id: employee.id,
                employeeName: {
                    text: `${employee.forename_1} ${employee.surname}`,
                    link: `/employees/${employee.id}`,
                },
                payrollNo: {
                    text: employee.payroll_no,
                },
            };

            return filterObjectEmptyValues(employeeMap);
        });
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    renderNameLink () {
        const searchValue = this.state.queryString.toLowerCase();
        const employeeMap = this.mapAllEmployees();

        return employeeMap.map((employee) => {
            const displayText = employee.payrollNo.text;
            const comparisonValueName = employee.employeeName.text.toLowerCase();
            const comparisonValueInput = displayText.toString().toLowerCase();

            const searchName = comparisonValueName.indexOf(searchValue) >= 0;
            const searchInput = comparisonValueInput.indexOf(searchValue) >= 0;

            if (!searchName && !searchInput) {
                return <div />;
            }

            return (
                <div className={styles.columnContainer} key={employee.id}>
                    <NavLink
                        to={employee.employeeName.link}
                        className={styles.column1}
                    >
                        {employee.employeeName.text}
                    </NavLink>
                    <div className={styles.column2}>
                        {displayText}
                    </div>
                </div>
            );
        });
    }

    /**
     * Renders the payroll stats section
     *
     * @return {ReactElement} The stats container
     */
    render () {
        const searchBarStyles = this.state.menuOpen ? {borderRadius: "10px 10px 0 0"} : {};

        return (
            <div className={styles.searchInputContainer}>
                <input
                    aria-label="Search"
                    type="search"
                    name="search_term"
                    ref={this.searchInputRef}
                    className={styles.searchInput}
                    placeholder={"Search Employees..."}
                    onClick={this.handleClick}
                    style={searchBarStyles}
                    value={this.state.queryString}
                    onChange={this.onSearchInput}
                />
                <FloatingMenu
                    anchor={this.searchInputRef}
                    anchorPoint={this.props.anchorPoint}
                    open={this.state.menuOpen}
                    visible={this.state.menuVisible}
                    ref={this.menuRef}
                    onClickOutside={this.handleClickOutside}
                    className={styles.menu}
                    insideBody={true}
                >
                    {this.renderNameLink()}
                </FloatingMenu>
            </div>
        );
    }

}


// eslint-disable-next-line react/no-multi-comp,new-cap, max-len
export default ReactTimeout(React.forwardRef((props, ref) => (<EmployeeSearchDashboard forwardedRef={ref} {...props} />)));
