import React from 'react';

import CardFrame from '../../../components/cards/CardFrame';

import styles from './UploadSection.module.scss';
import { withRouter } from "react-router-dom";
import { paths } from "../../../lib";
import PropTypes from "prop-types";

class UploadSection extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    /**
     * Gets the cloud upload icon
     *
     * @return {ReactElement} The icon element
     */
    getUploadIcon () {
        /* eslint-disable max-len */
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className={styles.icon}>
                <path d="M27.928 13.055A7.57 7.57 0 0 0 28 12c0-4.41-3.589-8-8-8a7.985 7.985 0 0 0-6.396 3.189A6.832 6.832 0 0 0 12 7c-3.859 0-7 3.141-7 7 0 .098.002.191.006.288A7.008 7.008 0 0 0 0 21c0 3.857 3.141 7 7 7h17c4.411 0 8-3.588 8-8 0-2.917-1.604-5.551-4.072-6.945zM24 26H6.999a5 5 0 0 1-5-5c0-2.763 2.238-5 5-5 .152 0 .298.031.445.045A4.969 4.969 0 0 1 6.999 14c0-2.763 2.238-5 5-5 .902 0 1.738.258 2.47.675A6 6 0 0 1 26 12c0 .788-.162 1.538-.439 2.229 2.554.69 4.439 3 4.439 5.771a6 6 0 0 1-6 6z" />
                <path d="M9.999 18h4v6h4v-6h4l-6-6-6 6z" />
            </svg>

        );
        /* eslint-enable */
    }

    handleRedirect = () => {
        const { history } = this.props;

        history.push(paths.upload);
    }

    /**
     * Renders the pay data upload
     *
     * @return {ReactElement} The container
     */
    render () {
        return (
            <CardFrame
                className={[styles.frame, styles.actionFrame].join(' ')}
                action={this.handleRedirect}
            >
                <div tabIndex="0" className={styles.innerContainer}>
                    <div className={styles.textContainer}>
                        {this.getUploadIcon()}
                        <span>Upload pay files</span>
                    </div>
                </div>
            </CardFrame>
        );
    }

}

export default withRouter(UploadSection);
