import React from "react";
import { CloseIcon } from "@dataplan/react-components/dist/components/icons";

export default class Close extends React.Component {

    /**
     * Renders the close icon
     *
     * @return {ReactElement} The icon component
     */
    render () {
        /* eslint-disable max-len */
        return (
            <CloseIcon {...this.props} />
        );
        /* eslint-enable */
    }

}
