import React from 'react';
import PropTypes from 'prop-types';
import api from 'lib/api';
import appState from 'state/App';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";

import TemplateListTable from './TemplateListTable';
import styles from '../assets/TemplateListTable.module.scss';

class TemplatesList extends React.Component {

    static propTypes = {
        payroll: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            templateList: null,
            dataLoaded: false,
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleApiCall();
    }

    /**
     * Invoked before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Called once the component has loaded, queries the API for templates
     *
     * @return {void} Changes state
     */
    handleApiCall = () => {
        const { payroll } = this.props;

        api.get(`/templates/${payroll.id}/list`, { component: this })
            .then(({ data }) => {
                this.setState({
                    templateList: data,
                    dataLoaded: true,
                });
            })
            .catch(() => {
                // Will catch if axios request is canceled
            });
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { payroll } = this.props;
        const { templateList, dataLoaded } = this.state;

        if (!templateList) {
            return <LoadingSpinner className={styles.loadingSpinner} label="Loading" />;
        }

        return (
            <>
                <div className={styles.bodyContainer}>
                    <h2>Templates</h2>
                </div>
                <TemplateListTable
                    key={payroll.id}
                    data={{
                        payroll,
                        templateList,
                    }}
                    dataLoaded={dataLoaded}
                />
            </>
        );
    }

}

export default appState.attachState(TemplatesList);
