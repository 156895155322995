import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Portal extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        container: PropTypes.object,
    }

    static defaultProps = {
        children: null,
        container: document.body,
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return ReactDOM.createPortal(
            this.props.children,
            this.props.container,
        );
    }

}

export default Portal;
