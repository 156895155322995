import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import styles from './SimpleTable.module.scss';

export default class SimpleTable extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
        className: PropTypes.string,
    }

    static defaultProps = {
        className: null,
    }

    /**
     * Renders a standard data table
     *
     * @return {ReactElement} The table element
     */
    render () {
        const tableClass = classNames([
            styles.simpleTable,
            this.props.className,
        ]);

        return (
            <table className={tableClass}>
                {this.props.children}
            </table>
        );
    }

}
