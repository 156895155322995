import _ from 'lodash';

/**
 * Helper class for consistently opening files, using the browsers default method
 *
 * @param {string} url The file data
 * @param {object} attributes The anchor tag attributes to be included
 *
 * @return {void}
 */
const handleFile = (url, attributes) => {
    const link = document.createElement('a');

    const setAttributes = {
        href: url,
        ...attributes,
    };

    if (setAttributes.target === '_blank') {
        setAttributes.rel = 'noopener noreferrer';
    }

    _.forOwn(setAttributes, (value, key) => {
        link.setAttribute(key, value);
    });

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default handleFile;
