import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from "../../lib";
import appState from '../../state/App';
import PropTypes from 'prop-types';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';

class EntryPage extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Renders the default styleguide page
     *
     * @return {ReactElement} The page component
     */
    render () {
        return (
            <PageLayout
                pageType="boxed"
                maxWidth={this.props.appState.maxWidth}
                display={{
                    isDetached: false,
                    isPadded: true,
                    hasBackground: true,
                    hasGutter: true,
                }}
            >
                <div><Link to={paths.styleguideLayout}>Page layout</Link></div>
                <div><Link to={paths.styleguideComponents}>Components</Link></div>
            </PageLayout>
        );
    }

}

export default appState.attachState(EntryPage);
