import React from 'react';
import PropTypes from 'prop-types';

import styles from './TelLink.module.scss';

export default class TelLink extends React.Component {

    static propTypes = {
        telephone: PropTypes.string.isRequired,
        children: PropTypes.any.isRequired,
    }

    /**
     * Renders the telephone link
     *
     * @return {ReactElement} The telephone link
     */
    render () {
        const {telephone, children, ...otherProps} = this.props;

        return (
            <a href={`tel:${telephone}`} className={styles.telLink} {...otherProps}>
                {children}
            </a>
        );
    }

}
