import React from 'react';
import styles from './ArrowBack.module.scss';
import { FullArrow } from "@dataplan/react-components/dist/components/icons";

export default class ArrowBack extends React.Component {

    /**
     * Renders the icon component
     *
     * @return {ReactElement} The icon element
     */
    render () {
        return (
            <FullArrow width={24} height={24} classlist={styles.backArrow} {...this.props} />
        );
    }

}
