import React from 'react';
import PropTypes from 'prop-types';
import appState from 'state/App';
import { withRouter, Prompt } from 'react-router-dom';

import { SnackBar } from "@dataplan/react-components/dist/components/ui/snack_bar";

class RouterNavigationBlocking extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.state = {
            showSnackBar: false,
            confirmedNavigation: false,
            nextLocation: null,
        };
    }

    /**
     * Sets the initial state of the component
     *
     * @return {void} Modifies the state
     */
    setInitialState () {
        this.setState({
            showSnackBar: false,
            confirmedNavigation: false,
            nextLocation: null,
        });
    }

    /**
     * Called when the Prompt is triggered, replaces default browser modal
     * https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
     *
     * @param {object} nextLocation The location object of the navigation from react router
     *
     * @return {boolean} Expected return for the prompt
     */
    handleBlockedNavigation = (nextLocation) => {
        const { confirmedNavigation } = this.state;

        if (!confirmedNavigation) {
            this.setState({
                showSnackBar: true,
                nextLocation,
            });

            return false;
        }

        return true;
    }

    /**
     * Handle user confirmation of navigating away
     *
     * @return {void}
     */
    handleConfirm = () => {
        const { nextLocation } = this.state;

        appState.unblockNavigation();

        this.setState({
            confirmedNavigation: true,
            showSnackBar: false,
        }, () => {
            if (nextLocation) {
                this.props.history.push(nextLocation.pathname);
            }

            this.setInitialState();
        });
    }

    /**
     * Handle user cancelling navigation
     */
    handleCancel = () => {
        this.setState({
            showSnackBar: false,
        });
    }

    /**
     * Render the snack bar to prompt the user
     *
     * @return {ReactElement} The snack bar
     */
    renderSnackBar () {
        const { navigationBlockPrompt } = this.props.appState;
        const { showSnackBar } = this.state;

        if (!showSnackBar) {
            return null;
        }

        return (
            <SnackBar
                type="warn"
                heading={navigationBlockPrompt.heading}
                message={navigationBlockPrompt.message}
                onConfirm={this.handleConfirm}
                onCancel={this.handleCancel}
                confirmText="Leave page"
                cancelText="Stay on page"
            />
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { navigationBlocked } = this.props.appState;

        return (
            <>
                <Prompt
                    when={navigationBlocked}
                    message={this.handleBlockedNavigation}
                />
                {this.renderSnackBar()}
            </>
        );
    }

}

export default withRouter(appState.attachState(RouterNavigationBlocking));
