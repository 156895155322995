import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './TextLink.module.scss';

export default class TextLink extends React.Component {

    static propTypes = {
        bold: PropTypes.bool,
    }

    static defaultProps = {
        bold: false,
    }

    /**
     * Renders the textual link
     *
     * @return {ReactElement} The text link component
     */
    render () {
        const { bold, ...otherProps } = this.props;
        const classList = classNames(
            styles.textLink,
            {
                [styles.bold]: bold,
            },
        );

        return (
            <Link
                className={classList}
                {...otherProps}
            />
        );
    }

}
