import React from 'react';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import TablesSection from './assets/TablesSection';
import FormControlsSection from './assets/FormControlsSection';
import StepsSection from './assets/StepsSection';
import TooltipSection from './assets/TooltipSection';
import AvatarSection from './assets/AvatarSection';

export default class PageLayoutGuide extends React.Component {

    /**
     * Demo component
     *
     * @return {ReactElement} A component with demo data
     */
    payslipTabDemo = () => {
        const subTabs = [
            {
                text: "2016-17",
                component: <TablesSection />,
            },
            {
                text: "2015-16",
                component: <TablesSection />,
            },
            {
                text: "2014-15",
                component: <TablesSection />,
            },
        ];

        return (
            <PageLayout
                theme="light"
                primary={false}
                isPadded={false}
                hasGutter={false}
                hasBackground={false}
                tabContent={subTabs}
            />
        );
    }

    /**
     * Demo component
     *
     * @return {ReactElement} A component with demo data
     */
    detailsTabDemo = () => {
        const subTabs = [
            {
                text: "Payroll",
                component: <FormControlsSection />,
            },
            {
                text: "Pensions",
                component: <TooltipSection />,
            },
            {
                text: "Contract & Salaries",
                component: <AvatarSection />,
            },
        ];

        return (
            <PageLayout
                theme="dark"
                primary={false}
                isPadded={true}
                hasGutter={false}
                hasBackground={false}
                tabContent={subTabs}
            />
        );
    }

    /**
     * Demo component
     *
     * @return {ReactElement} A component with demo data
     */
    documentsTabDemo = () => {
        return (
            <PageLayout
                theme="light"
                primary={false}
                isPadded={true}
                hasGutter={false}
                hasBackground={false}
            >
                <StepsSection />
            </PageLayout>
        );
    }

    /**
     * Renders the layout demo page
     *
     * @return {ReactElement} The page component
     */
    render () {
        const tabbedPages = [
            {
                text: "Payslips",
                component: <this.payslipTabDemo />,
            },
            {
                text: "Personal details",
                component: <this.detailsTabDemo />,
            },
            {
                text: "Documents",
                component: <this.documentsTabDemo />,
            },
        ];

        return (
            <PageLayout
                theme="dark"
                heading={{
                    text: "Jennie Barking Wright",
                    size: "h1",
                }}
                showBackArrow={true}
                hasGutter={false}
                hasBackground={false}
                tabContent={tabbedPages}
            />
        );
    }

}
