import React from 'react';
import PropTypes from 'prop-types';
import reactHtmlParser from 'react-html-parser';

import { TextInputRow } from "@dataplan/react-components/dist/components/forms";

import styles from './TemplateTextInput.module.scss';

class TemplateTextInput extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        isPreview: PropTypes.bool,
        showInPreview: PropTypes.bool,
        disabled: PropTypes.bool,
        subject: PropTypes.bool,
    };

    static defaultProps = {
        name: "",
        label: "",
        value: "",
        isPreview: false,
        showInPreview: false,
        disabled: false,
        subject: false,
    }

    /**
     * Get the input classname depending on the input type
     *
     * @return {?string} The class name
     */
    getClassNames () {
        const { subject } = this.props;

        return (subject) ? styles.subjectTextInput : null;
    }

    /**
     * Render the preview version of the component
     *
     * @return {ReactElement} The component
     */
    renderPreview () {
        const { value, subject } = this.props;
        const HeadingTag = (subject) ? "h1" : "h4";

        return (
            <div className={styles.preview}>
                <HeadingTag>{reactHtmlParser(value)}</HeadingTag>
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { name, label, value, isPreview, showInPreview, disabled, onChange } = this.props;

        if ((isPreview && !showInPreview)) {
            return null;
        }

        if (isPreview) {
            return this.renderPreview();
        }

        return (
            <TextInputRow
                label={label}
                name={name}
                value={value}
                disabled={disabled}
                inputClassName={this.getClassNames()}
                rowClassName={styles.inputRow}
                onChange={onChange}
            />
        );
    }

}

export default TemplateTextInput;
