import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { paths } from "../../../lib";
import { checkValidity } from 'lib/validationHelpers';
import appState from 'state/App';

import {
    DefaultForm,
    PrimaryButton,
    SecondaryButton,
} from "@dataplan/react-components/dist/components/forms";
import { AddressForm, NameForm, LogoForm } from "./index";

import { defaultAccentColour } from '../../../Colours';

import styles from "./AddCompanyForm.module.scss";

class AddCompanyForm extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            companyName: {
                valid: false,
                hasChanged: false,
                value: "",
            },
            companyLogo: {
                valid: false,
                hasChanged: false,
                value: "",
            },
            companyAddress: {
                address1: {
                    valid: false,
                    hasChanged: false,
                    label: "Address Line 1",
                    name: "address1",
                    value: "",
                    required: true,
                },
                address2: {
                    valid: false,
                    hasChanged: false,
                    label: "Address Line 2",
                    name: "address2",
                    value: "",
                    required: false,
                },
                address3: {
                    valid: false,
                    hasChanged: false,
                    label: "Address Line 3",
                    name: "address3",
                    value: "",
                    required: false,
                },
                town: {
                    valid: false,
                    hasChanged: false,
                    label: "City/Town",
                    name: "town",
                    value: "",
                    required: true,
                },
                postcode: {
                    valid: false,
                    hasChanged: false,
                    label: "Post Code",
                    name: "postcode",
                    value: "",
                    required: true,
                },
            },
        };
    }

    /**
     * Helper method to change the state of a given input key's validation
     *
     * @param {object} valid validation handler
     * @param {string} key state key to be validated
     * @param {object} value value added from the input fields
     *
     * @return {void} triggers a state change for a given key
     */
    changeValidationState (valid, key, value) {
        this.setState({
            [key]: {
                hasChanged: true,
                valid: valid.isValid,
                value,
            },
        });
    }

    /**
     * Helper method to the change the state of the given input key's validation
     *
     * @param {object} valid validation handler
     * @param {string} key state key to be validated
     * @param {string} value the value entered
     * @return {void} triggers a state change for the given key
     */
    changeValidationStateAddress = (valid, key, value) => {
        this.setState((prevState) => {
            return {
                companyAddress: {
                    ...prevState.companyAddress,
                    [key]: {
                        ...prevState.companyAddress[key],
                        hasChanged: true,
                        valid: valid.isValid,
                        value,
                    },
                },
            };
        });
    }

    /**
     * Handles the saving of changes once the form has been validated
     *
     * @param {event} event the form onSubmit event
     *
     * @return {void} submits the form if it is valid
     */
    handleSubmit = (event) => {
        event.preventDefault();
        const { companyName, companyLogo, companyAddress } = this.state;

        if (!companyName || !companyAddress) {
            return;
        }

        this.props.handleSubmit(companyName, companyLogo, companyAddress);
    }

    /**
     * Handles cancelling form submission
     *
     * @return {void}
     */
    handleCancel = () => {
        const { history } = this.props;

        history.push(paths.companies);
    }

    /**
     * Called when the value of the company name is changed
     *
     * @param {event} event the onChange event
     *
     * @return {void} causes a state change
     */
    handleNameChange = (event) => {
        const companyName = event.target.value;
        appState.blockNavigation();
        const valid = checkValidity(companyName, 'requiredInput', 'companyName');
        this.changeValidationState(valid, 'companyName', companyName);
    }

    /**
     * Called when the value of the file upload is changed
     *
     * @param {event} event the onChange event
     *
     * @return {void} causes a state change
     */
    handleFileInput = (event) => {
        const companyLogo = event.target.files[0];
        appState.blockNavigation();
        const valid = checkValidity(companyLogo, 'file', 'companyLogo');
        this.changeValidationState(valid, 'companyLogo', companyLogo);
    }

    /**
     * Called when one of the values for the address input rows is changed
     *
     * @param {event} event the onChange event
     * @param {string} key the identifying key for which state is validated
     *
     * @return {void} causes a state change
     */
    handleAddressChange = (event, key) => {
        const companyAddress = event.target.value;
        appState.blockNavigation();
        const valid = checkValidity(companyAddress, 'requiredInput', 'companyAddress');
        this.changeValidationStateAddress(valid, key, companyAddress);
    }

    /**
     * Renders the button row
     *
     * @return {ReactElement} The button row
     */
    renderButtons () {
        const { companyName, companyLogo, companyAddress } = this.state;
        const validAd = companyAddress.address1.valid && companyAddress.postcode.valid && companyAddress.town.valid;
        return (
            <div className={styles.upload}>
                <PrimaryButton
                    type="submit"
                    disabled={!(companyName.valid && companyLogo.valid && validAd)}
                >
                    create company
                </PrimaryButton>
                <SecondaryButton
                    accent={defaultAccentColour}
                    className={styles.secondaryButton}
                    text={"Cancel"}
                    aria-label={"Cancel"}
                    onClick={this.handleCancel}
                />
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                <NameForm
                    onChange={this.handleNameChange}
                    errorCheck={this.state.companyName}
                />
                <AddressForm
                    onChange={this.handleAddressChange}
                    formContent={this.state.companyAddress}
                />
                <LogoForm
                    onChange={this.handleFileInput}
                    errorCheck={this.state.companyLogo}
                />
                {this.renderButtons()}
            </DefaultForm>
        );
    }

}

export default withRouter(appState.attachState(AddCompanyForm));
