import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './PayrollLabel.module.scss';

class PayrollLabel extends React.Component {

    static propTypes = {
        text: PropTypes.string.isRequired,
        className: PropTypes.string,
    }

    static defaultProps = {
        className: '',
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const classList = classNames([
            this.props.className,
            styles.payrollLabel,
        ]);

        return (
            <div className={classList}>
                {this.props.text}
            </div>
        );
    }

}

export default PayrollLabel;
