import React from 'react';
import PropTypes from 'prop-types';

import styles from "./Flex.module.scss";

export default class Column extends React.Component {

    static propTypes = {
        align: PropTypes.oneOf(['left', 'center', 'right']),
        children: PropTypes.any.isRequired,
    }

    static defaultProps = {
        align: 'left',
    }

    /**
     * Renders the Column component
     *
     * @return {ReactElement} The component
     */
    render () {
        let className;

        const { align } = this.props;

        switch (align) {
        case 'right':
            className = styles.columnRight;
            break;

        case 'center':
            className = styles.columnCenter;
            break;

        default:
            className = styles.columnLeft;
            break;
        }

        return (
            <div className={className}>
                {this.props.children}
            </div>
        );
    }

}
