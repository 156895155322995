import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';
import classNames from 'classnames';

import ButtonMenu from './floating-menu/ButtonMenu';

import styles from './ActionMenu.module.scss';

class ActionMenu extends React.Component {

    static propTypes = {
        label: PropTypes.string,
        children: PropTypes.any.isRequired,
        className: PropTypes.string,
        anchorPoint: PropTypes.oneOf(['left', 'right']),
    }

    static defaultProps = {
        label: "Actions",
        className: '',
        anchorPoint: 'right',
    }

    /**
     * Renders the menu component
     *
     * @return {ReactElement} The menu element
     */
    render () {
        const button = (clickHandler, parentRef, isOpen) => (
            <button
                type="button"
                className={classNames([
                    styles.actionMenuButton,
                    this.props.className,
                    (isOpen ? styles.open : styles.closed),
                ])}
                onClick={clickHandler}
                ref={parentRef}
            >
                {this.props.label}
            </button>
        );

        return (
            <ButtonMenu button={button} anchorPoint={this.props.anchorPoint}>
                {this.props.children}
            </ButtonMenu>
        );
    }

}

export default ReactTimeout(ActionMenu); // eslint-disable-line new-cap
