import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import TableHeader from '../TableHeader';
import DataTableSortButton from './DataTableSortButton';
import { CheckboxInput } from '@dataplan/react-components/dist/components/forms';
import { defaultAccentColour } from '../../../Colours';

import hideColumnStyles from './HideColumns.module.scss';

export default class DataTableHeader extends React.Component {

    static propTypes = {
        colOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
        headings: PropTypes.object.isRequired,
        hidden: PropTypes.object,
        showCheckBoxes: PropTypes.bool.isRequired,
        onCheckAll: PropTypes.func,
        isChecked: PropTypes.bool,
        sortable: PropTypes.bool.isRequired,
        tableSort: PropTypes.object,
        onSetSort: PropTypes.func,
    }

    static defaultProps = {
        hidden: {},
        onCheckAll: null,
        isChecked: null,
        tableSort: {},
        onSetSort: null,
    }

    /**
     * Render the table headings for each column
     *
     * @param {array} headings An array of the table headings
     *
     * @return {array} An array of table headings to render
     */
    renderHeadings = (headings) => {
        const { colOrder } = this.props;

        return colOrder.map((key) => {
            if (!headings[key]) {
                return null;
            }

            return this.renderHeading(headings[key], key);
        });
    }

    /**
     * Renders a single heading value
     *
     * @param {object} value The column value object
     * @param {string} key The column key
     *
     * @return {Component} The heading value
     */
    renderHeading = (value, key) => {
        const { hidden, onSetSort, sortable, tableSort } = this.props;
        let alignRight, text;

        if (typeof value === 'object') {
            alignRight = value.alignRight;
            text = value.text;
        } else {
            text = value;
        }

        const classList = classNames({
            [hideColumnStyles.hideSmall]: (_.indexOf(hidden.small, key) >= 0),
            [hideColumnStyles.hideMedium]: (_.indexOf(hidden.medium, key) >= 0),
            [hideColumnStyles.hideLarge]: (_.indexOf(hidden.large, key) >= 0),
            alignRight,
        });

        const content = (!sortable)
            ? text
            : (
                <DataTableSortButton
                    text={text}
                    sortOrder={(tableSort[key] && tableSort[key].order)}
                    onSetSort={onSetSort}
                    value={key}
                />
            );

        return (
            <th key={key} className={classList}>
                {content}
            </th>
        );
    }

    /**
     * Renders the data table header
     *
     * @return {ReactElement} The data table header
     */
    render () {
        const {
            headings,
            showCheckBoxes,
            isChecked,
            onCheckAll,
        } = this.props;

        if (_.isEmpty(headings)) {
            return null;
        }

        return (
            <TableHeader>
                { showCheckBoxes
                    && (
                        <th>
                            <CheckboxInput
                                name="select_all"
                                value="all"
                                checked={isChecked}
                                onChange={onCheckAll}
                                submitOnEnter={false}
                                backgroundColour={defaultAccentColour}
                                large={true}
                            />
                        </th>
                    )
                }

                { this.renderHeadings(headings) }
            </TableHeader>
        );
    }

}
