import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

import styles from './DashboardSection.module.scss';

export default class DashboardSection extends React.Component {

    static propTypes = {
        heading: PropTypes.string.isRequired,
        children: PropTypes.any.isRequired,
        className: PropTypes.string,
    }

    static defaultProps = {
        className: '',
    }

    /**
     * Renders the dashboard section
     *
     * @return {ReactElement} The section element
     */
    render () {
        const statsOrUpload = this.props.heading === "Employees" ? 400 : 800;
        const appearTimeout = this.props.heading === "Recent Pay Runs" ? 1000 : statsOrUpload;

        return (
            <div className={classNames([styles.container, this.props.className])}>
                <h1 className={styles.heading}>
                    <AnimationContainer
                        animationStyle="animationContainer"
                        appearTimeout={appearTimeout}
                        enterTimeout={1000}
                        exitTimeout={100}
                    >
                        {this.props.heading}
                    </AnimationContainer>
                </h1>
                <div className={styles.content}>
                    {this.props.children}
                </div>
            </div>
        );
    }

}
