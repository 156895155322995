import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { paths } from "../../../lib";
import appState from 'state/App';
import _ from 'lodash';

import { defaultAccentColour } from '../../../Colours';

import { AddAnnouncementContext, EditAnnouncementContext } from '..';
import {
    DefaultForm,
    PrimaryButton,
    SecondaryButton,
    InputRow,
    DateTimeRow,
    CheckboxInput,
    OptionalSelectRow,
} from '@dataplan/react-components/dist/components/forms';

import styles from './AnnouncementAdd.module.scss';

const editingProps = {
    type: PropTypes.bool,
    default: false,
};

/*
 * This component is used in both add and edit announcement components
 * Changes must be checked in both areas!
 */
class AnnouncementAddEditSettings extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        context: PropTypes.object.isRequired,
        editing: editingProps.type,
    }

    static defaultProps = {
        editing: editingProps.default,
    }

    /**
     * Creates an instance of the add publishing settings for announcements
     *
     * @param {object} props The section properties
     */
    constructor (props) {
        super(props);

        this.state = {
            datestart: {
                valid: (props.editing),
                hasChanged: false,
            },
            dateend: {
                valid: (props.editing),
                hasChanged: false,
            },
            visibility: {
                valid: (props.editing),
                hasChanged: false,
            },
            initialVisibilitySet: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        if (!this.props.editing) {
            this.setInitialVisibility();
            this.checkStepTwoValidity();
        }
    }

    /**
     * Called when the component is being removed from the DOM
     * Checks if the form has been changed, and if it has, refreshes the settings from cache
     *
     * @return {void}
     */
    componentWillUnmount () {
        const hasChanged = this.checkHasFormChanged();
        const { editing } = this.props;
        const { refreshInitialState } = this.props.context;

        if (hasChanged && editing) {
            refreshInitialState();
        }
    }

    /**
     * Sets the initial states for the check/select combos for payroll/department
     *
     * @return {void}
     */
    setInitialVisibility () {
        let context = this.props.context;

        const { payrolls } = this.props.appState;
        let visibility = [];

        payrolls.forEach((payroll) => {
            visibility.push({
                payroll: payroll.id,
                department: '0',
            });
        });

        context.onFieldChange(visibility, 'visibility', () => {
            this.setState({
                initialVisibilitySet: true,
                visibility: {
                    valid: true,
                    hasChanged: true,
                },
            });
        });
    }

    /**
     * Sets the state allowing for submission if valid when
     * clicking back to the 1st stage then back to the 2nd
     *
     * @return {void}
     */
    checkStepTwoValidity () {
        const { nextToStepTwo, announcement } = this.props.context;

        if (nextToStepTwo) {
            this.setState({
                datestart: {
                    valid: (!_.isNull(announcement.datestart)),
                },
                dateend: {
                    valid: (!_.isNull(announcement.dateend)),
                },
                visibility: {
                    valid: (announcement.visibility.length > 0),
                },
            });
        }
    }

    /**
     * Handler for the standard input change events
     *
     * @param {event} event The input onChange event
     * @param {string} field The field that has been changed
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {void}
     */
    handleInputChange (event, field, context) {
        context.onFieldChange(event, field, (newValue) => {
            let valid = (!_.isNull(newValue.announcement[field]));

            this.setState({
                [field]: {
                    hasChanged: true,
                    valid,
                },
            });
        });
    }

    /**
     * Handler for the date's checkbox input change
     *
     * @param {event} event The checkbox onChange event
     * @param {string} field The date field being changed
     * @param {Object} context The full context of the add announcement section (single source of truth)
     * @param {string} override The text value to set for the date value
     *
     * @return {void}
     */
    handleDateCheckbox = (event, field, context, override) => {
        const value = event.target.checked;
        const date = (value) ? override : null;

        context.onFieldChange(date, field, (newValue) => {
            let valid = (!_.isNull(newValue.announcement[field]));

            this.setState({
                [field]: {
                    hasChanged: true,
                    valid,
                },
            });
        });
    }

    /**
     * Handler for changes to the checkbox/select combos for department/payroll
     *
     * @param {Object} values The values passed back from the check/select component
     * @param {number} payroll The payroll ID
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {void}
     */
    handleVisibilityChange = (values, payroll, context) => {
        let { visibility } = context.announcement;

        const index = _.findIndex(visibility, (item) => item.payroll === payroll);

        if (index < 0) {
            visibility.push({
                payroll,
                department: '0',
            });
        } else if (values.checked) {
            visibility[index] = {
                payroll,
                department: values.selected,
            };
        } else {
            visibility.splice(index, 1);
        }

        context.onFieldChange(_.uniq(visibility), 'visibility', (newValue) => {
            let valid = (newValue.announcement.visibility.length > 0);

            this.setState({
                visibility: {
                    hasChanged: true,
                    valid,
                },
            });
        });
    }

    /**
     * Renders the date selection
     *
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {ReactElement} The date section
     */
    renderAnnouncementDates (context) {
        return (
            <div className={styles.publishSection}>
                <h3 className={styles.sectionTitle}>Date</h3>
                {this.renderStartDate(context)}
                {this.renderExpiryDate(context)}
            </div>
        );
    }

    /**
     * Renders the start date section
     *
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {ReactElement} The start date section
     */
    renderStartDate (context) {
        const pubImmediately = (context.announcement.datestart === 'immediately');
        const { valid, hasChanged } = this.state.datestart;
        let dateStartError = (!valid && hasChanged) ? "Publish date is required" : "";

        return (
            <InputRow className={styles.dateRow} errorText={dateStartError}>
                {this.renderStartDateInput(pubImmediately, context)}
                {(!this.props.editing) && (
                    <CheckboxInput
                        label={(pubImmediately) ? "Publish immediately" : "Immediately"}
                        name="immediately"
                        value=""
                        checked={pubImmediately}
                        onChange={(event) => this.handleDateCheckbox(event, 'datestart', context, 'immediately')}
                        large
                        backgroundColour={defaultAccentColour}
                    />
                )}
            </InputRow>
        );
    }

    /**
     * Renders the start date input
     *
     * @param {Boolean} pubImmediately If the announcement is set to publish immediately
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {ReactElement} The end date input
     */
    renderStartDateInput (pubImmediately, context) {
        if (pubImmediately) {
            return null;
        }

        const datestart = (context.announcement.datestart instanceof Date)
            ? context.announcement.datestart
            : null;

        return (
            <DateTimeRow
                name="datestart"
                label="Publish date"
                minDate={new Date()}
                todayButton={"Today"}
                className={styles.datePicker}
                selected={datestart}
                onChange={(date) => this.handleInputChange(date, 'datestart', context)}
            />
        );
    }

    /**
     * Renders the end date section
     *
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {ReactElement} The end date section
     */
    renderExpiryDate (context) {
        const neverExpires = (context.announcement.dateend === 'never');
        const { valid, hasChanged } = this.state.dateend;
        let dateEndError = (!valid && hasChanged) ? "Expiry date is required" : "";

        return (
            <InputRow className={styles.dateRow} errorText={dateEndError}>
                {this.renderExpiryDateInput(neverExpires, context)}
                <CheckboxInput
                    label={(neverExpires) ? "Never expire" : "Never"}
                    name="never"
                    value=""
                    checked={neverExpires}
                    onChange={(event) => this.handleDateCheckbox(event, 'dateend', context, 'never')}
                    large
                    backgroundColour={defaultAccentColour}
                />
            </InputRow>
        );
    }

    /**
     * Renders the end date input
     *
     * @param {Boolean} neverExpires If the announcement is set to never expire
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {ReactElement} The end date input
     */
    renderExpiryDateInput (neverExpires, context) {
        if (neverExpires) {
            return null;
        }

        const dateend = (context.announcement.dateend instanceof Date)
            ? context.announcement.dateend
            : null;

        return (
            <DateTimeRow
                name="dateend"
                label="Expiry date"
                minDate={moment().add(1, 'days').toDate()}
                className={styles.datePicker}
                selected={dateend}
                onChange={(date) => this.handleInputChange(date, 'dateend', context)}
            />
        );
    }

    /**
     * Renders the payroll visibility section
     *
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {ReactElement} The payroll visibility section
     */
    renderAnnouncementVisibility (context) {
        const { valid, hasChanged } = this.state.visibility;

        if (this.props.editing) {
            return null;
        }

        let visibilityError = (!valid && hasChanged) ? "At least 1 payroll must be selected" : "";

        return (
            <div className={styles.publishSection}>
                <h3 className={styles.sectionTitle}>Visibility</h3>
                {(this.state.initialVisibilitySet) && (
                    <InputRow errorText={visibilityError}>
                        {this.renderPayrollsCompaniesSelection(context)}
                    </InputRow>
                )}
            </div>
        );
    }

    /**
     * Renders a checkbox/select compoent for each payroll
     *
     * @param {Object} context The full context of the add announcement section (single source of truth)
     *
     * @return {ReactElement[]} The payroll checkbox/select components
     */
    renderPayrollsCompaniesSelection (context) {
        const { payrolls, companies } = this.props.appState;
        const { visibility } = context.announcement;

        return payrolls.map((payroll, index) => {
            const showCompany = (companies.length > 1);
            const matchedCompany = _.find(companies, (company) => {
                return company.id === payroll.company_id;
            });
            const rowValues = _.find(visibility, (row) => {
                return row.payroll === payroll.id;
            });
            const rowSettings = {
                name: `${payroll.id}-${payroll.name}`,
                value: `${payroll.id}-${payroll.name}`,
                text: (showCompany) ? `${payroll.name} (${matchedCompany.name})` : payroll.name,
                labels: [
                    (index === 0) ? "Payrolls" : "",
                    "Department",
                ],
                options: {
                    values: [
                        { text: "All", value: '0' },
                        ...payroll.departments.map((department) => {
                            return {
                                text: department.dept_name,
                                value: _.toString(department.dept_no),
                            };
                        }),
                    ],
                    checked: (!_.isEmpty(rowValues)),
                    selected: (!_.isEmpty(rowValues)) ? rowValues.department : '0',
                },
            };

            return (
                <OptionalSelectRow
                    key={payroll.id}
                    name={rowSettings.name}
                    value={rowSettings.value}
                    text={rowSettings.text}
                    labels={rowSettings.labels}
                    options={rowSettings.options}
                    onChange={(values) => this.handleVisibilityChange(values, payroll.id, context)}
                    className={styles.payrollRow}
                    backgroundColour={defaultAccentColour}
                />
            );
        });
    }

    /**
     * Check the form inputs are valid
     *
     * @return {boolean} If the form inputs are valid
     */
    checkIsFormValid () {
        const { datestart, dateend, visibility } = this.state;

        return (datestart.valid && dateend.valid && visibility.valid);
    }

    /**
     * Check if any of the form inputs have changes
     *
     * @return {boolean} If the form inputs have changed
     */
    checkHasFormChanged () {
        const { datestart, dateend, visibility } = this.state;

        return (datestart.hasChanged || dateend.hasChanged || visibility.hasChanged);
    }

    /**
     * Check if the form can be submitted
     *
     * @param {boolean} checkHasChanged If the form changing is part of the submission criteria
     *
     * @return {boolean} If the form can be submitted
     */
    checkCanFormSubmit (checkHasChanged = true) {
        const { isSubmitting } = this.props.context;
        const isValid = this.checkIsFormValid();
        const hasChanged = this.checkHasFormChanged();

        return (checkHasChanged)
            ? (isValid && hasChanged && !isSubmitting)
            : (isValid && !isSubmitting);
    }

    /**
     * Render the form buttons if editing
     *
     * @return {ReactElement} The buttons section
     */
    renderEditButtons () {
        const { context, history } = this.props;
        const { handleAnnouncementSettingsEdit } = context;
        const cancelAction = () => history.push(paths.announcements);
        const hasChanged = this.checkHasFormChanged();
        const canSubmit = this.checkCanFormSubmit();
        const buttonText = (hasChanged) ? "Cancel" : "Close";

        return (
            <>
                <PrimaryButton onClick={handleAnnouncementSettingsEdit} disabled={!canSubmit}>
                    Save
                </PrimaryButton>
                <SecondaryButton
                    className={styles.secondaryButton}
                    onClick={cancelAction}
                    accent={defaultAccentColour}
                    text={buttonText}
                    aria-label={buttonText}
                />
            </>
        );
    }

    /**
     * Render the form buttons if adding new
     *
     * @return {ReactElement} The buttons section
     */
    renderAddButtons () {
        const { context, history } = this.props;
        const { onStepBack } = context;

        const canSubmit = this.checkCanFormSubmit(false);

        return (
            <>
                <PrimaryButton type="submit" disabled={!canSubmit}>
                    Publish
                </PrimaryButton>
                <SecondaryButton
                    className={styles.secondaryButton}
                    onClick={onStepBack}
                    accent={defaultAccentColour}
                    text={"Back"}
                    aria-label={"Back"}
                />
                <SecondaryButton
                    className={styles.secondaryButton}
                    onClick={() => history.push(paths.announcements)}
                    accent={defaultAccentColour}
                    text={"Cancel"}
                    aria-label={"Cancel"}
                />
            </>
        );
    }

    /**
     * Render the form buttons
     *
     * @return {ReactElement} The buttons section
     */
    renderButtons () {
        if (this.props.editing) {
            return this.renderEditButtons();
        } else {
            return this.renderAddButtons();
        }
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        let context = this.props.context;

        return (
            <DefaultForm onSubmit={context.onPublishConfirm}>
                {this.renderAnnouncementDates(context)}
                {this.renderAnnouncementVisibility(context)}
                {this.renderButtons(context)}
            </DefaultForm>
        );
    }

}

/* eslint-disable react/no-multi-comp */
// https://github.com/yannickcr/eslint-plugin-react/issues/2172
const component = React.forwardRef((props, ref) => (
    <AddAnnouncementContext.Consumer>
        {(addAnnouncementContext) => (
            <EditAnnouncementContext.Consumer>
                {(editAnnouncementContext) => (
                    <AnnouncementAddEditSettings
                        forwardedRef={ref}
                        context={(props.editing) ? editAnnouncementContext : addAnnouncementContext}
                        {...props}
                    />
                )}
            </EditAnnouncementContext.Consumer>
        )}
    </AddAnnouncementContext.Consumer>
));

component.propTypes = {
    editing: editingProps.type,
};

component.defaultProps = {
    editing: editingProps.default,
};

export default withRouter(appState.attachState(component));
/* eslint-enable */
