import React from 'react';
import faker from 'faker';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';

import SimpleTable from '../../../components/tables/SimpleTable';
import TableHeader from '../../../components/tables/TableHeader';
import TableBody from '../../../components/tables/TableBody';
import ActionCell from '../../../components/tables/ActionCell';
import ActionMenu from '../../../components/ActionMenu';
import Action from '../../../components/Action';

export default class TablesSection extends React.Component {

    /**
     * Renders a given number of randomly generated example table rows
     *
     * @param {integer} count The number of rows
     *
     * @return {array} A list of rows to render
     */
    rendersTableRows (count) {
        let tableRows = [];

        for (let i = 0; i < count; ++i) {
            const ni = [
                faker.random.arrayElement(["BG", "GB", "NK", "KN", "TN", "NT", "ZZ"]),
                faker.random.number({ min: 10000, max: 99999 }),
                faker.random.arrayElement(["A", "B", "C", "D"]),
            ];

            const payroll = faker.random.arrayElement([
                "Monthly",
                "Weekly",
                "Head office",
                "Teachers",
                "Warehouse",
            ]);

            const department = faker.random.arrayElement([
                "Finance",
                "HR",
                "Sales",
            ]);

            tableRows.push((
                <tr key={ni}>
                    <td>{faker.name.firstName()} {faker.name.lastName()}</td>
                    <td>20{faker.random.number({ min: 100000, max: 999999 })}</td>
                    <td>{ni}</td>
                    <td>{payroll}</td>
                    <td>{department}</td>
                    <td>{(faker.random.number({ min: 0, max: 10 }) > 3) ? "Yes" : "No"}</td>
                    <ActionCell>
                        <ActionMenu label="Actions">
                            <Action label="Upload Doc" action={() => null} />
                            <Action label="View Latest Payslip" action={() => null} />
                            <Action label="Deactivate Account" action={() => null} />
                        </ActionMenu>
                    </ActionCell>
                </tr>
            ));
        }

        return tableRows;
    }

    /**
     * Renders the table section
     *
     * @return {ReactElement} The section
     */
    render () {
        return (
            <CardRow>
                <CardRowContainer gridWidth={12}>
                    <ContentCard heading="Tables">
                        <SimpleTable>
                            <TableHeader>
                                <th>Name</th>
                                <th>Payroll No.</th>
                                <th>NI No.</th>
                                <th>Payroll</th>
                                <th>Department</th>
                                <th>Registered</th>
                                <th />
                            </TableHeader>
                            <TableBody>
                                {this.rendersTableRows(10)}
                            </TableBody>
                        </SimpleTable>
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
