import React from 'react';

export default class Info extends React.Component {

    /**
     * Renders the icon component
     *
     * @return {ReactElement} The icon element
     */
    render () {
        return (
            /* eslint-disable max-len */
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" {...this.props}>
                <circle cx="13" cy="13" r="12" />
                <path d="M10.365 8.184A2.184 2.184 0 1 0 12.549 6a2.183 2.183 0 0 0-2.184 2.184zm0 4.64v4.913h-.682a.682.682 0 1 0 0 1.365h5.728a.682.682 0 1 0 0-1.365h-.682V12.06a.6.6 0 0 0-.6-.6H9.68a.682.682 0 0 0 0 1.365h.682z" />
            </svg>
            /* eslint-enable */
        );
    }

}
