import React from 'react';
import PropTypes from 'prop-types';

import './ProgressBar.scss';

export default class ProgressBar extends React.Component {

    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.number.isRequired,
    }

    static defaultProps = {
        label: "Progress",
    }

    /**
     * Renders the progress bar
     *
     * @return {ReactElement} The progress element
     */
    render () {
        return (
            <div className="progress-bar-container">
                <div className="progress-bar-label">
                    {this.props.label}
                </div>
                <progress
                    className="progress-bar"
                    value={this.props.value}
                    max="1.0"
                >
                    {this.props.value}
                </progress>
                <div className="progress-bar-details">
                    {Math.round(this.props.value * 100)}%
                </div>
            </div>
        );
    }

}
