import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../Alert';

import styles from './InlineValidationErrors.module.scss';

class InlineValidationErrors extends React.Component {

    static propTypes = {
        errors: PropTypes.object,
    }

    static defaultProps = {
        errors: null,
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { errors } = this.props;

        if (errors.isValid) {
            return null;
        }

        return (
            <Alert type="error" className={styles.errorListAlert}>
                <ul className={styles.errorList}>
                    {
                        errors.messages.map((message) => {
                            return <li key={message}>{message}</li>;
                        })
                    }
                </ul>
            </Alert>
        );
    }

}

export default InlineValidationErrors;
