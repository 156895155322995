import React from 'react';
import PropTypes from 'prop-types';
import appState from '../../state/App';
import _ from 'lodash';

import Toast from './Toast';
import Alert from '../Alert';

import styles from './NotificationToast.module.scss';

class NotificationToast extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Renders the notifications to display
     *
     * @param {array} notifications The notifications to display
     *
     * @return {array} An array of the elements to display
     */
    getNotifications = (notifications) => {
        return _.map(notifications, (notification) => {
            return (
                <div key={notification.id} className={styles.notification}>
                    <Alert
                        className={styles.notification}
                        type={notification.type}
                        message={notification.text}
                        onClose={() => appState.closeNotification(notification.id)}
                    />
                </div>
            );
        });
    }

    /**
     * Renders the notification toast
     *
     * @return {ReactElement} The notification toast
     */
    render () {
        const { notifications } = this.props.appState;

        return (
            <Toast>
                <div className={styles.notificationContainer}>
                    {this.getNotifications(notifications)}
                </div>
            </Toast>
        );
    }

}

export default appState.attachState(NotificationToast);
