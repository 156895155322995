import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';
import { getTransitionTime } from '../../lib/animationHelpers';

import FloatingMenu from './FloatingMenu';

class ButtonMenu extends React.Component {

    static propTypes = {
        button: PropTypes.any.isRequired,
        children: PropTypes.any.isRequired,
        setTimeout: PropTypes.func.isRequired,
        className: PropTypes.string,
        anchorPoint: PropTypes.oneOf(['left', 'right']),
    }

    static defaultProps = {
        className: '',
        anchorPoint: 'right',
    }

    /**
     * Creates an instance of the action menu
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            menuOpen: false,
            menuVisible: false,
        };

        this.buttonRef = React.createRef();
        this.menuRef = React.createRef();
    }

    /**
     * Adds the menu to the DOM then makes it visible
     *
     * @return {void}
     */
    openMenu () {
        this.setState({
            menuOpen: true,
            menuVisible: false,
        }, () => {
            this.setState({
                menuVisible: true,
            });
        });
    }

    /**
     * Hides the menu then removes it from the DOM
     *
     * @return {void}
     */
    closeMenu () {
        if (!this.menuRef.current) {
            return;
        }

        const delay = getTransitionTime(this.menuRef.current);

        // Set the menu to hidden
        this.setState({
            menuVisible: false,
        }, () => {
            // Then remove it once the animations completed
            this.props.setTimeout(() => {
                this.setState({
                    menuOpen: false,
                });
            }, delay);
        });
    }

    /**
     * Click handler for any click outside of the menu
     *
     * @return {void}
     */
    handleClickOutside = () => {
        this.closeMenu();
    }

    /**
     * Click handler for the open menu button
     *
     * @return {void}
     */
    handleClick = () => {
        this.openMenu();
    }

    /**
     * Renders the menu component
     *
     * @return {ReactElement} The menu element
     */
    render () {
        return (
            <React.Fragment>
                {this.props.button(this.handleClick, this.buttonRef, this.state.menuOpen)}
                <FloatingMenu
                    anchor={this.buttonRef}
                    anchorPoint={this.props.anchorPoint}
                    open={this.state.menuOpen}
                    visible={this.state.menuVisible}
                    ref={this.menuRef}
                    onClickOutside={this.handleClickOutside}
                    className={this.props.className}
                >
                    {this.props.children}
                </FloatingMenu>
            </React.Fragment>
        );
    }

}

export default ReactTimeout(ButtonMenu); // eslint-disable-line new-cap
