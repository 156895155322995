import React from 'react';
import PropTypes from 'prop-types';

import { TextInputRow } from "@dataplan/react-components/dist/components/forms";

import styles from "./AddCompanyForm.module.scss";

class NameForm extends React.Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        errorCheck: PropTypes.object.isRequired,
    }

    /**
     * Renders the company name text input row
     *
     * @return {ReactElement} text input row for company name
     */
    render () {
        const {valid, hasChanged} = this.props.errorCheck;
        const nameError = (!valid && hasChanged) ? "Company Name is required" : "";
        const classList = (nameError) ? styles.error : "";

        return (
            <TextInputRow
                name="name"
                label="Company Name"
                placeholder="My Company Name"
                onChange={this.props.onChange}
                errorText={nameError}
                inputClassName={classList}
            />
        );
    }

}

export default NameForm;
