import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import api from 'lib/api';
import { paths } from "../../lib";
import appState from 'state/App';
import _ from 'lodash';
import moment from 'moment';

import {
    EditAnnouncementContext,
    editAnnouncementContextDefault,
    AnnouncementAddEditContent,
    AnnouncementEditPreview,
    AnnouncementAddEditSettings,
    AnnouncementReport,
} from ".";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';

class AnnouncementViewEdit extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the announcement edit wrapper
     *
     * @param {object} props The component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            ...editAnnouncementContextDefault,
            hasChanged: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.getAnnouncementFromCache();
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = "EMC";
    }

    /**
     * Get the announcement from the announcement list stored in the Redux store by ID
     * and store it in state here where it will be the single source of truth for editing
     * an announcement
     *
     * @return {void}
     */
    getAnnouncementFromCache = () => {
        const { history, match } = this.props;
        const announcementIdFromURL = _.toNumber(match.params.announcement_id);
        const announcementFromCache = _.find(this.props.appState.announcementsCache, (cachedAnnouncement) => {
            return cachedAnnouncement.id === announcementIdFromURL;
        });

        if (!announcementFromCache) {
            appState.addNotification({
                text: `Announcement not found`,
                type: "error",
                duration: 5,
            }, () => history.push(paths.announcements));
        }

        let announcement = {
            id: announcementFromCache.id,
            payrollid: announcementFromCache.payrollid,
            deptno: announcementFromCache.deptno,
            subject: announcementFromCache.subject,
            message: announcementFromCache.message,
            addedby: announcementFromCache.addedby,
            notice_read: announcementFromCache.notice_read, // eslint-disable-line camelcase
            dateadded: announcementFromCache.dateadded,
            datestart: (announcementFromCache.datestart !== "-001-11-30T00:00:00+00:00")
                ? moment(announcementFromCache.datestart).toDate()
                : moment(announcementFromCache.dateadded).toDate(),
            dateend: (moment(announcementFromCache.dateend).format("DD-MM-YYYY") === "31-12-9999")
                ? "never"
                : moment(announcementFromCache.dateend).toDate(),
        };

        this.setState({
            announcement,
        }, () => {
            this.setPageName(announcement);
        });
    }

    /**
     * Set page name
     *
     * @param {object} announcement the announcement data
     *
     * @return {void}
     */
    setPageName = (announcement) => {
        const pageName = `${announcement.subject}`;
        document.title = pageName;
    }

    /**
     * Handles an input within the subsections change event, storing the value
     * in state, and subsequently, context
     *
     * @param {any} newValue The new value to save to state
     * @param {string} field The field to change in the state
     * @param {?Function} callback A function to run once the state has been set
     *
     * @return {void}
     */
    onFieldChange = (newValue, field, callback = _.noop) => {
        const value = (!_.isNull(newValue) && !_.isUndefined(newValue.target))
            ? newValue.target.value
            : newValue;

        this.setState((prevState) => {
            return {
                announcement: {
                    ...prevState.announcement,
                    [field]: value,
                },
                hasChanged: true,
            };
        }, () => {
            appState.blockNavigation();
            callback(this.state);
        });
    }

    /**
     * Called when the save button on the announcement content
     * edit pages is clicked to save the changes
     *
     * @return {void}
     */
    handleAnnouncementContentEdit = () => {
        const { history } = this.props;
        const { id, message, subject } = this.state.announcement;

        appState.unblockNavigation();

        api.patch(`/announcements/${id}`, {
            subject,
            message,
        }).then(() => {
            appState.addNotification({
                text: `Announcement has been updated`,
                type: "success",
                duration: 5,
            }, () => {
                appState.updateCachedAnnouncement(id, {
                    subject,
                    message,
                }, () => {
                    history.go();
                });
            });
        }).catch(() => {
            appState.addNotification({
                text: `Failed to update announcement`,
                type: "error",
                duration: 0,
            });
        });
    }

    /**
     * Called when the save button on the announcement settings
     * edit pages is clicked to save the changes
     *
     * @return {void}
     */
    handleAnnouncementSettingsEdit = () => {
        const { history } = this.props;
        const { id, datestart, dateend } = this.state.announcement;
        const dateFormat = "Y-MM-DD HH:mm:ss";
        const newDatestart = moment(datestart).format(dateFormat);
        const newDateend = (dateend !== "never")
            ? moment(dateend).format(dateFormat)
            : moment("9999-12-31", "YYYY-MM-DD").format(dateFormat);

        appState.unblockNavigation();

        api.patch(`/announcements/${id}`, {
            datestart: newDatestart,
            dateend: newDateend,
        }).then(() => {
            appState.addNotification({
                text: `Announcement has been updated`,
                type: "success",
                duration: 5,
            }, () => {
                appState.updateCachedAnnouncement(id, {
                    datestart: newDatestart,
                    dateend: newDateend,
                }, () => {
                    history.go();
                });
            });
        }).catch(() => {
            appState.addNotification({
                text: `Failed to update announcement`,
                type: "error",
                duration: 0,
            });
        });
    }

    /**
     * Get the tab content to be shown in the PageLayout component
     *
     * @return {array} An array of the tabs
     */
    getTabContent () {
        return [
            {
                text: "Preview",
                url: "/preview",
                component: <AnnouncementEditPreview />,
            },
            {
                text: "Edit",
                url: "/edit",
                component: <AnnouncementAddEditContent editing={true} />,
            },
            {
                text: "Settings",
                url: "/settings",
                component: <AnnouncementAddEditSettings editing={true} />,
            },
            {
                text: "Report",
                url: "/report",
                component: <AnnouncementReport editing={false} />,
            },
        ];
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const context = {
            ...this.state,
            onFieldChange: this.onFieldChange,
            handleAnnouncementContentEdit: this.handleAnnouncementContentEdit,
            handleAnnouncementSettingsEdit: this.handleAnnouncementSettingsEdit,
            refreshInitialState: this.getAnnouncementFromCache,
        };

        return (
            <EditAnnouncementContext.Provider value={context}>
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={200}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    <PageLayout
                        heading={{
                            text: `Announcement`,
                            size: "h1",
                        }}
                        isRouted={true}
                        maxWidth={this.props.appState.maxWidth}
                        display={{
                            isDetached: false,
                            isPadded: true,
                            hasBackground: true,
                            hasGutter: true,
                        }}
                        tabContent={this.getTabContent()}
                    />
                </AnimationContainer>

            </EditAnnouncementContext.Provider>
        );
    }

}

export default withRouter(appState.attachState(AnnouncementViewEdit));
