import React from 'react';
import PropTypes from 'prop-types';
import api from 'lib/api';
import axios from 'axios';
import appState from 'state/App';
import { withRouter } from 'react-router-dom';
import { paths } from "../../lib";
import _ from 'lodash';
import moment from 'moment';

import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import {
    AddAnnouncementContext,
    AnnouncementAddEditContent,
    AnnouncementAddEditSettings,
    addAnnouncementContextDefault,
} from '.';

class AnnouncementAdd extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Creates an instace of the add announcement page
     *
     * @param {object} props The component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            stage: "content",
            editing: false,
            isSubmitting: false,
            ...addAnnouncementContextDefault,
        };
    }

    /**
     * Handles an input within the subsections change event, storing the value
     * in state, and subsequently, context
     *
     * @param {any} newValue The new value to save to state
     * @param {string} field The field to change in the state
     * @param {?Function} callback A function to run once the state has been set
     *
     * @return {void}
     */
    onFieldChange = (newValue, field, callback = _.noop) => {
        const value = (!_.isNull(newValue) && !_.isUndefined(newValue.target))
            ? newValue.target.value
            : newValue;

        this.setState((prevState) => {
            return {
                announcement: {
                    ...prevState.announcement,
                    [field]: value,
                },
            };
        }, () => {
            appState.blockNavigation();
            callback(this.state);
        });
    }

    /**
     * Handler for the next button event
     *
     * @param {event} event The onSubmit event
     *
     * @return {void}
     */
    onContentConfirm = (event) => {
        event.preventDefault();

        this.setState((prevState) => {
            return {
                stage: "settings",
                nextToStepTwo: (prevState.backToStepOne),
            };
        });
    }

    /**
     * Handler for the back button event
     *
     * @return {void}
     */
    onStepBack = () => {
        this.setState({
            stage: "content",
            backToStepOne: true,
        });
    }

    /**
     * Handler for the publish button event
     * Retrieves all data from state and sends to API
     *
     * @param {event} event The onSubmit event
     *
     * @return {void}
     */
    onPublishConfirm = (event) => {
        const { history } = this.props;
        const { message, subject, datestart, dateend, visibility } = this.state.announcement;
        event.preventDefault();

        let requests = [];
        const dateFormat = "Y-MM-DD HH:mm:ss";

        appState.unblockNavigation();

        if (this.state.isSubmitting) {
            return;
        }

        this.setState({
            isSubmitting: true,
        }, () => {
            _.each(visibility, (payroll) => {
                requests.push(api.post('/announcements', {
                    payrollid: payroll.payroll,
                    deptno: _.toInteger(payroll.department),
                    subject,
                    message,
                    datestart: (datestart !== "immediately")
                        ? moment(datestart).format(dateFormat)
                        : moment().format(dateFormat),
                    dateend: (dateend !== "never")
                        ? moment(dateend).format(dateFormat)
                        : moment("9999-12-31", "YYYY-MM-DD").format(dateFormat),
                    hideonread: false,
                }));
            });

            axios.all(requests)
                .then(() => {
                    appState.addNotification({
                        text: `${visibility.length} announcements have been posted`,
                        type: "success",
                        duration: 5,
                    }, () => {
                        history.push(paths.announcements);
                    });
                })
                .catch(() => {
                    appState.addNotification({
                        text: `Failed to post ${visibility.length} announcements`,
                        type: "error",
                        duration: 0,
                    });
                });
        });
    }

    /**
     * Render the tab content
     *
     * @return {ReactElement} The component to show
     */
    renderContent () {
        return (this.state.stage === 'content')
            ? <AnnouncementAddEditContent />
            : <AnnouncementAddEditSettings />;
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const context = {
            ...this.state,
            onFieldChange: this.onFieldChange,
            onContentConfirm: this.onContentConfirm,
            onPublishConfirm: this.onPublishConfirm,
            onStepBack: this.onStepBack,
        };

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    pageType="boxed"
                    maxWidth={this.props.appState.maxWidth}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                    heading={{
                        text: "Add an announcement",
                        size: "h1",
                        steps: ["Content", "Publishing"],
                        currentStep: (this.state.stage === 'content') ? 1 : 2,
                    }}
                >
                    <AddAnnouncementContext.Provider value={context}>
                        {this.renderContent()}
                    </AddAnnouncementContext.Provider>
                </PageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(AnnouncementAdd));

