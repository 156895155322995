import _ from 'lodash';

/**
 * Gets the values from the data table we are checking against filters
 * What is displayed in the table is not always what we filter against
 *
 * @param {array} data The data from the data table
 * @param {string} key The column of the data table
 *
 * @return {string} The value to compare
 */
const getValueToCheck = (data, key) => {
    return _.isObject(data[key])
        ? data[key].filterValues || data[key].text
        : data[key];
};

/**
 * Gets the values from each filter
 *
 * @param {array} filter The values of a filter
 *
 * @return {array} An array of filters values that are active
 */
const getFilterValues = (filter) => {
    let filterValues = [];

    _.each(filter, (isActive, filterValue) => {
        if (isActive) {
            filterValues.push(filterValue);
        }
    });

    return filterValues;
};

/**
 * Checks the data against each active value of each filter
 *
 * @param {array} filtersSelected Which filters are selected
 * @param {array} data The data from the data table we are filtering
 *
 * @return {array} Returns the data that matches the filters
 */
const checkConditionsMet = (filtersSelected, data) => {
    return _.filter(filtersSelected, (filter, key) => {
        let filterValues = getFilterValues(filter);
        let valueToCheck = getValueToCheck(data, key);

        if (filterValues.length < 1) {
            return true;
        }

        if (!data[key]) {
            return false;
        }

        let matchedValues = _.find(filterValues, (filterValue) => {
            return (_.isArray(valueToCheck))
                ? valueToCheck.indexOf(filterValue) >= 0
                : valueToCheck === filterValue;
        });

        return (!_.isUndefined(matchedValues) && !_.isEmpty(matchedValues));
    });
};

/**
 * Returns true or false if the data matches the selected filters
 *
 * @param {object} data The data to check against
 * @param {object} filtersSelected The filters checked
 *
 * @return {boolean} If the data matches the selected filters
 */
const dataSetFilter = (data, filtersSelected) => {
    const conditionsMet = checkConditionsMet(filtersSelected, data);

    return _.size(conditionsMet) === _.size(filtersSelected);
};

export default dataSetFilter;
