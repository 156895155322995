import React from 'react';
import { withRouter } from "react-router-dom";
import { formatFullName } from '../../../lib/formattingHelpers';
import PropTypes from 'prop-types';
import employeePropTypes from '../../../lib/props-schema/employeeDetailsSchema';

import UserAvatar from '../../../components/UserAvatar';
import { ArrowIcon } from "@dataplan/react-components/dist/components/icons";

import styles from './EmployeeProfileTitle.module.scss';

class EmployeeProfileTitle extends React.Component {

    static propTypes = {
        employee: employeePropTypes.isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Conditionally render the back arrow
     *
     * @return {element} A HTML arrow
     */
    renderBackArrow () {
        const { history } = this.props;

        return (
            <button
                aria-label="Go back to previous page"
                className={styles.backButton}
                onClick={history.goBack}
                type="button"
            >
                <ArrowIcon icon="ArrowBack" className={styles.backButtonArrow} />
            </button>
        );
    }

    /**
     * Renders the employees name along with avatar, wrapped for use in the page layout component
     *
     * @return {ReactElement} The page header with avatar
     */
    render () {
        const employee = this.props.employee;

        return (
            <React.Fragment>
                {this.renderBackArrow()}
                <div className={styles.empAvatar}>
                    <UserAvatar
                        forename={employee.forename_1}
                        surname={employee.surname}
                        size={36}
                    />
                </div>

                {formatFullName(
                    employee.forename_1,
                    employee.forename_2,
                    employee.surname,
                    employee.name,
                )}
            </React.Fragment>
        );
    }

}

export default withRouter(EmployeeProfileTitle);
