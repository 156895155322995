import React from "react";

const UploadContext = React.createContext({
    companyId: "",
    payrollId: "",
    year: "",
    period: "",
    files: {},
    batch: null,
});

export default UploadContext;
