import React from 'react';
import appState from '../../../state/App';
import createToasts from '../../../components/toasts/createToasts';
import _ from 'lodash';
import api from '../../../lib/api';
import { checkValidity } from '../../../lib/validationHelpers';
import classNames from 'classnames';

import DefaultForm from '../../../components/forms/DefaultForm';
import {
    TextInputRow,
    SelectInputRow,
    InputRow,
} from '@dataplan/react-components/dist/components/forms';
import PrimaryButton from '../../../components/forms/controls/PrimaryButton';
import InlineValidationErrors from '../../../components/validation/InlineValidationErrors';

import styles from './ChangeSecurityQuestions.module.scss';
import sharedStyles from '../SharedStyles.module.scss';

class ChangeSecurityQuestions extends React.Component {

    /**
     * Creates an instance of the change security questions page
     *
     * @param {object} props Security questions page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            password: '',
            questionList: [],
            answerList: [],
            hasChanged: false,
            error: {
                isValid: true,
                messages: [],
                invalidInputs: [],
            },
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.setInitialState();
    }

    /**
     * Sets the component default state and performs API calls
     *
     * @return {void}
     */
    setInitialState = () => {
        this.setState({
            hasChanged: false,
            password: '',
        }, () => {
            this.getQuestionList();
            this.getQuestionAnswers();
        });
    }

    /**
     * Gets the list of available security questions from the API, storing them into state
     *
     * @return {void}
     */
    getQuestionList = () => {
        api.get(`/security/questions_list`)
            .then((response) => {
                this.setState({
                    questionList: _.map(response.data, (question) => {
                        return {
                            id: question.id,
                            question: question.question,
                        };
                    }),
                });
            });
    }

    /**
     * Gets the users security questions and answers from the API, storing them into state
     *
     * @return {void}
     */
    getQuestionAnswers = () => {
        api.get(`/security/questions`)
            .then((response) => {
                this.setState({
                    answerList: _.map(response.data, (answer) => {
                        return {
                            id: answer.id,
                            number: answer.number,
                            question: answer.question,
                            answer: answer.answer,
                        };
                    }),
                }, () => {
                    this.handleValidation();
                });
            });
    }

    /**
     * Called when the value of the password input is changed
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handlePasswordInput = (event) => {
        let password = event.target.value;

        this.setState({
            password,
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    }

    /**
     * Called when a security question dropdown or answer input is changed
     *
     * @param {event} event The onChange event
     * @param {string} type The type of field being changed
     * @param {number} id The ID of the answer
     *
     * @return {void}
     */
    handleChange = (event, type, id) => {
        const value = event.target.value;

        this.setState((prevProps) => {
            let change = _.find(prevProps.answerList, (answer) => answer.id === id);

            if (type === "question") {
                change.id = _.toNumber(value);
                change.answer = '';
            } else if (type === "answer") {
                change.answer = value;
            }

            return {
                answerList: _.uniq([
                    ...prevProps.answerList,
                    change,
                ]),
                hasChanged: Boolean(value),
            };
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    }

    /**
     * Checks the password field in completed and the security questions are unique and answered
     *
     * @return {void}
     */
    handleValidation = () => {
        const { password, answerList } = this.state;
        let passwordEntered = checkValidity(password, 'passwordEntered');
        let securityQuestionsValidation = checkValidity(answerList, 'securityQuestions');
        let errors = [];

        [passwordEntered, securityQuestionsValidation].forEach((validation) => {
            if (!validation.isValid) {
                errors.push(validation.message);
            }
        });

        this.setState({
            error: {
                isValid: (passwordEntered.isValid && securityQuestionsValidation.isValid),
                messages: errors,
                invalidInputs: [
                    !passwordEntered.isValid ? 'currentPassword' : null,
                ].filter((input) => input !== null),
            },
        });
    }

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        event.preventDefault();

        const { hasChanged, error, answerList, password } = this.state;

        if (!hasChanged || !error.isValid) {
            return;
        }

        appState.unblockNavigation();

        api.put('/security/questions', {
            password,
            questions: answerList,
        }).then(() => {
            appState.addNotification({
                text: "Security questions successfully saved.",
                type: "success",
                duration: 5,
            });
            this.setInitialState();
        }).catch((apiError) => {
            createToasts(apiError.response.data, 'error');
        });
    }

    /**
     * Creates an array of the elements to render the question answer inputs
     *
     * @return {array} An array of elements
     */
    getQuestionAnswerRows = () => {
        const { questionList, answerList } = this.state;

        if (!questionList && !answerList) {
            return null;
        }

        return _.map(answerList, (questionAnswer) => {
            const { id, number, answer } = questionAnswer;
            return this.renderQuestionAnswerRow(id, number, answer);
        });
    }

    /**
     * Renders the password field
     *
     * @return {ReactElement} The password input
     */
    renderPasswordField = () => {
        const { invalidInputs } = this.state.error;
        const classList = classNames([
            sharedStyles.currentPassword,
            (invalidInputs.indexOf('currentPassword') >= 0) ? sharedStyles.error : '',
        ]);

        return (
            <TextInputRow
                name="currentPassword"
                label="Current Password"
                type="password"
                onChange={this.handlePasswordInput}
                value={this.state.password}
                inputClassName={classList}
            />
        );
    }

    /**
     * Renders a question answer row
     *
     * @param {number} id The ID of the question
     * @param {number} number The number of the question (arbitrary order returned from API)
     * @param {string} answer The existing answer or answer provided
     *
     * @return {ReactElement} The question answer row
     */
    renderQuestionAnswerRow = (id, number, answer) => {
        const { questionList, answerList } = this.state;
        const questionsInUse = _.map(answerList, (existingAnswer) => existingAnswer.id);

        let answerErrorTest = (!answer) ? "Answer required" : "";

        return (
            <InputRow key={`question-${number}`} className={sharedStyles.flexRow}>
                <div className={styles.questionCell}>
                    <SelectInputRow
                        name={`question[${number}]`}
                        label={`Question ${number}`}
                        value={_.toString(id)}
                        onChange={(event) => this.handleChange(event, 'question', id)}
                    >
                        {_.map(questionList, (question) => {
                            if (questionsInUse.includes(question.id) && question.id !== id) {
                                return null;
                            }

                            return (
                                <option
                                    key={`question-${number}-${question.id}`}
                                    value={question.id}
                                >
                                    {question.question}
                                </option>
                            );
                        })}
                    </SelectInputRow>
                </div>
                <div className={styles.answerCell}>
                    <TextInputRow
                        name={`answer[${number}]`}
                        label={`Answer ${number}`}
                        value={answer}
                        onChange={(event) => this.handleChange(event, 'answer', id)}
                        errorText={answerErrorTest}
                        inputClassName={(answer.length < 1) ? sharedStyles.error : ''}
                    />
                </div>
            </InputRow>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { hasChanged, error } = this.state;

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                <InlineValidationErrors errors={error} />
                {this.renderPasswordField()}
                {this.getQuestionAnswerRows()}
                <PrimaryButton
                    type="submit"
                    disabled={!hasChanged || !error.isValid}
                    className={sharedStyles.saveButton}
                >
                    Save
                </PrimaryButton>
            </DefaultForm>
        );
    }

}

export default ChangeSecurityQuestions;
