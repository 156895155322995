import {
    handleViewingLatestPayslip,
    handleResetEmployee,
    handleArchivingEmployee,
    handleEmailActivation,
    handlePrintActivation,
} from '../../../lib';

class GetEmployeeListActions {

    redrawCallback = null;

    createSnackBarConfirmation = null;

    /**
     * Creates an instance of the "view employee list" action list
     *
     * @param {function} redrawCallback The callback to run when making an update that required a redraw of the page
     * @param {function} createSnackBarConfirmation The callback to run when showing a confirm snack bar
     *
     * @return {void}
     */
    constructor (redrawCallback, createSnackBarConfirmation) {
        this.redrawCallback = redrawCallback;
        this.createSnackBarConfirmation = createSnackBarConfirmation;
    }

    /**
     * Shown on 'Active' tab
     */
    activeTabActions = {
        label: "Actions",
        actions: [
            {
                label: "View Latest Payslip",
                callback: (employeeId) => handleViewingLatestPayslip(employeeId),
            },
            {
                label: "Reset account",
                callback: (employeeId, employeeData) => handleResetEmployee(
                    [employeeId],
                    employeeData,
                    this.redrawCallback,
                    this.createSnackBarConfirmation
                ),
            },
            {
                label: "Archive account",
                callback: (employeeId, employeeData) => handleArchivingEmployee(
                    [employeeId],
                    false,
                    employeeData,
                    this.redrawCallback,
                    this.createSnackBarConfirmation
                ),
            },
        ],
    };

    /**
     * Shown on 'Active' tab
     */
    activeBulkActions = {
        actions: [
            {
                label: "Reset accounts",
                callback: (selected) => handleResetEmployee(
                    selected,
                    null,
                    this.redrawCallback,
                    this.createSnackBarConfirmation
                ),
            },
            {
                label: "Archive accounts",
                callback: (selected) => handleArchivingEmployee(
                    selected,
                    false,
                    null,
                    this.redrawCallback,
                    this.createSnackBarConfirmation
                ),
            },
        ],
    };

    /**
     * Shown on 'Activation Required' tab
     */
    activationRequiredActions = {
        label: "Actions",
        actions: [
            {
                label: "Send Welcome Email",
                callback: (employeeId, employeeData) => handleEmailActivation(
                    [employeeId],
                    employeeData,
                    this.redrawCallback
                ),
            },
            {
                // TO DO: https://jira.dataplan.co.uk/browse/EAPI-140
                label: "Print Welcome Letter",
                callback: (employeeId) => handlePrintActivation([employeeId]),
            },
            {
                label: "Archive account",
                callback: (employeeId, employeeData) => handleArchivingEmployee(
                    [employeeId],
                    false,
                    employeeData,
                    this.redrawCallback,
                    this.createSnackBarConfirmation
                ),
            },
        ],
    };

    /**
     * Shown on 'Activation Required' tab
     */
    activationRequiredBulkActions = {
        actions: [
            {
                label: "Send Welcome Emails",
                callback: (selected) => handleEmailActivation(
                    selected,
                    null,
                    this.redrawCallback,
                    this.createSnackBarConfirmation
                ),
            },
            {
                // TO DO: https://jira.dataplan.co.uk/browse/EAPI-140
                label: "Print Welcome Letters",
                callback: (selected) => handlePrintActivation(selected),
            },
            {
                label: "Archive accounts",
                callback: (selected) => handleArchivingEmployee(
                    selected,
                    false,
                    null,
                    this.redrawCallback,
                    this.createSnackBarConfirmation
                ),
            },
        ],
    };

    /**
     * Shown on 'Archived' tab
     */
    archivedActions = {
        label: "Actions",
        actions: [
            {
                label: "Reinstate account",
                callback: (employeeId, employeeData) => handleArchivingEmployee(
                    [employeeId],
                    true,
                    employeeData,
                    this.redrawCallback,
                    this.createSnackBarConfirmation
                ),
            },
        ],
    };

    /**
     * Shown on 'Archived' tab
     */
    archivedBulkActions = {
        actions: [
            {
                label: "Reinstate accounts",
                callback: (selected) => handleArchivingEmployee(
                    selected,
                    true,
                    null,
                    this.redrawCallback,
                    this.createSnackBarConfirmation
                ),
            },
        ],
    };

}

export default GetEmployeeListActions;
