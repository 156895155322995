import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DocumentPdfDownload from './DocumentPdfDownload';
import SecondaryButton from '../forms/controls/SecondaryButton';

import styles from './DocumentPdfNav.module.scss';

class DocumentPdfNav extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        showDownload: PropTypes.bool,
        viewMore: PropTypes.string,
        documentPdf: PropTypes.object.isRequired,
    }

    static defaultProps = {
        showDownload: true,
        viewMore: null,
    }

    /**
     * Renders the download button for the PDF being viewed
     *
     * @return {?ReactElement} The download button
     */
    renderDownloadButton = () => {
        const { showDownload, title, documentPdf } = this.props;

        if (!showDownload) {
            return null;
        }

        return (
            <DocumentPdfDownload
                documentPdf={documentPdf}
                title={title}
            />
        );
    }

    /**
     * Renders the view more button for the PDF being viewed
     *
     * @return {?ReactElement} The view more button
     */
    renderViewMoreButton = () => {
        const { viewMore } = this.props;

        if (!viewMore) {
            return null;
        }

        return (
            <Link to={`${viewMore}`}>
                <SecondaryButton>
                    View Details
                </SecondaryButton>
            </Link>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <nav className={styles.documentPreviewActions} aria-label="FooterActions">
                {this.renderDownloadButton()}
                {this.renderViewMoreButton()}
            </nav>
        );
    }

}

export default DocumentPdfNav;
