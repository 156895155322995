import React, { Component } from 'react';

import ErrorPage from '../../components/error-page/ErrorPage';

export default class NotFound extends Component {

    /**
     * Renders the no match (404) page
     *
     * @return {ReactElement} The page component
     */
    render () {
        return (
            <ErrorPage
                title="Sorry, the page you requested could not be found"
                content="Please use the buttons below to resume"
            />
        );
    }

}
