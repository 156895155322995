import React from 'react';
import PropTypes from 'prop-types';
import api, { apiUrl } from 'lib/api';
import {generatePath, withRouter} from 'react-router-dom';
import appState from 'state/App';
import { paths } from "../../lib";
import moment from 'moment';

import {
    formatLongDate,
    getPayrollNameForDocs,
    filterObjectEmptyValues,
    handleDeletingDocument,
} from 'lib/';
import { PayrollLabel, PayrollLabelExpander } from '.';
import { DataTable } from '../../components/';
import styles from "./assets/CompanyDocumentsShared.module.scss";

class CompanyDocumentsList extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            companyDocs: [],
            dataLoaded: false,
            redrawRequired: false,
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleApiCall();
    }

    /**
     * Invoked when the component updates
     *
     * @return {void}
     */
    componentDidUpdate () {
        if (this.state.redrawRequired) {
            this.handleApiCall();
        }
    }

    /**
     * Called once the component has loaded, queries the API for company docs
     *
     * @return {void}
     */
    handleApiCall () {
        api.get("/documents/company?generate_access_token=true")
            .then(({ data }) => {
                this.setState({
                    redrawRequired: false,
                }, () => {
                    this.mapDocsSaveToState(data);
                });
            });
    }

    /**
     * Forces a redraw of the HOC to query the API again for changes
     * This is done as the API result is stored in the state, and display default values
     * are taken from this
     *
     * @return {void}
     */
    handleRedraw = () => {
        this.setState({
            dataLoaded: false,
            redrawRequired: true,
        });
    }

    /**
     * Runs the documents through a mapping function and saves the result into component
     * state
     *
     * @param {array} documents An array of the documents returned from the API
     *
     * @return {void}
     */
    mapDocsSaveToState (documents) {
        this.setState({
            companyDocs: this.mapDocuments(documents),
            dataLoaded: true,
        });
    }

    /**
     * Maps the document array to a shape used in the data table
     *
     * @param {array} documents An array of documents
     *
     * @return {array} The mapped array
     */
    mapDocuments (documents) {
        const { payrolls } = this.props.appState;
        const multiplePayrolls = (payrolls.length > 1);

        const sortedDocuments = this.sortDocsByDateAdded(documents);
        return sortedDocuments.map((doc) => {
            let docMap = {
                id: doc.id,
                file: {
                    component: (
                        <a
                            href={`${apiUrl}/raw/file/company/${doc.id}?token=${doc.access_token}`}
                            // https://mathiasbynens.github.io/rel-noopener/
                            rel="noopener noreferrer"
                            className={styles.textLink}
                        >
                            {doc.filename}
                        </a>
                    ),
                },
                title: {
                    text: doc.descr,
                },
                published: {
                    text: formatLongDate(doc.datetimeadded),
                },
                author: {
                    text: [doc.uploader.forename, doc.uploader.surname].join(' ').trim(),
                },
                payroll: (multiplePayrolls ? {
                    component: this.renderDocPayrolls(doc),
                    searchText: this.getPayrollNames(doc).join(' ').trim(),
                    filterValues: this.getPayrollNames(doc),
                } : null),
            };

            return filterObjectEmptyValues(docMap);
        });
    }

    /**
     * Sorts the documents by their data added
     *
     * @param {array} documents The array of documents
     *
     * @return {array} The sorted array of documents
     */
    sortDocsByDateAdded (documents) {
        return documents.sort((a, b) => {
            const dateA = moment(a.datetimeadded).unix();
            const dateB = moment(b.datetimeadded).unix();

            return dateB - dateA;
        });
    }

    /**
     * Get an array list of payroll names for the given doc
     *
     * @param {object} document The details fo the document
     * @param {array} document.payrolls The list of payrolls for a document
     * @param {number} document.companyid The ID of the company the document belongs to
     *
     * @return {array} The array of payrolls
     */
    getPayrollNames ({ payrolls, companyid }) {
        const { companies } = this.props.appState;

        return payrolls.map((payroll) => {
            return getPayrollNameForDocs(payroll, companies, companyid, false);
        });
    }

    /**
     * Creates an object of attributes/properties for the data table
     *
     * @return {object} The attributes
     */
    getDataTableAttrs () {
        const multiplePayrolls = (this.props.appState.payrolls.length > 1);

        const colOrder = [
            "file",
            "title",
            "published",
            "author",
            "payroll",
        ];

        const docTableHeadings = filterObjectEmptyValues({
            file: "File",
            title: "Title",
            published: "Date published",
            author: "Published by",
            payroll: (multiplePayrolls ? "Payrolls" : null),
        });

        const docTableFilters = filterObjectEmptyValues({
            payroll: (multiplePayrolls ? "Payroll" : null),
        });

        const docTableActions = {
            label: "Actions",
            actions: [
                {
                    label: "Delete",
                    callback: (docId, docData) => handleDeletingDocument(
                        docId,
                        'company',
                        docData.title.text,
                        this.handleRedraw,
                        this.createSnackBarConfirmation
                    ),
                },
                {
                    label: "Report",
                    callback: (docId) => {
                        // eslint-disable-next-line camelcase
                        this.props.history.push(generatePath(paths.companyDocumentsReports, {document_id: docId}));
                    },
                },
            ],
        };

        return {
            colOrder,
            docTableHeadings,
            docTableFilters,
            docTableActions,
        };
    }

    /**
     * Render the component for use in the payroll collumn of the data table
     *
     * @param {object} document The details fo the document
     * @param {array} document.payrolls The list of payrolls for a document
     * @param {number} document.id The ID of the document
     * @param {number} document.companyid The ID of the company the document belongs to
     *
     * @return {ReactElement} The component to render
     */
    renderDocPayrolls ({ payrolls, id, companyid }) {
        const { payrolls: userPayrolls, companies } = this.props.appState;

        if (userPayrolls.length === payrolls.length) {
            return <PayrollLabel key={id} text="All" />;
        }

        if (payrolls.length > 1) {
            return <PayrollLabelExpander key={id} payrolls={payrolls} companies={companies} companyId={companyid} />;
        }

        // Single payroll will still be an array
        return payrolls.map((payroll) => (
            <PayrollLabel key={payroll.id} text={getPayrollNameForDocs(payroll, companies, companyid)} />
        ));
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const {
            companyDocs,
            dataLoaded,
        } = this.state;

        const {
            colOrder,
            docTableHeadings,
            docTableFilters,
            docTableActions,
        } = this.getDataTableAttrs();

        return (
            <DataTable
                key="all-company-docs"
                colOrder={colOrder}
                data={companyDocs}
                dataLoaded={dataLoaded}
                headings={docTableHeadings}
                searchable
                filterable={docTableFilters}
                actions={docTableActions}
                emptySearch="documents"
            />
        );
    }

}

export default withRouter(appState.attachState(CompanyDocumentsList));
