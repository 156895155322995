import React from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { paths } from "../../../lib";
import appState from '../../../state/App';
import UploadContext from "./UploadContext";
import DefaultForm from '../../../components/forms/DefaultForm';
import {
    InputRow,
    MultiFileInput,
    PrimaryButton,
    SearchInputRow,
    SecondaryButton,
    SelectInput,
} from '@dataplan/react-components/dist/components/forms';
import styles from "./UploadFiles.module.scss";

class UploadFiles extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Renders the company select input if there is more than one company available
     *
     * @param {object} context The upload context
     *
     * @return {ReactElement} The select input
     */
    renderCompanyDropdown (context) {
        if (this.props.appState.companies.length <= 1) {
            return null;
        }

        const { companies } = this.props.appState;

        return (
            <SearchInputRow
                array={companies}
                name={"companyId"}
                label={"Organization"}
                onChange={context.handleChange}
                value={context.companyId}
                searchable={true}
                branded={true}
            />
        );
    }

    /**
     * Renders the payroll select input if there is more than one available for the selected company
     *
     * @param {object} context The upload context
     *
     * @return {ReactElement} The select input
     */
    renderPayrollDropdown (context) {
        if (this.props.appState.payrolls.length <= 1) {
            return null;
        }

        const payrollList = _.filter(this.props.appState.payrolls, (payroll) => {
            return payroll.company_id === parseInt(context.companyId, 10);
        });

        return (
            <SearchInputRow
                array={payrollList}
                name="payrollId"
                label="Payroll"
                onChange={context.handleChange}
                value={context.payrollId}
                placeholder="Select"
                disabled={!context.companyId}
                branded={true}
            />
        );
    }

    /**
     * Gets the list of periods for a payroll
     *
     * @param {number} payrollId The ID of the payroll
     *
     * @return {array} The periods
     */
    getPayrollPeriods (payrollId) {
        const payroll = _.find(this.props.appState.payrolls, { id: payrollId });

        if (!payroll) {
            return [];
        }

        return payroll.periods;
    }

    /**
     * Gets the list of options for the year select input
     *
     * @param {object} context The upload context
     *
     * @return {ReactElement} The list of options
     */
    getYearOptions (context) {
        const periods = this.getPayrollPeriods(parseInt(context.payrollId, 10));
        const years = _(periods)
            .map((period) => period.year)
            .uniq()
            .value()
            .sort((a, b) => b - a);

        return _.map(years, (year) => {
            return {
                id: year,
                name: `${year}/${(year + 1).toString().substr(2)}`,
            };
        });
    }

    /**
     * Gets the display names for period numbers on a payroll
     *
     * @param {integer} payrollId The ID of the payroll
     * @param {Array} periodNumbers The period numbers
     *
     * @return {Object} The formatted label
     */
    formatPeriodNumbers (payrollId, periodNumbers) {
        const payroll = _.find(this.props.appState.payrolls, { id: payrollId });

        return periodNumbers.reduce((acc, periodNumber) => {
            if (periodNumber === 0) {
                acc[0] = "Other";
            } else if (payroll.frequency === "Monthly") {
                const date = moment({ month: 2, day: 1 });

                date.add(periodNumber, "months");

                acc[periodNumber] = `${date.format('MMMM')} - Period ${periodNumber}`;
            } else {
                acc[periodNumber] = `Period ${periodNumber}`;
            }

            return acc;
        }, {});
    }

    /**
     * Gets the list of options for the periods select input
     *
     * @param {Object} formattedValues unfiltered options
     *
     * @return {ReactElement} The list of options
     */
    getPeriodOptions (formattedValues) {
        return _.map(Object.keys(formattedValues), (number) => {
            return {
                id: number,
                name: formattedValues[number],
            };
        });
    }

    /**
     * Renders the the period selector inputs
     *
     * @param {object} context The upload context
     *
     * @return {ReactElement} The inputs
     */
    renderPeriodDropdowns (context) {
        const periods = this.getPayrollPeriods(parseInt(context.payrollId, 10));
        const periodNumbers = _(periods)
            .filter((period) => period.year === parseInt(context.year, 10))
            .map((period) => period.period_number)
            .uniq()
            .sortBy()
            .value();

        const formattedValues = this.formatPeriodNumbers(parseInt(context.payrollId, 10), periodNumbers);

        return (
            <>
                <SearchInputRow
                    array={this.getYearOptions(context)}
                    name="year"
                    label="Year"
                    onChange={context.handleChange}
                    value={context.year}
                    disabled={!context.payrollId}
                    branded={true}
                />
                <SearchInputRow
                    array={this.getPeriodOptions(formattedValues)}
                    name="period"
                    label="Period"
                    onChange={context.handleChange}
                    value={context.period}
                    searchable={true}
                    disabled={!context.year}
                    branded={true}
                />
            </>
        );
    }

    /**
     * Renders the file input area
     *
     * @param {object} context The upload context
     *
     * @return {ReactElement} The input area
     */
    renderFileInput (context) {
        return (
            <InputRow>
                <MultiFileInput
                    disabled={!context.period}
                    name="files"
                    multiple
                    onChange={context.handleFileChange}
                    fileErrors={context.fileErrors}
                    initialFiles={context.files}
                    renderExtraFields={(handleChange, hash) => {
                        const extraFields = (context.files[hash])
                            ? context.files[hash].extraFields
                            : {};

                        return [
                            (
                                <SelectInput
                                    key="type"
                                    name="type"
                                    label="File Type"
                                    onChange={handleChange}
                                    value={extraFields.type || ""}
                                    placeholder="Select"
                                >
                                    <option value="payslips">Payslips</option>
                                    <option value="p60s">P60s</option>
                                    <option value="p11ds">P11ds</option>
                                    <option value="p45s">P45s</option>
                                    <option value="timesheets">Timesheets</option>
                                </SelectInput>
                            ),
                        ];
                    }}
                />
            </InputRow>
        );
    }

    /**
     * Checks if all form inputs have been populated
     *
     * @param {object} context The upload context
     *
     * @return {boolean} If all inputs have a value
     */
    formInputsPopulated (context) {
        return context.companyId
            && context.payrollId
            && context.year
            && context.period;
    }

    /**
     * Checks if there is at least one file and that all are inputs filled in
     *
     * @param {object} context The upload context
     *
     * @return {boolean} If the files section is populted
     */
    fileInputsPopulated (context) {
        const reducer = (previous, file) => {
            return previous && file.extraFields.type;
        };

        return _.size(context.files) > 0
            && _.reduce(context.files, reducer, true);
    }

    /**
     * Checks if the form is ready to be submitted
     *
     * @param {object} context The upload context
     *
     * @return {bool} If the form can be sent
     */
    submitButtonEnabled (context) {
        return this.formInputsPopulated(context)
            && this.fileInputsPopulated(context)
            && context.importStatus === null;
    }

    /**
     * Renders the submit button and upload status
     *
     * @param {object} context The upload context
     *
     * @return {ReactElement} The input row
     */
    renderSubmitButton (context) {
        const { history } = this.props;

        return (
            <InputRow className={styles.submitRow}>
                <PrimaryButton
                    type="submit"
                    disabled={!this.submitButtonEnabled(context)}
                >
                    Next
                </PrimaryButton>
                <SecondaryButton
                    className={styles.secondaryButton}
                    onClick={() => history.push(paths.payruns)}
                    accent={'#682f91'}
                    text={"Cancel"}
                    aria-label={"Cancel"}
                />
                {context.importStatus && (
                    <div className={styles.importStatus}>
                        {context.importStatus}
                    </div>
                )}
            </InputRow>
        );
    }

    /**
     * Renders the upload form
     *
     * @return {ReactElement} The form element
     */
    render () {
        return (
            <UploadContext.Consumer>
                {(context) => (
                    <DefaultForm onSubmit={context.handleUpload}>
                        {this.renderCompanyDropdown(context)}
                        {this.renderPayrollDropdown(context)}
                        {this.renderPeriodDropdowns(context)}
                        {this.renderFileInput(context)}
                        {this.renderSubmitButton(context)}
                    </DefaultForm>
                )}
            </UploadContext.Consumer>
        );
    }

}

export default withRouter(appState.attachState(UploadFiles));
