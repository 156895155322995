import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clientRect from '../../lib/domClientRect';
import { FullArrow } from "@dataplan/react-components/dist/components/icons";

import styles from './ScrollingCardRow.module.scss';

export default class ScrollingCardRow extends React.Component {

    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.element).isRequired,
    }

    /**
     * Creates an instance of the scrolling container
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.cardWrapperRef = React.createRef();

        this.state = {
            selectedCard: 0,
            resizing: false,
        };
    }

    /**
     * Called when the browser window is resized.
     *
     * Forces the card offset to be recalculated as that depends on the screen width.
     *
     * @return {void}
     */
    handleWindowResize = _.throttle(() => {
        // Set a flag to disable the CSS transition while the update is happening.
        // Improves performance and prevents weird scrolling happening :)
        this.setState({ resizing: true }, () => {
            this.forceUpdate(() => {
                this.setState({ resizing: false });
            });
        });
    }, 500)

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        window.addEventListener('resize', this.handleWindowResize);
    }

    /**
     * Called just before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    /**
     * Called when the previous button is clicked
     *
     * @return {void}
     */
    handlePreviousClick = () => {
        this.setState((prevState) => {
            return { selectedCard: prevState.selectedCard - 1 };
        });
    }

    /**
     * Called when the next card button is clicked
     *
     * @return {void}
     */
    handleNextClick = () => {
        this.setState((prevState) => {
            return { selectedCard: prevState.selectedCard + 1 };
        });
    }

    /**
     * Calculates the transform value for the inner container based on the selected card
     *
     * @return {integer} The offset in pixels
     */
    getContainerOffset () {
        if (!this.cardWrapperRef.current) {
            return 0;
        }

        const children = this.cardWrapperRef.current.children;

        if (children.length === 0) {
            return 0;
        }

        const selectedCard = children[this.state.selectedCard];

        const containerRect = this.cardWrapperRef.current.getBoundingClientRect();
        const cardRect = selectedCard.getBoundingClientRect();

        return -(clientRect.getX(cardRect) - clientRect.getX(containerRect));
    }

    /**
     * Renders the scrolling card row
     *
     * @return {ReactElement} The container
     */
    render () {
        return (
            <div className={styles.container}>
                <div className={styles.buttonContainer}>
                    <button
                        aria-label="Scroll Left"
                        type="button"
                        className={styles.button}
                        onClick={this.handlePreviousClick}
                        disabled={this.state.selectedCard <= 0}
                    >
                        <FullArrow width={24} height={24} classlist={styles.iconLeft} {...this.props} />
                    </button>
                    <button
                        aria-label="Scroll Right"
                        type="button"
                        className={styles.button}
                        onClick={this.handleNextClick}
                        disabled={this.state.selectedCard >= this.props.children.length - 1}
                    >
                        <FullArrow width={24} height={24} classlist={styles.iconRight} {...this.props} />
                    </button>
                </div>
                <div className={styles.innerContainer}>
                    <div
                        className={(this.state.resizing) ? styles.staticCardWrapper : styles.cardWrapper}
                        ref={this.cardWrapperRef}
                        style={{ transform: `translateX(${this.getContainerOffset()}px)` }}
                    >
                        {this.props.children.map((card) => (
                            <div className={styles.cardContainer} key={card.key}>
                                {card}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

}
