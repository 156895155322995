import React from 'react';
import PropTypes from 'prop-types';
import reactHtmlParser from 'react-html-parser';

import styles from './TemplateSteps.module.scss';

class TemplateSteps extends React.Component {

    static propTypes = {
        value: PropTypes.array,
        isPreview: PropTypes.bool,
        showInPreview: PropTypes.bool,
    };

    static defaultProps = {
        value: null,
        isPreview: false,
        showInPreview: false,
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { value, isPreview, showInPreview } = this.props;

        if ((isPreview && !showInPreview) || !value) {
            return null;
        }

        return (
            <ul className={styles.stepList}>
                {value.map((stepItem) => {
                    return (
                        <li key={stepItem.title} className={styles.step}>
                            <strong>{stepItem.title}</strong>
                            {reactHtmlParser(stepItem.value)}
                        </li>
                    );
                })}
            </ul>
        );
    }

}

export default TemplateSteps;
