import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import api from 'lib/api';
import axios from 'axios';
import appState from 'state/App';
import { withRouter } from 'react-router-dom';
import { paths } from "../../lib";
import { DocumentUploadForm } from '.';
import { SnackBar } from 'components/';

class CompanyDocumentAdd extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        closeDrawer: PropTypes.func,
    }

    static defaultProps = {
        closeDrawer: _.noop,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            showSnackBar: false,
            snackBarType: "",
            snackBarMessage: "",
            snackBarOnConfirm: null,
        };
    }

    /**
     * Get an array of company IDs which we will be uploading documents to
     *
     * @param {object} userPayrolls The users payrolls stored in app state
     * @param {array} selectedPayrolls The payrolls selected on the form
     *
     * @return {array} The company IDs
     */
    getCompanyIdsForUpload (userPayrolls, selectedPayrolls) {
        const selectedCompanies = [];

        if (userPayrolls.length === 1) {
            return [_.head(userPayrolls).company_id];
        }

        _.each(selectedPayrolls, (selectedPayroll) => {
            const matchedPayroll = _.find(userPayrolls, (userPayroll) => {
                return userPayroll.id === _.toNumber(selectedPayroll);
            });

            if (matchedPayroll) {
                selectedCompanies.push(matchedPayroll.company_id);
            }
        });

        return _.uniq(selectedCompanies);
    }

    /**
     * Handles the api call to add a document
     *
     * @param {HTMLFormElement} form The form data being submitted
     * @param {array} selectedPayrolls The payrolls selected on the form
     *
     * @return {void}
     */
    handleSubmit = (form, selectedPayrolls) => {
        const { appState: { payrolls }, history } = this.props;
        const companyIds = this.getCompanyIdsForUpload(payrolls, selectedPayrolls);
        const apiRequests = [];

        _.each(companyIds, (company) => {
            let data = new FormData(form);
            data.append("company", company);

            if (payrolls.length === 1) {
                data.append("payrolls[]", _.head(payrolls).id);
            }

            apiRequests.push(api.post('/file/company', data));
        });

        axios.all(apiRequests)
            .then(() => {
                appState.addNotification({
                    text: `Document successfully uploaded`,
                    type: "success",
                    duration: 5,
                }, () => {
                    history.go();
                });
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to upload document`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Handles the API calls
     *
     * @param {HTMLFormElement} form The form data being submitted
     * @param {array} selectedPayrolls The payrolls selected on the form
     *
     * @return {void}
     */
    handleFileSubmission = (form, selectedPayrolls = []) => {
        const snackBarMessage = [
            `Please note the file you are uploading will be visible to`,
            `${this.props.appState.employees.length} employees, do you wish to continue?`,
        ].join(' ');

        this.setState({
            showSnackBar: true,
            snackBarType: "warn",
            snackBarMessage,
            snackBarOnConfirm: () => this.handleSubmit(form, selectedPayrolls),
        });
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const {
            showSnackBar,
            snackBarType,
            snackBarMessage,
            snackBarOnConfirm,
        } = this.state;

        return (
            <>
                <DocumentUploadForm
                    handleSubmit={this.handleFileSubmission}
                    cancelUrl={paths.companyDocuments}
                    closeDrawer={this.props.closeDrawer}
                />
                {showSnackBar && (
                    <SnackBar
                        type={snackBarType}
                        message={snackBarMessage}
                        onConfirm={() => this.setState({showSnackBar: false}, snackBarOnConfirm)}
                        onCancel={() => this.setState({showSnackBar: false})}
                    />
                )}
            </>
        );
    }

}

export default withRouter(appState.attachState(CompanyDocumentAdd));
