import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import DefaultButton from './DefaultButton';

import './SecondaryButton.scss';

class SecondaryButton extends React.Component {

    static propTypes = {
        children: PropTypes.string.isRequired,
        className: PropTypes.string,
    }

    static defaultProps = {
        className: null,
    }

    /**
     * Renders the button
     *
     * @return {ReactElement} The button
     */
    render () {
        const { className, ...otherProps } = this.props;
        const buttonClass = classNames([
            "secondary-button",
            className,
        ]);

        return (
            <DefaultButton
                className={buttonClass}
                {...otherProps}
            >
                {this.props.children}
            </DefaultButton>
        );
    }

}

// eslint-disable-next-line react/no-multi-comp
export default React.forwardRef((props, ref) => {
    return <SecondaryButton forwardedRef={ref} {...props} />;
});
