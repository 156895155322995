import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import api from '../../lib/api';
import { paths } from "../../lib";
import appState from '../../state/App';
import _ from 'lodash';
import queryString from 'query-string';

import {
    DataTable,
    SnackBar,
    InlineEdit,
} from '../../components';
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";
import {
    formatFormalName,
    getFirstEmail,
    getFullFirstEmail,
    getPayrollName,
    getFullPayrollName,
    filterObjectEmptyValues,
} from 'lib/';
import GetEmployeeListActions from './assets/GetEmployeeListActions';
import AdvancedSearch from "./assets/AdvancedSearch";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

class ViewEmployeeList extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        location: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the employee list
     *
     * @param {object} props List properties
     */
    constructor (props) {
        super(props);

        this.state = {
            activeEmployees: [],
            activationRequiredEmployees: [],
            archivedEmployees: [],
            dataLoaded: false,
            showSnackBar: false,
            snackBarType: "",
            snackBarMessage: "",
            snackBarHeading: "",
            snackBarOnConfirm: null,
            redrawRequired: false,
        };

        this.setInitialTabIndexFromUrl();
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleApiCall();
    }

    /**
     * Invoked when the component updates
     *
     * @return {void}
     */
    componentDidUpdate () {
        if (this.state.redrawRequired) {
            this.handleApiCall();
        }
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Sets initial tab index to pass to page layout component based on filter query in url
     *
     * @return {void}
     */
    setInitialTabIndexFromUrl = () => {
        const { location } = this.props;
        const { filter } = queryString.parse(location.search);

        this.initialTabIndex = (filter === "toActivate") ? 1 : 0;
    }

    /**
     * Called once the component has loaded, queries the API for employees
     *
     * @return {void}
     */
    handleApiCall = () => {
        const { payrolls } = this.props.appState;
        const requests = [];

        _.each(payrolls, (payroll) => {
            requests.push(api.get('/employees', {
                component: this,
                params: {
                    payroll_id: payroll.id, /* eslint-disable-line camelcase */
                    include_leavers: true, /* eslint-disable-line camelcase */
                },
            }));
        });

        axios.all(requests)
            .then((responses) => {
                const employees = responses.reduce((acc, response) => {
                    acc.push(...response.data);
                    return acc;
                }, []);

                appState.setEmployees(employees);

                this.setState({
                    redrawRequired: false,
                }, () => {
                    this.splitEmployeesLeavers(employees);
                });
            })
            .catch(() => {
                // Will catch if axios request is canceled
            });
    }

    /**
     * Forces a redraw of the HOC to query the API again for changes
     * This is done as the API result is stored in the state, and display default values
     * are taken from this
     *
     * @return {void}
     */
    handleRedraw = () => {
        this.setState({
            dataLoaded: false,
            redrawRequired: true,
        });
    }

    /**
     * Function passed to the actions menu to create snackbars for confirmation
     *
     * @param {Object} snackBar The snackbar properties
     *
     * @return {void}
     */
    createSnackBarConfirmation = (snackBar) => {
        const {
            type,
            heading,
            message,
            onConfirm,
        } = snackBar;

        this.setState({
            showSnackBar: true,
            snackBarType: type,
            snackBarMessage: message,
            snackBarHeading: heading,
            snackBarOnConfirm: onConfirm,
        });
    }

    /**
     * Splits employee data into 3 arrays; current, to activate, and leavers
     *
     * @param {array} employees All employees returned from the API
     *
     * @return {void}
     */
    splitEmployeesLeavers = (employees) => {
        const state = employees.reduce(this.employeeReducer, {
            activeEmployees: [],
            activationRequiredEmployees: [],
            archivedEmployees: [],
            dataLoaded: true,
        });

        this.setState(state);
    }

    /**
     * Function used for reducer in splitEmployeesLeavers method
     *
     * @param {object} acc The accumulator
     * @param {object} employee The employee
     *
     * @return {object} The accumulator
     */
    employeeReducer = (acc, employee) => {
        const { activated, enabled } = employee;
        const activationRequired = (!activated && enabled);
        const formattedEmployee = this.formatEmployeeData(employee, activationRequired);

        if (activated && enabled) {
            acc.activeEmployees.push(formattedEmployee);
        } else if (activationRequired) {
            acc.activationRequiredEmployees.push(formattedEmployee);
        } else {
            acc.archivedEmployees.push(formattedEmployee);
        }

        return acc;
    }

    /**
     * Formats employee data to be read by data table
     *
     * @param {object} employee The employee
     * @param {boolean} activationRequired If activation is required
     *
     * @return {object} Formatted data of employee
     */
    formatEmployeeData = (employee, activationRequired) => {
        const { payrolls, companies } = this.props.appState;
        const multiplePayrolls = (payrolls.length > 1);

        const formattedEmployee = this.getBasicEmployeeFormat(employee);

        if (activationRequired) {
            formattedEmployee.activationSent = {
                text: "",
            };
        }

        if (multiplePayrolls) {
            formattedEmployee.payroll = {
                text: getPayrollName(employee.payroll_id, payrolls, companies),
                component: this.wrapTooltip(
                    getFullPayrollName(employee.payroll_id, payrolls, companies),
                    getPayrollName(employee.payroll_id, payrolls, companies)
                ),
            };
        }

        return filterObjectEmptyValues(formattedEmployee);
    };

    /**
     * Formats employee data to be read by data table
     *
     * @param {object} employee The employee
     *
     * @return {object} Formatted data of employee
     */
    getBasicEmployeeFormat = (employee) => {
        return {
            id: employee.id,
            employeeName: {
                text: formatFormalName(employee),
                // eslint-disable-next-line camelcase
                link: generatePath(paths.employee, { employee_id: employee.id }),
            },
            payrollNo: {
                text: employee.payroll_no || "-",
            },
            niNo: {
                text: employee.nino,
                component: this.wrapEditableField(employee, "ni", employee.nino, 'nino'),
            },
            department: {
                text: employee.department || "-",
            },
            employeeStatus: {
                text: employee.employee_status || "-",
            },
            emailAddress: {
                text: employee.emails ? [employee.emails.work, employee.emails.home] : "-",
                component: this.wrapTooltip(
                    getFullFirstEmail(employee.emails),
                    this.wrapEditableField(
                        employee,
                        "emails",
                        getFullFirstEmail(employee.emails),
                        'email',
                        getFirstEmail(employee.emails),
                    ),
                ),
            },
            mobileNo: {
                text: employee.phone_numbers.mobile,
            },
            DOB: {
                text: moment(employee.date_of_birth).format('DD-MM-YYYY'),
            },
        };
    }

    /**
     * Adds a tooltip to show the full company name
     *
     * @param {string} tooltip full company name
     * @param {string} display shortened company name
     *
     * @return {ReactElement} The tooltip component
     */
    wrapTooltip = (tooltip, display) => {
        return (
            <Tooltip text={tooltip} position="bottom">
                {display}
            </Tooltip>
        );
    }

    /**
     * Simple wrapper to display the inline edit component for various rows in the table
     *
     * @param {object} employee The employee represented in the row
     * @param {string} key The textual ID of the field we are editing
     * @param {string} edit The editable part of the component
     * @param {string} type The type of field, used in validation or adding a date picker
     * @param {mixed} display What we are going to display inside the editable component
     *
     * @return {ReactElement} The inline edit component
     */
    wrapEditableField = (employee, key, edit, type = 'string', display) => {
        if (!employee.user_can_edit || !type) {
            return display;
        }

        return (
            <InlineEdit
                patchUrl={`/employee/${employee.id}`}
                objectKey={key}
                handleRedraw={this.handleRedraw}
                type={type}
                inDataTable={true}
                emphasizeEmpty={true}
                fullEdit={display}
                field={type}
            >
                {edit || "-"}
            </InlineEdit>
        );
    }

    /**
     * Provides a structured object of attributes to be used when creating the data table
     *
     * @return {object} The attributes for the data table
     */
    getDataTableAttrs = () => {
        const { payrolls } = this.props.appState;
        const multiplePayrolls = (payrolls.length > 1);

        const colOrder = [
            "employeeName",
            "payroll",
            "payrollNo",
            "niNo",
            "department",
            "employeeStatus",
            "activationSent",
            "actions",
        ];

        const activationRequiredColOrder = [
            "employeeName",
            "payroll",
            "payrollNo",
            "niNo",
            "department",
            "employeeStatus",
            "emailAddress",
            "activationSent",
            "actions",
        ];

        const activeTabHeadings = filterObjectEmptyValues({
            employeeName: "Employee Name",
            payroll: (multiplePayrolls ? "Payroll" : null),
            payrollNo: "Payroll No.",
            niNo: "NI No.",
            department: "Department",
            employeeStatus: "Employee Status",
            actions: "",
        });

        const filters = filterObjectEmptyValues({
            payroll: (multiplePayrolls ? "Payroll" : null),
            department: "Department",
            employeeStatus: "Employee Status",
        });

        const activationRequiredTabHeadings = filterObjectEmptyValues({
            employeeName: "Name",
            payrollNo: "Payroll No.",
            niNo: "NI No.",
            payroll: (multiplePayrolls ? "Payroll" : null),
            department: "Department",
            employeeStatus: "Status",
            emailAddress: "Email Address",
            activationSent: "Activation Sent",
            actions: "",
        });

        const activeHiddenCols = {
            small: [
                'payroll',
                'department',
                'employeeStatus',
            ],
            medium: [
                'payroll',
                'employeeStatus',
            ],
            large: [
                'employeeStatus',
            ],
        };

        const activationRequiredHiddenCols = {
            small: [
                'payroll',
                'department',
                'employeeStatus',
                'activationSent',
            ],
            medium: [
                'payroll',
                'employeeStatus',
                'activationSent',
            ],
            large: [
                'payroll',
                'employeeStatus',
                'activationSent',
            ],
        };

        return {
            activeTabHeadings,
            filters,
            activationRequiredTabHeadings,
            activeHiddenCols,
            activationRequiredHiddenCols,
            colOrder,
            activationRequiredColOrder,
        };
    }

    /**
     * Gets advanced search for active drawer
     *
     * @return {array} The advanced search content
     */
    getActiveAdvancedSearch = () => {
        const { activeEmployees } = this.state;

        return (<AdvancedSearch data={activeEmployees} />);
    }

    /**
     * Gets advanced search for active drawer
     *
     * @return {array} The advanced search content
     */
    getActivationRequiredAdvancedSearch = () => {
        const { activationRequiredEmployees } = this.state;

        return (<AdvancedSearch data={activationRequiredEmployees} />);
    }

    /**
     * Gets advanced search for active drawer
     *
     * @return {array} The advanced search content
     */
    getArchivedAdvancedSearch = () => {
        const { archivedEmployees } = this.state;

        return (<AdvancedSearch data={archivedEmployees} />);
    }

    /**
     * Returns the tab content for the page layout
     *
     * @return {array} The tab content
     */
    getTabContent = () => {
        const {
            activeEmployees,
            activationRequiredEmployees,
            archivedEmployees,
            dataLoaded,
        } = this.state;

        const {
            activeTabHeadings,
            filters,
            activationRequiredTabHeadings,
            activeHiddenCols,
            activationRequiredHiddenCols,
            colOrder,
            activationRequiredColOrder,
        } = this.getDataTableAttrs();

        const {
            activeTabActions,
            activationRequiredActions,
            archivedActions,
            activeBulkActions,
            activationRequiredBulkActions,
            archivedBulkActions,
        } = new GetEmployeeListActions(this.handleRedraw, this.createSnackBarConfirmation);

        return [
            {
                text: "Active",
                url: "/active",
                component: (
                    <DataTable
                        key="active"
                        colOrder={colOrder}
                        data={activeEmployees}
                        dataLoaded={dataLoaded}
                        headings={activeTabHeadings}
                        hidden={activeHiddenCols}
                        showCheckBoxes={true}
                        searchable={true}
                        advancedSearch={activeEmployees.length > 0}
                        advancedSearchComponent={this.getActiveAdvancedSearch}
                        sortable={true}
                        actions={activeTabActions}
                        bulkActions={activeBulkActions}
                        filterable={filters}
                        emptySearch="employees"
                    />
                ),
            },
            {
                text: "Activation Required",
                url: "/activation-required",
                component: (
                    <DataTable
                        key="activationRequired"
                        colOrder={activationRequiredColOrder}
                        data={activationRequiredEmployees}
                        dataLoaded={dataLoaded}
                        headings={activationRequiredTabHeadings}
                        hidden={activationRequiredHiddenCols}
                        showCheckBoxes={true}
                        searchable={true}
                        advancedSearch={activationRequiredEmployees.length > 0}
                        advancedSearchComponent={this.getActivationRequiredAdvancedSearch}
                        sortable={true}
                        filterable={filters}
                        actions={activationRequiredActions}
                        bulkActions={activationRequiredBulkActions}
                        emptySearch="employees"
                    />
                ),
            },
            {
                text: "Archived",
                url: "/archived",
                component: (
                    <DataTable
                        key="archived"
                        colOrder={colOrder}
                        data={archivedEmployees}
                        dataLoaded={dataLoaded}
                        headings={activeTabHeadings}
                        hidden={activeHiddenCols}
                        showCheckBoxes={true}
                        searchable={true}
                        advancedSearch={archivedEmployees.length > 0}
                        advancedSearchComponent={this.getArchivedAdvancedSearch}
                        sortable={true}
                        filterable={filters}
                        actions={archivedActions}
                        bulkActions={archivedBulkActions}
                        emptySearch="employees"
                    />
                ),
            },
        ];
    }

    /**
     * Renders the employee list page
     *
     * @return {ReactElement} The employee list for a given payroll
     */
    render () {
        const {
            showSnackBar,
            snackBarType,
            snackBarMessage,
            snackBarHeading,
            snackBarOnConfirm,
        } = this.state;

        return (
            <>
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={200}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    <PageLayout
                        heading={{
                            text: `Employees`,
                            size: "h1",
                        }}
                        isRouted={true}
                        pageType="boxed"
                        maxWidth={this.props.appState.maxWidth}
                        display={{
                            isDetached: false,
                            isPadded: true,
                            hasBackground: true,
                            hasGutter: true,
                        }}
                        tabContent={this.getTabContent()}
                        initialTabIndex={this.initialTabIndex}
                    />
                </AnimationContainer>

                { showSnackBar && (
                    <SnackBar
                        type={snackBarType}
                        heading={snackBarHeading}
                        message={snackBarMessage}
                        onConfirm={() => this.setState({showSnackBar: false}, snackBarOnConfirm)}
                        onCancel={() => this.setState({showSnackBar: false})}
                    />
                )}
            </>
        );
    }

}

export default withRouter(appState.attachState(ViewEmployeeList));
