import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';

import PrimaryButton from '../../../components/forms/controls/PrimaryButton';
import SecondaryButton from '../../../components/forms/controls/SecondaryButton';
import { TertiaryButton } from "@dataplan/react-components/dist/components/forms/controls";

import { defaultAccentColour } from '../../../Colours';

export default class ButtonSection extends React.Component {

    /**
     * Renders the button section of the styleguide
     *
     * @return {ReactElement} The button section
     */
    render () {
        return (
            <CardRow>
                <CardRowContainer gridWidth={12}>
                    <ContentCard heading="Buttons &amp; Actions">
                        <table className="styleguide-button-section">
                            <tbody>
                                <tr>
                                    <th />
                                    <th>Normal</th>
                                    <th>Disabled</th>
                                </tr>
                                <tr>
                                    <th>Primary</th>
                                    <td><PrimaryButton>Manage</PrimaryButton></td>
                                    <td><PrimaryButton disabled>Manage</PrimaryButton></td>
                                </tr>
                                <tr>
                                    <th>Secondary</th>
                                    <td><SecondaryButton>Manage</SecondaryButton></td>
                                    <td><SecondaryButton disabled>Manage</SecondaryButton></td>
                                </tr>
                                <tr>
                                    <th>Tertiary</th>
                                    <td>
                                        <TertiaryButton
                                            accent={defaultAccentColour}
                                            text={"Manage"}
                                            aria-label={"Manage"}
                                        />
                                    </td>
                                    <td>
                                        <TertiaryButton
                                            disabled
                                            accent={defaultAccentColour}
                                            text={"Manage"}
                                            aria-label={"Manage"}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
