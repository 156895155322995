import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';

export default class FiltersSection extends React.Component {

    /**
     * Renders the table filters section
     *
     * @return {ReactElement} The section
     */
    render () {
        return (
            <CardRow>
                <CardRowContainer gridWidth={6}>
                    <ContentCard heading="Advanced Filters">
                        Filters
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
