import _ from 'lodash';

/**
 * Returns true or false if the query string is found in the data
 *
 * @param {object} data The data to query against
 * @param {string} queryString The query string to use
 *
 * @return {boolean} If the query is found
 */
const dataSetSearch = (data, queryString) => {
    if (queryString.length < 1) {
        return true;
    }

    const queryStringRegEx = RegExp(queryString, 'i');

    if (!_.isObject(data)) {
        return queryStringRegEx.test(data);
    }

    const result = _.filter(data, (field, key) => {
        if (key === "id") {
            return false;
        }

        if (_.isObject(field)) {
            return queryStringRegEx.test(field.searchText || field.text);
        }

        return queryStringRegEx.test(field);
    });

    return result.length > 0;
};

export default dataSetSearch;
