/**
 * Parse the content provided replacing the template tags with their values
 *
 * @param {string} value The value to parse
 * @param {object} tags The template tags
 *
 * @return {string} The parsed value with tags replaced
 */
const parseTemplateTags = (value, tags) => {
    const regEx = new RegExp(Object.keys(tags).join("|"), "gm");

    return value.replace(regEx, (match) => {
        return tags[match];
    });
};

export default parseTemplateTags;
