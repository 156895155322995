import PropTypes from 'prop-types';

export const documentsPropTypes = PropTypes.arrayOf(PropTypes.shape({
    "id": PropTypes.number.isRequired,
    "filename": PropTypes.string.isRequired,
    "description": PropTypes.string.isRequired,
    "datetime_added": PropTypes.string.isRequired,
    "type": PropTypes.oneOf(['personalcompany', 'pension']).isRequired,
    "status": PropTypes.string.isRequired,
    "uploaded_by": PropTypes.string.isRequired,
}));

export const documentsDefaultProps = [];

export default documentsPropTypes;
