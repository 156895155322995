import api from '../api';
import appState from '../../state/App';
import noEmployeesSelected from './noEmployeesSelected';
import { StringHelper } from '../StringHelper';

/**
 * Handles the archiving of a single user (quick action)
 *
 * @param {number} employeeId The employees ID
 * @param {bool} reinstate If we are reinstating as opposed to archiving
 * @param {?object} employeeData The employees data for a single action to customise response
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 *
 * @return {void}
 */
const handleSingleArchive = (employeeId, reinstate, employeeData, redrawCallback) => {
    const method = (reinstate) ? 'reinstate' : 'archive';
    const employeeName = new StringHelper(employeeData.employeeName.text).parseName();

    api.put(`/employee/${employeeId}`, {
        enabled: reinstate,
    }).then(() => {
        appState.addNotification({
            text: `${employeeName}'s account ${method}d`,
            type: "success",
            duration: 5,
        }, redrawCallback);
    }).catch(() => {
        appState.addNotification({
            text: `Failed to ${method} account for ${employeeName}`,
            type: "error",
            duration: 0,
        });
    });
};

/**
 * Handles the archiving of multiple users (bulk action)
 *
 * @param {number[]} employeeIds Array of employee IDs
 * @param {bool} reinstate If we are reinstating as opposed to archiving
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 *
 * @return {void}
 */
const handleMultipleArchive = (employeeIds, reinstate, redrawCallback) => {
    const method = (reinstate) ? 'reinstate' : 'archive';

    api.put(`/employees/bulk/archive`, {
        'employee_ids': employeeIds,
        method: (reinstate) ? 'unarchive' : 'archive',
    }).then(({ data }) => {
        if (data.successful) {
            appState.addNotification({
                text: `${data.successful.length} accounts have been ${method}d`,
                type: "success",
                duration: 5,
            }, redrawCallback);
        }

        if (data.failed) {
            appState.addNotification({
                text: `Failed to ${method} ${data.failed.length} accounts`,
                type: "error",
                duration: 0,
            });
        }
    }).catch(() => {
        appState.addNotification({
            text: `Failed to ${method} accounts`,
            type: "error",
            duration: 0,
        });
    });
};

/**
 * Prompts the user to confirm archiving a single account
 *
 * @param {number} employeeId The employees ID
 * @param {bool} reinstate If we are reinstating as opposed to archiving
 * @param {?object} employeeData The employees data for a single action to customise response
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 */
const confirmSingleArchive = (employeeId, reinstate, employeeData, redrawCallback, createSnackBarConfirmation) => {
    const employeeName = new StringHelper(employeeData.employeeName.text).parseName();
    const method = (reinstate) ? 'reinstate' : 'archive';

    createSnackBarConfirmation({
        type: "warn",
        heading: `${employeeName}'s account will be ${method}d`,
        message: `Are you sure you want to ${method} this user account?`,
        onConfirm: () => handleSingleArchive(employeeId, reinstate, employeeData, redrawCallback),
    });
};

/**
 * Prompts the user to confirm archiving multiple accounts
 *
 * @param {number[]} employeeIds Array of employee IDs
 * @param {bool} reinstate If we are reinstating as opposed to archiving
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 */
const confirmMultipleArchive = (employeeIds, reinstate, redrawCallback, createSnackBarConfirmation) => {
    const selectedCount = employeeIds.length;
    const method = (reinstate) ? 'reinstate' : 'archive';

    createSnackBarConfirmation({
        type: "warn",
        heading: `Accounts for ${selectedCount} selected employees will be ${method}d`,
        message: `Are you sure you want to ${method} the selected user accounts?`,
        onConfirm: () => handleMultipleArchive(employeeIds, reinstate, redrawCallback),
    });
};

/**
 * Handles archiving an employee
 *
 * @param {number[]} employeeIds Array of employee IDs
 * @param {bool} reinstate If we are reinstating as opposed to archiving
 * @param {?object} employeeData The employees data for a single action to customise response
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 */
const handleArchivingEmployee = (
    employeeIds,
    reinstate = false,
    employeeData = null,
    redrawCallback = null,
    createSnackBarConfirmation = null,
) => {
    if (employeeIds.length < 1) {
        noEmployeesSelected();

        return;
    }

    if (employeeIds.length === 1 && employeeData) {
        confirmSingleArchive(employeeIds[0], reinstate, employeeData, redrawCallback, createSnackBarConfirmation);
    } else {
        confirmMultipleArchive(employeeIds, reinstate, redrawCallback, createSnackBarConfirmation);
    }
};

export default handleArchivingEmployee;
