import React from 'react';
import _ from 'lodash';
import appState from 'state/App';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import {
    PropSchema,
    TemplateLayout,
    TemplateTextInput,
    TemplateTextarea,
    TemplateTitle,
    TemplateParagraph,
    TemplateList,
    TemplateSteps,
} from '../';

class InstructionsNewExistingEmployees extends React.Component {

    static propTypes = PropSchema.getPropTypes;

    static defaultProps = PropSchema.getDefaultProps;

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.state = {
            valuesLoaded: false,
            canSubmit: {
                content: false,
                settings: false,
            },
        };
    }

    /**
     * Called when the component props have changed
     *
     * @param {object} prevProps The components previous props
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.setInitialValues();
        }
    }

    /**
     * Set the initial values of the inputs from the API into the component state
     *
     * @return {void} Modifies state
     */
    setInitialValues () {
        const { templateValues, payroll } = this.props;

        if (_.isEmpty(templateValues)) {
            return;
        }

        this.setState({
            valuesLoaded: false,
            canSubmit: {
                content: false,
                settings: false,
            },
        });

        const { epay_signupmethod: epaySignupMethod } = payroll;
        const { epay_useblank_templates: epayUseBlankTemp } = payroll;

        if (epayUseBlankTemp) {
            this.setUseBlankTempValues(templateValues);
        } else if (epaySignupMethod !== 0) {
            this.setTemplateValues(templateValues);
        }
    }

    /**
     * Set the values for template when epay_useblank_templates is set
     *
     * @param {object} values The values from the API
     *
     * @return {void} Modifies state
     */
    setUseBlankTempValues (values) {
        const headerKey = (this.props.templateKey === "instructions_existing_employees")
            ? "header_reminder"
            : "header_new_employee";

        this.setState({
            from_name: { // eslint-disable-line camelcase
                value: values.from_name,
                hasChanged: false,
            },
            from_email: { // eslint-disable-line camelcase
                value: values.from_email,
                hasChanged: false,
            },
            subject: {
                value: values.subject,
                hasChanged: false,
            },
            [headerKey]: {
                value: values[headerKey],
                hasChanged: false,
            },
            valuesLoaded: true,
        });
    }

    /**
     * Set the values for template
     *
     * @param {object} values The values from the API
     *
     * @return {void} Modifies state
     */
    setTemplateValues (values) {
        const headerKey = (this.props.templateKey === "instructions_existing_employees")
            ? "header_reminder"
            : "header_new_employee";

        this.setState({
            from_name: { // eslint-disable-line camelcase
                value: values.from_name,
                hasChanged: false,
            },
            from_email: { // eslint-disable-line camelcase
                value: values.from_email,
                hasChanged: false,
            },
            subject: {
                value: values.subject,
                hasChanged: false,
            },
            [headerKey]: {
                value: values[headerKey],
                hasChanged: false,
            },
            footer: {
                value: values.footer,
                hasChanged: false,
            },
            valuesLoaded: true,
        });
    }

    /**
     * Called when an input value is changes
     *
     * @param {event} event The onChange event
     * @param {string} key The name of the input
     * @param {string} section If the input is in the content or settings tab
     *
     * @return {void} Modifies state
     */
    handleInputChange = (event, key, section) => {
        event.preventDefault();

        const value = event.target.value;

        this.setState((prevState) => {
            return {
                [key]: {
                    value,
                    hasChanged: true,
                },
                canSubmit: {
                    ...prevState.canSubmit,
                    [section]: true,
                },
            };
        }, appState.blockNavigation());
    }

    /**
     * Get the template elments to render - Intro
     *
     * @param {boolean} isPreview If the components are being displayed in the preview tab
     *
     * @return {ReactElement[]} The components to render
     */
    getIntro (isPreview) {
        const { valuesLoaded, subject } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        return [
            <TemplateTextInput
                key="subject"
                name="subject"
                label="Subject"
                value={subject.value}
                onChange={(event) => this.handleInputChange(event, "subject", "content")}
                isPreview={isPreview}
                showInPreview
                subject
            />,
        ];
    }

    /**
     * Get the template elments to render - Section 1
     *
     * @param {boolean} isPreview If the components are being displayed in the preview tab
     *
     * @return {ReactElement[]} The components to render
     */
    getSectionOne (isPreview) {
        const { templateContent, templateTags } = this.props;
        const headerKey = (this.props.templateKey === "instructions_existing_employees")
            ? "header_reminder"
            : "header_new_employee";
        const { valuesLoaded, [headerKey]: headerValue } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        return [
            <TemplateTextarea
                key={headerKey}
                name={headerKey}
                label="Intro"
                value={headerValue.value}
                onChange={(event) => this.handleInputChange(event, headerKey, "content")}
                isPreview={isPreview}
                showInPreview
                templateTagList={templateContent.template_tags}
                templateTagValues={templateTags}
            />,
        ];
    }

    /**
     * Get the template elments to render - Section 1 when using blank templates
     *
     * @param {boolean} isPreview If the components are being displayed in the preview tab
     *
     * @return {ReactElement[]} The components to render
     */
    getSectionOneBlankTemp (isPreview) {
        const { templateContent, templateTags } = this.props;
        const headerKey = (this.props.templateKey === "instructions_existing_employees")
            ? "header_reminder"
            : "header_new_employee";
        const { valuesLoaded, [headerKey]: headerValue } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        return [
            <TemplateTextarea
                key={headerKey}
                name={headerKey}
                label="Message"
                value={headerValue.value}
                onChange={(event) => this.handleInputChange(event, headerKey, "content")}
                isPreview={isPreview}
                showInPreview
                templateTagList={templateContent.template_tags}
                templateTagValues={templateTags}
            />,
        ];
    }

    /**
     * Get the template elments to render - Section 2
     *
     * @param {boolean} isPreview If the components are being displayed in the preview tab
     *
     * @return {ReactElement[]} The components to render
     */
    getSectionTwo (isPreview) {
        const { templateContent } = this.props;
        const { valuesLoaded } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        const section2 = templateContent.content.section_2;

        return [
            <TemplateTitle
                key={section2.sub_title}
                value={section2.sub_title}
                isPreview={isPreview}
                showInPreview
                size="h3"
            />,
            <TemplateParagraph
                key={section2.list_label}
                value={section2.list_label}
                isPreview={isPreview}
                showInPreview
            />,
            <TemplateList
                key={section2.info_required}
                value={section2.info_required}
                isPreview={isPreview}
                showInPreview
                isOrdered
            />,
            <TemplateSteps
                key={section2.steps}
                value={section2.steps}
                isPreview={isPreview}
                showInPreview
            />,
            <TemplateParagraph
                key={section2.outro}
                value={section2.outro}
                isPreview={isPreview}
                showInPreview
            />,
        ];
    }

    /**
     * Get the template elments to render - Section 3
     *
     * @param {boolean} isPreview If the components are being displayed in the preview tab
     *
     * @return {ReactElement[]} The components to render
     */
    getSectionThree (isPreview) {
        const { templateContent, templateTags } = this.props;
        const { valuesLoaded, footer } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        return [
            <TemplateTextarea
                key="footer"
                name="footer"
                label="Outro"
                value={footer.value}
                onChange={(event) => this.handleInputChange(event, "footer", "content")}
                isPreview={isPreview}
                showInPreview
                templateTagList={templateContent.template_tags}
                templateTagValues={templateTags}
            />,
        ];
    }

    /**
     * Get the template elments to render - Setting tab
     *
     * @return {ReactElement[]} The values to render
     */
    getTemplateSettings () {
        const { valuesLoaded, from_name: fromName, from_email: fromEmail } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        return [
            <TemplateTextInput
                key="from_name"
                name="from_name"
                label="From name"
                value={fromName.value}
                onChange={(event) => this.handleInputChange(event, "from_name", "settings")}
                isPreview={false}
            />,
            <TemplateTextInput
                key="from_email"
                name="from_email"
                label="Reply to"
                value={fromEmail.value}
                onChange={(event) => this.handleInputChange(event, "from_email", "settings")}
                isPreview={false}
            />,
        ];
    }

    /**
     * Get the template content for the page based on epay_useblank_templates
     *
     * @param {boolean} isPreview If the components are being displayed in the preview tab
     *
     * @return {array} The values to render
     */
    getTemplateContent (isPreview = false) {
        const { epay_useblank_templates: epayUseBlankTemp } = this.props.payroll;

        if (epayUseBlankTemp) {
            return _.flattenDeep([
                this.getIntro(isPreview),
                this.getSectionOneBlankTemp(isPreview),
            ]);
        } else {
            return _.flattenDeep([
                this.getIntro(isPreview),
                this.getSectionOne(isPreview),
                this.getSectionTwo(isPreview),
                this.getSectionThree(isPreview),
            ]);
        }
    }

    /**
     * Get the template view when epay_signup_method is 0
     *
     * @return {ReactElement} The template view
     */
    renderMethodZeroContent () {
        const { templateContent } = this.props;
        const title = templateContent.name || "Template";
        const intro = (templateContent.content) ? templateContent.content.intro : "";

        return (
            <PageLayout
                heading={{
                    text: title,
                    size: "h1",
                }}
                pageType="boxed"
                maxWidth={this.props.appState.maxWidth}
                display={{
                    isDetached: false,
                    isPadded: true,
                    hasBackground: true,
                    hasGutter: true,
                }}
            >
                <p>{intro}</p>
            </PageLayout>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { onComponentUnmount, dataLoaded } = this.props;
        const { canSubmit } = this.state;

        const { epay_signupmethod: epaySignupMethod } = this.props.payroll;

        if (epaySignupMethod === 0) {
            return this.renderMethodZeroContent();
        }

        const preview = this.getTemplateContent(true);
        const content = this.getTemplateContent(false);
        const settings = this.getTemplateSettings();
        const rawValues = _.omit(this.state, ["valuesLoaded", "canSubmit"]);

        return (
            <TemplateLayout
                dataLoaded={dataLoaded}
                title={this.props.templateContent.name}
                preview={preview}
                content={content}
                settings={settings}
                rawValues={rawValues}
                canSubmit={canSubmit}
                onComponentUnmount={onComponentUnmount}
            />
        );
    }

}

export default InstructionsNewExistingEmployees;
