import React from 'react';
import PropTypes from 'prop-types';

import { TextInput } from '@dataplan/react-components/dist/components/forms';

import styles from './DataTableSearch.module.scss';

export default class DataTableSearch extends React.Component {

    static propTypes = {
        onSearchInput: PropTypes.func.isRequired,
        queryString: PropTypes.string,
    }

    static defaultProps = {
        queryString: '',
    }

    /**
     * Renders the data table search input
     *
     * @return {ReactElement} The data table search input
     */
    render () {
        return (
            <TextInput
                aria-label="Search"
                type="search"
                name="search_term"
                className={styles.dataTableSearchInput}
                placeholder="Search"
                value={this.props.queryString}
                onChange={this.props.onSearchInput}
            />
        );
    }

}
