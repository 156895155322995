import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../icons/Icon';

import styles from './DataTableSortButton.module.scss';

export default class DataTableSortButton extends React.Component {

    static propTypes = {
        onSetSort: PropTypes.func,
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        sortOrder: PropTypes.oneOf(["asc", "desc", null]),
    }

    static defaultProps = {
        onSetSort: null,
        sortOrder: null,
    }

    /**
     * Displays the sort order arrow on the button
     *
     * @param {string|null} sortOrder The order the column is sorted
     *
     * @return {ReactElement} The corrent icon
     */
    displaySortArrow = (sortOrder) => {
        if (!sortOrder) {
            return null;
        }

        const classList = classNames(styles.sortIcon, {
            [styles.arrowUp]: (sortOrder === "asc"),
        });

        return (
            <Icon
                icon={"ArrowUp"}
                className={classList}
                width="15"
                height="15"
                aria-label={this.props.text}
                value={this.props.text}
            />
        );
    }

    /**
     * Renders the sort button
     *
     * @return {ReactElement} The sort button
     */
    render () {
        return (
            <button
                type="button"
                onClick={this.props.onSetSort}
                value={this.props.value}
                className={styles.sortButton}
                aria-label={this.props.value}
            >
                {this.displaySortArrow(this.props.sortOrder)}
                {this.props.text}
            </button>
        );
    }

}
