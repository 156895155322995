import React from 'react';
import { paths } from "../../../lib";
import _ from 'lodash';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import CardFrame from '../../../components/cards/CardFrame';
import PayrollStat from './PayrollStat';
import Circle from "../../../components/circles/Circle";
import NonGender from '../../../components/icons/assets/NonGender';
import { calculatePercentage } from '../../../lib/mathHelpers';
import EmployeeSearchDashboard from "./EmployeeSearchDashboard";

import styles from './PayrollStatsSection.module.scss';

class PayrollStatsSection extends React.Component {

    static propTypes = {
        employees: PropTypes.array.isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Helper function to get total counts of each account status of employees
     *
     * @param {array} employees An array of employees
     * @return {object} An object containing the total counts of each account status of employees
     */
    getAccountStatusTotals (employees) {
        return _.reduce(employees, (totals, employee) => {
            if (employee.account_status === "Active") {
                totals.activeTotal++;
            } else if (employee.account_status === "Not Activated") {
                totals.toActivateTotal++;
            }

            return totals;
        }, {
            activeTotal: 0,
            toActivateTotal: 0,
        });
    }

    /**
     * Creates an employee SVG with an active percentage being applied for the fill offset animation
     *
     * @param {number} activePercentage The current percentage - used to create the offset animation
     * @return {ReactElement} The employee SVG
     */
    renderEmployeeSVGs (activePercentage) {
        if (!activePercentage && activePercentage !== 0) {
            return null;
        }

        return (
            <NonGender offset={activePercentage} id="employee" />
        );
    }

    /**
     * Renders the payroll stats section
     *
     * @return {ReactElement} The stats container
     */
    render () {
        const { employees, history } = this.props;
        const { activeTotal, toActivateTotal } = this.getAccountStatusTotals(employees);
        const employeesTotal = activeTotal + toActivateTotal;
        const activePercentage = calculatePercentage(activeTotal, employeesTotal) || 0;

        if (!employees) {
            return (
                <CardFrame theme="light" className={styles.card}>
                    <LoadingSpinner label="Loading" />
                </CardFrame>
            );
        }

        return (
            <CardFrame theme="light" className={styles.card}>
                <div className={styles.list}>
                    <EmployeeSearchDashboard employees={employees} />
                    <div className={styles.statSignup}>
                        <div className={styles.statColumns}>
                            <div className={styles.signupHeading}>Sign up rate</div>
                            <Circle text={`${activePercentage}%`} />
                        </div>
                        <div className={styles.employeeIcons}>
                            {this.renderEmployeeSVGs(activePercentage)}
                        </div>
                    </div>
                    <dl className={styles.statRow}>
                        <PayrollStat
                            onClick={() => history.push(paths.employees)}
                            term="employees"
                            value={employeesTotal.toString()}
                        />
                        <PayrollStat
                            onClick={() => history.push(paths.employees)}
                            term="active"
                            value={activeTotal.toString()}
                        />
                        <PayrollStat
                            onClick={() => history.push(`${paths.employees}?filter=toActivate`)}
                            term="to activate"
                            value={toActivateTotal.toString()}
                        />
                    </dl>
                </div>
            </CardFrame>
        );
    }

}

export default withRouter(PayrollStatsSection);
