import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import './Pagination.scss';

export default class Pagination extends React.Component {

    static propTypes = {
        total: PropTypes.number.isRequired,
        defaultPage: PropTypes.number,
        maxButtons: PropTypes.number,
        onPageChange: PropTypes.func.isRequired,
    }

    static defaultProps = {
        defaultPage: 1,
        maxButtons: 5,
    }

    /**
     * Creates an instance of the page control
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            currentPage: props.defaultPage,
        };
    }

    /**
     * Updates the tracked page number and passes an event up to the parent
     *
     * @param {integer} newPage The new page number
     */
    setNewPage (newPage) {
        this.setState({ currentPage: newPage });
        this.props.onPageChange(newPage);
    }

    /**
     * Called when one of the number buttons is clicked.
     *
     * @param {integer} newPage The new page number
     *
     * @return {void}
     */
    handleNumberClick = (newPage) => {
        this.setNewPage(newPage);
    }

    /**
     * Handles the next button being clicked
     * Note that this will do nothing if there is no following page.
     *
     * @return {void}
     */
    handleNextClick = () => {
        if (this.state.currentPage < this.props.total) {
            this.setNewPage(this.state.currentPage + 1);
        }
    }

    /**
     * Handles the previous button being clicked
     * Note that this will do nothing if there is no previous page.
     *
     * @return {void}
     */
    handlePreviousClick = () => {
        if (this.state.currentPage > 1) {
            this.setNewPage(this.state.currentPage - 1);
        }
    }

    /**
     * Gets a list of all page buttons
     *
     * @return {array} The button list
     */
    getPageButtons () {
        let buttons = [];

        for (let page = 1; page <= this.props.total; ++page) {
            const classList = classNames(
                "pagination-number-button",
                { "current": page === this.state.currentPage },
            );

            buttons.push((
                <button
                    type="button"
                    key={page}
                    className={classList}
                    onClick={() => this.handleNumberClick(page)}
                >
                    <span>{page}</span>
                </button>
            ));
        }

        return buttons;
    }

    /**
     * Reders the pagination control
     *
     * @return {ReactElement} The component
     */
    render () {
        // Don't render a page thing if there is only one page
        if (this.props.total <= 1) {
            return null;
        }

        const buttons = this.getPageButtons();

        const start = Math.max(0, this.state.currentPage - Math.ceil(this.props.maxButtons / 2));
        const visibleButtons = _.slice(buttons, start, start + this.props.maxButtons);

        return (
            <div className="pagination-container">
                <button
                    type="button"
                    className="pagination-previous-button"
                    onClick={this.handlePreviousClick}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                    </svg>
                </button>
                {visibleButtons}
                <button
                    type="button"
                    className="pagination-next-button"
                    onClick={this.handleNextClick}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                    </svg>
                </button>
            </div>
        );
    }

}
