import React from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
import { Drawer } from "@dataplan/react-components/dist/components/ui/drawer";
import { TertiaryButton } from "@dataplan/react-components/dist/components/forms/controls";
import { CloseIcon } from "@dataplan/react-components/dist/components/icons";
import classNames from "classnames";
import { AuthenticatorForm, QRcode } from "./";
import { LinkDropdown, StepHeading } from "@dataplan/react-components/dist/components/ui/MFAsetup";

import styles from "./TOTPSetupDrawer.module.scss";

class TOTPSetupDrawer extends React.Component {

    static propTypes = {
        accentColour: PropTypes.string,
        pageName: PropTypes.string,
        value: PropTypes.bool,
        disable: PropTypes.bool,
    }

    static defaultProps = {
        accentColour: "#157e7b",
        pageName: "",
        value: false,
        disable: false,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Props passed from parent component
     */
    constructor (props) {
        super(props);

        this.resizeThrottle = _.throttle(this.handleResize, 100);
        this.nativeDrawer = React.createRef();

        this.state = {
            thin: null,
            visible: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        window.addEventListener("resize", this.resizeThrottle);
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        window.removeEventListener("resize", this.resizeThrottle);
    }

    /**
     * Sets drawer and pdf classes depending on width of drawer and pdf
     */
    handleResize = () => {
        const drawer = this.nativeDrawer.current;

        // Only execute if drawer is visible
        if (drawer && drawer.state.visible) {
            // Checks if drawer class requires updating
            const width = drawer.getWidth();

            this.setState((prevState) => {
                return {
                    thin: (width < 600),
                };
            });
        }
    }

    /**
     * Toggle drawer visibility
     *
     * @param {boolean} setVisible If setting drawer visible
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = (setVisible) => {
        const drawer = this.nativeDrawer.current;
        const { visible } = this.state;

        if (drawer && setVisible !== visible) {
            this.setState((prevState) => {
                return {
                    visible: !prevState.visible,
                    sendFormVisible: false,
                };
            }, () => {
                drawer.toggleDrawer(this.handleResize);
            });
        }
    }

    /**
     * Helper method to get the drawer top action
     *
     * @return {Object} The drawer top action
     */
    getTopAction = () => {
        const topAction = {
            icon: <CloseIcon className={styles.icon} />,
            action: this.toggleDrawer,
        };

        return topAction;
    }

    /**
     * Render method to get the tertiary button component
     *
     * @param {string} text The button text
     * @param {function} onClick The function called when the button is clicked
     * @return {ReactElement} The tertiary button component
     */
    renderButton = (text, onClick = null) => {
        const { accentColour } = this.props;

        return (
            <TertiaryButton
                accent={accentColour}
                aria-label={text}
                onClick={onClick}
                text={text}
            />
        );
    }

    /**
     * Render step one of the authentication process
     *
     * @return {ReactElement} The step one elements
     */
    renderStepOne () {
        return (
            <>
                <StepHeading
                    stepNumber="Step 1"
                    stepText="If you don't have an authenticator app on your device"
                />
                <br />
                <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.externalLink}
                    styles={{color: this.props.accentColour}}
                >
                    download google app for android
                </a>
                <a
                    href="https://apps.apple.com/us/app/google-authenticator/id388497605?mt=8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.externalLink}
                    styles={{color: this.props.accentColour}}
                >
                    download google app for ios
                </a>
                <br />
                <LinkDropdown accentColour={this.props.accentColour} />
            </>
        );
    }

    /**
     * Renders step 2 of the authentication process
     *
     * @return {ReactElement} The step two elements
     */
    renderStepTwo () {
        return (
            <>
                <StepHeading
                    stepNumber="Step 2"
                    stepText="Open your authenticator app and register your device by scanning the QR code below"
                    linebreak={true}
                />
                <QRcode
                    visible={this.props.value}
                />
            </>
        );
    }

    /**
     * Renders step 3 of the authentication process
     *
     * @return {ReactElement} The step three elements
     */
    renderStepThree () {
        return (
            <>
                <div className={styles.container}>
                    <StepHeading
                        stepNumber="Step 3"
                        stepText="Enter the six digit code generated by your authenticator app"
                        linebreak={true}
                    />
                    <AuthenticatorForm />
                </div>
            </>
        );
    }

    /**
     * Renders the drawer
     *
     * @return {ReactElement} The drawer
     */
    render = () => {
        const { pageName } = this.props;
        const { nativeDrawer, getTopAction, toggleDrawer } = this;
        const { thin, visible } = this.state;

        const drawerClasses = classNames(styles.drawer, {
            [styles.visible]: visible,
            [styles.thin]: thin,
        });

        const setupDrawer = (
            <>
                {this.renderStepOne()}
                {this.renderStepTwo()}
                {this.renderStepThree()}
            </>
        );

        const disableDrawer = (
            <AuthenticatorForm disable={true} />
        );

        const drawerType = (this.props.disable) ? disableDrawer : setupDrawer;

        return (
            <Drawer
                className={drawerClasses}
                ref={nativeDrawer}
                targetNode={document.body}
                topAction={getTopAction()}
                onClickOutside={toggleDrawer}
            >
                <h1 className={styles.heading}>
                    {pageName}
                </h1>
                {drawerType}
            </Drawer>
        );
    }

}

export default TOTPSetupDrawer;
