import React from 'react';
import appState from '../../../state/App';
import createToasts from '../../../components/toasts/createToasts';
import { checkValidity } from '../../../lib/validationHelpers';
import api from '../../../lib/api';
import classNames from 'classnames';

import DefaultForm from '../../../components/forms/DefaultForm';
import {
    TextInputRow,
    ReadOnlyRow,
    InputRow,
} from '@dataplan/react-components/dist/components/forms';
import PrimaryButton from '../../../components/forms/controls/PrimaryButton';
import InlineValidationErrors from '../../../components/validation/InlineValidationErrors';
import sharedStyles from '../SharedStyles.module.scss';

class ChangeUsername extends React.Component {

    /**
     * Creates an instance of the change username page
     *
     * @param {object} props Change username page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            currentUsername: 'loading...',
            password: '',
            username: {
                newUsername: '',
                confirmNewUsername: '',
            },
            hasChanged: false,
            error: {
                isValid: true,
                messages: [],
                invalidInputs: [],
            },
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.setInitialState();
    }

    /**
     * Sets the component default state
     *
     * @return {void}
     */
    setInitialState = () => {
        this.setState({
            password: '',
            username: {
                newUsername: '',
                confirmNewUsername: '',
            },
            hasChanged: false,
        }, () => {
            this.getCurrentUsername();
        });
    }

    /**
     * Gets the users current username
     *
     * @return {void}
     */
    getCurrentUsername = () => {
        api.get(`/user/profile`)
            .then((response) => {
                this.setState({
                    currentUsername: response.data.username,
                });
            });
    }

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handlePasswordInput = (event) => {
        let password = event.target.value;

        this.setState({
            password,
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    }

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     * @param {string} field Which username field has changed
     *
     * @return {void}
     */
    handleUsernameInput = (event, field) => {
        let username = event.target.value;

        this.setState((prevState) => {
            return {
                username: {
                    ...prevState.username,
                    [field]: username,
                },
                hasChanged: Boolean(username),
            };
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    }

    /**
     * Checks which inputs are invalid
     *
     * @return {string[]} the invalid inputs
     */
    invalidInputChecker () {
        const { password, username } = this.state;
        let passwordEntered = checkValidity(password, 'passwordEntered');
        let usernameValidation = checkValidity([username.newUsername, username.confirmNewUsername], 'newUsername');

        return [
            !passwordEntered.isValid ? 'currentPassword' : null,
            !usernameValidation.isValid ? 'newUsername' : null,
            !usernameValidation.isValid ? 'confirmNewUsername' : null,
        ].filter((input) => input !== null);
    }

    /**
     * Checks the password field in completed and the username is valid
     *
     * @return {void}
     */
    handleValidation = () => {
        const { password, username } = this.state;
        let passwordEntered = checkValidity(password, 'passwordEntered');
        let usernameValidation = checkValidity([username.newUsername, username.confirmNewUsername], 'newUsername');
        let errors = [];

        [passwordEntered, usernameValidation].forEach((validation) => {
            if (!validation.isValid) {
                errors.push(validation.message);
            }
        });

        if (username.confirmNewUsername) {
            this.setState({
                error: {
                    isValid: (passwordEntered.isValid && usernameValidation.isValid),
                    messages: errors,
                    invalidInputs: this.invalidInputChecker(),
                },
            });
        }
    }

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        event.preventDefault();

        const { password, username, hasChanged, error } = this.state;

        if (!hasChanged || !error.isValid) {
            return;
        }

        appState.unblockNavigation();

        api.patch('/security/username', {
            password,
            "username": username.newUsername,
            "username_confirm": username.confirmNewUsername,
        }).then(() => {
            appState.addNotification({
                text: "Username successfully updated.",
                type: "success",
                duration: 5,
            });
            this.setInitialState();
        }).catch((apiError) => {
            createToasts(apiError.response.data, 'error');
        });
    }

    /**
     * Renders the password field
     *
     * @return {ReactElement} The password input
     */
    renderPasswordField = () => {
        const { invalidInputs } = this.state.error;
        const classList = classNames([
            sharedStyles.currentPassword,
            (invalidInputs.indexOf('currentPassword') >= 0) ? sharedStyles.error : '',
        ]);

        return (
            <TextInputRow
                name="currentPassword"
                label="Current Password"
                type="password"
                onChange={this.handlePasswordInput}
                value={this.state.password}
                inputClassName={classList}
            />
        );
    }

    /**
     * Renders a username input field
     *
     * @param {string} field Which username field to render
     * @param {string} display The textual label to display
     *
     * @return {ReactElement} The username input component
     */
    renderUsernameField = (field, display) => {
        const { invalidInputs } = this.state.error;
        const classList = (invalidInputs.indexOf(field) >= 0) ? sharedStyles.error : '';
        const invalidConfirm = (field === "confirmNewUsername");

        return (
            <div className={sharedStyles.inputPair}>
                <TextInputRow
                    name={field}
                    label={display}
                    type="text"
                    onChange={(event) => this.handleUsernameInput(event, field)}
                    value={this.state.username[field]}
                    placeholder={this.state.currentUsername}
                    inputClassName={classList}
                />
                {invalidConfirm ? <div className={sharedStyles.description}>Usernames must match</div> : null}
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { hasChanged, error, currentUsername } = this.state;

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                <InlineValidationErrors errors={error} />
                <ReadOnlyRow
                    label="Current Username:"
                    value={currentUsername}
                />
                {this.renderPasswordField()}
                <InputRow className={sharedStyles.flexRow}>
                    {this.renderUsernameField("newUsername", "New username")}
                    {this.renderUsernameField("confirmNewUsername", "Confirm new username")}
                </InputRow>
                <PrimaryButton
                    type="submit"
                    disabled={!hasChanged || !error.isValid}
                    className={sharedStyles.saveButton}
                >
                    Save
                </PrimaryButton>
            </DefaultForm>
        );
    }

}

export default ChangeUsername;
