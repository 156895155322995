import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AdvancedSearchInput from "./AdvancedSearchInput";

import styles from './AdvancedSearch.module.scss';

class AdvancedSearch extends Component {

    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
    }

    /**
     * Creates an instance of the personal details section
     *
     * @param {object} props The section properties
     */
    constructor (props) {
        super(props);

        this.nameInputRef = React.createRef();
        this.payrollNoInputRef = React.createRef();
        this.emailInputRef = React.createRef();
        this.DOBInputRef = React.createRef();
        this.mobileNoInputRef = React.createRef();
        this.niNoInputRef = React.createRef();
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        const { data } = this.props;

        return (
            <div className={styles.searchContainer}>
                <div className={styles.rowContainer}>
                    <AdvancedSearchInput
                        data={data}
                        label={"Name"}
                        placeholder={"name"}
                        searchInputRef={this.nameInputRef}
                        searchKey={"payrollNo"}
                    />
                    <AdvancedSearchInput
                        data={data}
                        label={"Mobile No."}
                        placeholder={"00000000000"}
                        searchInputRef={this.mobileNoInputRef}
                        searchKey={"mobileNo"}
                        anchorPoint={"right"}
                    />
                </div>
                <div className={styles.rowContainer}>
                    <AdvancedSearchInput
                        data={data}
                        label={"Email address"}
                        placeholder={"name@something.com"}
                        searchInputRef={this.emailInputRef}
                        searchKey={"emailAddress"}
                    />
                    <AdvancedSearchInput
                        data={data}
                        label={"Date of birth"}
                        placeholder={"00-00-0000"}
                        searchInputRef={this.DOBInputRef}
                        searchKey={"DOB"}
                        anchorPoint={"right"}
                    />
                </div>
                <div className={styles.rowContainer}>
                    <AdvancedSearchInput
                        data={data}
                        label={"Payroll No."}
                        placeholder={"00000000"}
                        searchInputRef={this.payrollNoInputRef}
                        searchKey={"payrollNo"}
                    />
                    <AdvancedSearchInput
                        data={data}
                        label={"National Insurance No."}
                        placeholder={"000-000-000-0"}
                        searchInputRef={this.niNoInputRef}
                        searchKey={"niNo"}
                        anchorPoint={"right"}
                    />
                </div>
            </div>
        );
    }

}

export default AdvancedSearch;
