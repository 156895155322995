import React from 'react';
import PropTypes from 'prop-types';
// import Raven from 'raven-js'; to be added when Sentry added to app
import api from '../lib/api';

import ErrorPage from '../components/error-page/ErrorPage';

export default class ErrorBoundary extends React.Component {

    static propTypes = {
        children: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the error boundary
     *
     * @param {object} props The properties of the boundary
     */
    constructor (props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    /**
     * Called when the component is addd to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.requestInterceptor = api.interceptors.request.use((request) => {
            this.setState({ error: null });
            return request;
        });

        this.responseInterceptor = api.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status >= 500 && error.response.status < 600) {
                    this.setState({ error });
                }

                return Promise.reject(error);
            }
        );
    }

    /**
     * Called when the component catches an error
     *
     * @param {*} error The error caught
     * @param {object} errorInfo Detailed info of the error stack
     *
     * @return {void}
     */
    componentDidCatch (error, errorInfo) {
        this.setState({
            error,
        });

        // Raven.captureException(error, { extra: errorInfo }); to be added here when Sentry added to app
    }

    /**
     * Called when the component is being removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        api.interceptors.request.eject(this.requestInterceptor);
        api.interceptors.response.eject(this.responseInterceptor);
    }

    /**
     * Callback passed to the error page to reset the state of the error boundary when using nav buttons
     *
     * @return {void}
     */
    resetErrorState = () => {
        this.setState({
            error: null,
        });
    }

    /**
     * Renders the Error Boundary, with the application inside
     *
     * @return {ReactElement} The application wrapped in the error boundary or an "Opps" page
     */
    render () {
        if (this.state.error) {
            return (
                <ErrorPage
                    title="Sorry, something went wrong"
                    content="Please use the buttons below to resume"
                    navCallback={this.resetErrorState}
                    fromErrorBoundary
                />
            );
        } else {
            return this.props.children;
        }
    }

}
