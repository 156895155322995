import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';
import UserAvatar from '../../../components/UserAvatar';

import { defaultAccentColour } from '../../../Colours';

import styles from './AvatarSection.module.scss';

export default class AvatarSection extends React.Component {

    /**
     * Renders the styleguide section
     *
     * @return {ReactElement} The styleguide section
     */
    render () {
        const examples = [
            {
                forename: "Bob",
                surname: "Lobson",
                color: "#ccc",
            },
            {
                forename: "Simon",
                surname: "Charles",
                color: defaultAccentColour,
            },
            {
                forename: "Larry",
                surname: "Simons",
                color: "#000",
            },
        ];

        return (
            <CardRow>
                <CardRowContainer gridWidth={12}>
                    <ContentCard heading="Avatars">
                        <div className={styles.container}>
                            {examples.map((props) => {
                                return (
                                    <div className={styles.avatar} key={props.surname}>
                                        <UserAvatar {...props} />
                                    </div>
                                );
                            })}
                        </div>
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
