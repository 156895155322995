import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from 'react-router-dom';
import { paths } from "../../../lib";

import { PrimaryButton, SecondaryButton } from "@dataplan/react-components/dist/components/forms";

import { defaultAccentColour } from '../../../Colours';

import styles from './TemplateEditPreview.module.scss';

class TemplatePreview extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        children: PropTypes.any,
    };

    static defaultProps = {
        children: null,
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { children, history, match } = this.props;
        const editAction = () => history.push(generatePath(paths.templatesViewEdit, match.params));
        const cancelAction = () => history.push(generatePath(paths.singlePayroll, match.params));

        return (
            <>
                <div className={styles.templatePreviewCard}>
                    <div className={styles.whiteSpaceWrapper}>
                        {children}
                    </div>
                </div>
                <div>
                    <PrimaryButton onClick={editAction}>Edit</PrimaryButton>
                    <SecondaryButton
                        className={styles.secondaryButton}
                        onClick={cancelAction}
                        accent={defaultAccentColour}
                        text={"Close"}
                        aria-label={"Close"}
                    />
                </div>
            </>
        );
    }

}

export default withRouter(TemplatePreview);
