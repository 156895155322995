import api from '../api';
import appState from '../../state/App';
import { generatePath } from "react-router-dom";
import { history } from '../../redux-store';
import { paths } from "../";

/**
 * Handles viewing latest payslips
 *
 * @param {number} employeeId The employee ID
 *
 * @return {void}
 */
const handleViewingLatestPayslip = (employeeId) => {
    api.get(`/employee/${employeeId}/payslips/latest`)
        .then(({ data }) => {
            if (data.id) {
                // eslint-disable-next-line camelcase
                const employeePath = generatePath(paths.employee, { employee_id: employeeId });
                history.push(`${employeePath}?payslipId=${data.id}`);
            } else {
                appState.addNotification({
                    text: "No payslips available",
                    type: "warn",
                    duration: 5,
                });
            }
        })
        .catch(() => {
            appState.addNotification({
                text: "Failed to get payslips",
                type: "error",
                duration: 5,
            });
        });
};

export default handleViewingLatestPayslip;
