import React from 'react';
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { generatePath, Link, withRouter } from 'react-router-dom';
import { paths } from "../../../lib";
import { DataTable } from 'components/';

import styles from '../assets/SingleCompany.module.scss';

/**
 * Gets the components of each row in the list
 *
 * @param {object} props The props
 *
 * @return {object} The components of each row in the list
 */
const getComponents = ({
    payrolls,
    employees,
    match,
}) => {
    return payrolls.map((payroll) => {
        return {
            Payrolls: {
                component: (
                    <Link
                        // eslint-disable-next-line camelcase
                        to={generatePath(paths.singlePayroll, { ...match.params, payroll_id: payroll.id })}
                        className={styles.payrollLink}
                    >
                        {payroll.name}
                    </Link>
                ),
            },
            Employees: {
                component: (
                    /* eslint-disable camelcase */
                    <div>
                        {employees.filter(({ payroll_id }) => payroll_id === payroll.id).length}
                    </div>
                    /* eslint-enable */
                ),
            },
        };
    });
};

/**
 * Renders an instance of the Payrolls component
 *
 * @param {object} props The props
 *
 * @return {ReactElement} The component
 */
const Payrolls = (props) => {
    const { match, payrolls } = props;
    const { employees } = useSelector((state) => state.appState);

    const colOrder = [
        "Payrolls",
        "Employees",
    ];

    const docTableHeadings = {
        Payrolls: "Payroll",
        Employees: "Current Employees",
    };

    return (
        <>
            <div className={styles.bodyContainer}>
                <h2>Payrolls</h2>
            </div>
            <DataTable
                key="all-payrolls"
                colOrder={colOrder}
                data={getComponents({ payrolls, employees, match })}
                dataLoaded={true}
                headings={docTableHeadings}
            />
        </>
    );
};

Payrolls.propTypes = {
    match: PropTypes.object.isRequired,
    payrolls: PropTypes.array.isRequired,
};

export default withRouter(Payrolls);
