class windowScroll {

    static getX = () => {
        return (window.scrollX !== undefined)
            ? window.scrollX
            : window.pageXOffset;
    }

    static getY = () => {
        return (window.scrollY !== undefined)
            ? window.scrollY
            : window.pageYOffset;
    }

}

export default windowScroll;
