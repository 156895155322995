import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';
import _ from 'lodash';

import dataSetSearch from '../../../../lib/dataSetSearch';
import FilterValue from './FilterValue';
import FilterSearch from './FilterSearch';
import { ButtonMenu } from "@dataplan/react-components/dist/components/ui/floating_menu";

import styles from './Filter.module.scss';

class Filter extends React.Component {

    static propTypes = {
        filter: PropTypes.shape({
            text: PropTypes.string.isRequired,
            filterKey: PropTypes.string.isRequired,
            values: PropTypes.array.isRequired,
        }).isRequired,
        filtersSelected: PropTypes.object,
        onFilterSelect: PropTypes.func,
        onFiltersClear: PropTypes.func,
        setTimeout: PropTypes.func.isRequired,
    }

    static defaultProps = {
        filtersSelected: {},
        onFilterSelect: null,
        onFiltersClear: null,
    }

    /**
     * Creates an instance of the filter compnent
     *
     * @param {object} props The filters properties
     */
    constructor (props) {
        super(props);

        this.state = {
            queryString: '',
            showClear: false,
        };

        this.filterSearch = React.createRef();
    }

    /**
     * Invoked immediately after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        const { filtersSelected } = this.props;
        const activeFiltersCount = _.size(_.filter(filtersSelected));

        this.checkActiveFilters(activeFiltersCount);
    }

    /**
     * Invoked when the component updates
     *
     * @param {object} prevProps The components previous properties
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        const { filtersSelected } = this.props;
        const activeFiltersCount = _.size(_.filter(filtersSelected));

        if (filtersSelected !== prevProps.filtersSelected) {
            this.checkActiveFilters(activeFiltersCount);
        }
    }

    /**
     * Checks active filters and sets if the clear filter button should show
     *
     * @param {number} activeFiltersCount The number of active filters
     *
     * @return {void}
     */
    checkActiveFilters = (activeFiltersCount) => {
        this.setState({
            showClear: activeFiltersCount > 0,
        });
    }

    /**
     * Gets the filter values, creating compnents for each
     *
     * @return {array} An array of filter value components
     */
    getFilterValues = () => {
        const { filterKey: key, values } = this.props.filter;
        const { onFilterSelect, filtersSelected } = this.props;
        const { queryString } = this.state;

        let filters = _.map(values, (value) => {
            const isChecked = filtersSelected[value] || false;

            if (!dataSetSearch(value, queryString)) {
                return null;
            }

            return (
                <FilterValue
                    key={`${key}-${value}`}
                    filterKey={key}
                    value={value}
                    onFilterSelect={onFilterSelect}
                    checked={isChecked}
                />
            );
        });

        filters = _.filter(filters, (filter) => {
            return filter !== null;
        });

        return filters;
    }

    /**
     * Creates a visual display of how many filters are active
     *
     * @return {string} The number of active filters
     */
    getActiveFilterCount = () => {
        const { filtersSelected } = this.props;
        const activeFiltersCount = _.size(_.filter(filtersSelected));

        if (activeFiltersCount === 0) {
            return "All";
        }

        return `(${activeFiltersCount})`;
    }

    /**
     * Handles users typing in the search input
     *
     * @param {Event} event The onChange event for the input
     *
     * @return {void}
     */
    onSearchInput = (event) => {
        this.setState({
            queryString: event.target.value,
        });
    }

    /**
     * Handles the filter button click
     *
     * @param {function} clickHandler The callback from the component
     *
     * @return {void}
     */
    onButtonClick = (clickHandler) => {
        clickHandler();

        this.props.setTimeout(() => {
            if (this.filterSearch.current) {
                this.filterSearch.current.focus();
            }
        }, 100);
    }

    /**
     * Renders the individual filter component
     *
     * @return {ReactElement} The filter button and dropdown
     */
    render () {
        const { text, filterKey } = this.props.filter;
        const { onFiltersClear } = this.props;
        const { queryString, showClear } = this.state;

        const button = (clickHandler, parentRef) => (
            <button
                type="button"
                className={styles.filterButton}
                onClick={() => this.onButtonClick(clickHandler)}
                ref={parentRef}
            >
                {text}: {this.getActiveFilterCount()}
            </button>
        );

        return (
            <ButtonMenu
                button={button}
                className={styles.filterButtonMenu}
            >
                <>
                    <FilterSearch
                        name={`${filterKey}-search`}
                        queryString={queryString}
                        onSearchInput={this.onSearchInput}
                        forwardedRef={this.filterSearch}
                    />
                    {this.getFilterValues()}
                    { showClear
                        && (
                            <button
                                type="button"
                                className={styles.clearFilters}
                                onClick={() => onFiltersClear(filterKey)}
                            >
                                Clear all
                            </button>
                        )
                    }
                </>
            </ButtonMenu>
        );
    }

}

export default ReactTimeout(Filter); // eslint-disable-line new-cap
