import PropTypes from 'prop-types';

export const payslipsPropTypes = PropTypes.arrayOf(PropTypes.shape({
    "id": PropTypes.number.isRequired,
    "period_number": PropTypes.number.isRequired,
    "net": PropTypes.number.isRequired,
    "gross": PropTypes.number.isRequired,
    "total_deductions": PropTypes.number.isRequired,
    "year": PropTypes.number.isRequired,
    "marked_as_read": PropTypes.bool.isRequired,
    "paydate": PropTypes.string.isRequired,
}));

export const payslipsDefaultProps = [];

export const latestPayslipPropTypes = PropTypes.shape({
    "id": PropTypes.number.isRequired,
    "period_number": PropTypes.number.isRequired,
    "net": PropTypes.number.isRequired,
    "gross": PropTypes.number.isRequired,
    "total_deductions": PropTypes.number.isRequired,
    "year": PropTypes.number.isRequired,
    "marked_as_read": PropTypes.bool.isRequired,
    "paydate": PropTypes.string.isRequired,
});

export const latestPayslipDefaultProps = {};

export default payslipsPropTypes;
