import React from 'react';
import PropTypes from 'prop-types';

import styles from "./Flex.module.scss";

export default class Row extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
    }

    /**
     * Renders the Row component
     *
     * @return {ReactElement} React element
     */
    render () {
        const { children } = this.props;

        return (
            <div className={styles.row}>{children}</div>
        );
    }

}
