import React from 'react';
import PropTypes from 'prop-types';
import CardFrame from './CardFrame';

import styles from './ContentCard.module.scss';

export default class ContentCard extends React.Component {

    static propTypes = {
        heading: PropTypes.string.isRequired,
        children: PropTypes.any,
        theme: PropTypes.string,
    }

    static defaultProps = {
        children: null,
        theme: "light",
    }

    /**
     * Renders the card frame
     *
     * @return {ReactElement} The card element
     */
    render () {
        return (
            <CardFrame theme={this.props.theme}>
                <div className={styles.container}>
                    <h2 className={styles.heading}>{this.props.heading}</h2>
                    <div className={styles.body}>
                        {this.props.children}
                    </div>
                </div>
            </CardFrame>
        );
    }

}
