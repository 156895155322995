import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from "classnames";

import styles from './MenuButton.module.scss';

class MenuButton extends React.Component {

    static propTypes = {
        onClick: PropTypes.func,
        forwardedRef: PropTypes.any,
        isOpen: PropTypes.bool,
    }

    static defaultProps = {
        onClick: _.noop,
        forwardedRef: null,
        isOpen: false,
    }

    /**
     * Renders the hamburger menu button
     *
     * @return {ReactElement} The menu button
     */
    render () {
        const buttonClass = classNames(styles.button, {
            [styles.menuOpen]: this.props.isOpen,
        });

        return (
            <button
                aria-label="Menu"
                id="menuButton"
                type="button"
                className={buttonClass}
                onClick={this.props.onClick}
                ref={this.props.forwardedRef}
            >
                <div className={styles.menuButtonLine} />
                <div className={styles.menuButtonLine} />
                <div className={styles.menuButtonLine} />
            </button>
        );
    }

}

// eslint-disable-next-line react/no-multi-comp
export default (React.forwardRef((props, ref) => (<MenuButton forwardedRef={ref} {...props} />)));
