import React from 'react';
import PropTypes from 'prop-types';
import reactHtmlParser from 'react-html-parser';

class TemplateList extends React.Component {

    static propTypes = {
        value: PropTypes.array,
        isPreview: PropTypes.bool,
        showInPreview: PropTypes.bool,
        isOrdered: PropTypes.bool,
    };

    static defaultProps = {
        value: null,
        isPreview: false,
        showInPreview: false,
        isOrdered: false,
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { value, isPreview, showInPreview, isOrdered } = this.props;
        const ListTags = (isOrdered) ? 'ol' : 'li';

        if ((isPreview && !showInPreview) || !value) {
            return null;
        }

        return (
            <ListTags>
                {value.map((listItem) => {
                    return <li key={listItem}>{reactHtmlParser(listItem)}</li>;
                })}
            </ListTags>
        );
    }

}

export default TemplateList;
