import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { withRouter } from 'react-router-dom';
import { paths } from "../../lib";

import api from '../../lib/api';
import appState from '../../state/App';

import { UploadContext, UploadFiles, Confirmation } from "./assets";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

class Upload extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Creates a list of the file upload page
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.fileIdMap = {};
        this.state = this.getInitialState();
    }

    /**
     * Gets the initial state of the component
     *
     * @return {object} The state
     */
    getInitialState () {
        const { companies, payrolls } = this.props.appState;

        return {
            companyId: (companies.length === 1) ? companies[0].id.toString() : "",
            payrollId: (payrolls.length === 1) ? payrolls[0].id.toString() : "",
            year: "",
            period: "",
            files: {},
            batch: null,
            importStatus: null,
            fileErrors: {},
            uploaded: false,
            dataCheckStatus: null,
            dataErrors: [],
            dataWarnings: [],
            parsedData: {},
            notificationTimeOverrides: {},
        };
    }

    /**
     * Resets the state ready for upload and validating.
     * This will not clear the list of selected files.
     *
     * @return {void}
     */
    resetUploadDetails = () => {
        this.fileIdMap = {};

        this.setState({
            batch: null,
            importStatus: null,
            fileErrors: {},
            uploaded: false,
            dataCheckStatus: null,
            dataErrors: [],
            dataWarnings: [],
            parsedData: {},
            notificationTimeOverrides: {},
        });
    }

    /**
     * Uploads the selected files to a created batch
     *
     * @param {oject} response The response from the batch create request
     *
     * @return {Promise} The upload requests
     */
    uploadFiles = (response) => {
        // Upload all of the files to the batch
        const batchId = response.data.id;
        const uploads = [];

        this.setState({
            importStatus: "uploading",
            batch: response.data,
        });

        _.forOwn(this.state.files, (file, hash) => {
            const data = new FormData();

            data.append("type", "payslips:sage_payroll");
            data.append("file", file.file);

            // Pass the hash and API response to the next function
            uploads.push(hash);
            uploads.push(api.post(`/upload_paydata/${batchId}/files`, data));
        });

        return Promise.all(uploads);
    }

    /**
     * Populates the map of file ID to their hash and triggers the parsing
     *
     * @param {array} responses The list of responses from the upload request
     *
     * @return {Promise} The next request
     */
    populateMap = (responses) => {
        const batchId = responses[1].data.batchid;

        // Populate a map of the file ID from the API to its hash
        for (let i = 0; i < responses.length; i += 2) {
            const hash = responses[i];
            const file = responses[i + 1].data;

            this.fileIdMap[file.id] = hash;
        }

        this.setState({ importStatus: "validating" });

        return api.post(`/upload_paydata/${batchId}/parse`);
    }

    /**
     * Handles any errors returned by the parser and triggers data checking and preview generation
     *
     * @param {object} response The parse response
     *
     * @return {Promise} The check and preview responses
     */
    handleParserErrors = (response) => {
        // Handle any parser errors
        const fileErrors = {};

        _.forEach(response.data, (fileStatus, index) => {
            const fileHash = this.fileIdMap[fileStatus.file.id];

            if (fileStatus.status === "error") {
                fileErrors[fileHash] = fileStatus.errors;
            }
        });

        this.setState({
            fileErrors,
            importStatus: "checking",
        });

        return Promise.all([
            api.post(`/upload_paydata/${this.state.batch.id}/check`),
            api.get(`/upload_paydata/${this.state.batch.id}/preview`),
        ]);
    }

    /**
     * Handles the checking and preview data
     *
     * @param {array} responses The responses
     *
     * @return {void}
     */
    handleUploadResults = (responses) => {
        const checkResponse = responses[0];
        const previewResponse = responses[1];

        this.setState({
            uploaded: true,
            importStatus: null,
            dataCheckStatus: checkResponse.data.status,
            dataErrors: checkResponse.data.errors,
            dataWarnings: checkResponse.data.warnings,
            preview: previewResponse.data,
        });
    }

    /**
     * Called when the upload form is submitted
     *
     * @param {Event} event The form submit event
     *
     * @return {void}
     */
    handleUpload = (event) => {
        event.preventDefault();

        this.resetUploadDetails();

        const postData = {
            "payrollId": this.state.payrollId,
            "year": parseInt(this.state.year, 10),
            "period": parseInt(this.state.period, 10),
        };

        // Create the import batch
        api.post("/upload_paydata", postData)
            .then(this.uploadFiles)
            .then(this.populateMap)
            .then(this.handleParserErrors)
            .then(this.handleUploadResults);
    }

    /**
     * Imports the upload batch data
     *
     * @return {void}
     */
    importUploadedData = () => {
        const { history } = this.props;
        appState.unblockNavigation();

        api.post(`/upload_paydata/${this.state.batch.id}/import`, {
            payslips: {
                notification_time_overrides: _.mapValues(/* eslint-disable-line camelcase */
                    this.state.notificationTimeOverrides,
                    (date) => date.format("YYYY-MM-DD HH:mm:ss")
                ),
            },
        }).then(() => {
            this.setState({
                ...this.getInitialState(),
            }, () => {
                this.handleAddGlobalSnackBars();
                history.push(paths.payruns);
            });
        });
    }

    /**
     * Called when one of the inputs in the main form is changed
     *
     * @param {Event} event The change event
     *
     * @return {void}
     */
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            appState.blockNavigation();
        });
    }

    /**
     * Called when a change is made to the list of files
     *
     * @param {array} fileList The new file list
     *
     * @return {void}
     */
    handleFileChange = (fileList) => {
        this.setState({
            files: fileList,
        });
    }

    /**
     * Called when the notifcation time is changed for one of the paydates
     *
     * @param {string} paydate The paydate this release time is for in the format YYYY-MM-DD
     * @param {moment} newTime A momentjs object for the new time
     */
    handleNotificationTimeChange = (paydate, newTime) => {
        this.setState((prevState) => {
            return {
                notificationTimeOverrides: {
                    ...prevState.notificationTimeOverrides,
                    [paydate]: newTime,
                },
            };
        });
    }

    /**
     * Pushes snack bar notifcations to the appState on successful upload
     * allowing them to persist through redirect to payrun page
     *
     * @return {void}
     */
    handleAddGlobalSnackBars = () => {
        const { preview } = this.state;

        appState.addSnackBar({
            type: "success",
            message: `${preview.total_payslips} payslips have been uploaded.`,
        });

        if (preview.new_employees > 0) {
            appState.addSnackBar({
                type: "success",
                message: `${preview.new_employees} new employees have been added.`,
            });
        }
    }

    /**
     * Checks if at least one file has been uploaded and they are all valid
     *
     * @return {boolean} If the files are valid
     */
    haveValidFiles () {
        return this.state.uploaded && _.size(this.state.fileErrors) === 0;
    }

    /**
     * Renders the page content
     *
     * @return {ReactElement} The content
     */
    renderContent () {
        return (!this.haveValidFiles())
            ? <UploadFiles />
            : <Confirmation />;
    }

    /**
     * Renders the start of the upload process
     *
     * @return {ReactElement} The component
     */
    render () {
        const context = {
            ...this.state,
            handleChange: this.handleChange,
            handleFileChange: this.handleFileChange,
            handleUpload: this.handleUpload,
            resetUploadDetails: this.resetUploadDetails,
            importUploadedData: this.importUploadedData,
            handleNotificationTimeChange: this.handleNotificationTimeChange,
        };

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    pageType="boxed"
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                    maxWidth={this.props.appState.maxWidth}
                    heading={{
                        text: "Upload",
                        size: "h1",
                        steps: ["Pay files", "Confirmation"],
                        currentStep: (!this.haveValidFiles()) ? 1 : 2,
                    }}
                >
                    <UploadContext.Provider value={context}>
                        {this.renderContent()}
                    </UploadContext.Provider>
                </PageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(Upload));
