import React from 'react';
import PropTypes from 'prop-types';

import ScrollingCardRow from '../../../components/cards/ScrollingCardRow';
import ActionCard from '../../../components/cards/ActionCard';

export default class ActionsSection extends React.Component {

    static propTypes = {
        actions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            total: PropTypes.number.isRequired,
        })).isRequired,
    }

    /**
     * Renders the actions section of the dashboard]
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <ScrollingCardRow>
                {this.props.actions.map((action) => (
                    <ActionCard theme="dark" key={action.id} {...action} />
                ))}
            </ScrollingCardRow>
        );
    }

}
