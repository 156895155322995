import React from 'react';
import PropTypes from 'prop-types';

class ValidationCollection extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.string,
        children: PropTypes.any,
    }

    static defaultProps = {
        className: '',
        label: '',
        children: null,
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <div className={this.props.className}>
                <p>{this.props.label}</p>
                {this.props.children}
            </div>
        );
    }

}

export default ValidationCollection;
