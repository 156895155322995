import React from 'react';
import PropTypes from 'prop-types';

import './TableBody.scss';

export default class TableBody extends React.Component {

    static propTypes = {
        children: PropTypes.any,
    }

    static defaultProps = {
        children: null,
    }

    /**
     * Renders the table body
     *
     * @return {ReactElement} The table body element
     */
    render () {
        return (
            <tbody className="table-body">
                {this.props.children}
            </tbody>
        );
    }

}
