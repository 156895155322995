import React from 'react';
import PropTypes from 'prop-types';
import userState from 'state/User';
import appState from "state/App";
import { ToggleSwitchRow } from '@dataplan/react-components/dist/components/forms';
import {
    SnackBar,
    TOTPSetupDrawer,
} from "components/";

class MultiFactorAuthentication extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        userState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Instance of MFA page
     *
     * @param {object} props MFA props
     */
    constructor (props) {
        super(props);

        this.nativeDrawer = React.createRef();
        this.disableDrawer = React.createRef();

        this.state = {
            multiFactorAuthentication: this.props.userState.mfa_totp, // eslint-disable-line camelcase
            toggleSnackBar: false,
        };
    }

    /**
     * Renders the toggle switch
     *
     * @return {ReactElement} The toggle switch component
     */
    renderToggleSwitch () {
        const { multiFactorAuthentication } = this.state;
        const { accentColour } = this.props.appState;

        return (
            <ToggleSwitchRow
                accentColour={accentColour}
                handleChange={(value) => this.handleMFAToggle(value)}
                label={"Multi Factor Authentication"}
                key={"multi_factor_authentication"} // eslint-disable-line camelcase
                initialValue={multiFactorAuthentication} // eslint-disable-line camelcase
            />
        );
    }

    /**
     * Called when a value of the toggle switch is changed
     *
     * @param {bool} value the value of the toggle
     *
     * @return {void}
     */
    handleMFAToggle = (value) => {
        const { mfa_totp } = this.props.userState; // eslint-disable-line camelcase

        this.setState((prevState) => {
            return {
                ...prevState,
                multiFactorAuthentication: value,
                toggleSnackBar: mfa_totp && !value, // eslint-disable-line camelcase
            };
        }, () => {
            if (value && !mfa_totp) { // eslint-disable-line camelcase
                this.toggleDrawer();
            }
        });
    }

    /**
     * Toggles the drawer
     *
     * @return {void}
     */
    toggleDrawer = () => {
        const drawer = this.nativeDrawer.current;

        if (drawer) {
            drawer.toggleDrawer();
        }
    }

    /**
     * Toggles the disable mfa drawer
     *
     * @return {void}
     */
    toggleDisableDrawer = () => {
        const disableDrawer = this.disableDrawer.current;

        this.setState({
            toggleSnackBar: false,
        });

        if (disableDrawer) {
            disableDrawer.toggleDrawer();
        }
    }

    /**
     * Renders the snackbar
     *
     * @return {ReactElement} The snackbar
     */
    renderSnackBar () {
        return (
            <SnackBar
                type={"warn"}
                message={"Are you sure you want to turn off authentication?"}
                onConfirm={this.toggleDisableDrawer}
                onCancel={() => this.setState({toggleSnackBar: false})}
            />
        );
    }

    /**
     * Renders the MFA tab
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <>
                {this.renderToggleSwitch()}
                <TOTPSetupDrawer
                    accentColour={this.props.appState.accentColour}
                    value={this.state.multiFactorAuthentication}
                    ref={this.nativeDrawer}
                    pageName={"Setup Authentication"}
                />
                <TOTPSetupDrawer
                    ref={this.disableDrawer}
                    pageName={"Authorize changes"}
                    disable={true}
                />
                {this.state.toggleSnackBar && this.renderSnackBar()}
            </>
        );
    }

}

export default appState.attachState(userState.attachState((MultiFactorAuthentication)));
