import React from 'react';
import PropTypes from 'prop-types';

import { history } from "../../redux-store";
import { paths } from "../../lib";

import Icon from '../icons/Icon';
import { TertiaryButton } from "@dataplan/react-components/dist/components/forms/controls";

import { defaultAccentColour } from '../../Colours';

import styles from './ErrorPage.module.scss';

export default class ErrorPage extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        navCallback: PropTypes.func,
        fromErrorBoundary: PropTypes.bool,
    };

    static defaultProps = {
        title: '',
        content: '',
        navCallback: null,
        fromErrorBoundary: false,
    }

    /**
     * Event called when the previous page button is clicked
     *
     * @return {void}
     */
    handleGoBackClick = () => {
        const { navCallback } = this.props;

        history.goBack();

        if (navCallback) {
            navCallback();
        }
    }

    /**
     * Event called when the dashboard button is clicked
     *
     * @return {void}
     */
    handleDashboardClick = () => {
        const { navCallback } = this.props;

        history.push(paths.dashboard);

        if (navCallback) {
            navCallback();
        }
    }

    /**
     * Renders the error page component
     *
     * @return {ReactElement} The error page
     */
    render () {
        const { title, content, fromErrorBoundary } = this.props;

        return (
            <div className={styles.contentWrapper}>
                <div className={styles.errorCard}>
                    <div className={styles.cardHeader}>
                        <div className={styles.cardHeaderInner}>
                            <Icon icon="Warning" className={styles.icon} />
                            {title}
                        </div>
                    </div>
                    <div className={styles.cardContent}>
                        <p className={styles.cardText}>{content}</p>
                        <div>
                            {!fromErrorBoundary
                                && (
                                    <TertiaryButton
                                        onClick={this.handleGoBackClick}
                                        className={styles.buttonContainer}
                                        accent={defaultAccentColour}
                                        text={"Previous Page"}
                                        aria-label={"Previous Page"}
                                    />
                                )
                            }
                            <TertiaryButton
                                onClick={this.handleDashboardClick}
                                accent={defaultAccentColour}
                                text={"Dashboard"}
                                aria-label={"Dashboard"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
