import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import employeePropTypes from '../../lib/props-schema/employeeDetailsSchema';
import {
    latestPayslipPropTypes,
    latestPayslipDefaultProps,
} from '../../lib/props-schema/payslipsSchema';
import documentsPropTypes, { documentsDefaultProps } from '../../lib/props-schema/documentsSchema';
import appState from '../../state/App';

import PersonalDetailsSection from './profile/PersonalDetailsSection';
import LatestPayslipSection from './profile/LatestPayslipSection';
import LatestDocumentsSection from './profile/LatestDocumentsSection';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

import styles from './EmployeeProfileTab.module.scss';
import sections from './assets/ProfileSections.module.scss';

class EmployeeProfileTab extends React.Component {

    static propTypes = {
        employeeDetails: employeePropTypes.isRequired,
        latestPayslip: latestPayslipPropTypes,
        latestDocuments: documentsPropTypes,
        handleRedraw: PropTypes.func,
        setCurrentTab: PropTypes.func,
        employeeDocuments: documentsPropTypes,
    }

    static defaultProps = {
        latestPayslip: latestPayslipDefaultProps,
        latestDocuments: documentsDefaultProps,
        handleRedraw: null,
        setCurrentTab: null,
        employeeDocuments: documentsDefaultProps,
    }

    /**
     * Renders the personal details card
     *
     * @return {ReactElement} The personal details card
     */
    renderPersonalDetails () {
        const { employeeDetails: employee } = this.props;

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                className={styles.employeeProfileCardContainer}
            >
                <PersonalDetailsSection
                    employeeDetails={employee}
                    editable={employee.user_can_edit || false}
                    handleRedraw={this.props.handleRedraw}
                    classNames={classNames([
                        sections.section,
                        styles.personalDetailsSection,
                    ])}
                />
            </AnimationContainer>
        );
    }

    /**
     * Renders the latest payslip card
     *
     * @return {ReactElement} The latest payslip card
     */
    renderLatestPayslip () {
        const { latestPayslip, employeeDetails} = this.props;

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={400}
                enterTimeout={1000}
                exitTimeout={100}
                className={styles.latestPayslipSection}
            >
                <LatestPayslipSection
                    employee={employeeDetails.id.toString()}
                    latestPayslip={latestPayslip}
                    classNames={classNames([
                        styles.latestPayslipSection,
                        sections.section,
                    ])}
                />
            </AnimationContainer>
        );
    }

    /**
     * Renders the latest documents card
     *
     * @return {ReactElement} The latest document card
     */
    renderLatestDocuments () {
        const { latestDocuments, setCurrentTab, employeeDocuments } = this.props;

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={600}
                enterTimeout={1000}
                exitTimeout={100}
                className={styles.latestDocumentsSection}
            >
                <LatestDocumentsSection
                    employeeDocuments={employeeDocuments}
                    latestDocuments={latestDocuments}
                    classNames={classNames([
                        sections.section,
                        styles.latestDocumentsSection,
                    ])}
                    setCurrentTab={setCurrentTab}
                />
            </AnimationContainer>
        );
    }

    /**
     * Renders the employee personal details tab
     *
     * @return {ReactElement} The personal details tab
     */
    render () {
        return (
            <div className={styles.employeeProfileTab}>
                {this.renderPersonalDetails()}
                <div className={styles.employeeProfileCardContainer}>
                    {this.renderLatestPayslip()}
                    {this.renderLatestDocuments()}
                </div>
            </div>
        );
    }

}

export default withRouter(appState.attachState(EmployeeProfileTab));
