import moment from 'moment';

/* eslint-disable max-len, no-useless-escape */
const emailRegEx = new RegExp(/^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/);
/* eslint-enable */

/**
 * Helper funtion to format the return values of the validation
 *
 * @param {bool} bool If the input is valid
 * @param {string} message The message to send back
 *
 * @return {object} The information to return regarding validation
 */
const formatReturn = (bool, message) => {
    return {
        isValid: bool,
        message,
    };
};

/**
 * Checks if the date is valid
 *
 * @param {string} date The date value to validate
 * @param {?string} format The date format to validate
 *
 * @return {object} The information to return regarding validation
 */
const validateDate = (date, format = 'DD-MM-YYY') => {
    const momentDate = moment(date, format);

    if (!momentDate.isValid) {
        return formatReturn(false, 'Please enter a valid date');
    }

    return formatReturn(true, '');
};

/**
 * Checks if the email is valid
 *
 * @param {string} email The email value to validate
 *
 * @return {object} The information to return regarding validation
 */
const validateEmail = (email) => {
    if (!emailRegEx.test(email)) {
        return formatReturn(false, 'Please enter a valid email address');
    }

    return formatReturn(true, '');
};

/**
 * Checks if the phone number is valid
 *
 * @param {string} mobile The phone number value to validate
 *
 * @return {object} The information to return regarding validation
 */
const validateMobile = (mobile) => {
    /* eslint-disable max-len */
    const phoneRegEx = new RegExp(/^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*$/);
    /* eslint-enable */

    if (!phoneRegEx.test(mobile)) {
        return formatReturn(false, 'Please enter a valid phone number');
    }

    return formatReturn(true, '');
};

/**
 * Checks if the NINO is valid
 *
 * @param {string} nino The NINO value to validate
 *
 * @return {object} The information to return regarding validation
 */
const validateNino = (nino) => {
    const ninoRegEx = new RegExp(/^[A-Za-z]{2}\d{6}[A-Za-z]$/);

    if (!ninoRegEx.test(nino)) {
        return formatReturn(false, 'Please enter a valid NI number');
    }

    return formatReturn(true, '');
};

/**
 * Checks that a password has been entered, used when password needed to make security changes
 *
 * @param {string} password The password
 *
 * @return {object} The information to return regarding validation
 */
const validatePasswordEntered = (password) => {
    if (password.length < 1) {
        return formatReturn(false, 'Password is required to make changes to your account');
    }

    return formatReturn(true, '');
};

/**
 * Checks the array of security questions to check all questions are unique and all have answers
 *
 * @param {array} securityQuestions The list of security questions and answers
 *
 * @return {object} The information to return regarding validation
 */
const validateSecurityQuestions = (securityQuestions) => {
    let selectedQuestionIds = [];
    let validAnswers = [];

    securityQuestions.forEach((question) => {
        selectedQuestionIds.push(question.id);
        validAnswers.push(question.answer.length > 0);
    });

    let distinctQuestionIds = [...new Set(selectedQuestionIds)];
    let allAnswersValid = validAnswers.indexOf(false);

    if (distinctQuestionIds.length < 5) {
        return formatReturn(false, 'You must chose 5 different questions');
    }

    if (allAnswersValid >= 0) {
        return formatReturn(false, 'All questions must have an answer');
    }

    return formatReturn(true, '');
};

/**
 * Checks the new password has been entered and that it matches the entered confirmed value
 *
 * @return {object} The information to return regarding validation
 */
const validateNewPassword = ([newPassword, confirmPassword]) => {

    if (!newPassword && !confirmPassword) {
        return formatReturn(true, '');
    }

    if (newPassword.length < 1) {
        return formatReturn(false, 'Please enter your new password');
    }

    if (newPassword !== confirmPassword) {
        return formatReturn(false, 'Passwords must match');
    }

    return formatReturn(true, '');
};

/**
 * Checks the new email has been entered and that it matches the entered confirmed value
 *
 * @return {object} The information to return regarding validation
 */
const validateNewEmail = ([newEmail, confirmEmail]) => {

    if (!newEmail && !confirmEmail) {
        return formatReturn(true, '');
    }

    if (!emailRegEx.test(newEmail)) {
        return formatReturn(false, 'Please enter a valid email address');
    }

    if (newEmail !== confirmEmail) {
        return formatReturn(false, 'Email addresses must match');
    }

    return formatReturn(true, '');
};

/**
 * Checks the new username has been entered and that it matches the entered confirmed value
 *
 * @return {object} The information to return regarding validation
 */
const validateUsernamesMatch = ([newUsername, confirmNewUsername]) => {

    if (!newUsername && !confirmNewUsername) {
        return formatReturn(true, '');
    }

    if (newUsername !== confirmNewUsername) {
        return formatReturn(false, 'Usernames must match');
    }

    return formatReturn(true, '');
};

/**
 * Helper method to check if file exists
 *
 * @param {object} file the file to be uploaded
 * @return {object} information to return regarding validation
 */
const validateFile = (file) => {
    let valueExists = file.name.length > 0;

    if (!valueExists) {
        return formatReturn(false, 'Invalid file');
    }

    return formatReturn(true, "");
};

/**
 * Helper method to check if an input value exists
 *
 * @param {string} input The input value
 * @param {string} name The input name
 *
 * @return {object} The information to return regarding validation
 */
const validateRequiredInput = (input, name) => {
    let valueExists = input.length > 0;
    let message = (!valueExists) ? `${name} is required` : '';

    return formatReturn(valueExists, message);
};

/**
 * Define new validation types and relevant functions here
 */
const validTypes = {
    'date': validateDate,
    'email': validateEmail,
    'mobile': validateMobile,
    'nino': validateNino,
    'passwordEntered': validatePasswordEntered,
    'securityQuestions': validateSecurityQuestions,
    'newPassword': validateNewPassword,
    'newEmail': validateNewEmail,
    'newUsername': validateUsernamesMatch,
    'requiredInput': validateRequiredInput,
    'file': validateFile,
};

/**
 * Checks validity of the value
 *
 * @param {mixed} value The value to validate
 * @param {string} type The type of validation to perform
 * @param {?string} format The format to use in validation if required (e.g. date)
 *
 * @return {object} The information to return regarding validation
 */
export const checkValidity = (value, type, format = null) => {
    if (!validTypes[type]) {
        return formatReturn(true, '');
    } else {
        return validTypes[type](value, format);
    }
};
