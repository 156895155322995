import appState from '../../state/App';
import _ from 'lodash';

/**
 * Renders a toast error if the API errors/success
 *
 * @param {object} objectMessages The API response
 * @param {string} type The type of toast
 *
 * @return {void}
 */
const createToasts = (objectMessages, type) => {
    if (!objectMessages) {
        return;
    }

    if (objectMessages.messages) {
        _.forEach(objectMessages.messages, (fieldMessage) => {
            _.forEach(fieldMessage, (message) => {
                appState.addNotification({
                    text: message,
                    duration: 5,
                    type,
                });
            });
        });
    } else if (objectMessages.message) {
        appState.addNotification({
            text: objectMessages.message,
            duration: 5,
            type,
        });
    }
};

export default createToasts;
