import React, {Component} from 'react';
import PropTypes from "prop-types";
import { generatePath, Link, withRouter } from "react-router-dom";
import { paths } from "../../../lib";
import api from "../../../lib/api";
import axios from "axios";
import appState from 'state/App';

import {
    TextInputRow,
    PrimaryButton,
    SecondaryButton,
} from '@dataplan/react-components/dist/components/forms';
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from '../../../lib/contrastingColorList';

import styles from '../../SinglePayroll/assets/Edit.module.scss';

class EditCompanyPage extends Component {

    static propTypes = {
        company: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        const { company } = this.props;

        this.state = {
            company,
            companyName: company.name,
            address1: company.address.line_1, /* eslint-disable-line camelcase */
            address2: company.address.line_2, /* eslint-disable-line camelcase */
            address3: company.address.line_3, /* eslint-disable-line camelcase */
            townCity: company.address.post_town,
            postcode: company.address.post_code,
        };
    }

    /**
     * Called when the component first renders
     *
     * @return {void}
     */
    componentDidMount () {
        const { company } = this.props;
        axios.all([api.get(`/company/${company.id}`)])
            .then((response) => {
                this.setState({
                    company: response[0].data,
                    companyName: response[0].data.name,
                    address1: response[0].data.address.line_1, /* eslint-disable-line camelcase */
                    address2: response[0].data.address.line_2, /* eslint-disable-line camelcase */
                    address3: response[0].data.address.line_3, /* eslint-disable-line camelcase */
                    townCity: response[0].data.address.post_town,
                    postcode: response[0].data.address.post_code,
                });
            });
    }

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handleInput = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }

    /**
     * Checks for change in address lines
     *
     * @return {boolean} The address line check
     */
    addressLineCheck () {
        const { company, address1, address2, address3 } = this.state;
        // eslint-disable-next-line max-len
        const addressLine1Change = ((company.address.line_1 !== address1) && (address1.length)); /* eslint-disable-line camelcase */
        const addressLine2Change = (company.address.line_2 !== address2); /* eslint-disable-line camelcase */
        const addressLine3Change = (company.address.line_3 !== address3); /* eslint-disable-line camelcase */

        return (addressLine1Change || addressLine2Change || addressLine3Change);
    }

    /**
     * Checks for change in postcode and town/city lines
     *
     * @return {boolean} The address line check
     */
    postCodeTownCityLineCheck () {
        const {
            company,
            townCity,
            postcode,
        } = this.state;
        const townCityChange = ((company.address.post_town !== townCity) && (townCity.length));
        const postcodeChange = ((company.address.post_code !== postcode) && (postcode.length));

        return (townCityChange || postcodeChange);
    }

    /**
     * Checks for a change in the forms to activate submit button
     *
     * @return {boolean} The submit check
     */
    checkCanSubmit = () => {
        const { companyName, company } = this.state;

        const nameChange = ((company.name !== companyName) && (companyName.length));

        return (
            nameChange
            || this.addressLineCheck()
            || this.postCodeTownCityLineCheck()
        );
    }

    /**
     * Handles submission
     */
    handleSubmission = () => {
        const {
            company,
            companyName,
            address1,
            address2,
            address3,
            townCity,
            postcode,
        } = this.state;

        api.patch(`/company/${company.id}/edit`, {
            name: companyName,
            address1,
            address2,
            address3,
            town: townCity,
            postcode,
        })
            .then(() => {
                appState.addNotification({
                    text: `Company successfully edited`,
                    type: "success",
                    duration: 5,
                }, () => {
                    this.props.history.go();
                });
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to edit company`,
                    type: "error",
                    duration: 0,
                });
            });
    }


    /**
     * Render the form buttons
     *
     * @return {ReactElement} The form buttons
     */
    renderButtons () {
        const { match } = this.props;

        const canSubmit = this.checkCanSubmit();
        const buttonStyles = {
            primary: {
                backgroundColor: "#652D90",
                color: getMostContrastingColour("#652D90", contrastingColourList),
            },
        };

        return (
            <div className={styles.buttonContainer}>
                <PrimaryButton
                    type="submit"
                    style={(canSubmit) ? buttonStyles.primary : null}
                    disabled={!canSubmit}
                    onClick={this.handleSubmission}
                    text="Save"
                />
                <Link to={generatePath(paths.singleCompanyAbout, match.params)} >
                    <SecondaryButton
                        text="Cancel"
                    />
                </Link>
            </div>
        );
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        const { match } = this.props;
        const {
            companyName,
            address1,
            address2,
            address3,
            townCity,
            postcode,
        } = this.state;

        return (
            <>
                <div className={styles.bodyContainer}>
                    <h2>About</h2>
                    <Link to={generatePath(paths.singleCompanyAbout, match.params)} >
                        <button
                            type="submit"
                            className={styles.editButton}
                        >
                            {'Cancel'}
                        </button>
                    </Link>
                </div>
                <div className={styles.wrapper}>
                    <TextInputRow
                        name="companyName"
                        label={"Name"}
                        type="text"
                        onChange={(event) => this.handleInput(event)}
                        value={companyName}
                    />
                    <TextInputRow
                        name="address1"
                        label={"Address Line 1"}
                        type="text"
                        onChange={(event) => this.handleInput(event)}
                        value={address1}
                    />
                    <TextInputRow
                        name="address2"
                        label={"Address Line 2"}
                        type="text"
                        onChange={(event) => this.handleInput(event)}
                        value={address2}
                    />
                    <TextInputRow
                        name="address3"
                        label={"Address Line 3"}
                        type="text"
                        onChange={(event) => this.handleInput(event)}
                        value={address3}
                    />
                    <TextInputRow
                        name="townCity"
                        label={"Town/City"}
                        type="text"
                        onChange={(event) => this.handleInput(event)}
                        value={townCity}
                    />
                    <TextInputRow
                        name="postcode"
                        label={"Postcode"}
                        type="text"
                        onChange={(event) => this.handleInput(event)}
                        value={postcode}
                    />
                    {this.renderButtons()}
                </div>
            </>
        );
    }

}

export default withRouter(appState.attachState(EditCompanyPage));
