import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { paths } from "../../../lib";

import Payrolls from './Payrolls';
import About from "./About";
import Edit from "./Edit";

const Routes = (props) => {
    const { company, payrolls } = props;

    return (
        <Switch>
            <Route exact path={paths.singleCompanyPayrolls} >
                <Payrolls payrolls={payrolls} />
            </Route>
            <Route exact path={paths.singleCompanyAbout} >
                <About {...props} />
            </Route>
            <Route exact path={paths.singleCompanyEdit} >
                <Edit {...props} />
            </Route>
            <Route>
                <Redirect
                    to={generatePath(paths.singleCompanyPayrolls, {
                        company_id: company.id, /* eslint-disable-line camelcase */
                    })}
                />
            </Route>
        </Switch>
    );
};

Routes.propTypes = {
    company: PropTypes.object.isRequired,
    payrolls: PropTypes.array.isRequired,
};

export default Routes;
