import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import numeral from "numeral";
import moment from "moment";
import { withRouter } from 'react-router-dom';
import { paths } from "../../../lib";

import appState from "../../../state/App";

import UploadContext from "./UploadContext";
import {
    PrimaryButton,
    SecondaryButton,
    TertiaryButton,
} from "@dataplan/react-components/dist/components/forms/controls";
import InlineEditDateCell from "../../../components/tables/InlineEditDateCell";
import SimpleTable from '../../../components/tables/SimpleTable';
import TableHeader from '../../../components/tables/TableHeader';
import TableBody from '../../../components/tables/TableBody';

import { defaultAccentColour } from '../../../Colours';

import styles from "./Confirmation.module.scss";

class Confirmation extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Gets the name of a payroll from the ID
     *
     * @param {string} payrollId The payroll ID form the form input on the previous page
     *
     * @return {string} The name of the payroll
     */
    getPayrollName (payrollId) {
        const selectedPayroll = _.find(this.props.appState.payrolls, (payroll) => {
            return payroll.id === parseInt(payrollId, 10);
        });

        return selectedPayroll.name || "";
    }

    /**
     * Renders a message section for uploaded data
     *
     * @param {string} sectionName The name of the section
     * @param {array} messages The messages to render
     *
     * @return {ReactElement} The section or null if there are no messages
     */
    renderDataMessages (sectionName, messages) {
        if (_.size(messages) === 0) {
            return null;
        }

        return (
            <>
                <h2 className={styles.sectionHeading}>
                    {sectionName}
                </h2>
                {_.map(messages, (message) => <div key={message}>{message}</div>)}
            </>
        );
    }

    /**
     * Renders the list of paydates
     *
     * @param {object} context The upload context
     *
     * @return {ReactElement} The table element
     */
    renderPaydateTable (context) {
        return (
            <SimpleTable className={styles.paydateTable}>
                <TableHeader>
                    <th>Pay date</th>
                    <th>Payslips</th>
                    <th>Payslip release</th>
                </TableHeader>
                <TableBody>
                    {_.map(context.preview.paydates, (paydate) => {
                        const momentPaydate = moment(paydate.date);
                        const paydateKey = momentPaydate.format("YYYY-MM-DD");
                        const paydateFormatted = momentPaydate.format("DD-MM-YYYY");
                        const releaseTime = context.notificationTimeOverrides[paydateKey]
                                         || moment(paydate.release);

                        return (
                            <tr key={paydate.date}>
                                <td>{paydateFormatted}</td>
                                <td>{paydate.payslips}</td>
                                <InlineEditDateCell
                                    className={styles.releaseDateCell}
                                    value={releaseTime}
                                    onChange={(newTime) => {
                                        context.handleNotificationTimeChange(paydateKey, newTime);
                                    }}
                                >
                                    {(selectedTime) => (
                                        <>
                                            <span className={styles.date}>
                                                {selectedTime.format("ddd D MMM, YYYY")}
                                            </span>
                                            <span className={styles.time}>
                                                {selectedTime.format("h:mma")}
                                            </span>
                                        </>
                                    )}
                                </InlineEditDateCell>
                            </tr>
                        );
                    })}
                </TableBody>
            </SimpleTable>
        );
    }

    /**
     * Renders the paydata checking page
     *
     * @return {ReactElement} The confirmation container
     */
    render () {
        const { history } = this.props;

        return (
            <UploadContext.Consumer>
                {(context) => {
                    return (
                        <div>
                            <p className={styles.helpText}>Please evaluate this upload</p>

                            {this.renderDataMessages("Errors", context.dataErrors)}
                            {this.renderDataMessages("Warnings", context.dataWarnings)}

                            <h2 className={styles.sectionHeading}>Summary</h2>
                            <dl className={styles.summaryList}>
                                <div>
                                    <dt>Payroll</dt>
                                    <dd>{this.getPayrollName(context.payrollId)}</dd>
                                </div>
                                <div>
                                    <dt>Payslips</dt>
                                    <dd>{context.preview.total_payslips}</dd>
                                </div>
                                <div>
                                    <dt>New starters</dt>
                                    <dd>{context.preview.new_employees}</dd>
                                </div>
                                <div>
                                    <dt>Gross</dt>
                                    <dd>£{numeral(context.preview.total_gross).format("0,0.00")}</dd>
                                </div>
                                <div>
                                    <dt>Net</dt>
                                    <dd>£{numeral(context.preview.total_net).format("0,0.00")}</dd>
                                </div>
                                <div>
                                    <dt>Period</dt>
                                    <dd>{context.preview.period}</dd>
                                </div>
                            </dl>

                            <h2 className={styles.sectionHeading}>Key Dates</h2>
                            {this.renderPaydateTable(context)}

                            <div className={styles.actionButtons}>
                                <PrimaryButton
                                    onClick={context.importUploadedData}
                                    disabled={context.dataErrors.length > 0}
                                >
                                    Upload
                                </PrimaryButton>
                                <SecondaryButton
                                    className={styles.secondaryButton}
                                    onClick={context.resetUploadDetails}
                                    accent={defaultAccentColour}
                                    text={"Back"}
                                    aria-label={"Back"}
                                />
                                <SecondaryButton
                                    className={styles.secondaryButton}
                                    onClick={() => history.push(paths.payruns)}
                                    accent={'#682f91'}
                                    text={"Cancel"}
                                    aria-label={"Cancel"}
                                />
                            </div>
                            <TertiaryButton
                                onClick={context.resetUploadDetails}
                                accent={defaultAccentColour}
                                text={"Back"}
                                aria-label={"Back"}
                            />
                        </div>
                    );
                }}
            </UploadContext.Consumer>
        );
    }

}

export default withRouter(appState.attachState(Confirmation));
