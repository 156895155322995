import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';
import PropertiesCard from '../../../components/cards/PropertiesCard';
import Paragraph from '../../../components/Paragraph';

export default class CardsSection extends React.Component {

    /**
     * Renders the card section of the styleguide
     *
     * @return {ReactElement} The section container
     */
    render () {
        const sampleProperties = [
            {
                name: 'Paydate',
                value: '10-10-17',
            },
            {
                name: 'Payslip release date',
                value: '15-10-17',
            },
            {
                name: 'Status',
                value: 'Pending',
            },
        ];

        return (
            <div>
                <h2>Cards</h2>
                <CardRow>
                    <CardRowContainer gridWidth={12}>
                        <ContentCard heading="Some title">
                            <Paragraph>
                                An Internet meme (/miːm/ MEEM[1][2][3][4]) is an activity, concept, catchphrase, or
                                piece of media that spreads, often as mimicry or for humorous purposes, from person to
                                person via the Internet.[5] An Internet meme may take the form of an image (typically an
                                image macro[6]), hyperlink, video, website, or hashtag. It may be just a word or phrase,
                                sometimes including an intentional misspelling. These small movements tend to spread
                                from person to person via social networks, blogs, direct email, or news sources. They
                                may relate to various existing Internet cultures or subcultures, often created or spread
                                on various websites, or by Usenet boards and other such early-Internet communications
                                facilities. Fads and sensations tend to grow rapidly on the Internet because the instant
                                communication facilitates word-of-mouth transmission. Some examples include posting a
                                photo of people lying down in public places (called &quot;planking&quot;) and uploading
                                a short video of people dancing to the Harlem Shake.
                            </Paragraph>
                            <Paragraph>
                                The word meme was coined by Richard Dawkins in his 1976 book The Selfish Gene as an
                                attempt to explain the way cultural information spreads;[7] Internet memes are a subset
                                of this general meme concept specific to the culture and environment of the Internet.
                                The concept of the Internet meme was first proposed by Mike Godwin in the June 1993
                                issue of Wired. In 2013, Dawkins characterized an Internet meme as being a meme
                                deliberately altered by human creativity—distinguished from biological genes and
                                Dawkins&apos; pre-Internet concept of a meme, which involved mutation by random change
                                and spreading through accurate replication as in Darwinian selection.[8] Dawkins
                                explained that Internet memes are thus a &quot;hijacking of the original idea&quot;, the
                                very idea of a meme having mutated and evolved in this new direction.[9] Further,
                                Internet memes carry an additional property that ordinary memes do not—Internet memes
                                leave a footprint in the media through which they propagate (for example, social
                                networks) that renders them traceable and analyzable.[10]
                            </Paragraph>
                        </ContentCard>
                    </CardRowContainer>
                </CardRow>

                <CardRow>
                    <CardRowContainer gridWidth={6}>
                        <ContentCard heading="Typography">
                            <h1>Heading 1</h1>
                            <h2>Heading 2</h2>
                            <h3>Heading 3</h3>
                            <h4>Heading 4</h4>
                            <h5>Heading 5</h5>
                            <h6>Heading 6</h6>
                        </ContentCard>
                    </CardRowContainer>
                    <CardRowContainer gridWidth={6}>
                        <ContentCard heading="Typography Classes">
                            <h6 className="h1">Heading 6</h6>
                            <h5 className="h2">Heading 5</h5>
                            <h4 className="h3">Heading 4</h4>
                            <h3 className="h4">Heading 3</h3>
                            <h2 className="h5">Heading 2</h2>
                            <h1 className="h6">Heading 1</h1>
                        </ContentCard>
                    </CardRowContainer>
                </CardRow>

                <CardRow>
                    <CardRowContainer gridWidth={6}>
                        <ContentCard heading="Some title">1</ContentCard>
                    </CardRowContainer>
                    <CardRowContainer gridWidth={6}>
                        <ContentCard heading="Some title">3</ContentCard>
                    </CardRowContainer>
                </CardRow>

                <CardRow>
                    <CardRowContainer gridWidth={4}>
                        <PropertiesCard
                            keyProperty={sampleProperties[0]}
                            properties={sampleProperties.slice(1)}
                        />
                    </CardRowContainer>
                    <CardRowContainer gridWidth={8}>
                        <PropertiesCard
                            properties={sampleProperties}
                            theme="accent"
                        />
                    </CardRowContainer>
                </CardRow>

                <CardRow>
                    <CardRowContainer gridWidth={5}>
                        <PropertiesCard
                            keyProperty={sampleProperties[0]}
                            properties={sampleProperties.slice(1)}
                        />
                    </CardRowContainer>
                    <CardRowContainer gridWidth={7}>
                        <PropertiesCard
                            properties={sampleProperties}
                            theme="dark"
                        />
                    </CardRowContainer>
                </CardRow>

                <CardRow>
                    <CardRowContainer gridWidth={6}>
                        <PropertiesCard
                            keyProperty={sampleProperties[0]}
                            properties={sampleProperties.slice(1)}
                        />
                    </CardRowContainer>
                    <CardRowContainer gridWidth={6}>
                        <PropertiesCard
                            keyProperty={sampleProperties[0]}
                            properties={sampleProperties.slice(1)}
                            theme="dark"
                        />
                    </CardRowContainer>
                </CardRow>
            </div>
        );
    }

}
