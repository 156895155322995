import React from 'react';
import PropTypes from 'prop-types';

import ArrowUp from './assets/ArrowUp';
import ArrowForward from './assets/ArrowForward';
import ArrowDown from './assets/ArrowDown';
import ArrowBack from './assets/ArrowBack';
import SearchIcon from './assets/SearchIcon';
import Attachment from './assets/Attachment';
import Remove from './assets/Remove';
import Close from "./assets/Close";
import CloseSolid from './assets/CloseSolid';
import Info from './assets/Info';
import Warning from './assets/Warning';
import Tick from './assets/Tick';
import TickSolid from './assets/TickSolid';

export default class Icon extends React.Component {

    static propTypes = {
        icon: PropTypes.string.isRequired,
        className: PropTypes.string,
    }

    static defaultProps = {
        className: '',
    }

    /**
     * Gets an instance of the destired icon based on props provided
     *
     * @param {string} icon The name of the icon
     *
     * @return {element} An isntance of the icon
     */
    getIcon = (icon) => {
        const availableIcons = {
            ArrowUp,
            ArrowForward,
            ArrowDown,
            ArrowBack,
            SearchIcon,
            Attachment,
            Remove,
            Close,
            CloseSolid,
            Info,
            Warning,
            Tick,
            TickSolid,
        };

        return availableIcons[icon];
    }

    /**
     * Renders the icon component
     *
     * @return {ReactElement} The icon element
     */
    render () {
        const { icon: iconSelected, ...otherProps } = this.props;
        const IconComponent = this.getIcon(iconSelected);

        if (!IconComponent) {
            return null;
        }

        return (
            <IconComponent {...otherProps} />
        );
    }

}
