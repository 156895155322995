import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { CheckboxInput } from '@dataplan/react-components/dist/components/forms';
import { defaultAccentColour } from '../../../Colours';

import {
    TextLink,
    ActionCell,
    ActionMenu,
    Action,
} from '../../../components';

import styles from './DataTableRow.module.scss';
import hideColumnStyles from './HideColumns.module.scss';

export default class DataTableRow extends React.Component {

    static propTypes = {
        colOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
        id: PropTypes.number.isRequired,
        dataRow: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
        ]).isRequired,
        hidden: PropTypes.object,
        showCheckBoxes: PropTypes.bool,
        checked: PropTypes.bool,
        onCheckRow: PropTypes.func,
        actions: PropTypes.object,
        rowHeight: PropTypes.string,
    }

    static defaultProps = {
        hidden: {},
        showCheckBoxes: false,
        checked: null,
        onCheckRow: null,
        actions: null,
        rowHeight: "70px",
    }

    /**
     * Renders each table column for the given row
     *
     * @param {array} row The data row
     *
     * @return {array} An array of the table columns to be rendered
     */
    renderRowCols = (row) => {
        const { colOrder } = this.props;

        if (!row) {
            return null;
        }

        return colOrder.map((key) => {
            if (!row[key] || key === "id") {
                return null;
            }

            return this.renderCol(row[key], key);
        });
    }

    /**
     * Renders a single column value for the given row
     *
     * @param {object} value The column value object
     * @param {string} key The column key
     *
     * @return {Component} The column value
     */
    renderCol = (value, key) => {
        const {
            alignRight,
            component,
            link,
            text,
        } = value;

        const classList = classNames(styles.dataTableCell, {
            [hideColumnStyles.hideSmall]: (_.indexOf(this.props.hidden.small, key) >= 0),
            [hideColumnStyles.hideMedium]: (_.indexOf(this.props.hidden.medium, key) >= 0),
            [hideColumnStyles.hideLarge]: (_.indexOf(this.props.hidden.large, key) >= 0),
            alignRight,
        });

        const content = (link && text)
            ? (
                <TextLink to={link} bold={true}>
                    {text}
                </TextLink>
            )
            : (component || text);

        return (
            <td key={key} className={classList}>
                {content}
            </td>
        );
    }

    /**
     * Returns a list of actions for a table row.
     *
     * @param {any} id The row item ID
     *
     * @return {null|ReactElement} Returns actions list
     */
    renderActions = (id) => {
        const { actions, dataRow } = this.props;

        if (actions === null) {
            return null;
        }

        return (
            <ActionCell key={`action-menu-${id}`} className={styles.dataTableCell}>
                <ActionMenu label={actions.label} className={styles.actionMenu}>
                    {
                        // Generate list of actions
                        actions.actions.map((value, index) => {
                            if (!value.callback) {
                                return null;
                            }

                            return (
                                <Action
                                    key={`action-${id}-${value.label}`}
                                    label={value.label}
                                    action={() => value.callback(id, dataRow)}
                                />
                            );
                        })
                    }
                </ActionMenu>
            </ActionCell>
        );
    }

    /**
     * Renders the row inside the data table
     *
     * @return {ReactElement} The row to be displayed inside the data table
     */
    render () {
        const { dataRow: row, showCheckBoxes, id, checked, onCheckRow, rowHeight } = this.props;

        return (
            <tr className={styles.dataTableRow} style={{height: rowHeight}}>
                { showCheckBoxes
                    && (
                        <td className={styles.dataTableCell}>
                            <CheckboxInput
                                name={`select_data_row[${id}]`}
                                value={`${id}`}
                                checked={checked}
                                onChange={onCheckRow}
                                submitOnEnter={false}
                                backgroundColour={defaultAccentColour}
                                large={true}
                            />
                        </td>
                    )
                }

                { this.renderRowCols(row) }

                { this.renderActions(id) }
            </tr>
        );
    }

}
