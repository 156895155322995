import React from 'react';
import PropTypes from 'prop-types';

import './CardRowContainer.scss';

export default class CardRowContainer extends React.Component {

    static propTypes = {
        gridWidth: PropTypes.number.isRequired,
        children: PropTypes.any,
    };

    static defaultProps = {
        children: null,
    };

    /**
     * Renders the card container
     *
     * @return {ReactElement} The container element
     */
    render () {
        return (
            <div className="card-row-container" data-grid-width={this.props.gridWidth}>
                {this.props.children}
            </div>
        );
    }

}
