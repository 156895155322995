import React from 'react';
import PropTypes from 'prop-types';

import styles from './EmailLink.module.scss';

export default class EmailLink extends React.Component {

    static propTypes = {
        email: PropTypes.string.isRequired,
        children: PropTypes.any.isRequired,
    }

    /**
     * Renders the email link
     *
     * @return {ReactElement} The email link
     */
    render () {
        const {email, children, ...otherProps} = this.props;

        return (
            <a href={`mailto:${email}`} className={styles.emailLink} {...otherProps}>
                {children}
            </a>
        );
    }

}
