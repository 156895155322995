import PropTypes from 'prop-types';
import _ from 'lodash';

const getPropTypes = {
    payroll: PropTypes.object,
    templateKey: PropTypes.string,
    templateValues: PropTypes.object,
    templateContent: PropTypes.object,
    templateTags: PropTypes.object,
    dataLoaded: PropTypes.bool,
    onComponentUnmount: PropTypes.func,
};

const getDefaultProps = {
    payroll: {},
    templateKey: "",
    templateValues: {},
    templateContent: {},
    templateTags: {},
    dataLoaded: false,
    onComponentUnmount: _.noop,
};

export default { getPropTypes, getDefaultProps };
