import React from 'react';
import PropTypes from 'prop-types';
import reactHtmlParser from 'react-html-parser';

class TemplateTitle extends React.Component {

    static propTypes = {
        value: PropTypes.string,
        isPreview: PropTypes.bool,
        showInPreview: PropTypes.bool,
        size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    };

    static defaultProps = {
        value: null,
        isPreview: false,
        showInPreview: false,
        size: "h3",
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { value, isPreview, showInPreview, size: Size } = this.props;

        if ((isPreview && !showInPreview) || !value) {
            return null;
        }

        return <Size>{reactHtmlParser(value)}</Size>;
    }

}

export default TemplateTitle;
