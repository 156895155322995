import React from 'react';
import PropTypes from 'prop-types';
import employeePropTypes from '../../lib/props-schema/employeeDetailsSchema';
import appState from '../../state/App';

import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';

import AccountAccess from './account/AccountAccess';

class AccountDetailsTab extends React.Component {

    static propTypes = {
        employeeDetails: employeePropTypes.isRequired,
        handleRedraw: PropTypes.func,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    static defaultProps = {
        handleRedraw: null,
    }

    /**
     * Renders the employee personal details summary
     *
     * @return {ReactElement} The personal details summary
     */
    render () {
        const employee = this.props.employeeDetails;

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    heading={{
                        text: "Account",
                        size: "h2",
                    }}
                    maxWidth={this.props.appState.maxWidth}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        isNested: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                >
                    <AccountAccess
                        employeeDetails={employee}
                        handleRedraw={this.props.handleRedraw}
                    />
                </PageLayout>
            </AnimationContainer>
        );
    }

}

export default (appState.attachState(AccountDetailsTab));
