import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';
import classNames from 'classnames';

import Icon from '../icons/Icon';

import styles from './DocumentTray.module.scss';

class DocumentTray extends React.Component {

    static propTypes = {
        trayTitle: PropTypes.string.isRequired,
        setTimeout: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        children: PropTypes.any.isRequired,
    }

    static defaultProps = {
        onClose: null,
    }

    /**
     * Creates an instance of the document preview tray
     *
     * @param {object} props Document preview tray properties
     */
    constructor (props) {
        super(props);

        this.state = {
            title: '',
            trayStyle: "hidden",
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        const { trayTitle } = this.props;

        this.handleInitialLoad(trayTitle);
    }

    /**
     * Called if the component is updated
     *
     * @param {object} prevProps The components previous props
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        const { trayTitle } = this.props;
        const { trayStyle } = this.state;

        const childrenChanged = prevProps.children !== this.props.children;
        const titleChanged = prevProps.trayTitle !== this.props.trayTitle;
        const updated = childrenChanged || titleChanged;

        if (updated && trayStyle === "visible") {
            this.resetTray(trayTitle);
        }
    }

    /**
     * Handles the initial load of the document tray
     *
     * @param {string} trayTitle The title of the document (usually the pay date)
     *
     * @return {void}
     */
    handleInitialLoad = (trayTitle) => {
        this.setState({
            title: trayTitle,
            children: this.props.children,
        }, () => {
            this.props.setTimeout(() => {
                this.setState({
                    trayStyle: "visible",
                });
            }, 100);
        });
    }

    /**
     * Handles animation and garbage collection if/when the tray is closed
     *
     * @return {void}
     */
    handleTrayClose = () => {
        this.setState({
            trayStyle: "hidden",
        }, () => {
            this.props.setTimeout(this.props.onClose, 600);
        });
    }

    /**
     * Handles a reset of the tray, if another document tray is opened
     *
     * @param {string} trayTitle The title of the document (usually the pay date)
     *
     * @return {void}
     */
    resetTray = (trayTitle) => {
        this.setState({
            trayStyle: "hidden",
        }, () => {
            this.props.setTimeout(() => {
                this.setState({
                    children: this.props.children,
                    trayStyle: "visible",
                    title: trayTitle,
                });
            }, 600);
        });
    }

    /**
     * Renders the document tray component
     *
     * @return {ReactElement} The document tray
     */
    render () {
        const { title, trayStyle, children } = this.state;
        const trayStyles = classNames([
            styles.docTray,
            styles[trayStyle],
        ]);

        return (
            <div className={trayStyles}>
                <div className={styles.docTrayHeader}>
                    <button
                        type="button"
                        className={styles.backButton}
                        onClick={this.handleTrayClose}
                    >
                        <Icon icon="Close" className={styles.closeButton} />
                    </button>
                    <h2 className={styles.title}>{title}</h2>
                </div>
                <div className={styles.docTrayContent}>
                    {children}
                </div>
            </div>
        );
    }

}

export default ReactTimeout(DocumentTray); // eslint-disable-line new-cap
