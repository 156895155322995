import StateAccessor from './StateAccessor';
import PropTypes from 'prop-types';
import _ from 'lodash';

class App extends StateAccessor {

    /**
     * Creates an instance of the app state container
     */
    constructor () {
        super("App");
    }

    /**
     * Gets the intial state
     *
     * @return {object} The state object
     */
    getInitialState () {
        return {
            loaded: false,
            entered: false,
            mainMenuOpen: false,
            profileMenuPosition: {
                top: null,
                left: null,
            },
            companies: [],
            payrolls: [],
            selectedPayroll: null,
            currentNotificationId: 0,
            currentSnackbarId: 0,
            notifications: [],
            globalSnackBars: [],
            announcementsCache: [],
            navigationBlocked: false,
            navigationBlockPrompt: {
                heading: "You have unsaved changes",
                message: "Are you sure you wish to leave this page?",
            },
            maxWidth: "1500px",
        };
    }

    /**
     * Gets the propTypes description of the state
     *
     * @return {object} The description
     */
    getPropTypes () {
        return {
            loaded: PropTypes.bool,
            entered: PropTypes.bool,
            mainMenuOpen: PropTypes.bool,
            profileMenuPosition: PropTypes.shape({
                top: PropTypes.integer,
                left: PropTypes.integer,
            }),
            companies: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            })),
            payrolls: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                company_id: PropTypes.number, // eslint-disable-line camelcase
                periods: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.integer,
                    year: PropTypes.integer,
                    period_number: PropTypes.number, // eslint-disable-line camelcase
                })),
                frequency: PropTypes.string,
                data_template_group: PropTypes.integer, // eslint-disable-line camelcase
                departments: PropTypes.arrayOf(PropTypes.shape({
                    dept_no: PropTypes.integer, // eslint-disable-line camelcase
                    dept_name: PropTypes.string, // eslint-disable-line camelcase
                })),
            })),
            selectedPayroll: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                company_id: PropTypes.number, // eslint-disable-line camelcase
            }),
            currentNotificationId: PropTypes.number,
            currentSnackbarId: PropTypes.number,
            notifications: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                text: PropTypes.string.isRequired,
                type: PropTypes.oneOf(["warn", "success", "error", "info"]).isRequired,
                duration: PropTypes.number,
            })),
            globalSnackBars: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                type: PropTypes.oneOf(["warn", "success", "error", "info"]).isRequired,
                message: PropTypes.string.isRequired,
            })),
            announcementsCache: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                payrollid: PropTypes.number.isRequired,
                deptno: PropTypes.number.isRequired,
                subject: PropTypes.string,
                message: PropTypes.string,
                addedby: PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    forename: PropTypes.string,
                    surname: PropTypes.string,
                }),
                hideonread: PropTypes.bool,
                notice_read: PropTypes.array, // eslint-disable-line camelcase
                dateadded: PropTypes.string,
                datestart: PropTypes.string,
                dateend: PropTypes.string,
            })),
            navigationBlocked: PropTypes.bool.isRequired,
            navigationBlockPrompt: PropTypes.shape({
                heading: PropTypes.string.isRequired,
                message: PropTypes.string.isRequired,
            }).isRequired,
            maxWidth: PropTypes.string,
        };
    }

    /**
     * Sets that the initial data has loaded from the API
     */
    setLoaded () {
        this.setState((prevState) => {
            return {
                ...prevState,
                loaded: true,
            };
        });
    }

    /**
     * Sets that the initial user has completed the login process and entered the app
     */
    setEntered () {
        this.setState((prevState) => {
            return {
                ...prevState,
                entered: true,
            };
        });
    }

    /**
     * Sets the open state for the main app menu
     *
     * @param {boolean} open If the menu is open
     */
    setMainMenuOpen (open) {
        this.setState((prevState) => {
            return {
                ...prevState,
                mainMenuOpen: open,
            };
        });
    }

    /**
     * Sets the position of the profile menu icon
     *
     * @param {integer} top The number of pixels from the top of the page
     * @param {integer} left The number of pixels from the left of the page
     */
    setProfileMenuPosition (top, left) {
        this.setState((prevState) => {
            return {
                ...prevState,
                profileMenuPosition: {
                    top,
                    left,
                },
            };
        });
    }

    /**
     * Sets the list of companies and payrolls that the user has access to
     *
     * @param {array} companies The list of companies
     * @param {array} payrolls The list of payrolls
     */
    setCompaniesAndPayrolls (companies, payrolls) {
        this.setState((prevState) => {
            return {
                ...prevState,
                companies,
                payrolls,
            };
        });
    }

    /**
     * Sets the list of employees that the user has access to
     *
     * @param {array} employeesArr The list of employees
     */
    setEmployees (employeesArr) {
        const employees = employeesArr.map(({
            account_status, /* eslint-disable-line camelcase */
            forename_1, /* eslint-disable-line camelcase */
            id,
            name,
            payroll_id, /* eslint-disable-line camelcase */
            payroll_no, /* eslint-disable-line camelcase */
            surname,
        }) => {
            return {
                account_status, /* eslint-disable-line camelcase */
                forename_1, /* eslint-disable-line camelcase */
                id,
                name: (name) ? name : `${surname}, ${forename_1}`, /* eslint-disable-line camelcase */
                payroll_id, /* eslint-disable-line camelcase */
                payroll_no, /* eslint-disable-line camelcase */
                surname,
            };
        });

        this.setState((prevState) => {
            return {
                ...prevState,
                employees,
            };
        });
    }

    /**
     * Attaches a list of periods to the payroll they are for
     *
     * @param {integer} payrollId The ID of the payroll
     * @param {array} periods the list of periods
     */
    setPeriods (payrollId, periods) {
        this.setState((prevState) => {
            const newPayrolls = prevState.payrolls.map((payroll) => {
                if (payroll.id === payrollId) {
                    payroll.periods = periods;
                }

                return payroll;
            });

            return {
                ...prevState,
                payrolls: newPayrolls,
            };
        });
    }

    /**
     * Sets the currently selected payroll
     *
     * @param {object} payroll The payroll info
     */
    setSelectedPayroll (payroll) {
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedPayroll: payroll,
            };
        });
    }

    /**
     * Adds a notification to the appState
     *
     * @param {object} notification The notification
     * @param {?function} callback An optional callback
     */
    addNotification (notification, callback = _.noop) {
        this.setState((prevState) => {
            let nextId = ++prevState.currentNotificationId;
            const { duration } = notification;
            const newNotifications = prevState.notifications.concat({
                id: nextId,
                ...notification,
            });

            if (duration > 0) {
                this.startNotificationTimer(nextId, duration);
            }

            return {
                ...prevState,
                notifications: newNotifications,
                currentNotificationId: nextId,
            };
        });

        callback();
    }

    /**
     * Starts the timer to close the notification if a duration is supplied
     *
     * @param {integer} id The ID of the notification to close
     * @param {integer} duration The duration (in seconds) to display the notification
     */
    startNotificationTimer (id, duration) {
        const timer = duration * 1000;

        window.setTimeout(() => {
            this.closeNotification(id);
        }, timer);
    }

    /**
     * Removes the notification from the appState on close
     *
     * @param {integer} id The ID of the notification to remove
     */
    closeNotification (id) {
        this.setState((prevState) => {
            let newNotifications = prevState.notifications.filter((notification) => {
                return notification.id !== id;
            });

            return {
                ...prevState,
                notifications: newNotifications,
            };
        });
    }

    /**
     * Adds a snack bar to the appState
     *
     * @param {object} snackBar The snack bar
     */
    addSnackBar (snackBar) {
        this.setState((prevState) => {
            let nextId = ++prevState.currentSnackbarId;
            const newSnackBars = prevState.globalSnackBars.concat({
                id: nextId,
                ...snackBar,
            });

            return {
                ...prevState,
                globalSnackBars: newSnackBars,
                currentSnackbarId: nextId,
            };
        });
    }

    /**
     * Removes the snack bar from the appState on close
     *
     * @param {integer} id The ID of the snack bar to remove
     */
    closeSnackBar (id) {
        this.setState((prevState) => {
            let newGlobalSnackBars = prevState.globalSnackBars.filter((snackBar) => {
                return snackBar.id !== id;
            });

            return {
                ...prevState,
                globalSnackBars: newGlobalSnackBars,
            };
        });
    }

    /**
     * Stores the announcements from the API in the store, used in single announcement view/edit
     * Refreshed each time the announcement API endpoint is called
     *
     * @param {array} announcements The array of announcememts
     *
     * @return {void}
     */
    setAnnouncementsCache (announcements) {
        this.setState((prevState) => {
            return {
                ...prevState,
                announcementsCache: announcements,
            };
        });
    }

    /**
     * Used to update the changes made to the announcement in cache
     * Cache is refreshed when the announcment list is loaded
     *
     * @param {integer} id The ID of the announcement we're updating
     * @param {object} payload A collection of the changes
     * @param {?function} callback An optional callback to run after the cache has been updated
     *
     * @return {void}
     */
    updateCachedAnnouncement (id, payload, callback = _.noop) {
        this.setState((prevState) => {
            let { announcementsCache } = prevState;
            let announcementToUpdate = _.first(_.remove(announcementsCache, (announcement) => {
                return announcement.id === id;
            }));

            if (!announcementToUpdate || _.isEmpty(payload)) {
                return { ...prevState };
            }

            _.forEach(payload, (value, key) => {
                announcementToUpdate[key] = value;
            });

            return {
                ...prevState,
                announcementsCache: announcementsCache.concat(announcementToUpdate),
            };
        });

        callback();
    }

    /**
     * Blocks navigation, asking user to confirm action
     */
    blockNavigation () {
        this.setState((prevState) => {
            return {
                ...prevState,
                navigationBlocked: true,
            };
        });
    }

    /**
     * Unblocks navigation, allowing user to continue
     */
    unblockNavigation () {
        this.setState((prevState) => {
            return {
                ...prevState,
                navigationBlocked: false,
            };
        });
    }

}

export default new App();
