import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { paths } from "../../lib";
import appState from 'state/App';
import api from '../../lib/api';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { employeeDefaultProps } from '../../lib/props-schema/employeeDetailsSchema';
import { payslipsDefaultProps } from '../../lib/props-schema/payslipsSchema';
import { documentsDefaultProps } from '../../lib/props-schema/documentsSchema';
import { formatFullName } from '../../lib/formattingHelpers';

import EmployeeProfileTitle from './assets/EmployeeProfileTitle';
import EmployeeProfileTab from './EmployeeProfileTab';
import PayrollTab from './PayrollTab';
import DocumentsTab from './DocumentsTab';
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import AccountDetails from './AccountDetailsTab';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { ViewPayslip } from "../Payslips/index";

class ViewEmployeeProfile extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the employee profile page
     *
     * @param {object} props Profile properties
     */
    constructor (props) {
        super(props);
        this.tabRef = React.createRef();

        this.state = {
            dataLoaded: false,
            redrawRequired: false,
            employeeDetails: employeeDefaultProps,
            employeePayslips: payslipsDefaultProps,
            employeeDocuments: documentsDefaultProps,
            payslipId: null,
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleInitialApiCall();
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = "EMC";
    }

    /**
     * Invoked when the component updates
     *
     * @return {void}
     */
    componentDidUpdate ({ location: prevLocation }) {
        const { location } = this.props;

        if (this.state.redrawRequired) {
            this.handleDependantApiCall();
        }

        if (location !== prevLocation) {
            this.checkViewPayslip(true);
        }
    }

    /**
     * Checks if a payslipId query is included in the url and, if so, take the user to the payslip
     *
     * @param {boolean} pathMatch If the previous url was view employee profile
     *
     * @return {void}
     */
    checkViewPayslip = (pathMatch) => {
        const { history, location, match } = this.props;
        const { payslipId: prevPayslipId } = this.state;
        const { payslipId } = queryString.parse(location.search);

        if (payslipId && (payslipId !== prevPayslipId)) {
            this.setState({
                payslipId,
            }, () => {
                if (pathMatch) {
                    history.goBack();
                }

                history.replace(generatePath(paths.employee, match.params));
                this.tabRef.current.setCurrentTab(1);
            });
        }
    }

    /**
     * Called once the component has loaded, queries the API for the employee by ID
     *
     * @return {void}
     */
    handleInitialApiCall = () => {
        const { history, match } = this.props;
        const employeeId = _.toNumber(match.params.employee_id);

        api.get(`/employee/${employeeId}`)
            .then((response) => {
                this.setState({
                    employeeDetails: response.data,
                }, () => {
                    this.handleDependantApiCall();
                    this.setPageName(response.data);
                    this.checkViewPayslip();
                });
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    history.push(paths.forbidden);
                }
            });
    }

    /**
     * Called once the initial API call has completed, used to make subsequent API calls
     * that require data from the initial call
     *
     * @return {void}
     */
    handleDependantApiCall = () => {
        const { start_date: startDate, id } = this.state.employeeDetails;
        let payslipUrl = `/employee/${id}/payslips`;

        if (startDate) {
            payslipUrl += `?start_date=${moment(startDate).format("YYYY-MM-DD")}`;
        }

        axios.all([
            this.handleGetPayslips(payslipUrl),
            this.handleGetPersonalCompanyDocs(id),
            this.handleGetPensionDocs(id),
        ]).then(axios.spread((payslips, personalcompany, pension) => {
            this.setState({
                employeePayslips: payslips,
                employeeDocuments: [].concat(personalcompany, pension),
                redrawRequired: false,
            }, () => {
                this.handleApiCallComplete();
            });
        }));
    }

    /**
     * Set page name
     *
     * @param {object} employee employee data
     *
     * @return {void}
     */
    setPageName = (employee) => {
        const pageName = formatFullName(
            employee.forename_1, // eslint-disable-line camelcase
            employee.forename_2, // eslint-disable-line camelcase
            employee.surname,
            employee.name,
        );

        document.title = pageName;
    }

    /**
     * Get the payslips from the API
     *
     * @param {string} payslipUrl The API URL for the payslips
     *
     * @return {array} The response
     */
    async handleGetPayslips (payslipUrl) {
        try {
            const res = await api.get(payslipUrl);

            return res.data;
        } catch (error) {
            appState.addNotification({
                text: `Failed to load payslips`,
                type: "error",
                duration: 5,
            });

            return [];
        }
    }

    /**
     * Get the personalcompany documents from the API
     *
     * @return {array} The response
     */
    async handleGetPersonalCompanyDocs () {
        const { id } = this.state.employeeDetails;

        try {
            const res = await api.get(`/documents/${id}/personalcompany`);

            return res.data;
        } catch (error) {
            appState.addNotification({
                text: `Failed to load personal documents`,
                type: "error",
                duration: 5,
            });

            return [];
        }
    }

    /**
     * Get the pension documents from the API
     *
     * @return {array} The response
     */
    async handleGetPensionDocs () {
        const { id } = this.state.employeeDetails;

        try {
            const res = await api.get(`/documents/${id}/pension`);

            return res.data;
        } catch (error) {
            appState.addNotification({
                text: `Failed to load pension documents`,
                type: "error",
                duration: 5,
            });

            return [];
        }
    }

    /**
     * Called once all API calls have completed
     *
     * @return {void}
     */
    handleApiCallComplete = () => {
        this.setState({
            dataLoaded: true,
        });
    }

    /**
     * Forces a redraw of the HOC to query the API again for changes
     * This is done as the API result is stored in the state, and display default values
     * are taken from this
     *
     * @return {void}
     */
    handleRedraw = () => {
        this.setState({
            redrawRequired: true,
            dataLoaded: true,
        });
    }

    /**
     * Gets the employees latest payslip
     *
     * @param {array} payslips The Employees payslips
     *
     * @return {object} The payslip
     */
    getLatestPayslip = (payslips) => {
        if (_.isEmpty(payslips)) {
            return null;
        }

        return _.first(_.orderBy(payslips, (payslip) => {
            return payslip.paydate;
        }, 'desc'));
    }

    /**
     * Gets the employees latest 4 documents
     *
     * @param {array} documents The Employees latest documents
     *
     * @return {object} The latest documents
     */
    getLatestDocuments = (documents) => {
        if (documents.length < 1) {
            return null;
        }

        return _.slice(_.orderBy(documents, (latestDocument) => {
            return latestDocument.datetime_added;
        }, 'desc'), 0, 10);
    }

    /**
     * Returns the tab content for the page layout
     *
     * @return {array} The tab content
     */
    getTabContent = () => {
        const {
            dataLoaded,
            employeeDetails,
            employeePayslips,
            employeeDocuments,
        } = this.state;

        if (!dataLoaded) {
            return [];
        }

        return [
            {
                text: "Profile",
                component: (
                    <EmployeeProfileTab
                        employeeDetails={employeeDetails}
                        latestPayslip={this.getLatestPayslip(employeePayslips)}
                        latestDocuments={this.getLatestDocuments(employeeDocuments)}
                        employeeDocuments={employeeDocuments}
                    />
                ),
            },
            {
                text: "Payroll",
                component: this.getPayrollTabComponent(),
            },
            {
                text: "Documents",
                component: (
                    <DocumentsTab
                        employeeDocuments={employeeDocuments}
                        employeeDetails={employeeDetails}
                        reLoadTableDocs={this.handleRedraw}
                    />),
            },
            {
                text: "Account",
                component: (
                    <AccountDetails
                        employeeDetails={employeeDetails}
                        handleRedraw={this.handleRedraw}
                    />
                ),
            },
        ];
    }

    /**
     * Get the payroll tab component
     *
     * @return {ReactElement} The payroll tab component
     */
    getPayrollTabComponent = () => {
        const { employeeDetails, employeePayslips, payslipId } = this.state;

        if (payslipId) {
            return (
                <ViewPayslip
                    unsetPayslipId={() => this.setState({ payslipId: null })}
                    payslipId={payslipId}
                />
            );
        }

        return (
            <PayrollTab
                employeeDetails={employeeDetails}
                employeePayslips={employeePayslips}
            />
        );
    }

    /**
     * Renders the employee profile page
     *
     * @return {ReactElement} The employee profile for a given ID
     */
    render () {
        const { employeeDetails } = this.state;
        const tabContent = this.getTabContent();

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    heading={{
                        text: <EmployeeProfileTitle employee={employeeDetails} />,
                        size: "h1",
                    }}
                    theme="light"
                    maxWidth={"100%"}
                    pageType="full"
                    display={{
                        isDetached: false,
                        isPadded: false,
                        hasBackground: false,
                        hasGutter: false,
                    }}
                    tabContent={tabContent}
                    ref={this.tabRef}
                />
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(ViewEmployeeProfile));
