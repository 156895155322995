import React from 'react';
import PropTypes from 'prop-types';
import CardFrame from './CardFrame';
import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";
import moment from "moment";

import styles from './PropertiesCard.module.scss';

export default class PropertiesCard extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        payRun: PropTypes.object.isRequired,
        theme: PropTypes.string,
    }

    static defaultProps = {
        className: null,
        theme: "light",
    }

    /**
     * Renders the employee svg
     *
     * @return {*} The employee svg
     */
    renderEmployeeSVG () {
        /* eslint-disable max-len */
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" className={styles.svg}>
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5z" />
            </svg>
        );
        /* eslint-enable */
    }

    /**
     * Renders the card content
     *
     * @return {ReactElement} The card element
     */
    render () {
        const { payDate, payrollFullName, payrollName, paySlipTotal } = this.props.payRun;
        const formattedDate = moment(payDate, "YYYY-MM-DD").format("DD-MM-YY");

        return (
            <CardFrame className={this.props.className} theme={this.props.theme}>
                <div className={styles.card}>
                    <div className={styles.dateContainer}>
                        <div className={styles.date}>
                            {formattedDate}
                        </div>
                    </div>
                    <div className={styles.totalContainer}>
                        <div className={styles.name}>
                            <Tooltip text={payrollFullName} position="bottom">
                                {payrollName}
                            </Tooltip>
                        </div>
                        <div className={styles.total}>
                            {this.renderEmployeeSVG()}
                            {paySlipTotal}
                        </div>
                    </div>
                </div>
            </CardFrame>
        );
    }

}
