import React from 'react';
import PropTypes from 'prop-types';
import api from 'lib/api';
import { generatePath, withRouter } from 'react-router-dom';
import { paths } from "../../../lib";
import appState from 'state/App';
import _ from 'lodash';

import { DefaultForm } from "@dataplan/react-components/dist/components/forms";
import { PrimaryButton, SecondaryButton } from "@dataplan/react-components/dist/components/forms/controls";

import { defaultAccentColour } from '../../../Colours';

import styles from './TemplateEditPreview.module.scss';

class TemplateEditContent extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        rawValues: PropTypes.object.isRequired,
        canSubmit: PropTypes.bool.isRequired,
        onComponentUnmount: PropTypes.func.isRequired,
        children: PropTypes.any,
        previewCard: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        previewCard: false,
    };

    /**
     * Called when the component is about to be removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        const { canSubmit, onComponentUnmount } = this.props;

        if (canSubmit) {
            onComponentUnmount();
        }
    }

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void} submits the form if valid
     */
    handleSubmit = (event) => {
        const { history, match, rawValues } = this.props;
        const { payroll_id: payrollId, template_key: templateKey } = match.params;
        event.preventDefault();

        let valuesToSave = {};

        _.forEach(rawValues, (value, key) => {
            if (!value.hasChanged) {
                return;
            }

            valuesToSave[key] = value.value;
        });

        appState.unblockNavigation();

        api.patch(`/templates/${payrollId}/${templateKey}`, valuesToSave)
            .then(() => {
                appState.addNotification({
                    text: `Template has been updated`,
                    type: "success",
                    duration: 5,
                }, () => {
                    history.push(generatePath(paths.templatesPreview, match.params));
                });
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to update template`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Render the form buttons
     *
     * @return {ReactElement} The form buttons
     */
    renderButtons () {
        const { canSubmit, history, match } = this.props;
        const cancelAction = () => history.push(generatePath(paths.singlePayroll, match.params));

        return (
            <div>
                <PrimaryButton type="submit" disabled={!canSubmit}>Save</PrimaryButton>
                <SecondaryButton
                    className={styles.secondaryButton}
                    onClick={cancelAction}
                    accent={defaultAccentColour}
                    text={"Cancel"}
                    aria-label={"Cancel"}
                />
            </div>
        );
    }

    /**
     * Render the form content
     *
     * @return {ReactElement} The form content
     */
    renderContent () {
        const { previewCard } = this.props;

        if (previewCard) {
            return (
                <div className={styles.templatePreviewCard}>
                    {this.props.children}
                    {this.renderButtons()}
                </div>
            );
        }

        return (
            <>
                {this.props.children}
                {this.renderButtons()}
            </>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                {this.renderContent()}
            </DefaultForm>
        );
    }

}

export default withRouter(TemplateEditContent);
