import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from "react-timeout";

import { TextInput } from '@dataplan/react-components/dist/components/forms';
import { getTransitionTime } from '../../../lib/animationHelpers';
import FloatingMenu from "@dataplan/react-components/dist/components/ui/floating_menu/FloatingMenu";
import {NavLink} from "react-router-dom";

import styles from './AdvancedSearch.module.scss';

class AdvancedSearchInput extends Component {

    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
        setTimeout: PropTypes.func.isRequired,
        anchorPoint: PropTypes.oneOf(['left', 'right']),
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        searchKey: PropTypes.string,
        searchInputRef: PropTypes.any,
    }

    static defaultProps = {
        anchorPoint: 'left',
        placeholder: '',
        searchKey: '',
        searchInputRef: '',
    }

    /**
     * Creates an instance of the action menu
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            menuOpen: false,
            menuVisible: false,
            queryString: "",
        };

        this.menuRef = React.createRef();
    }

    /**
     * Adds the menu to the DOM then makes it visible
     *
     * @return {void}
     */
    openMenu () {
        this.setState({
            menuOpen: true,
            menuVisible: false,
        }, () => {
            this.setState({
                menuVisible: true,
            });
        });
    }

    /**
     * Hides the menu then removes it from the DOM
     *
     * @return {void}
     */
    closeMenu () {
        if (!this.menuRef.current) {
            return;
        }

        const delay = getTransitionTime(this.menuRef.current);

        // Set the menu to hidden
        this.setState({
            menuVisible: false,
        }, () => {
            // Then remove it once the animations completed
            this.props.setTimeout(() => {
                this.setState({
                    menuOpen: false,
                });
            }, delay);
        });
    }

    /**
     * Click handler for any click outside of the menu
     *
     * @return {void}
     */
    handleClickOutside = () => {
        this.closeMenu();
    }

    /**
     * Click handler for the open menu button
     *
     * @return {void}
     */
    handleClick = () => {
        this.openMenu();
    }

    /**
     * Handles users typing in the search input
     *
     * @param {Event} event The onChange event for the input
     *
     * @return {void}
     */
    onSearchInput = (event) => {
        this.setState({
            queryString: event.target.value,
        });
    }

    /**
     * Renders the name search component
     *
     * @return {Component} The name search component
     */
    renderSearch () {
        return (
            <div className={styles.row}>
                <div>{this.props.label}</div>
                <TextInput
                    aria-label="Search"
                    type="search"
                    name="search_term"
                    ref={this.props.searchInputRef}
                    className={styles.dataTableSearchInput}
                    placeholder={this.props.placeholder}
                    onClick={this.handleClick}
                    value={this.state.queryString}
                    onChange={this.onSearchInput}
                />
            </div>
        );
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    renderNameLink () {
        const { data, searchKey } = this.props;
        const searchValue = this.state.queryString.toLowerCase();

        return data.map((employee) => {
            const displayText = employee[searchKey].text;
            const comparisonValueName = employee.employeeName.text.toLowerCase();
            const comparisonValueInput = displayText.toString().toLowerCase();

            const searchName = comparisonValueName.indexOf(searchValue) >= 0;
            const searchInput = comparisonValueInput.indexOf(searchValue) >= 0;

            if (!searchName && !searchInput) {
                return <div />;
            }

            return (
                <div className={styles.columnContainer} key={employee.id}>
                    <NavLink
                        to={employee.employeeName.link}
                        className={styles.column1}
                    >
                        {employee.employeeName.text}
                    </NavLink>
                    <div className={styles.column2}>
                        {displayText}
                    </div>
                </div>
            );
        });
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        return (
            <>
                {this.renderSearch()}
                <FloatingMenu
                    anchor={this.props.searchInputRef}
                    anchorPoint={this.props.anchorPoint}
                    open={this.state.menuOpen}
                    visible={this.state.menuVisible}
                    ref={this.menuRef}
                    onClickOutside={this.handleClickOutside}
                    className={styles.menu}
                >
                    {this.renderNameLink()}
                </FloatingMenu>
            </>
        );
    }

}

// eslint-disable-next-line react/no-multi-comp,new-cap
export default ReactTimeout(React.forwardRef((props, ref) => (<AdvancedSearchInput forwardedRef={ref} {...props} />)));
