import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardFrame from './CardFrame';

import styles from './ActionCard.module.scss';

export default class ActionCard extends React.Component {

    static propTypes = {
        label: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        theme: PropTypes.string,
        action: PropTypes.func,
    }

    static defaultProps = {
        theme: "light",
        action: null,
    }

    /**
     * Renders the action container
     *
     * @return {ReactElement} The container
     */
    render () {
        const classList = classNames(styles.frame, {
            [styles.hasAction]: (this.props.action),
        });

        return (
            <CardFrame theme={this.props.theme} className={classList} action={this.props.action}>
                <div className={styles.container}>
                    <span className={styles.total}>
                        {this.props.total}
                    </span>
                    <span className={styles.label}>
                        {this.props.label}
                    </span>
                </div>
            </CardFrame>
        );
    }

}
