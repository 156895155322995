import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Toggle.scss';

export default class Toggle extends React.Component {

    static propTypes = {
        label: PropTypes.string.isRequired,
        initialValue: PropTypes.bool,
        handleChange: PropTypes.func,
    }

    static defaultProps = {
        handleChange: null,
        initialValue: false,
    }

    /**
     * Creates an instance of the toggle control
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            value: props.initialValue,
        };
    }

    /**
     * Handle the button being clicked, updates the state and calls the handler
     *
     * @return {void}
     */
    handleClick = () => {
        this.setState((oldState) => {
            return {
                value: !oldState.value,
            };
        }, () => {
            if (this.props.handleChange) {
                this.props.handleChange(this.state.value);
            }
        });
    }

    /**
     * Renders the toggle control
     *
     * @return {ReactElement} The toggle control
     */
    render () {
        const containerClassList = classNames({
            "toggle-container": true,
            "toggle-state-on": this.state.value,
            "toggle-state-off": !this.state.value,
        });

        return (
            <div className={containerClassList}>
                <button
                    type="button"
                    className="toggle-switch-button"
                    onClick={this.handleClick}
                >
                    <div className="toggle-switch-indicator" />
                </button>
                <label className="toggle-label">
                    {this.props.label}
                </label>
            </div>
        );
    }

}
