import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from 'react-router-dom';
import api from "lib/api";
import appState from 'state/App';
import moment from 'moment';
import _ from 'lodash';
import naturalCompare from 'string-natural-compare';

import {
    formatLongDate,
    getPayrollName,
    handleArchiveAnnouncement,
    filterObjectEmptyValues,
    getFullPayrollName,
    paths,
} from 'lib/';
import { DataTable, SnackBar } from 'components/';
import { AddButton } from "@dataplan/react-components/dist/components/forms";
import { defaultAccentColour } from '../../Colours';
import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';

class AnnouncementList extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the announcement list
     *
     * @param {object} props List properties
     */
    constructor (props) {
        super(props);

        this.state = {
            activeAnnouncements: [],
            archivedAnnouncements: [],
            dataLoaded: false,
            redrawRequired: false,
            showSnackBar: false,
            snackBarType: "",
            snackBarMessage: "",
            snackBarHeading: "",
            snackBarOnConfirm: null,
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleApiCall();
    }

    /**
     * Invoked when the component updates
     *
     * @return {void}
     */
    componentDidUpdate () {
        if (this.state.redrawRequired) {
            this.handleApiCall();
        }
    }

    /**
     * Called once the component has loaded, queries the API for announcements
     *
     * @return {void}
     */
    handleApiCall () {
        api.get('/announcements')
            .then((res) => {
                const announcements = res.data;

                this.setState({
                    redrawRequired: false,
                }, () => {
                    appState.setAnnouncementsCache(announcements);
                    this.splitActiveArchived(announcements);
                });
            });
    }

    /**
     * Forces a redraw of the HOC to query the API again for changes
     * This is done as the API result is stored in the state, and display default values
     * are taken from this
     *
     * @return {void}
     */
    handleRedraw = () => {
        this.setState({
            dataLoaded: false,
            redrawRequired: true,
        });
    }

    /**
     * Splits the announcements into active and archived
     *
     * @param {array} announcements The array of announcements
     *
     * @return {void}
     */
    splitActiveArchived (announcements) {
        let activeAnnouncements = [];
        let archivedAnnouncements = [];

        activeAnnouncements.push(_.filter(announcements, (announcement) => {
            return moment(announcement.dateend).unix() >= moment().unix();
        }));
        archivedAnnouncements.push(_.filter(announcements, (announcement) => {
            return moment(announcement.dateend).unix() < moment().unix();
        }));

        this.setState({
            activeAnnouncements: this.mapAnnouncementData(_.flatten(activeAnnouncements), true),
            archivedAnnouncements: this.mapAnnouncementData(_.flatten(archivedAnnouncements), false),
            dataLoaded: true,
        });
    }

    /**
     * Map announcements into a shape for the data table
     *
     * @param {array} announcements The raw array of announcements
     * @param {bool} isActive If we are handling active or archived announcements
     *
     * @return {array} The mapped array of announcements
     */
    mapAnnouncementData (announcements, isActive) {
        const { payrolls, companies } = this.props.appState;
        const multiplePayrolls = (payrolls.length > 1);

        const sortedAnnouncments = this.sortAnnouncementsByPubDate(announcements);

        return sortedAnnouncments.map((announcement) => {
            const expiryDate = formatLongDate(announcement.dateend);
            const isPublished = this.getAnnouncementStatus(announcement.datestart);

            let announcementMap = {
                id: announcement.id,
                subject: {
                    text: announcement.subject,
                    // eslint-disable-next-line camelcase
                    link: generatePath(paths.announcementViewEdit, { announcement_id: announcement.id }),
                },
                expiry: {
                    text: (expiryDate === '31 Dec 9999') ? 'Never' : expiryDate,
                },
                published: {
                    text: formatLongDate(announcement.dateadded),
                },
                author: {
                    text: [announcement.addedby.forename, announcement.addedby.surname].join(' ').trim(),
                },
                payroll: (multiplePayrolls ? {
                    component: this.wrapTooltip(
                        getFullPayrollName(announcement.payrollid, payrolls, companies),
                        getPayrollName(announcement.payrollid, payrolls, companies)
                    ),
                } : null),
            };

            if (isActive) {
                announcementMap.status = {
                    text: (isPublished) ? 'Published' : 'Scheduled',
                };
            }

            return filterObjectEmptyValues(announcementMap);
        });
    }

    /**
     * Adds a tooltip to show the full company name
     *
     * @param {string} tooltip full company name
     * @param {string} display shortened company name
     *
     * @return {ReactElement} The tooltip component
     */
    wrapTooltip = (tooltip, display) => {
        return (
            <Tooltip text={tooltip} position="bottom">
                {display}
            </Tooltip>
        );
    }


    /**
     * Sort announcements by date published
     *
     * @param {array} announcements The announcements to sort
     *
     * @return {array} The array of sorted announcements
     */
    sortAnnouncementsByPubDate (announcements) {
        return announcements.sort((a, b) => {
            const dateA = moment(a.dateadded).unix().toString();
            const dateB = moment(b.dateadded).unix().toString();

            return naturalCompare(dateB, dateA);
        });
    }

    /**
     * Function passed to the actions menu to create snackbars for confirmation
     *
     * @param {Object} snackBar The snackbar properties
     *
     * @return {void}
     */
    createSnackBarConfirmation = (snackBar) => {
        const {
            type,
            heading,
            message,
            onConfirm,
        } = snackBar;

        this.setState({
            showSnackBar: true,
            snackBarType: type,
            snackBarMessage: message,
            snackBarHeading: heading,
            snackBarOnConfirm: onConfirm,
        });
    }

    /**
     * Checks if the announcement is published or scheduled
     *
     * @param {string} startDate The announcements start date
     *
     * @return {bool} true if published, false if scheduled
     */
    getAnnouncementStatus (startDate) {
        const validStartDateUnix = (moment(startDate, "YYYY-MM-DDTHH:mm:ssZ").isValid())
            ? moment(startDate, "YYYY-MM-DDTHH:mm:ssZ").unix()
            : moment("1970-01-01", "YYYY-MM-DD").unix();

        return (validStartDateUnix < moment().unix());
    }

    /**
     * Convenience function to map the attributes for displaying the data table
     *
     * @return {object} The data table attributes
     */
    getDataTableAttrs () {
        const multiplePayrolls = (this.props.appState.payrolls.length > 1);

        const colOrder = [
            "subject",
            "expiry",
            "published",
            "author",
            "payroll",
            "status",
        ];

        const activeAnnouncementHeadings = filterObjectEmptyValues({
            subject: "Title",
            expiry: "Expiry",
            published: "Date published",
            author: "Published by",
            payroll: (multiplePayrolls ? "Payroll" : null),
            status: "Status",
        });

        const archivedAnnouncementHeadings = filterObjectEmptyValues({
            subject: "Title",
            expiry: "Expiry",
            published: "Date published",
            author: "Published by",
            payroll: (multiplePayrolls ? "Payroll" : null),
        });

        const activeAnnouncementFilters = filterObjectEmptyValues({
            payroll: (multiplePayrolls ? "Payroll" : null),
            status: "Status",
        });

        const archivedAnnouncementFilters = filterObjectEmptyValues({
            payroll: (multiplePayrolls ? "Payroll" : null),
        });

        return {
            activeAnnouncementHeadings,
            archivedAnnouncementHeadings,
            activeAnnouncementFilters,
            archivedAnnouncementFilters,
            colOrder,
        };
    }

    /**
     * Get the tab content for the page
     *
     * @return {array} The array of content for the tabs
     */
    getTabContent () {
        const { history } = this.props;
        const { dataLoaded, activeAnnouncements, archivedAnnouncements } = this.state;
        const {
            activeAnnouncementHeadings,
            archivedAnnouncementHeadings,
            activeAnnouncementFilters,
            archivedAnnouncementFilters,
            colOrder,
        } = this.getDataTableAttrs();

        let activeActions = {
            label: "Actions",
            actions: [
                {
                    label: "Edit",
                    callback: (announcementId) => history.push(generatePath(paths.announcementViewEdit, {
                        announcement_id: announcementId, /* eslint-disable-line camelcase */
                    })),
                },
                {
                    label: "Archive",
                    callback: (announcementId, announcementData) => handleArchiveAnnouncement(
                        announcementId,
                        announcementData,
                        this.handleRedraw,
                        this.createSnackBarConfirmation
                    ),
                },
            ],
        };

        return [
            {
                text: "All",
                component: (
                    <DataTable
                        key="all-announcements"
                        colOrder={colOrder}
                        data={activeAnnouncements}
                        dataLoaded={dataLoaded}
                        headings={activeAnnouncementHeadings}
                        searchable
                        filterable={activeAnnouncementFilters}
                        actions={activeActions}
                        emptySearch="announcements"
                    />
                ),
            },
            {
                text: "Archived",
                component: (
                    <DataTable
                        key="archived-announcements"
                        colOrder={colOrder}
                        data={archivedAnnouncements}
                        dataLoaded={dataLoaded}
                        headings={archivedAnnouncementHeadings}
                        searchable
                        filterable={archivedAnnouncementFilters}
                        emptySearch="announcements"
                    />
                ),
            },
        ];
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { history } = this.props;

        const {
            showSnackBar,
            snackBarType,
            snackBarMessage,
            snackBarHeading,
            snackBarOnConfirm,
        } = this.state;

        const headerAction = {
            drawer: null,
            button: (
                <AddButton
                    colour={defaultAccentColour}
                    onClick={() => history.push(paths.announcementAdd)}
                />
            ),
        };

        return (
            <>
                <div>
                    <AnimationContainer
                        animationStyle="animationContainer"
                        appearTimeout={200}
                        enterTimeout={1000}
                        exitTimeout={100}
                    >
                        <PageLayout
                            headerAction={headerAction}
                            heading={{
                                text: `Announcements`,
                                size: "h1",
                            }}
                            isRouted={true}
                            pageType="boxed"
                            maxWidth={this.props.appState.maxWidth}
                            display={{
                                isDetached: false,
                                isPadded: true,
                                hasBackground: true,
                                hasGutter: true,
                            }}
                            tabContent={this.getTabContent()}
                        />
                    </AnimationContainer>
                </div>

                { showSnackBar && (
                    <SnackBar
                        type={snackBarType}
                        heading={snackBarHeading}
                        message={snackBarMessage}
                        onConfirm={() => this.setState({showSnackBar: false}, snackBarOnConfirm)}
                        onCancel={() => this.setState({showSnackBar: false})}
                    />
                )}
            </>
        );
    }

}

export default withRouter(appState.attachState(AnnouncementList));
