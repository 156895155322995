import React from 'react';
import ReactDOM from 'react-dom';
import naturalCompare from 'string-natural-compare';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
    DataTable,
    DocumentTray,
    DocumentPdfView,
} from '../../../components';
import { PageLayoutContext } from '@dataplan/react-components/dist/components/ui/page_layout';
import { PdfDownloader } from '../../../lib';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";

import styles from './Ep60ListTab.module.scss';
import sharedStyles from './SharedStyles.module.scss';

class Ep60ListTab extends React.Component {

    static propTypes = {
        docs: PropTypes.array,
        dataLoaded: PropTypes.bool,
    }

    static defaultProps = {
        docs: [],
        dataLoaded: false,
    }

    /**
     * Creates an instance of the eP60 list tab
     *
     * @param {object} props eP60 list properties
     */
    constructor (props) {
        super(props);

        this.selectedDoc = null;

        this.state = {
            docs: [],
            showDocTray: false,
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.setDocs();
    }

    /**
     * Invoked before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Set the EP60 documents
     *
     * @return {void}
     */
    setDocs = () => {
        this.setState({
            docs: this.mapDataObject(this.props.docs),
        });
    }

    /**
     * Maps the document data to an object shape suitable for the data table
     *
     * @param {array} docs The document data
     *
     * @return {array} The formatted data object
     */
    mapDataObject = (docs) => {
        const docsMap = docs.map((doc) => {
            return {
                id: doc.id,
                financialYear: {
                    component: this.renderDocLink(doc),
                    sortValue: doc.financial_year,
                },
                status: {
                    text: doc.status,
                },
            };
        });

        return docsMap.sort((a, b) => {
            return naturalCompare(b.financialYear.sortValue, a.financialYear.sortValue);
        });
    }

    /**
     * Creates a clickable element to run function on click
     *
     * @param {object} doc The document detailed in the row
     *
     * @return {ReactElement} The clickable link
     */
    renderDocLink = (doc) => {
        return (
            <button
                className={sharedStyles.buttonLink}
                onClick={() => this.showDocumentPreview(doc)}
                type="button"
            >
                {doc.financial_year}
            </button>
        );
    }

    /**
     * Handles showing the document tray on link click
     *
     * @param {object} doc The document detailed in the row
     *
     * @return {void}
     */
    showDocumentPreview = (doc) => {
        this.selectedDoc = {
            id: doc.id,
            title: `P60 - ${doc.financial_year}`,
        };

        this.setState({
            showDocTray: true,
        });
    }

    /**
     * Called when the doc tray is closed
     *
     * @return {void}
     */
    handleTrayClose = () => {
        this.setState({
            showDocTray: false,
        });
    }

    /**
     * Renders the portal for the doc tray
     *
     * @param {object} context The context from the PageLayoutConsumer
     *
     * @return {?ReactElement} The doc tray portal
     */
    renderDocTray = (context) => {
        const { showDocTray } = this.state;

        if (!showDocTray) {
            return null;
        }

        return ReactDOM.createPortal(
            (
                <div className={sharedStyles.previewTray}>
                    <DocumentTray
                        trayTitle={this.selectedDoc.title}
                        onClose={this.handleTrayClose}
                    >
                        <DocumentPdfView
                            className={sharedStyles.pdfCanvas}
                            key={this.selectedDoc.title}
                            url={`/file/eP60/${this.selectedDoc.id}/data`}
                            title={this.selectedDoc.title}
                            responsive
                        />
                    </DocumentTray>
                </div>
            ), context.pageLayoutTray.current
        );
    }

    /**
     * Creates a data table using the document data
     *
     * @return {ReactElement} The document data table
     */
    renderEP60Table = () => {
        const { dataLoaded } = this.props;
        const { docs } = this.state;

        if (!dataLoaded) {
            return null;
        }

        const colOrder = [
            "financialYear",
            "status",
        ];

        const headings = {
            financialYear: "Tax Year",
            status: "Status",
        };

        const actions = {
            actions: [
                {
                    label: "Download",
                    callback: this.handleDownload,
                },
            ],
        };

        return (
            <div className={styles.eP60Table}>
                <DataTable
                    key="ep60-datatable"
                    colOrder={colOrder}
                    data={docs}
                    headings={headings}
                    actions={actions}
                />
            </div>
        );
    }

    /**
     * Downloads the relevant p60 PDF
     *
     * @param {string} p60Id The p60's ID
     */
    handleDownload = (p60Id) => {
        const { docs } = this.state;
        const p60 = _.find(docs, (item) => (item.id === p60Id));

        new PdfDownloader({
            url: `/file/eP60/${p60Id}/data`,
            responseType: "blob",
            title: `P60 - ${p60.financialYear.sortValue}`,
        }).download();
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { dataLoaded } = this.props;

        if (!dataLoaded) {
            return <LoadingSpinner label="Loading" />;
        }

        return (
            <>
                {this.renderEP60Table()}
                <PageLayoutContext.Consumer>
                    {(context) => this.renderDocTray(context)}
                </PageLayoutContext.Consumer>
            </>
        );
    }

}

export default Ep60ListTab;
