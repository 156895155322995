import React from 'react';
import PropTypes from 'prop-types';

import { ContentCard } from '../../../components';
import Portal from '../../../utils/Portal';

import styles from './PayrunDetailsModal.module.scss';

class PayrunDetailsModal extends React.Component {

    static propTypes = {
        payrunDetails: PropTypes.object.isRequired,
        modalToggle: PropTypes.func.isRequired,
        calendarRef: PropTypes.object,
    }

    static defaultProps = {
        calendarRef: null,
    }

    /**
     * Creates an instance of the modal
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.modalRef = React.createRef();
    }

    /**
     * Called just after the component is added to the DOM
     */
    componentDidMount () {
        document.addEventListener("click", this.handleBackdropClick);
    }

    /**
     * Called just before the component is removed from the DOM
     */
    componentWillUnmount () {
        document.removeEventListener("click", this.handleBackdropClick);
    }

    /**
     * Checks if the click target is inside the targets bounding box
     *
     * @param {event} event The click event
     * @param {object} target The ref of the target element
     *
     * @return {bool} If the click was inside or not
     */
    isClickIn = (event, target) => {
        if (!target.current) {
            return false;
        }

        return target.current.contains(event.target);
    }

    /**
     * Called when there is a click anywhere on the backdrop
     *
     * @param {ClickEvent} event The event
     */
    handleBackdropClick = (event) => {
        const clickInCalendar = (this.props.calendarRef) && this.isClickIn(event, this.props.calendarRef);
        const clickInModal = this.isClickIn(event, this.modalRef);

        if (!clickInModal && !clickInCalendar) {
            this.props.modalToggle();
        }
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { payrunDetails } = this.props;

        return (
            <Portal>
                <div className={styles.cardContainer} ref={this.modalRef}>
                    <ContentCard heading="Payrun details">
                        <dl>
                            <div className={styles.detailRow}>
                                <div className={styles.detailCol}>
                                    <dt>Company</dt>
                                    <dd>{payrunDetails.company}</dd>
                                </div>
                                <div className={styles.detailCol}>
                                    <dt>Payroll</dt>
                                    <dd>{payrunDetails.payroll}</dd>
                                </div>
                            </div>
                            <div className={styles.detailRow}>
                                <div className={styles.detailCol}>
                                    <dt>Paydate</dt>
                                    <dd>{payrunDetails.paydate}</dd>
                                </div>
                                <div className={styles.detailCol}>
                                    <dt>Period</dt>
                                    <dd>{payrunDetails.period}</dd>
                                </div>
                            </div>
                            <div className={styles.detailRow}>
                                <div className={styles.detailCol}>
                                    <dt>Total Payslips</dt>
                                    <dd>{payrunDetails.totalPayslips}</dd>
                                </div>
                                <div className={styles.detailCol}>
                                    <dt>Gross</dt>
                                    <dd>£{payrunDetails.totalGross}</dd>
                                </div>
                                <div className={styles.detailCol}>
                                    <dt>Net</dt>
                                    <dd>£{payrunDetails.totalNet}</dd>
                                </div>
                            </div>
                            <div className={styles.detailRow}>
                                <div className={styles.detailCol}>
                                    <dt>Payslip release</dt>
                                    <dd>
                                        {payrunDetails.releaseDate}
                                        <span className={styles.releaseTime}>({payrunDetails.releaseTime})</span>
                                        {payrunDetails.dateEditButton(true)}
                                    </dd>
                                </div>
                            </div>
                        </dl>
                    </ContentCard>
                </div>
                <div className={styles.backdrop} />
            </Portal>
        );
    }

}

export default PayrunDetailsModal;
