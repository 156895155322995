import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from 'react-router-dom';
import api from 'lib/api';
import { paths } from "../../../lib";
import appState from 'state/App';
import _ from 'lodash';
import classNames from 'classnames';

import {
    DefaultForm,
    MultiFileInput,
    TextAreaRow,
    PrimaryButton,
    SecondaryButton,
} from "@dataplan/react-components/dist/components/forms";
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";

import { defaultAccentColour } from '../../../Colours';

import styles from "../assets/SingleMessage.module.scss";

class SingleMessageReply extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        prevMessage: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.initialState = {
            canReply: null,
            message: {
                hasChanged: false,
                valid: false,
                value: "",
            },
            files: {},
        };

        this.state = this.initialState;
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.checkCanReply();
    }

    /**
     * Checks if this is a message that user can reply to
     *
     * @return {void}
     */
    checkCanReply () {
        const { history, prevMessage } = this.props;
        const canReply = (prevMessage.recipient === "company");

        if (!canReply) {
            history.push(paths.forbidden);
        } else {
            this.setState({
                canReply,
            });
        }
    }

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The onSubmit event
     *
     * @return {void} submits the form if valid
     */
    handleSubmit = (event) => {
        event.preventDefault();

        const { history, prevMessage } = this.props;
        const { employee_id: employeeId, subject } = prevMessage;
        const attachmentsForm = event.target;
        const { message: { value: message } } = this.state;

        api.post("/legacy_message", {
            employee_id: employeeId, // eslint-disable-line camelcase
            message,
            subject: `RE: ${subject}`,
        })
            .then(({ data: { id } }) => {
                const { files } = this.state;

                const attachmentPromise = (!_.isEmpty(files))
                    ? api.post(`/legacy_message/${id}/attachments`, new FormData(attachmentsForm))
                    : null;

                const promises = _.filter([
                    id,
                    attachmentPromise,
                ]);

                return Promise.all(promises);
            })
            .then(([id]) => {
                appState.unblockNavigation();
                const { employees } = this.props.appState;
                const employee = _.find(employees, (item) => item.id === parseInt(employeeId, 10));

                appState.addNotification({
                    text: `Message successfully sent to ${employee.name}`,
                    type: "success",
                    duration: 5,
                }, () => {
                    // eslint-disable-next-line camelcase
                    history.push(generatePath(paths.singleMessage, { message_id: id }));
                    history.go();
                });
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to create message`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Handles change of input values
     *
     * @param {string} type The type of data changed
     * @param {number} value The new value
     *
     * @return {void}
     */
    handleInputChange = (type, value) => {
        appState.blockNavigation();
        let valid = true;

        if (type === "subject" || type === "message") {
            valid = Boolean(value.length);
        }

        this.setState({
            [type]: {
                hasChanged: true,
                valid,
                value,
            },
        });
    }

    /**
     * Called when a change is made to the list of files
     *
     * @param {array} fileList The new file list
     *
     * @return {void}
     */
    handleFileChange = (fileList) => {
        this.setState({
            files: fileList,
        });
    }

    /**
     * Check if the form can be submitted
     *
     * @return {boolean} If the form can be submitted
     */
    checkCanSubmit () {
        const { message } = this.state;

        return Boolean(message.valid);
    }

    /**
     * Renders the message section
     *
     * @return {ReactElement} The message row
     */
    renderMessageSection = () => {
        const { message: { hasChanged, valid, value} } = this.state;

        const classList = classNames(styles.textArea, {
            [styles.error]: (!valid && hasChanged),
        });

        let messageError = (!valid && hasChanged) ? "Message is required" : "";

        return (
            <div className={styles.textAreaRow}>
                <TextAreaRow
                    name="message"
                    label="Message"
                    onChange={(event) => this.handleInputChange("message", event.target.value)}
                    value={value}
                    inputClassName={classList}
                    errorText={messageError}
                />
            </div>
        );
    }

    /**
     * Renders the file input
     *
     * @return {ReactElement} The file input row
     */
    renderFileInput () {
        return (
            <MultiFileInput
                fileListClassName={styles.fileList}
                onChange={this.handleFileChange}
                initialFiles={this.state.files}
                label={null}
                name="attachments[]"
                multiple
            />
        );
    }

    /**
     * Renders the buttons section
     *
     * @return {ReactElement} The buttons section
     */
    renderButtonsSection = () => {
        const { history } = this.props;
        const canSubmit = this.checkCanSubmit();

        return (
            <div className={styles.buttons}>
                <PrimaryButton
                    aria-label={"Reply"}
                    disabled={!canSubmit}
                    text={"Reply"}
                    type="submit"
                />
                <SecondaryButton
                    accent={defaultAccentColour}
                    aria-label={"Back"}
                    className={styles.secondaryButton}
                    onClick={history.goBack}
                    text={"Back"}
                />
            </div>
        );
    }

    render = () => {
        const { canReply } = this.state;

        if (!canReply) {
            return <LoadingSpinner className={styles.loadingSpinner} label="Loading" />;
        }

        return (
            <>
                {this.renderMessageSection()}
                <DefaultForm onSubmit={this.handleSubmit}>
                    {this.renderFileInput()}
                    {this.renderButtonsSection()}
                </DefaultForm>
            </>
        );
    }

}

export default withRouter(appState.attachState(SingleMessageReply));
