import React from "react";

export const editAnnouncementContextDefault = {
    announcement: {
        id: null,
        payrollid: null,
        deptno: null,
        subject: "",
        message: "",
        addedby: {},
        notice_read: [], // eslint-disable-line camelcase
        dateadded: "",
        datestart: "",
        dateend: "",
    },
};

export const EditAnnouncementContext = React.createContext(editAnnouncementContextDefault);
