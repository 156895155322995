import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { paths, setDocumentTitle } from "../lib";

/**
 * Renders the PrivateRoute component
 * Redirects user to /auth/login if API token isn't present
 *
 * @param {object} props The props
 *
 * @return {ReactElement} The intended route/component
 */
const PrivateRoute = (props) => {
    const { component: Component, ...otherProps } = props;
    const { loggedIn } = useSelector((state) => state.userState);
    useEffect(() => setDocumentTitle(props.path), [props.path]);

    return (
        <Route
            {...otherProps}
            render={() => {
                if (!loggedIn) {
                    return <Redirect to={paths.login} />;
                } else {
                    return <Component />;
                }
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    path: PropTypes.string.isRequired,
};

export default withRouter(PrivateRoute);
