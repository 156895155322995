import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import api from 'lib/api';
import { withRouter } from 'react-router-dom';
import appState from 'state/App';
import _ from 'lodash';

import {
    InstructionsNewExistingEmployees,
    EpayslipsHomepageOld,
    PayslipNotifications,
    NewEpayslipTwitter,
    NewSmsGenericNotice,
    GenericTemplate,
} from '.';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

class TemplatesViewEdit extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        match: PropTypes.object.isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.state = {
            templateValues: {},
            templateContent: {},
            templateTags: {},
            dataLoaded: false,
        };
    }

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleApiCalls();
    }

    /**
    * Called when the component is removed from the DOM
    *
    * @return {void}
    */
    componentWillUnmount () {
        document.title = "EMC";
    }

    /**
     * Called when the change form components are removed from the DOM with changes
     *
     * @return {void}
     */
    handleResetState = () => {
        this.setState({
            dataLoaded: false,
        }, this.handleApiCalls);
    }

    /**
     * Handle the API calls for the values, content and template tags before saving to state
     *
     * @return {void}
     */
    handleApiCalls () {
        axios.all([
            this.handleGetTemplateValues(),
            this.handleGetTemplateContent(),
            this.handleGetTemplateTags(),
        ])
            .then(axios.spread((templateValues, templateContent, templateTags) => {
                this.setState({
                    templateValues: templateValues.data,
                    templateContent: templateContent.data,
                    templateTags: templateTags.data,
                    dataLoaded: true,
                }, () => {
                    this.setPageName(templateContent);
                });
            }));
    }

    /**
     * Set page name
     *
     * @param {object} template the template data
     *
     * @return {void}
     */
    setPageName = (template) => {
        const pageName = `${template.data.name}`;
        document.title = pageName;
    }

    /**
     * Get the template values from the API
     *
     * @return {object} The API response
     */
    async handleGetTemplateValues () {
        const { payroll_id: payrollId, template_key: templateKey } = this.props.match.params;

        try {
            return await api.get(`/templates/${payrollId}/${templateKey}`);
        } catch (error) {
            appState.addNotification({
                text: `Failed to load template values`,
                type: "error",
                duration: 5,
            });

            return {};
        }
    }

    /**
     * Get the template content from the API
     *
     * @return {object} The API response
     */
    async handleGetTemplateContent () {
        const { payroll_id: payrollId, template_key: templateKey } = this.props.match.params;

        try {
            return await api.get(`/templates/${payrollId}/${templateKey}/content`);
        } catch (error) {
            appState.addNotification({
                text: `Failed to load template content`,
                type: "error",
                duration: 5,
            });

            return {};
        }
    }

    /**
     * Get the template tags from the API
     *
     * @return {object} The API response
     */
    async handleGetTemplateTags () {
        const { payroll_id: payrollId } = this.props.match.params;

        try {
            return await api.get(`/templates/${payrollId}/list/template_tag_values`);
        } catch (error) {
            appState.addNotification({
                text: `Failed to load template tag values`,
                type: "error",
                duration: 5,
            });

            return {};
        }
    }

    /**
     * Get the template component based on the URL parameter
     *
     * @param {string} key The template type
     *
     * @return {ReactElement} The template component
     */
    getTemplateComponent (key) {
        const componentMap = {
            "instructions_new_employees": InstructionsNewExistingEmployees,
            "instructions_existing_employees": InstructionsNewExistingEmployees,
            "instructions_sms": NewSmsGenericNotice,
            "epayslips_homepage_old": EpayslipsHomepageOld,
            "payslip_notifications": PayslipNotifications,
            "signup_success": GenericTemplate,
            "new_epayslip_emailed": GenericTemplate,
            "new_epayslip_emailed_pdf": GenericTemplate,
            "new_epayslip_sms": NewSmsGenericNotice,
            "new_epayslip_twitter": NewEpayslipTwitter,
            "new_ep60_email_notice_not_signed_up": GenericTemplate,
            "new_ep60_email_notice_signed_up": GenericTemplate,
            "new_ep60_emailed_pdf": GenericTemplate,
            "new_company_doc_email_notice_not_signed_up": GenericTemplate,
            "new_company_doc_email_notice_signed_up": GenericTemplate,
        };

        return componentMap[key];
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { payroll_id: payrollId, template_key: templateKey } = this.props.match.params;
        const matchedPayroll = _.find(this.props.appState.payrolls, { id: _.toNumber(payrollId) });
        const { templateValues, templateContent, templateTags, dataLoaded } = this.state;
        const TemplateComponent = this.getTemplateComponent(templateKey);

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <TemplateComponent
                    payroll={matchedPayroll}
                    templateKey={templateKey}
                    templateValues={templateValues}
                    templateContent={templateContent}
                    templateTags={templateTags}
                    dataLoaded={dataLoaded}
                    onComponentUnmount={this.handleResetState}
                />
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(TemplatesViewEdit));
