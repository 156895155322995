import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import { getPayrollNameForDocs, getPayrollNameForCompany } from 'lib/';
import { PayrollLabel } from '../';

import styles from './PayrollLabelExpander.module.scss';

class PayrollLabelExpander extends React.Component {

    static propTypes = {
        payrolls: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })).isRequired,
        companies: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            address: PropTypes.object,
            telephone_number: PropTypes.string, // eslint-disable-line camelcase
            fax_number: PropTypes.string, // eslint-disable-line camelcase
        })).isRequired,
        companyId: PropTypes.number.isRequired,
        isCompany: PropTypes.bool,
    }

    static defaultProps = {
        isCompany: false,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.labelExpander = React.createRef();

        this.state = {
            showMore: false,
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        let labelExpander = this.labelExpander.current;
        const { width } = labelExpander.getBoundingClientRect();
        labelExpander.style.maxWidth = `${width}px`;
    }

    /**
     * Handles the show more/less button in the component
     *
     * @param {event} event The onClick event from the button
     *
     * @return {void}
     */
    handleShowHideToggle = (event) => {
        event.preventDefault();

        this.setState((prevState) => {
            return {
                showMore: !prevState.showMore,
            };
        });
    };

    /**
     * Renders the tag label for the given payrolls
     *
     * @param {array} payrolls The payrolls for a given document
     * @param {array} companies The companies a user has access to
     * @param {number} companyId The ID of the company the document belong to
     *
     * @return {ReactElement[]} An array of the payroll labels
     */
    renderPayrollLabels (payrolls, companies, companyId) {
        const firstPayroll = _.head(payrolls);
        return payrolls.map((payroll) => {
            const hiddenLabel = this.props.isCompany
                ? payrolls.length > 3
                : payroll.id !== firstPayroll.id;

            const classList = classNames(styles.label, {
                [styles.hidden]: (!this.state.showMore && hiddenLabel),
            });

            const labelFormat = this.props.isCompany
                ? getPayrollNameForCompany(payroll)
                : getPayrollNameForDocs(payroll, companies, companyId);

            return (
                <PayrollLabel
                    key={payroll.id}
                    text={labelFormat}
                    className={classList}
                />
            );
        });
    }

    /**
     * Renders the show more/show less button
     *
     * @param {array} payrolls The payrolls for a given document
     *
     * @return {ReactElement} The show/hide button
     */
    renderShowHideButton (payrolls) {
        const hiddenButtonAmount = this.props.isCompany ? 3 : 1;
        const moreText = `+ ${(payrolls.length - hiddenButtonAmount)} more`;
        const { showMore } = this.state;

        if (this.props.isCompany && payrolls.length <= 3) {
            return null;
        }

        return (
            <button type="button" className={styles.showHideButton} onClick={this.handleShowHideToggle}>
                {(showMore) ? "Close" : moreText}
            </button>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { payrolls, companies, companyId } = this.props;

        return (
            <div ref={this.labelExpander} className={styles.labelExpander}>
                {this.renderPayrollLabels(payrolls, companies, companyId)}
                {this.renderShowHideButton(payrolls)}
            </div>
        );
    }

}

export default PayrollLabelExpander;
