import api from '../api';
import _ from 'lodash';
import appState from '../../state/App';
import noEmployeesSelected from './noEmployeesSelected';
import { StringHelper } from '../StringHelper';

/**
 * Handle the API call to send the signup instructions email
 *
 * @param {number[]} idArray Array containing the employees ID
 * @param {object} notificationText The various outcome text to use in toasts
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 *
 * @return {void}
 */
const handleApiCall = (idArray, notificationText, redrawCallback) => {
    api.put('/employees/signup_instructions', {
        "employee_ids": idArray,
    }).then(({ data }) => {
        if (!_.isEmpty(data.successful)) {
            appState.addNotification({
                text: notificationText(data.successful.length).successful,
                type: "success",
                duration: 5,
            }, redrawCallback);
        }

        if (!_.isEmpty(data.already_sent)) {
            appState.addNotification({
                text: notificationText(data.already_sent.length).alreadySent,
                type: "info",
                duration: 5,
            });
        }

        if (!_.isEmpty(data.failed)) {
            appState.addNotification({
                text: notificationText(data.failed.length).failed,
                type: "error",
                duration: 0,
            });
        }
    }).catch(() => {
        appState.addNotification({
            text: notificationText('all').failed,
            type: "error",
            duration: 0,
        });
    });
};

/**
 * Handles sending single activation emails (quick action)
 *
 * @param {number} employeeId The employees ID
 * @param {?object} employeeData The employees data for a single action to customise response
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 *
 * @return {void}
 */
const handleSendSingleEmail = (employeeId, employeeData, redrawCallback) => {
    const employeeName = new StringHelper(employeeData.employeeName.text).parseName();
    const notificationText = () => {
        return {
            successful: `Welcome email sent to ${employeeName}`,
            alreadySent: `Welcome email already sent to ${employeeName}`,
            failed: `Failed to send welcome email to ${employeeName}`,
        };
    };

    handleApiCall([employeeId], notificationText, redrawCallback);
};

/**
 * Handles sending multiple activation emails (bulk action)
 *
 * @param {number[]} employeeIds Array of employee IDs
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 *
 * @return {void}
 */
const handleSendMultipleEmails = (employeeIds, redrawCallback) => {
    const notificationText = (count) => {
        return {
            successful: `Successfully sent ${count} activation emails to selected employees`,
            alreadySent: `Already sent ${count} activation emails`,
            failed: `Failed to send ${count} activation emails`,
        };
    };

    handleApiCall(employeeIds, notificationText, redrawCallback);
};

/**
 * Prompts the user to confirm sending multiple emails
 *
 * @param {number[]} employeeIds Array of employee IDs
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 */
const confirmMultipleEmails = (employeeIds, redrawCallback, createSnackBarConfirmation) => {
    const selectedCount = employeeIds.length;

    createSnackBarConfirmation({
        type: "warn",
        heading: `${selectedCount} selected employees will be sent the activation email`,
        message: "Do you wish to proceed?",
        onConfirm: () => handleSendMultipleEmails(employeeIds, redrawCallback),
    });
};

/**
 * Handles sending activation emails
 *
 * @param {number[]} employeeIds Array of employee IDs
 * @param {?object} employeeData The employees data for a single action to customise response
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 */
const handleEmailActivation = (
    employeeIds,
    employeeData = null,
    redrawCallback = null,
    createSnackBarConfirmation = null,
) => {
    if (employeeIds.length < 1) {
        noEmployeesSelected();

        return;
    }

    if (employeeIds.length === 1 && employeeData) {
        handleSendSingleEmail(employeeIds[0], employeeData, redrawCallback);
    } else {
        confirmMultipleEmails(employeeIds, redrawCallback, createSnackBarConfirmation);
    }
};

export default handleEmailActivation;
