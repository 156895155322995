import React from 'react';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { PropSchema } from '../';

class EpayslipsHomepageOld extends React.Component {

    static propTypes = PropSchema.getPropTypes;

    static defaultProps = PropSchema.getDefaultProps;

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { templateContent } = this.props;
        const title = templateContent.name || "Template";

        return (
            <PageLayout
                heading={{
                    text: title,
                    size: "h1",
                }}
                pageType="boxed"
                maxWidth={this.props.appState.maxWidth}
                display={{
                    isDetached: false,
                    isPadded: true,
                    hasBackground: true,
                    hasGutter: true,
                }}
            >
                <p>There is nothing to edit</p>
            </PageLayout>
        );
    }

}

export default EpayslipsHomepageOld;
