import appState from '../../state/App';

/**
 * Creates a notification toast if no employees have been selected for bulk actions
 *
 * @return {void}
 */
const noEmployeesSelected = () => {
    appState.addNotification({
        text: "No employees selected",
        type: "info",
        duration: 5,
    });
};

export default noEmployeesSelected;
