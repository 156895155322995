import api from 'lib/api';
import { truncateString } from 'lib/';
import appState from 'state/App';

/**
 * Does the API call and creates the notification toast
 *
 * @param {number} announcementId The ID of the announcement to archive
 * @param {string} announcementTitle The announcement title
 * @param {?Function} redrawCallback The callback from the HOC to redraw once complete
 *
 * @return {void}
 */
const archiveAnnouncement = (announcementId, announcementTitle, redrawCallback) => {
    api.delete(`/announcements/${announcementId}`)
        .then(() => {
            appState.addNotification({
                text: `Announcement (${announcementTitle}) has been archived`,
                type: "success",
                duration: 5,
            }, redrawCallback);
        }).catch(() => {
            appState.addNotification({
                text: `Failed to archive announcement (${announcementTitle})`,
                type: "error",
                duration: 0,
            });
        });
};

/**
 * Creates the snackbar to confirm the action
 *
 * @param {number} announcementId The ID of the announcement to archive
 * @param {?Object} announcementData The row data in the data table for the announcement
 * @param {?Function} redrawCallback The callback from the HOC to redraw once complete
 * @param {?Function} createSnackBarConfirmation Creates a snackbar to confirm the action
 *
 * @return {void}
 */
const confirmArchiveAnnouncement = (announcementId, announcementData, redrawCallback, createSnackBarConfirmation) => {
    const announcementTitle = truncateString(announcementData.subject.text, 35);

    createSnackBarConfirmation({
        type: "warn",
        heading: `This announcement (${announcementTitle}) will be archived.`,
        message: "Archiving the announcement will mean employees can no "
            + "longer see it. Are you sure you want to archive this?",
        onConfirm: () => archiveAnnouncement(announcementId, announcementTitle, redrawCallback),
    });
};

/**
 * Called when the archive announcement action is clicked
 *
 * @param {number} announcementId The ID of the announcement to archive
 * @param {?Object} announcementData The row data in the data table for the announcement
 * @param {?Function} redrawCallback The callback from the HOC to redraw once complete
 * @param {?Function} createSnackBarConfirmation Creates a snackbar to confirm the action
 *
 * @return {void}
 */
const handleArchiveAnnouncement = (
    announcementId,
    announcementData = null,
    redrawCallback = null,
    createSnackBarConfirmation = null,
) => {
    if (!announcementId) {
        return;
    }

    confirmArchiveAnnouncement(announcementId, announcementData, redrawCallback, createSnackBarConfirmation);
};

export default handleArchiveAnnouncement;
