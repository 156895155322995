import React from 'react';

export default class Attachment extends React.Component {

    /**
     * Renders the icon component
     *
     * @return {ReactElement} The icon element
     */
    render () {
        /* eslint-disable max-len */
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...this.props}>
                <path d="M331.874 163.179L172.879 323.167c-5.279 5.273-13.815 5.273-19.086 0-5.269-5.261-5.269-13.893 0-19.161l186.35-187.528c7.722-7.812 20.258-7.812 27.976 0l41.977 42.248c7.718 7.722 7.718 20.352 0 28.166L223.754 374.418c-33.343 33.521-87.403 33.521-120.836 0-33.351-33.621-33.351-88.034 0-121.657L261.913 92.768c8.812-8.812 8.812-23.168 0-31.983-8.719-8.815-22.986-8.815-31.797 0L71.121 220.779c-50.885 51.25-50.885 134.377 0 185.616 50.971 51.245 133.558 51.245 184.431 0l200.333-201.604c17.541-17.717 17.541-46.336 0-64.05l-69.957-70.323c-17.533-17.72-46.069-17.72-63.599 0L121.996 272.029c-22.803 22.891-22.803 60.137 0 83.127 22.891 22.986 59.872 22.986 82.683 0L363.675 195.16c8.812-8.814 8.812-23.169 0-31.98a22.433 22.433 0 0 0-31.801-.001z" />
            </svg>
        );
        /* eslint-enable */
    }

}
