import React from 'react';
import PropTypes from 'prop-types';
import appState from 'state/App';
import { withRouter } from "react-router-dom";

import { AddButton } from "@dataplan/react-components/dist/components/forms";
import { defaultAccentColour } from '../../Colours';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { Drawer } from '@dataplan/react-components/dist/components/ui/drawer';
import { Icon } from "../../components";
import CompanyDocumentsList from "./CompanyDocumentsList";
import { CompanyDocumentAdd } from "./";

import styles from "./assets/CompanyDocumentsShared.module.scss";

class CompanyDocumentsPage extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.nativeDrawer = React.createRef();
    }

    /**
     * Helper method to get the drawer top action
     *
     * @return {Object} The drawer top action
     */
    getTopAction = () => {
        return {
            icon: <Icon icon="Close" aria-label="Close" className={styles.icon} />,
            action: this.toggleDrawer,
        };
    }

    /**
     * Helper method to get the drawer component config
     *
     * @return {ReactElement} The drawer component
     */
    getNewUploadPortal () {
        const header = {
            size: 'h2',
            text: 'Upload a document',
        };

        return (
            <Drawer
                targetNode={document.body}
                ref={this.nativeDrawer}
                header={header}
                loadingLabel={"Uploading..."}
                onClickOutside={this.toggleDrawer}
                topAction={this.getTopAction()}
            >
                <CompanyDocumentAdd closeDrawer={this.toggleDrawer} />
            </Drawer>
        );
    }

    /**
     * Helper method to toggle the drawer
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = () => {
        const { nativeDrawer } = this;
        const drawer = nativeDrawer.current;

        if (drawer) {
            nativeDrawer.current.setState({
                visible: !drawer.state.visible,
            });
        }
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const headerAction = {
            drawer: this.getNewUploadPortal(),
            button: (
                <AddButton colour={defaultAccentColour} aria-label="Upload a document" onClick={this.toggleDrawer} />
            ),
        };

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    heading={{
                        text: "Documents",
                        size: "h1",
                    }}
                    headerAction={headerAction}
                    pageType="boxed"
                    maxWidth={this.props.appState.maxWidth}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                >
                    <CompanyDocumentsList />
                </PageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(CompanyDocumentsPage));

