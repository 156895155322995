import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import InputMoment from "input-moment";
import api from "../../../lib/api";
import windowScroll from '../../../lib/domWindowScroll';

import PayrunDetailsModal from "./PayrunDetailsModal";
import SecondaryButton from "../../../components/forms/controls/SecondaryButton";
import Portal from "../../../utils/Portal";

import styles from "./PayrunCard.module.scss";
import sharedStyles from "./SharedStyles.module.scss";

export default class PayrunCard extends React.Component {

    static propTypes = {
        payroll: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        company: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        showCompany: PropTypes.bool.isRequired,
        payrun: PropTypes.shape({
            period: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }).isRequired,
            paydates: PropTypes.arrayOf(PropTypes.shape({
                paydate: PropTypes.string.isRequired,
            })).isRequired,
        }).isRequired,
    }

    /**
     * Creates an instance of the card
     *
     * @param {object} props Input data
     */
    constructor (props) {
        super(props);

        const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";
        const notificationDate = this.getKeyPaydate().notification_time;

        this.state = {
            editing: false,
            paydateNotificationDate: moment(notificationDate, dateFormat),
            updatedNotificationDate: moment(notificationDate, dateFormat),
            showDetailsModal: false,
        };

        this.calendarRef = React.createRef();
        this.editButtonRef = React.createRef();
    }

    /**
     * Gets the key paydate to use for the release date and main paydate
     *
     * This is the paydate with the most payslips.
     *
     * @return {object} The paydate information
     */
    getKeyPaydate () {
        return _.last(_.sortBy(this.props.payrun.paydates, "total"));
    }

    /**
     * Called when the edit button is clicked for a release data
     *
     * @param {bool} inModal If the calendar is activated inside the details modal
     */
    handleEditClick = (inModal) => {
        this.setState({
            editing: true,
        }, () => {
            this.positionCalendar(inModal);
        });
    }

    /**
     * Called when there is a click anywhere on the document
     *
     * @param {ClickEvent} event The event
     */
    handleDocumentClick = (event) => {
        const clickInCalendar = this.calendarRef.current && this.calendarRef.current.contains(event.target);
        const clickInButton = this.editButtonRef.current && this.editButtonRef.current.contains(event.target);

        if (!clickInCalendar && !clickInButton) {
            this.setState({ editing: false });
        }
    }

    /**
     * Called just after the component is added to the DOM
     */
    componentDidMount () {
        document.addEventListener("click", this.handleDocumentClick);
    }

    /**
     * Called just before the component is removed from the DOM
     */
    componentWillUnmount () {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    /**
     * Sets the position of the calendar input so it appears next to the edit button
     *
     * @param {bool} inModal If the calendar is activated inside the details modal
     */
    positionCalendar = (inModal) => {
        const buttonBbox = this.editButtonRef.current.getBoundingClientRect();
        const calendarBbox = this.calendarRef.current.getBoundingClientRect();

        const calTop = (inModal)
            ? `${buttonBbox.bottom - calendarBbox.height}px`
            : `${buttonBbox.top + windowScroll.getY()}px`;
        const calLeft = (inModal)
            ? `${buttonBbox.left}px`
            : `${buttonBbox.right + windowScroll.getX() - calendarBbox.width}px`;

        this.calendarRef.current.style.position = (inModal) ? 'fixed' : 'absolute';
        this.calendarRef.current.style.top = calTop;
        this.calendarRef.current.style.left = calLeft;
    }

    /**
     * Called when the date is updated
     *
     * @param {object} newDate A moment object of the new date
     */
    handleDateChange = (newDate) => {
        this.setState({
            updatedNotificationDate: newDate,
        });
    }

    /**
     * Called when the date is saved
     */
    handleDateSave = () => {
        const payrollId = this.props.payroll.id;
        const periodId = this.props.payrun.period.id;
        const date = this.state.updatedNotificationDate.format("YYYY-MM-DD HH:mm:ss");

        api.patch(`/payroll/${payrollId}/payrun/${periodId}`, {
            notification_time: date, // eslint-disable-line camelcase
        }).then((response) => {
            this.setState((prevState) => {
                return {
                    editing: false,
                    paydateNotificationDate: moment(prevState.updatedNotificationDate),
                };
            });
        }).catch((error) => {
            const messages = error.response.data.messages.notification_time
                          || ["Failed to update notification time"];

            // Don't know how to show these yet see EMC-78
            alert(messages.join("\n")); // eslint-disable-line no-alert
        });
    }

    /**
     * Called when the detals button is clicked
     */
    handleDetailsClick = () => {
        this.setState((prevState) => {
            return {
                showDetailsModal: !prevState.showDetailsModal,
            };
        });
    }

    /**
     * Creates an object of the payrun details to be used in multiple places
     *
     * @param {object} payrun The payruns the card is for
     *
     * @return {object} The formatted object with relevant information
     */
    getPayrunDetails = (payrun) => {
        const { company, payroll } = this.props;
        const keyPaydate = this.getKeyPaydate();

        const totalGross = _.sum(_.map(payrun.paydates, "total_gross"));
        const totalNet = _.sum(_.map(payrun.paydates, "total_net"));

        return {
            paydate: moment(keyPaydate.paydate, "YYYY-MM-DD").format("DD-MM-YY"),
            period: payrun.period.period_number,
            company: company.name,
            payroll: payroll.name,
            totalPayslips: _.sum(_.map(payrun.paydates, "total")),
            totalGross: numeral(totalGross).format("0,0.00"),
            totalNet: numeral(totalNet).format("0,0.00"),
            releaseDate: this.state.paydateNotificationDate.format("DD-MM-YYYY"),
            releaseTime: this.state.paydateNotificationDate.format("h:mma"),
            dateEditButton: (inModal = false) => (
                <SecondaryButton
                    onClick={() => this.handleEditClick(inModal)}
                    ref={this.editButtonRef}
                >
                    Edit
                </SecondaryButton>
            ),
            detailsButton: (
                <button
                    type="button"
                    onClick={this.handleDetailsClick}
                    className={styles.actionButton}
                >
                    Details
                </button>
            ),
        };
    }

    /**
     * Renders the calendar input if the date is being edited
     *
     * @return {ReactElement} The element or null if not in edit mode
     */
    renderCalendarControl () {
        if (!this.state.editing) {
            return null;
        }

        const displayDate = (this.state.updatedNotificationDate.isValid())
            ? this.state.updatedNotificationDate
            : moment();

        return (
            <Portal>
                <div
                    className={styles.calendarWrapper}
                    ref={this.calendarRef}
                >
                    <InputMoment
                        moment={displayDate}
                        onChange={this.handleDateChange}
                        onSave={this.handleDateSave}
                    />
                </div>
            </Portal>
        );
    }

    /**
     * Renders the details modal with more details when viewing on smaller devices
     *
     * @param {object} payrunDetails The details of the payrun to display in the modal
     *
     * @return {?ReactElement} The modal component
     */
    renderDetailsModal = (payrunDetails) => {
        if (!this.state.showDetailsModal) {
            return null;
        }

        return (
            <PayrunDetailsModal
                payrunDetails={payrunDetails}
                modalToggle={this.handleDetailsClick}
                calendarRef={this.calendarRef}
            />
        );
    }

    /**
     * Renders the payrun card
     *
     * @return {ReactElement} The card component
     */
    render () {
        const { payrun, showCompany } = this.props;

        const payrunDetails = this.getPayrunDetails(payrun);
        const alignRightClasses = classNames(sharedStyles.hideLarge, styles.alignRight);
        const smallRight = classNames(styles.smallColumn, alignRightClasses);
        const mediumRight = classNames(styles.mediumColumn, alignRightClasses);
        const releaseClasses = classNames(styles.releaseColumn, sharedStyles.hideLarge);
        const classList = classNames([
            styles.largeColumn,
            sharedStyles.hideMedium,
        ]);

        return (
            <>
                <tr className={styles.row}>
                    <td className={styles.paydate}>
                        {payrunDetails.paydate}
                    </td>
                    <td className={styles.smallColumn}>{payrunDetails.period}</td>
                    {showCompany && <td className={classList}>{payrunDetails.company}</td>}
                    <td className={styles.largeColumn}>
                        {payrunDetails.payroll}
                        <span className={styles.appendedCompanyName}>
                            {showCompany && `(${payrunDetails.company})`}
                        </span>
                    </td>
                    <td className={styles.smallColumn}>
                        {payrunDetails.totalPayslips}
                    </td>
                    <td className={smallRight}>£{payrunDetails.totalGross}</td>
                    <td className={mediumRight}>£{payrunDetails.totalNet}</td>
                    <td className={releaseClasses}>
                        <span className={styles.releaseDate}>
                            {payrunDetails.releaseDate}
                        </span>
                        <span className={styles.releaseTime}>
                            {payrunDetails.releaseTime}
                        </span>
                        {payrunDetails.dateEditButton()}
                    </td>
                    <td className={sharedStyles.detailsCol}>
                        {payrunDetails.detailsButton}
                    </td>
                </tr>
                {this.renderCalendarControl()}
                {this.renderDetailsModal(payrunDetails)}
            </>
        );
    }

}
