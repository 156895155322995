import React from 'react';
import payslipPropTypes, { payslipsDefaultProps } from '../../../lib/props-schema/payslipSchema';
import _ from 'lodash';

import styles from './PayslipDetailsTab.module.scss';

export default class PayslipDetailsTab extends React.Component {

    static propTypes = {
        payslipDetails: payslipPropTypes,
    }

    static defaultProps = {
        payslipDetails: payslipsDefaultProps,
    }

    /**
     * Returns the table rows based on extras from the payslip
     *
     * @param {object} extras The extras from the payslip
     *
     * @return {array} An array of table rows
     */
    getExtrasRows = (extras) => {
        return _.map(extras, (value, key) => {
            return (
                <React.Fragment key={key}>
                    <dt className={styles.extraName}>{key}</dt>
                    <dd className={styles.extraValue}>{value}</dd>
                </React.Fragment>
            );
        });
    }

    /**
     * Renders the payslip details tab
     *
     * @return {ReactElement} The payslip details tabs
     */
    render () {
        const { payslipDetails } = this.props;

        return (
            <dl className={styles.payslipExtras}>
                {this.getExtrasRows(payslipDetails.extras)}
            </dl>
        );
    }

}
