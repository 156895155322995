import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';

import Alert from '../../../components/Alert';

export default class AlertsSection extends React.Component {

    /**
     * Renders the styleguide section
     *
     * @return {ReactElement} The styleguide section
     */
    render () {
        return (
            <CardRow>
                <CardRowContainer gridWidth={5}>
                    <ContentCard heading="Alerts">
                        <Alert type="warn" message="This is a warning message" />
                        <Alert type="success" message="This is a success message" />
                        <Alert type="error" message="This is a error message" />
                        <Alert type="info" message="This is a info message" />
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
