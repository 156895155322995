import React from 'react';
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import appState from '../../state/App';

import SnackBar from '../../components/toasts/SnackBar';

export default class ToastDemo extends React.Component {

    /**
     * Create an instance of the toast test
     *
     * @param {object} props The test properties
     */
    constructor (props) {
        super(props);

        this.state = {
            showToast: false,
        };
    }

    /**
     * Run the test on button click
     */
    runTest = () => {
        this.setState({
            showToast: true,
        });
    }

    /**
     * Handle cancel
     */
    onCancel = () => {
        this.setState({
            showToast: false,
        });
    }

    /**
     * Handle confirmation
     */
    onConfirm = () => {
        this.setState({
            showToast: false,
        }, () => {
            let notification = {
                text: "The test is complete",
                type: "success",
                duration: 0,
            };

            appState.addNotification(notification);
        });
    }

    /**
     * Render the test
     *
     * @return {ReactElement} The test component
     */
    render () {
        return (
            <PageLayout
                pageType="boxed"
                display={{
                    isDetached: false,
                    isPadded: true,
                    hasBackground: true,
                    hasGutter: true,
                }}
                heading={{
                    text: "Toast Demo",
                    size: "h1",
                }}
            >
                <p>This is a demo of the confirmation and notification toasts.</p>
                <p>
                    <button
                        type="submit"
                        onClick={this.runTest}
                    >
                        Click me!
                    </button>
                </p>

                {this.state.showToast && (
                    <SnackBar
                        type="warn"
                        message="Are you sure you want to do that?"
                        onConfirm={this.onConfirm}
                        onCancel={this.onCancel}
                    />
                )}
            </PageLayout>
        );
    }

}
