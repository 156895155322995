import React, {Component} from 'react';
import PropTypes from "prop-types";
import { generatePath, Link, withRouter } from 'react-router-dom';
import api from "../../../lib/api";

import {
    TextInputRow,
    SelectInputRow,
    PrimaryButton,
    SecondaryButton,
} from '@dataplan/react-components/dist/components/forms';
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from '../../../lib/contrastingColorList';
import appState from 'state/App';
import { paths } from "../../../lib";

import styles from '../assets/Edit.module.scss';

class EditPayrollPage extends Component {

    static propTypes = {
        payroll: PropTypes.object,
        employeeNumber: PropTypes.number,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    static defaultProps = {
        payroll: null,
        employeeNumber: null,
    }

    /**
     * Creates an instance of the EditPayrollPage
     *
     * @param {object} props The props
     */
    constructor (props) {
        super(props);

        this.state = {
            payrollName: this.props.payroll.name,
            selectValue: this.props.payroll.frequency,
        };
    }

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handleInput = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }

    /**
     * Checks for a change in the forms to activate submit button
     *
     * @return {boolean} The submit check
     */
    checkCanSubmit = () => {
        const { payrollName, selectValue } = this.state;
        const { payroll } = this.props;

        const nameHasChanged = (payroll.name !== payrollName);
        const frequencyHasChanged = (payroll.frequency !== selectValue);

        return (nameHasChanged || frequencyHasChanged);
    }

    /**
     * Handles submission
     */
    handleSubmission = () => {
        const { payrollName, selectValue } = this.state;
        const { payroll } = this.props;

        api.patch(`/payroll/${payroll.id}`, {name: payrollName, frequency: selectValue})
            .then(() => {
                appState.addNotification({
                    text: `Payroll successfully edited`,
                    type: "success",
                    duration: 5,
                }, () => {
                    this.props.history.go();
                });
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to edit payroll`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Render the form buttons
     *
     * @return {ReactElement} The form buttons
     */
    renderButtons () {
        const { match } = this.props;
        const canSubmit = this.checkCanSubmit();
        const buttonStyles = {
            primary: {
                backgroundColor: "#652D90",
                color: getMostContrastingColour("#652D90", contrastingColourList),
            },
        };

        return (
            <div className={styles.buttonContainer}>
                <PrimaryButton
                    type="submit"
                    style={(canSubmit) ? buttonStyles.primary : null}
                    disabled={!canSubmit}
                    onClick={this.handleSubmission}
                    text="Save"
                />
                <Link to={generatePath(paths.singlePayrollAbout, match.params)} >
                    <SecondaryButton
                        className={styles.secondaryButton}
                        text="Cancel"
                    />
                </Link>
            </div>
        );
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        const { match, payroll, employeeNumber } = this.props;
        const { selectValue } = this.state;

        return (
            <>
                <div className={styles.bodyContainer}>
                    <h2>About</h2>
                    <Link to={generatePath(paths.singlePayrollAbout, match.params)} >
                        <button
                            type="submit"
                            className={styles.editButton}
                        >
                            {'Cancel'}
                        </button>
                    </Link>
                </div>
                <div className={styles.wrapper}>
                    <TextInputRow
                        name="payrollName"
                        label={"Payroll Name"}
                        type="text"
                        onChange={(event) => this.handleInput(event)}
                        value={this.state.payrollName}
                    />
                    <SelectInputRow
                        key={payroll.frequency}
                        name="selectValue"
                        label={"Frequency"}
                        placeholder={"Please select"}
                        onChange={(event) => this.handleInput(event)}
                        value={selectValue}
                        inputClassName={styles.inputBox}
                        rowClassName={styles.selectInput}
                    >
                        <option value={"Weekly"} key={"Weekly"}>{"Weekly"}</option>
                        <option value={"Fortnightly"} key={"Fortnightly"}>{"Fortnightly"}</option>
                        <option value={"Monthly"} key={"Monthly"}>{"Monthly"}</option>
                    </SelectInputRow>
                    <div className={styles.container}>
                        <div className={styles.title}>
                            Employees
                        </div>
                        <div className={styles.content}>
                            {employeeNumber}
                        </div>
                    </div>
                    {this.renderButtons()}
                </div>
            </>
        );
    }

}

export default withRouter(appState.attachState(EditPayrollPage));
