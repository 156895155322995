import _ from 'lodash';

/**
 * Gets the value of a CSS property for an element handling browser prefixes
 *
 * @param {Element} element The HTML element
 * @param {string} property The name of the property to get
 *
 * @return {string} The style value
 */
const getCssProperty = (element, property) => {
    const style = window.getComputedStyle(element);
    const prefixProperty = _.upperFirst(property);

    return style[property]
         || style[`Moz${prefixProperty}`]
         || style[`Webkit${prefixProperty}`]
         || style[`ms${prefixProperty}`];
};

/**
 * Gets the duration of a css value in miliseconds
 *
 * @param {string} duration The duration to normalise
 *
 * @return {integer} The time
 */
const getDurationValue = (duration) => {
    let value = parseFloat(duration, 10);
    const unit = duration.replace(/[0-9.]+/, "");

    switch (unit) {
    case 's':
        value *= 1000;
        break;
    default:
    }

    return value;
};

/**
 * Gets the total duration for a list of CSS properties
 *
 * @param {Element} element The element
 * @param {string[]} propertyList A list of property values
 *
 * @return {integer} The total value in miliseconds
 */
const sumDurationProperties = (element, propertyList) => {
    const durationValues = _.map(propertyList, (property) => {
        return getDurationValue(getCssProperty(element, property));
    });

    return _.sum(durationValues);
};

/**
 * Gets the total transition time for an element
 *
 * @param {Element} element The native HTML element
 *
 * @return {float} The time in miliseconds
 */
export const getTransitionTime = (element) => {
    return sumDurationProperties(element, [
        "transitionDelay",
        "transitionDuration",
    ]);
};

/**
 * Gets the total animation time for an element
 *
 * @param {Element} element The native HTML element
 *
 * @return {float} The time in miliseconds
 */
export const getAnimationTime = (element) => {
    return sumDurationProperties(element, [
        "animationDelay",
        "animationDuration",
    ]);
};
