import React from 'react';
import _ from 'lodash';
import appState from 'state/App';

import { PropSchema, TemplateLayout, TemplateTitle, TemplateTextInput, TemplateTextarea } from '../';

class GenericTemplate extends React.Component {

    static propTypes = PropSchema.getPropTypes;

    static defaultProps = PropSchema.getDefaultProps;

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            valuesLoaded: false,
            canSubmit: {
                content: false,
                settings: false,
            },
        };
    }

    /**
     * Called when the component props have changed
     *
     * @param {object} prevProps The components previous props
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.setInitialValues();
        }
    }

    /**
     * Set the initial values of the inputs from the API into the component state
     *
     * @return {void} Modifies state
     */
    setInitialValues () {
        const { templateValues } = this.props;

        if (_.isEmpty(templateValues)) {
            return;
        }

        this.setState({
            from_name: { // eslint-disable-line camelcase
                value: templateValues.from_name,
                hasChanged: false,
            },
            from_email: { // eslint-disable-line camelcase
                value: templateValues.from_email,
                hasChanged: false,
            },
            subject: {
                value: templateValues.subject,
                hasChanged: false,
            },
            message: {
                value: templateValues.message,
                hasChanged: false,
            },
            valuesLoaded: true,
            canSubmit: {
                content: false,
                settings: false,
            },
        });
    }

    /**
     * Called when an input value is changes
     *
     * @param {event} event The onChange event
     * @param {string} key The name of the input
     * @param {string} section If the input is in the content or settings tab
     *
     * @return {void} Modifies state
     */
    handleInputChange = (event, key, section) => {
        event.preventDefault();

        const value = event.target.value;

        this.setState((prevState) => {
            return {
                [key]: {
                    value,
                    hasChanged: true,
                },
                canSubmit: {
                    ...prevState.canSubmit,
                    [section]: true,
                },
            };
        }, appState.blockNavigation());
    }

    /**
     * Get the template elments to render
     *
     * @param {boolean} isPreview If the components are being displayed in the preview tab
     *
     * @return {ReactElement[]} The components to render
     */
    getTemplateContent (isPreview) {
        const { templateContent, templateTags } = this.props;
        const { valuesLoaded, subject, message } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        return [
            <TemplateTitle
                key={templateContent.desc}
                value={templateContent.desc}
                isPreview={isPreview}
                size="h3"
            />,
            <TemplateTextInput
                key="subject"
                name="subject"
                label="Subject"
                value={subject.value}
                onChange={(event) => this.handleInputChange(event, "subject", "content")}
                isPreview={isPreview}
                showInPreview
                subject
            />,
            <TemplateTextarea
                key="message"
                name="message"
                label="Message"
                value={message.value}
                onChange={(event) => this.handleInputChange(event, "message", "content")}
                isPreview={isPreview}
                showInPreview
                templateTagList={templateContent.template_tags}
                templateTagValues={templateTags}
            />,
        ];
    }

    /**
     * Get the template elments to render - Setting tab
     *
     * @return {ReactElement[]} The components to render
     */
    getTemplateSettings () {
        const { valuesLoaded, from_name: fromName, from_email: fromEmail } = this.state;

        if (!valuesLoaded) {
            return [];
        }

        return [
            <TemplateTextInput
                key="from_name"
                name="from_name"
                label="From name"
                value={fromName.value}
                onChange={(event) => this.handleInputChange(event, "from_name", "settings")}
                isPreview={false}
            />,
            <TemplateTextInput
                key="from_email"
                name="from_email"
                label="Reply to"
                value={fromEmail.value}
                onChange={(event) => this.handleInputChange(event, "from_email", "settings")}
                isPreview={false}
            />,
        ];
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { onComponentUnmount, dataLoaded } = this.props;
        const { canSubmit } = this.state;

        const preview = this.getTemplateContent(true);
        const content = this.getTemplateContent(false);
        const settings = this.getTemplateSettings();
        const rawValues = _.omit(this.state, ["valuesLoaded", "canSubmit"]);

        return (
            <TemplateLayout
                dataLoaded={dataLoaded}
                title={this.props.templateContent.name}
                preview={preview}
                content={content}
                settings={settings}
                rawValues={rawValues}
                canSubmit={canSubmit}
                onComponentUnmount={onComponentUnmount}
            />
        );
    }

}

export default GenericTemplate;
