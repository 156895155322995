import React from 'react';
import PropTypes from 'prop-types';

import './Paragraph.scss';

export default class Paragraph extends React.Component {

    static propTypes = {
        children: PropTypes.string.isRequired,
    }

    /**
     * Renders the paragraph
     *
     * @return {ReactElement} The paragraph element
     */
    render () {
        return (
            <p className="paragraph">
                {this.props.children}
            </p>
        );
    }

}
