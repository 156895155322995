import React from 'react';
import appState from '../../../state/App';
import createToasts from '../../../components/toasts/createToasts';
import { checkValidity } from '../../../lib/validationHelpers';
import { checkPasswordStrength } from '../../../lib/passwordStrengthHelpers';
import api from '../../../lib/api';
import classNames from 'classnames';

import DefaultForm from '../../../components/forms/DefaultForm';
import { TextInputRow, InputRow } from '@dataplan/react-components/dist/components/forms';
import PrimaryButton from '../../../components/forms/controls/PrimaryButton';
import InlineValidationErrors from '../../../components/validation/InlineValidationErrors';
import ValidationCollection from '../../../components/validation/ValidationCollection';
import ValidationIcon from '../../../components/validation/ValidationIcon';

import styles from './ChangePassword.module.scss';
import sharedStyles from '../SharedStyles.module.scss';

class ChangePassword extends React.Component {

    /**
     * Creates an instance of the change password page
     *
     * @param {object} props Change passwod page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            passwords: {
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
            },
            hasChanged: false,
            error: {
                isValid: true,
                messages: [],
                invalidInputs: [],
            },
            passwordStrength: {
                isStrong: false,
                isLong: false,
                lowercase: false,
                uppercase: false,
                number: false,
                symbol: false,
            },
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.setInitialState();
        this.handleStrengthTest();
    }

    /**
     * Sets the component default state
     *
     * @return {void}
     */
    setInitialState = () => {
        this.setState({
            passwords: {
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
            },
            hasChanged: false,
        });
    }

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     * @param {string} field Which password field has changed
     *
     * @return {void}
     */
    handlePasswordInput = (event, field) => {
        let password = event.target.value;

        this.setState((prevState) => {
            return {
                passwords: {
                    ...prevState.passwords,
                    [field]: password,
                },
                hasChanged: Boolean(password),
            };
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    }

    /**
     * Checks the password field has been completed and the new password match
     *
     * @return {void}
     */
    handleValidation = () => {
        const { passwords } = this.state;
        let passwordEntered = checkValidity(passwords.oldPassword, 'passwordEntered');
        let validateNewPassword = checkValidity([passwords.newPassword, passwords.confirmNewPassword], 'newPassword');
        let errors = [];

        [passwordEntered, validateNewPassword].forEach((validation) => {
            if (!validation.isValid) {
                errors.push(validation.message);
            }
        });

        this.setState({
            error: {
                isValid: (passwordEntered.isValid && validateNewPassword.isValid),
                messages: errors,
                invalidInputs: [
                    !passwordEntered.isValid ? 'oldPassword' : null,
                    !validateNewPassword.isValid ? 'newPassword' : null,
                    !validateNewPassword.isValid ? 'confirmNewPassword' : null,
                ].filter((input) => input !== null),
            },
        }, () => {
            this.handleStrengthTest();
        });
    }

    /**
     * Checks the new passwords strength
     *
     * @return {void}
     */
    handleStrengthTest = () => {
        const { passwords } = this.state;
        let passwordStrength = checkPasswordStrength(passwords.newPassword);

        this.setState({
            passwordStrength,
        });
    }

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        event.preventDefault();

        const { passwords, hasChanged, error, passwordStrength } = this.state;

        if (!hasChanged || !error.isValid || !passwordStrength.isStrong) {
            return;
        }

        appState.unblockNavigation();

        api.patch('/security/password', {
            "old_password": passwords.oldPassword,
            "password": passwords.newPassword,
            "password_confirm": passwords.confirmNewPassword,
        }).then(() => {
            appState.addNotification({
                text: "Password successfully updated.",
                type: "success",
                duration: 5,
            });
            this.setInitialState();
        }).catch((apiError) => {
            createToasts(apiError.response.data, 'error');
        });
    }

    /**
     * Renders a password input field
     *
     * @param {string} field Which password field to render
     * @param {string} display The textual label to display
     *
     * @return {ReactElement} The password input component
     */
    renderPasswordField = (field, display) => {
        const { invalidInputs } = this.state.error;
        const classList = classNames([
            styles[field],
            (invalidInputs.indexOf(field) >= 0) ? sharedStyles.error : '',
        ]);

        return (
            <div className={styles.passwordField}>
                <TextInputRow
                    name={field}
                    label={display}
                    type="password"
                    onChange={(event) => this.handlePasswordInput(event, field)}
                    value={this.state.passwords[field]}
                    inputClassName={classList}
                />
            </div>
        );
    }

    /**
     * Renders the visual display for the password strength checker
     *
     * @return {ReactElement} The password strength checker visual
     */
    renderPasswordStrengthCheck = () => {
        const { passwordStrength } = this.state;

        return (
            <ValidationCollection
                className={styles.passwordStrengthCheck}
                label="Password must be 8 or more characters, and contain all of the following:"
            >
                <ValidationIcon label="8 or more characters long" isValid={passwordStrength.isLong} />
                <ValidationIcon label="Lowercase letter" isValid={passwordStrength.lowercase} />
                <ValidationIcon label="Uppercase letter" isValid={passwordStrength.uppercase} />
                <ValidationIcon label="Number" isValid={passwordStrength.number} />
                <ValidationIcon label="Symbol" isValid={passwordStrength.symbol} />
            </ValidationCollection>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { hasChanged, error, passwordStrength } = this.state;

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                <InlineValidationErrors errors={error} />
                <InputRow>
                    {this.renderPasswordField("oldPassword", "Old password")}
                </InputRow>
                <InputRow className={sharedStyles.flexRow}>
                    {this.renderPasswordField("newPassword", "New password")}
                    {this.renderPasswordField("confirmNewPassword", "Confirm new password")}
                </InputRow>
                {this.renderPasswordStrengthCheck()}
                <PrimaryButton
                    type="submit"
                    disabled={!hasChanged || !error.isValid || !passwordStrength.isStrong}
                    className={sharedStyles.saveButton}
                >
                    Save
                </PrimaryButton>
            </DefaultForm>
        );
    }

}

export default ChangePassword;
