import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from '../lib/contrastingColorList';
import userState from '../state/User';
import { UserAvatar } from "@dataplan/react-components/dist/components/ui/user_avatar";
import { defaultAccentColour } from '../Colours';

import styles from './ProfileButton.module.scss';

class ProfileButton extends React.Component {

    static propTypes = {
        onClick: PropTypes.func,
        forwardedRef: PropTypes.any,
    }

    static defaultProps = {
        onClick: _.noop,
        forwardedRef: null,
    }

    /**
     * Renders the hamburger nenu button
     *
     * @return {ReactElement} The menu button
     */
    render () {
        const WrappedUserAvatar = userState.attachState((props) => (
            <UserAvatar
                forename={props.userState.forename ? props.userState.forename : ""}
                surname={props.userState.surname ? props.userState.surname : ""}
                color={defaultAccentColour}
                textColour={getMostContrastingColour(defaultAccentColour, contrastingColourList)}
                size={25}
                className={styles.avatar}
            />
        ));

        return (
            <button
                type="button"
                className={styles.button}
                onClick={this.props.onClick}
                ref={this.props.forwardedRef}
            >
                <WrappedUserAvatar />
            </button>
        );
    }

}

// eslint-disable-next-line react/no-multi-comp
export default React.forwardRef((props, ref) => (
    <ProfileButton forwardedRef={ref} {...props} />
));
