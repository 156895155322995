import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
// import Raven from 'raven-js';

import ErrorBoundary from './utils/ErrorBoundary';
import { Provider } from 'react-redux';
import { store, history } from './redux-store';
import { ConnectedRouter } from 'connected-react-router';

import Emc from './Emc';

import './index.scss';
import "input-moment/dist/input-moment.css";
import "./lib/InputMomentOverrides.scss";

// Raven.config('https://99c2d5b43da14832a929d35a096c434e@crash.datacode.dev/19').install();

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Emc />
            </ConnectedRouter>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);
