import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ActionCell.module.scss';

export default class ActionCell extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
        className: PropTypes.string,
    }

    static defaultProps = {
        className: '',
    }

    /**
     * Renders the cell component
     *
     * @return {ReactElement} The cell
     */
    render () {
        const classList = classNames([
            styles.cell,
            this.props.className,
        ]);

        return (
            <td className={classList}>
                {this.props.children}
            </td>
        );
    }

}
