import React from 'react';
import PropTypes from 'prop-types';

import { TextInput } from '@dataplan/react-components/dist/components/forms';

import styles from './FilterSearch.module.scss';

export default class FilterSearch extends React.Component {

    static propTypes = {
        onSearchInput: PropTypes.func.isRequired,
        queryString: PropTypes.string,
        name: PropTypes.string,
        forwardedRef: PropTypes.any,
    }

    static defaultProps = {
        queryString: '',
        name: 'search_term',

        forwardedRef: null,
    }

    /**
     * Renders the search input for the filter
     *
     * @return {ReactElement} The search input
     */
    render () {
        const { onSearchInput, queryString, name, forwardedRef } = this.props;

        return (
            <TextInput
                ref={forwardedRef}
                type="search"
                name={name}
                placeholder="Filter"
                value={queryString}
                onChange={onSearchInput}
                className={styles.filterSearch}
            />
        );
    }

}
