import React from 'react';
import PropTypes from 'prop-types';
import naturalCompare from 'string-natural-compare';
import { formatLongDate } from '../../../lib/formattingHelpers';
import documentsPropTypes, { documentsDefaultProps } from '../../../lib/props-schema/documentsSchema';

import getFileDownload from '../../../lib/getFileDownload';
import { DataTable, SnackBar } from 'components/';
import { handleDeletingDocument } from 'lib/';

import styles from './DocumentsListTab.module.scss';

class DocumentsListTab extends React.Component {

    static propTypes = {
        employeeDocuments: documentsPropTypes,
        reLoadTableDocs: PropTypes.func,
    }

    static defaultProps = {
        employeeDocuments: documentsDefaultProps,
        reLoadTableDocs: null,
    }

    /**
     * Creates an instance of the document section of the employee profile
     *
     * @param {object} props The document section properties
     */
    constructor (props) {
        super(props);

        this.state = {
            documents: [],
            dataLoaded: false,
            showSnackBar: false,
            snackBarType: "",
            snackBarMessage: "",
            snackBarHeading: "",
            snackBarOnConfirm: null,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.parseInitialDocumentData(this.props.employeeDocuments);
    }

    /**
     * Documents can be uploaded and change. Updates the table with the relevant
     * new documents
     *
     * @param {object} prevProps The properties
     * @param {object} prevState The state
     */
    componentDidUpdate (prevProps, prevState) {
        if (prevProps.employeeDocuments.length !== prevState.documents.length) {
            this.parseInitialDocumentData(prevProps.employeeDocuments);
        }
    }

    /**
     * Takes the documents provided in the props, shapes it and stores them in state
     *
     * @param {array} documents The document data returned from the API
     *
     * @return {void}
     */
    parseInitialDocumentData = (documents) => {
        this.setState({
            documents: this.mapDataObject(documents),
            dataLoaded: true,
        });
    }

    /**
     * Function passed to the actions menu to create snackbars for confirmation
     *
     * @param {Object} snackBar The snackbar properties
     *
     * @return {void}
     */
    createSnackBarConfirmation = (snackBar) => {
        const {
            type,
            heading,
            message,
            onConfirm,
        } = snackBar;

        this.setState({
            showSnackBar: true,
            snackBarType: type,
            snackBarMessage: message,
            snackBarHeading: heading,
            snackBarOnConfirm: onConfirm,
        });
    }

    /**
     * Maps the document data to an object shape suitable for the data table
     *
     * @param {array} documents The document data
     *
     * @return {array} The formatted data object
     */
    mapDataObject = (documents) => {
        const documentsMap = documents.map((documentData) => {
            let singleDocumentMap = {
                id: documentData.id,
                filename: {
                    component: this.getDocumentLink(documentData),
                    sortValue: documentData.filename,
                },
                desc: {
                    text: documentData.description,
                    type: documentData.type,
                },
                uploadDate: {
                    text: formatLongDate(documentData.datetime_added),
                    sortValue: documentData.datetime_added,
                },
                uploadedBy: {
                    text: documentData.uploaded_by,
                },
                status: {
                    text: documentData.status,
                },
            };

            return singleDocumentMap;
        });

        return documentsMap.sort((a, b) => {
            return naturalCompare(b.uploadDate.sortValue, a.uploadDate.sortValue);
        });
    }

    /**
     * Renders a download button for the file
     *
     * @param {object} documentData The document detailed in the row
     *
     * @return {ReactElement} The clickable link
     */
    getDocumentLink = (documentData) => {
        return (
            <button
                className={styles.buttonLink}
                onClick={() => {
                    getFileDownload(`/file/${documentData.type}/${documentData.id}/data`, documentData.filename);
                }}
                type="button"
            >
                {documentData.filename}
            </button>
        );
    }

    /**
     * Creates a data table using the documents data
     *
     * @return {ReactElement} The documents data table
     */
    getDocumentsTable = () => {
        const { documents, dataLoaded } = this.state;

        if (!dataLoaded) {
            return null;
        }

        const colOrder = [
            "filename",
            "desc",
            "uploadDate",
            "uploadedBy",
            "status",
        ];

        const headings = {
            filename: "File",
            desc: "Title",
            uploadDate: "Date Published",
            uploadedBy: "Published By",
            status: "Status",
        };

        const hidden = {
            medium: [
                'desc',
                'uploadedBy',
            ],
            large: [
                'desc',
            ],
        };

        const actions = {
            label: "Actions",
            actions: [
                {
                    label: "Delete",
                    callback: (docId, docData) => handleDeletingDocument(
                        docId,
                        docData.desc.type,
                        docData.desc.text,
                        this.props.reLoadTableDocs,
                        this.createSnackBarConfirmation
                    ),
                },
            ],
        };

        return (
            <div className={styles.documentsTable}>
                <DataTable
                    key="document-table"
                    colOrder={colOrder}
                    data={documents || []}
                    headings={headings}
                    hidden={hidden}
                    searchable
                    dataLoaded={dataLoaded}
                    actions={actions}
                    emptySearch="documents"
                />
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const {
            dataLoaded,
            showSnackBar,
            snackBarType,
            snackBarMessage,
            snackBarHeading,
            snackBarOnConfirm,
        } = this.state;

        if (!dataLoaded) {
            return null;
        }

        return (
            <>
                {this.getDocumentsTable()}

                { showSnackBar && (
                    <SnackBar
                        type={snackBarType}
                        heading={snackBarHeading}
                        message={snackBarMessage}
                        onConfirm={() => this.setState({showSnackBar: false}, snackBarOnConfirm)}
                        onCancel={() => this.setState({showSnackBar: false})}
                    />
                )}
            </>
        );
    }

}

export default DocumentsListTab;
