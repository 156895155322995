import userState from "state/User";
import api from './api';

/**
 * Updates the user profile details stored in the user state if changed through my account
 *
 * @return {void}
 */
const refreshUserDetails = () => {
    const getProfile = api.get("/user/profile")
        .then((profile) => {
            const {forename, surname, mfa_totp} = profile.data; // eslint-disable-line camelcase
            userState.setName(forename, surname);
            userState.setMFA(mfa_totp);
        });

    return Promise.resolve(getProfile);
};

export default refreshUserDetails;
