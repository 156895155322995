import PropTypes from 'prop-types';

export const employeePropTypes = PropTypes.shape({
    "id": PropTypes.number.isRequired,
    "forename_1": PropTypes.string,
    "forename_2": PropTypes.string,
    "surname": PropTypes.string,
    "name": PropTypes.string,
    "title": PropTypes.string.isRequired,
    "payroll_ref": PropTypes.number,
    "ni": PropTypes.string.isRequired,
    "address": PropTypes.shape({
        "line_1": PropTypes.string.isRequired,
        "line_2": PropTypes.string,
        "line_3": PropTypes.string,
        "line_4": PropTypes.string,
        "postcode": PropTypes.string.isRequired,
    }),
    "phone_numbers": PropTypes.shape({
        "home": PropTypes.string,
        "work": PropTypes.string,
        "mobile": PropTypes.string,
    }),
    "emails": PropTypes.shape({
        "work": PropTypes.string,
        "home": PropTypes.string,
    }),
    "date_of_birth": PropTypes.string.isRequired,
    "start_date": PropTypes.string,
    "end_date": PropTypes.string,
    "account_status": PropTypes.string,
    "last_accessed": PropTypes.string,
    "department": PropTypes.string,
    "post_titles": PropTypes.array,
});

export const employeeDefaultProps = {
    "id": Number(),
    "forename_1": "",
    "forename_2": "",
    "surname": "",
    "name": "",
    "title": "",
    "payroll_ref": Number(),
    "ni": "",
    "address": {
        "line_1": "",
        "line_2": "",
        "line_3": "",
        "line_4": "",
        "postcode": "",
    },
    "phone_numbers": {
        "home": "",
        "work": "",
        "mobile": "",
    },
    "emails": {
        "home": "",
        "work": "",
    },
    "date_of_birth": "",
    "start_date": "",
    "end_date": "",
    "account_status": "",
    "last_accessed": "",
    "department": "",
    "post_titles": [],
};

export default employeePropTypes;
