import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import { withRouter } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import axios from 'axios';
import api from '../../lib/api';
import { paths } from "../../lib";
import appState from '../../state/App';
import dataSetFilter from '../../lib/dataSetFilter';

import {
    YearScroller,
} from '../../components';
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import PayrunCard from "./assets/PayrunCard";
import Filter from '../../components/tables/data-table/assets/Filter';
import { AddButton } from '@dataplan/react-components/dist/components/forms/controls';
import { defaultAccentColour } from '../../Colours';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

import styles from "./Payruns.module.scss";
import sharedStyles from "./assets/SharedStyles.module.scss";

class Payruns extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of payruns page
     *
     * @param {object} props Input data
     */
    constructor (props) {
        super(props);

        this.state = {
            years: {},
            currentTaxYear: moment().set({
                date: 1,
                month: 0,
            }),
            payruns: [],
            filtersSelected: {},
            shouldAnimate: true,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.getSetYears();
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Gets the range of years to display payruns for and sets them in the state
     *
     * @return {void}
     */
    getSetYears = () => {
        const requests = _.map(this.props.appState.payrolls, (payroll) => {
            return api.get(`/payroll/${payroll.id}/payruns/range`, { component: this });
        });

        axios.all(requests)
            .then((responses) => {
                let minYears = [];
                let maxYears = [];

                responses.forEach((response) => {
                    minYears.push(response.data.first_year);
                    maxYears.push(response.data.latest_year);
                });

                this.setState({
                    years: {
                        minYear: _.min(minYears),
                        maxYear: _.max(maxYears),
                    },
                    currentTaxYear: moment().set({
                        date: 1,
                        month: 0,
                        year: _.max(maxYears),
                    }),
                }, () => {
                    this.updatePayrunList();
                });
            })
            .catch(() => {
                // Will catch if axios request is canceled
            });
    }

    /**
     * Pulls the list of payruns for the current filter
     */
    updatePayrunList () {
        const requests = _.map(this.props.appState.payrolls, (payroll) => {
            const year = this.state.currentTaxYear.year();

            return api.get(`/payroll/${payroll.id}/payruns?year=${year}`, { component: this });
        });

        Promise.all(requests).then((responses) => {
            let payruns = _(responses)
                .map("data")
                .thru(_.spread(_.union))
                .filter((payrun) => {
                    return payrun.paydates.length > 0;
                })
                .sortBy((payrun) => {
                    const keyPaydate = _.last(_.sortBy(payrun.paydates, "total"));
                    const timestamp = parseInt(moment(keyPaydate.paydate, "YYYY-MM-DD").format("X"), 10);

                    return -timestamp;
                })
                .value();

            _.each(payruns, (payrun) => {
                payrun.payroll = _.find(this.props.appState.payrolls, (testPayroll) => {
                    return testPayroll.id === payrun.period.payroll_id;
                });

                payrun.company = _.find(this.props.appState.companies, (testCompany) => {
                    return testCompany.id === payrun.payroll.company_id;
                });
            });

            this.setState({ payruns });
        }).catch(() => {
            // Will catch if axios request is canceled
        });
    }

    /**
     * Updates the current visible month
     *
     * @param {string} action The action - either add or subtract
     * @param {number} value The value to modify by
     */
    updateTaxYear = (action, value) => {
        this.setState((prevState) => {
            const newMoment = moment(prevState.currentTaxYear);

            newMoment[action](value, "years");

            return { currentTaxYear: newMoment };
        }, () => {
            this.updatePayrunList();
        });
    }

    /**
     * Get a unique list of payrolls and company names associated with the payrun data
     *
     * @return {object} The list
     */
    getPayrollsCompanies = () => {
        const { payruns } = this.state;
        let payrolls = [];
        let companies = [];

        payruns.forEach((payrun) => {
            payrolls.push(payrun.payroll.name || '');
            companies.push(payrun.company.name || '');
        });

        return {
            payrolls: _.uniq(_.filter(payrolls)),
            companies: _.uniq(_.filter(companies)),
        };
    }

    /**
     * Handles user selecting a filter
     *
     * @param {string} value The filter value selected
     * @param {string} filterKey The filter the value was selected from
     * @param {boolean} checked If the checkbox is checked or un-checked
     */
    handleFilterSelect = (value, filterKey, checked) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                filtersSelected: {
                    ...prevState.filtersSelected,
                    [filterKey]: {
                        ...prevState.filtersSelected[filterKey],
                        [value]: checked,
                    },
                },
                shouldAnimate: false,
            };
        });
    }

    /**
     * Handles user clicking the clear filter button
     *
     * @param {string} filterKey The filter to clear
     */
    handleFiltersClear = (filterKey) => {
        if (filterKey === "all") {
            this.setState({
                filtersSelected: {},
            });
        } else {
            this.setState((prevState) => {
                const prevFilterValues = prevState.filtersSelected;
                let reducedFilterSelection = _.reduce(prevFilterValues, (newSelection, value, key) => {
                    if (key !== filterKey) {
                        newSelection[key] = value;
                    }

                    return newSelection;
                }, {});

                return {
                    ...prevState,
                    filtersSelected: reducedFilterSelection,
                };
            });
        }
    }

    /**
     * Renders the data navigation arrows
     *
     * @return {?ReactElement} The date navigation component
     */
    renderDateNavigation = () => {
        const { years, currentTaxYear } = this.state;

        if (!years.maxYear || !years.minYear) {
            return null;
        }

        return (
            <AnimationContainer
                animationStyle="animationContainerShowMore"
                appearTimeout={1000}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <YearScroller
                    year={currentTaxYear}
                    updateTaxYear={this.updateTaxYear}
                    maxYear={moment().set({
                        date: 1,
                        month: 0,
                        year: years.maxYear,
                    })}
                    minYear={moment().set({
                        date: 1,
                        month: 0,
                        year: years.minYear,
                    })}
                />
            </AnimationContainer>
        );
    }

    /**
     * Renders the drop down filter for Companies
     *
     * @return {?ReactElement} The Filters
     */
    renderCompanyFilter = () => {
        const { companies } = this.getPayrollsCompanies();
        const { filtersSelected } = this.state;

        if (companies.length <= 1) {
            return null;
        }

        return (
            <Filter
                filter={{
                    text: "Company",
                    filterKey: "company",
                    values: companies,
                }}
                onFilterSelect={this.handleFilterSelect}
                onFiltersClear={this.handleFiltersClear}
                filtersSelected={filtersSelected.company}
            />
        );
    }

    /**
     * Renders the drop down filter for Payrolls
     *
     * @return {?ReactElement} The Filters
     */
    renderPayrollFilter = () => {
        const { payrolls, companies } = this.getPayrollsCompanies();
        const { filtersSelected } = this.state;

        if (payrolls.length <= 1 && companies.length <= 1) {
            return null;
        }

        return (
            <Filter
                filter={{
                    text: "Payroll",
                    filterKey: "payroll",
                    values: payrolls,
                }}
                onFilterSelect={this.handleFilterSelect}
                onFiltersClear={this.handleFiltersClear}
                filtersSelected={filtersSelected.payroll}
            />
        );
    }

    /**
     * Renders the clear all filters button if filters are selected
     *
     * @return {?ReactElement} The button
     */
    renderClearAllFilters = () => {
        const { filtersSelected } = this.state;
        const activeFilters = (_.isEmpty(filtersSelected))
            ? false
            : _.some(filtersSelected, (filter) => {
                return _.size(_.filter(filter)) > 0;
            });

        if (!activeFilters) {
            return null;
        }

        return (
            <button
                type="button"
                onClick={() => this.handleFiltersClear("all")}
                className={styles.clearFilters}
            >
                Clear Filter(s)
            </button>
        );
    }

    /**
     * Renders the add new FAB icon button
     *
     * @return {ReactElement} The button
     */
    renderAddNew () {
        const { history } = this.props;

        return (
            <AnimationContainer
                animationStyle="animationContainerShowMore"
                appearTimeout={1000}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <AddButton colour={defaultAccentColour} onClick={() => history.push(paths.upload)} />
            </AnimationContainer>
        );
    }

    /**
     * Returns required animation timeout based on index of element in list
     * The first 3 (initial phase) appear in 200ms intervals
     * The others appear in 100ms intervals
     *
     * @param {number} index The index of element in list
     * @return {Array} The required animation timeout
     */
    getAnimationTimeout = (index) => {
        const itemNum = index + 1;
        const endPhaseNum = 6;
        const withinInitialPhase = (itemNum <= endPhaseNum);
        const timeout = (withinInitialPhase)
            ? (itemNum * 200)
            : ((itemNum + endPhaseNum) * 100);
        return timeout;
    }

    /**
     * Renders the payrun cards into the table body
     *
     * @return {ReactElement} The table body
     */
    renderTableBody = () => {
        const showCompany = (this.props.appState.companies.length > 1);
        const { payruns, filtersSelected } = this.state;
        let filteredPayruns = payruns;

        if (!_.isEmpty(filtersSelected)) {
            filteredPayruns = _.filter(filteredPayruns, (row) => {
                return dataSetFilter({
                    company: row.company.name,
                    payroll: row.payroll.name,
                }, filtersSelected);
            });
        }

        if (payruns < 1) {
            return (
                <AnimationContainer
                    animationStyle="animationContainerPayRun"
                    appearTimeout={400}
                    enterTimeout={1000}
                    exitTimeout={100}
                    wrapper="tbody"
                >
                    <tr>
                        <td colSpan={showCompany ? 8 : 7}>No payruns found</td>
                    </tr>
                </AnimationContainer>
            );
        }

        return filteredPayruns.map((payrun, index) => {
            return (
                <AnimationContainer
                    animationStyle="animationContainerPayRun"
                    appearTimeout={this.getAnimationTimeout(index)}
                    shouldAnimate={this.state.shouldAnimate}
                    enterTimeout={1000}
                    exitTimeout={100}
                    wrapper="tbody"
                    className={styles.tableBody}
                    key={payrun.period.id}
                >
                    <PayrunCard
                        key={payrun.period.id}
                        payrun={payrun}
                        payroll={payrun.payroll}
                        company={payrun.company}
                        showCompany={showCompany}
                    />
                </AnimationContainer>
            );
        });
    }

    /**
     * Renders the payrun table content
     *
     * @return {ReactElement} The payrun table
     */
    renderTableContent () {
        const showCompany = (this.props.appState.companies.length > 1);
        const alignRightClasses = classNames(sharedStyles.hideLarge, styles.alignRight);

        return (
            <table className={styles.table}>
                <thead className={styles.tableHeader}>
                    <AnimationContainer
                        animationStyle="animationContainerPayRun"
                        appearTimeout={200}
                        enterTimeout={1000}
                        exitTimeout={100}
                        wrapper="tr"
                    >
                        <th>Pay date</th>
                        <th>Period</th>
                        {showCompany && <th className={sharedStyles.hideMedium}>Company</th>}
                        <th>Payroll</th>
                        <th>Payslips</th>
                        <th className={alignRightClasses}>Gross</th>
                        <th className={alignRightClasses}>Net</th>
                        <th className={sharedStyles.hideLarge}>Release date</th>
                        <th className={sharedStyles.detailsCol} />
                    </AnimationContainer>
                </thead>
                {this.renderTableBody()}
            </table>
        );
    }

    /**
     * Renders the page header content
     *
     * @return {ParamElement} The header
     */
    renderPageHeader () {
        return (
            <div className={styles.navContainer}>
                <div className={styles.leftContainer}>
                    <AnimationContainer
                        animationStyle="animationContainer"
                        appearTimeout={200}
                        enterTimeout={1000}
                        exitTimeout={100}
                    >
                        <h1>Pay Runs</h1>
                    </AnimationContainer>
                </div>
                {this.renderDateNavigation()}
                <div className={styles.rightContainer}>
                    <div className={styles.filterContainer}>
                        <AnimationContainer
                            animationStyle="animationContainerShowMore"
                            appearTimeout={1000}
                            enterTimeout={1000}
                            exitTimeout={100}
                        >
                            {this.renderClearAllFilters()}
                            {this.renderCompanyFilter()}
                            {this.renderPayrollFilter()}
                        </AnimationContainer>
                    </div>
                    {this.renderAddNew()}
                </div>
            </div>
        );
    }

    /**
     * Renders the payruns page
     *
     * @return {ReactElement} The page component
     */
    render () {
        return (
            <PageLayout
                maxWidth={this.props.appState.maxWidth}
                display={{
                    isDetached: false,
                    isPadded: true,
                    hasBackground: false,
                    hasGutter: false,
                }}
            >
                {this.renderPageHeader()}
                <div className={styles.cardContainer}>
                    {this.renderTableContent()}
                </div>
            </PageLayout>
        );
    }

}

export default withRouter(appState.attachState(Payruns));
