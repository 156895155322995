/**
 * Class containing helpful string related helpers that can be reused.
 */
export class StringHelper {

    /**
     * @private
     */
    priString = "";

    /**
     * StringFormatter instance
     *
     * @param {string} string The string we want to set
     */
    constructor (string) {
        this.priString = string;
    }

    /**
     * Getter for `this._string`
     *
     * @return {string} Returns the currently set string (if any).
     */
    get string () {
        return this.priString;
    }

    /**
     * Setter for `this._string`
     *
     * @param {string} string The String we want to set.
     */
    set string (string) {
        this.priString = string;
    }

    /**
     * Parses a users name from `Bloggs, Joe` to `Joe Bloggs`.
     *
     * @return {string} Returns the parsed name or an unformatted version.
     */
    parseName () {
        const name = this.priString.split(', ');

        return (name.length > 1)
            ? `${name[name.length - 1]} ${name[0]}`
            : this.priString;
    }

}
