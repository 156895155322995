import React from 'react';
import PropTypes from 'prop-types';
import appState from '../../state/App';
import api from 'lib/api';
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { AddButton } from "@dataplan/react-components/dist/components/forms";
import { defaultAccentColour } from '../../Colours';
import _ from "lodash";
import { generatePath, withRouter } from 'react-router-dom';
import { paths } from "../../lib";
import { DataTable } from 'components/';
import { PayrollLabel, PayrollLabelExpander } from '../Documents/assets';

import {
    getPayrollNameForCompany,
    filterObjectEmptyValues,
} from 'lib/';
import styles from './assets/Companies.module.scss';

class Companies extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            company: [],
            dataLoaded: false,
        };
    }

    /**
     * Called when the component first renders
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleApiCall();
    }

    /**
     * Handles the api call to get the companies
     */
    handleApiCall () {
        api.get("/companies")
            .then(async ({ data: companies }) => {
                const payrolls = await Promise.all(companies.map((company) => {
                    return api.get(`/company/${company.id}/payrolls`);
                }));

                return {
                    companies,
                    payrolls: payrolls.reduce((acc, { data }) => {
                        acc.push(...data);

                        return acc;
                    }, []),
                };
            }).then(({ companies, payrolls }) => {
                this.mapCompanySaveToState(companies);
                appState.setCompaniesAndPayrolls(companies, payrolls);
                appState.setSelectedPayroll(payrolls[0]);

                const periodRequests = payrolls.map((payroll) => {
                    return api.get(`/payroll/${payroll.id}/periods`);
                });

                return Promise.all(periodRequests);
            }).then((responses) => {
                _.forEach(responses, (response) => {
                    const payrollId = response.data[0].payroll_id;
                    const periods = response.data.map((period) => {
                        return {
                            id: period.id,
                            year: period.year,
                            period_number: period.period_number, // eslint-disable-line camelcase
                        };
                    });

                    appState.setPeriods(payrollId, periods);
                });
            });
    }

    /**
     * Makes an object of the attributes for the data table
     *
     * @return {object} attributes
     */
    getTableAttributes () {
        const colOrder = [
            "Name",
            "Payrolls",
        ];

        const docTableHeadings = {
            Name: "Name",
            Payrolls: "Payrolls",
        };

        return {
            colOrder,
            docTableHeadings,
        };
    }

    /**
     * Maps the company array for use in the data table
     *
     * @param {object} companies the companies from the api
     * @return {array} mapped array
     */
    mapCompanies (companies) {
        return companies.map((company) => {
            let companyMap = {
                Name: {
                    component: this.getCompanyLink(company),
                },
                Payrolls: {
                    component: this.renderPayrolls(company),
                },
            };

            return filterObjectEmptyValues(companyMap);
        });
    }

    /**
     * Provides a link to the company pages
     *
     * @param {object} company the companies
     *
     * @return {ReactElement} The company links
     */
    getCompanyLink (company) {
        const { history } = this.props;

        return (
            <button
                type="button"
                // eslint-disable-next-line camelcase
                onClick={() => history.push(generatePath(paths.singleCompanyPayrolls, { company_id: company.id }))}
                className={styles.buttonLink}
            >
                {company.name}
            </button>
        );
    }

    /**
     * Saves the mapped companies to the saved state
     *
     * @param {array} companies An array of companies
     */
    mapCompanySaveToState (companies) {
        this.setState({
            company: this.mapCompanies(companies),
            dataLoaded: true,
        });
    }

    /**
     * Renders the payroll label component for the payroll column of the data table
     *
     * @param {object} company details of the company
     *
     * @return {ReactComponent} The Payroll Label component for render
     */
    renderPayrolls (company) {
        const payrollList = _.filter(this.props.appState.payrolls, (payroll) => {
            return payroll.company_id === parseInt(company.id, 10);
        });

        const payrollName = payrollList.map((payroll) => {
            return {
                id: payroll.id,
                name: payroll.name,
            };
        });

        if (payrollList.length <= 0) {
            return <PayrollLabel key={payrollName.id} text={getPayrollNameForCompany(payrollName)} />;
        }

        return (
            <PayrollLabelExpander
                key={payrollName.id}
                payrolls={payrollName}
                companies={this.props.appState.companies}
                companyId={company.id}
                isCompany={true}
            />
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { history } = this.props;

        const headerAction = {
            drawer: null,
            button: <AddButton colour={defaultAccentColour} onClick={() => history.push(paths.companiesAdd)} />,
        };

        const { colOrder, docTableHeadings } = this.getTableAttributes();

        return (
            <>
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={200}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    <PageLayout
                        heading={{
                            text: `Companies`,
                            size: "h1",
                        }}
                        headerAction={headerAction}
                        pageType="boxed"
                        maxWidth={this.props.appState.maxWidth}
                        display={{
                            isDetached: false,
                            isPadded: true,
                            hasBackground: true,
                            hasGutter: true,
                        }}
                    >
                        <DataTable
                            key="all-companies"
                            colOrder={colOrder}
                            data={this.state.company}
                            dataLoaded={this.state.dataLoaded}
                            headings={docTableHeadings}
                        />
                    </PageLayout>
                </AnimationContainer>
            </>
        );
    }

}

export default withRouter(appState.attachState(Companies));
