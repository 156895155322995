import React from 'react';
import PropTypes from 'prop-types';
import api from "../../lib/api";
import { filterObjectEmptyValues, paths } from "../../lib";
import appState from '../../state/App';
import _ from "lodash";
import moment from 'moment';
import { generatePath, Link, withRouter } from 'react-router-dom';
import { DataTable, Icon } from 'components/';
import { AnimationContainer, Drawer, PageLayout } from '@dataplan/react-components/dist/components/ui';
import { AttachmentIcon } from "@dataplan/react-components/dist/components/icons";
import { AddButton } from "@dataplan/react-components/dist/components/forms";
import { defaultAccentColour } from '../../Colours';
import CreateMessage from "./assets/CreateMessage.js";

import styles from "./assets/Messages.module.scss";

class Messages extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);
        this.nativeDrawer = React.createRef();

        this.state = {
            dataLoaded: false,
            dataTableAttrs: this.getDataTableAttrs(),
            messages: {
                inbox: null,
                sent: null,
            },
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        this.getMessages();
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Called once the component has loaded, queries the API for messages
     *
     * @return {void}
     */
    getMessages = () => {
        this.setState({
            dataLoaded: false,
        }, () => {
            api.get('/legacy_messages', {
                component: this,
                params: {
                    "show_attachment_count": true,
                },
            }).then(({ data }) => {
                this.formatMessages(data);
                this.categoriseMessages(data);
            });
        });
    }

    /**
     * Formats data of messages
     *
     * @param {array} messages The list of messages to format
     *
     * @return {void}
     */
    formatMessages = (messages) => {
        const { companies, employees, payrolls } = this.props.appState;

        const companiesRef = {};
        const ref = payrolls.reduce((acc, payroll) => {
            if (!companiesRef[payroll.company_id]) {
                companiesRef[payroll.company_id] = companies.filter((company) => {
                    return (company.id === payroll.company_id);
                })[0].name;
            }

            acc[payroll.id] = {
                payroll: payroll.name,
                company: companiesRef[payroll.company_id],
            };

            return acc;
        }, {});

        _.forEach(messages, (message) => {
            const employee = employees.filter((value) => value.id === message.employee_id)[0];
            message.company = ref[message.payroll_id].company;
            message.payroll = ref[message.payroll_id].payroll;
            // eslint-disable-next-line camelcase
            message.time_sent = moment(message.time_sent).format('DD MMM YYYY, HH:mm');

            if (message.recipient === "employee") {
                message.to = employee.name;
            } else {
                message.from = employee.name;
            }
        });
    }

    /**
     * Put each message into the inbox or sent list depending on recipient
     *
     * @param {array} messages The list of messages to categorise
     *
     * @return {void}
     */
    categoriseMessages = (messages) => {
        if (messages.length < 1) {
            return;
        }

        let inbox = [];
        let sent = [];

        _.forEach(messages, (message) => {
            message.moment = moment(message.time_sent, "DD MMM YYYY, HH:mm");

            if (message.recipient === "company") {
                inbox.push(message);
            } else if (message.recipient === "employee") {
                sent.push(message);
            }
        });

        _.forEach([inbox, sent], (messageList) => {
            messageList.sort((a, b) => {
                return b.moment.valueOf() - a.moment.valueOf();
            });
        });

        this.setState({
            dataLoaded: true,
            messages: {
                inbox: this.mapMessageData('inbox', inbox),
                sent: this.mapMessageData('sent', sent),
            },
        });
    }

    /**
     * Map messages into a shape for the data table
     *
     * @param {string} type String denoting data type (Inbox / Sent)
     * @param {array} messages The raw array of messages
     *
     * @return {array} The mapped array of messages
     */
    mapMessageData = (type, messages) => {
        const { dataTableAttrs } = this.state;
        const properties = Object.keys(dataTableAttrs[type].headings);
        properties.push("id");

        return messages.map((message) => {
            return properties.reduce((acc, property) => {
                switch (property) {
                case "id":
                    acc.id = message.id;

                    break;
                case "subject":
                    acc.subject = {
                        component: this.getSubjectComponent(message),
                        text: message.subject,
                    };

                    break;
                case "from":
                case "to":
                    acc[property] = ({
                        text: message[property],
                        link: generatePath(paths.employee, message),
                    });

                    break;
                default:
                    acc[property] = ({
                        text: message[property],
                    });

                    break;
                }

                return acc;
            }, {});
        });
    }

    /**
     * Get subject component for data table
     *
     * @param {object} message The given message
     *
     * @return {ReactElement} The subject component
     */
    getSubjectComponent = (message) => {
        return (
            <Link
                className={styles.messageLink}
                // eslint-disable-next-line camelcase
                to={generatePath(paths.singleMessage, { message_id: message.id })}
            >
                {message.subject}
                {Boolean(message.attachments) && <AttachmentIcon className={styles.attachmentIcon} />}
            </Link>
        );
    }

    /**
     * Makes api call to delete a message
     *
     * @param {string} messageId The id of the given message
     *
     * @return {void}
     */
    deleteMessage = (messageId) => {
        api.delete(`/legacy_message/${messageId}`, {
            component: this,
        })
            .then(() => {
                appState.addNotification({
                    text: `Message has been deleted`,
                    type: "success",
                    duration: 5,
                }, () => {
                    this.getMessages();
                });
            }).catch(() => {
                appState.addNotification({
                    text: `Failed to delete message`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Convenience function to map the attributes for displaying the data table
     *
     * @return {object} The data table attributes
     */
    getDataTableAttrs = () => {
        const { companies, payrolls } = this.props.appState;
        const multiplePayrolls = (payrolls.length > 1);

        const colOrder = [
            "subject",
            "time_sent",
            "payroll",
            "company",
            "from",
            "to",
        ];

        const filters = filterObjectEmptyValues({
            payroll: (multiplePayrolls) ? "Payroll" : null,
        });

        const headings = filterObjectEmptyValues({
            company: (companies.length > 1) ? "Company" : null,
            payroll: (multiplePayrolls) ? "Payroll" : null,
            subject: "Subject",
            time_sent: "Date", /* eslint-disable-line camelcase */
        });

        const inbox = {
            colOrder,
            filters: {
                ...filters,
                from: "Employee Name",
            },
            headings: {
                ...headings,
                from: "From",
            },
        };

        const sent = {
            colOrder,
            filters: {
                ...filters,
                to: "Employee Name",
            },
            headings: {
                ...headings,
                to: "To",
            },
        };

        return {
            inbox,
            sent,
        };
    }

    /**
     * Gets the actions user can apply on messages
     *
     * @param {string} type String denoting data type (Inbox / Sent)
     *
     * @return {object} The data table attributes
     */
    getActions = (type) => {
        const viewAction = this.getAction(type, "View");
        const replyAction = this.getAction(type, "Reply");
        const deleteAction = this.getAction(type, "Delete");

        const actions = (type === "inbox")
            ? [
                viewAction,
                replyAction,
                deleteAction,
            ]
            : [
                viewAction,
                deleteAction,
            ];

        return {
            label: "Actions",
            actions,
        };
    }

    /**
     * Gets a specific action object
     *
     * @param {string} type String denoting data type (Inbox / Sent)
     * @param {string} label String denoting action type (View / Reply / Delete)
     *
     * @return {object} The action object
     */
    getAction = (type, label) => {
        const { history } = this.props;
        const messages = this.state.messages[type];

        const actionConfig = {
            "View": {
                // eslint-disable-next-line camelcase
                callback: (message_id) => history.push(generatePath(paths.singleMessage, { message_id })),
            },
            "Reply": {
                // eslint-disable-next-line camelcase
                callback: (message_id) => history.push(generatePath(paths.singleMessageReply, { message_id })),
            },
            "Delete": {
                getSnackBarConfig: (messageId) => {
                    const { text } = _.find(messages, (message) => (message.id === messageId)).subject;

                    return ({
                        heading: `This message ${text} will be deleted`,
                        // eslint-disable-next-line max-len
                        message: "Deleting the message will mean employees can no longer see it. Are you sure you want to archive this?",
                        onConfirm: () => this.deleteMessage(messageId),
                        type: "warn",
                    });
                },
            },
        };

        return {
            label,
            ...actionConfig[label],
        };
    }

    /**
     * Set the visibility of the create a new message drawer
     *
     * @param {boolean} visible Whether the drawer should be visible
     *
     * @return {void} Changes state of drawer
     */
    setCreateMessageDrawerVisibility = (visible) => {
        const drawer = this.nativeDrawer.current;

        drawer.setState({
            visible,
        });
    }

    /**
     * Renders the create a new message drawer
     *
     * @return {ReactElement} The drawer component
     */
    renderCreateMessageDrawer = () => {
        const header = {
            size: 'h2',
            text: 'Create a message',
        };
        const topAction = {
            icon: <Icon icon="Close" aria-label="Close" />,
            action: () => this.setCreateMessageDrawerVisibility(false),
        };

        return (
            <Drawer
                targetNode={document.body}
                ref={this.nativeDrawer}
                header={header}
                loadingLabel="Uploading..."
                onClickOutside={() => this.setCreateMessageDrawerVisibility(false)}
                topAction={topAction}
            >
                <CreateMessage
                    closeDrawer={() => this.setCreateMessageDrawerVisibility(false)}
                    refreshData={this.getMessages}
                />
            </Drawer>
        );
    }

    /**
     * Gets the tab content for the page
     *
     * @return {array} The array of content for the tabs
     */
    getTabContent = () => {
        const { dataLoaded, dataTableAttrs, messages } = this.state;
        const { inbox, sent } = dataTableAttrs;

        return [
            {
                text: "Inbox",
                component: (
                    <DataTable
                        key="Inbox"
                        colOrder={inbox.colOrder}
                        data={messages.inbox || []}
                        dataLoaded={dataLoaded}
                        filterable={inbox.filters}
                        headings={inbox.headings}
                        searchable
                        actions={this.getActions("inbox")}
                    />
                ),
            },
            {
                text: "Sent",
                component: (
                    <DataTable
                        key="Sent"
                        colOrder={sent.colOrder}
                        data={messages.sent || []}
                        dataLoaded={dataLoaded}
                        filterable={sent.filters}
                        headings={sent.headings}
                        searchable
                        actions={this.getActions("sent")}
                    />
                ),
            },
        ];
    }

    /**
     * Renders the page content to render
     *
     * @return {ReactElement} The page content
     */
    renderPageContent = () => {
        const headerAction = {
            drawer: this.renderCreateMessageDrawer(),
            button: (
                <AddButton
                    colour={defaultAccentColour}
                    onClick={() => this.setCreateMessageDrawerVisibility(true)}
                />
            ),
        };

        return (
            <PageLayout
                headerAction={headerAction}
                heading={{
                    text: `Messages`,
                    size: "h1",
                }}
                isRouted={true}
                pageType="boxed"
                maxWidth={this.props.appState.maxWidth}
                display={{
                    isDetached: false,
                    isPadded: true,
                    hasBackground: true,
                    hasGutter: true,
                }}
                tabContent={this.getTabContent()}
            />
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render = () => {
        return (
            <>
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={200}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    {this.renderPageContent()}
                </AnimationContainer>
            </>
        );
    }

}

export default withRouter(appState.attachState(Messages));
