import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTimeout from 'react-timeout';

import Toast from './Toast';
import Icon from '../icons/Icon';
import PrimaryButton from '../forms/controls/PrimaryButton';
import { SecondaryButton } from "@dataplan/react-components/dist/components/forms/controls";
import { defaultAccentColour } from '../../Colours';

import styles from './SnackBar.module.scss';

class SnackBar extends React.Component {

    static propTypes = {
        type: PropTypes.oneOf(["warn", "success", "error", "info"]).isRequired,
        message: PropTypes.string.isRequired,
        heading: PropTypes.string,
        onConfirm: PropTypes.func,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        confirmText: PropTypes.string,
        cancelText: PropTypes.string,
        setTimeout: PropTypes.func.isRequired,
    }

    static defaultProps = {
        heading: null,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        confirmText: "Confirm",
        cancelText: "Cancel",
    }

    /**
     * Creates an instance of the snack bar component
     *
     * @param {object} props Snack bar props
     */
    constructor (props) {
        super(props);

        this.state = {
            closing: false,
            closed: false,
        };
    }

    /**
     * Gets the icon that should be shown on the alert card
     *
     * @param {string} alertType The type of alert
     *
     * @return {string} The icon to show
     */
    getIcon = (alertType) => {
        switch (alertType) {
        case "success":
            return "Tick";
        case "warn":
        case "error":
            return "Warning";
        case "info":
            return "Info";
        default:
            return null;
        }
    }

    /**
     * Render the confirm button if callback provided
     *
     * @param {function} callback The function to run on click
     * @param {string} text The text to display on the button
     *
     * @return {ReactElement} The button
     */
    getConfirmButton = (callback, text) => {
        if (!callback || typeof callback !== 'function') {
            return null;
        }

        return (
            <PrimaryButton
                type="button"
                onClick={(event) => this.handleClick(event, callback)}
                className={styles.confirmButton}
            >
                {text}
            </PrimaryButton>
        );
    }

    /**
     * Render the cancel button if callback provided
     *
     * @param {function} callback The function to run on click
     * @param {string} text The text to display on the button
     *
     * @return {ReactElement} The button
     */
    getCancelButton = (callback, text) => {
        if (!callback || typeof callback !== 'function') {
            return null;
        }

        return (
            <SecondaryButton
                type="button"
                onClick={(event) => this.handleClick(event, callback)}
                className={styles.cancelButton}
                accent={defaultAccentColour}
                text={text}
                aria-label={text}
            />
        );
    }

    /**
     * Render the close button if callback provided
     *
     * @param {function} callback The function to run on click
     *
     * @return {ReactElement} The button
     */
    getCloseButton = (callback) => {
        if (!callback || typeof callback !== 'function') {
            return null;
        }

        return (
            <button
                type="button"
                className={styles.closeButton}
                onClick={(event) => this.handleClick(event, callback)}
            >
                <Icon icon="CloseSolid" className={styles.closeIcon} />
            </button>
        );
    }

    /**
     * Handles the button click events, preventing default form submission
     *
     * @param {event} event The onClick event
     * @param {function} callback The function to run on click
     *
     * @return {void}
     */
    handleClick = (event, callback) => {
        event.preventDefault();

        this.setState((prevState) => {
            return {
                ...prevState,
                closing: !prevState.closing,
            };
        }, () => {
            this.props.setTimeout(() => {
                this.setState({closed: true});
                callback();
            }, 500);
        });
    }

    /**
     * Renders the snack bar
     *
     * @return {ReactElement} The snack bar
     */
    render () {
        const {
            type,
            message,
            heading,
            onConfirm,
            onCancel,
            onClose,
            confirmText,
            cancelText,
        } = this.props;

        const classList = classNames([
            styles.confirmationContainer,
            styles[type],
            this.state.closing && styles.closing,
        ]);

        const iconClass = classNames([
            styles.typeIcon,
            styles[type],
        ]);

        if (this.state.closed) {
            return null;
        }

        return (
            <Toast>
                <div className={classList}>
                    <div className={styles.confirmationInner}>
                        <div className={styles.iconContainer}>
                            <Icon icon={this.getIcon(type)} className={iconClass} />
                        </div>

                        <div className={styles.message}>
                            { heading && (<h3>{heading}</h3>) }
                            {message}
                        </div>

                        <div className={styles.actions}>
                            {this.getConfirmButton(onConfirm, confirmText)}
                            {this.getCancelButton(onCancel, cancelText)}
                            {this.getCloseButton(onClose)}
                        </div>
                    </div>
                </div>
            </Toast>
        );
    }

}

export default ReactTimeout(SnackBar); // eslint-disable-line new-cap
