import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';
import PrimaryButton from '../../../components/forms/controls/PrimaryButton';

import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";

export default class TooltipSection extends React.Component {

    /**
     * Renders the tooltip section
     *
     * @return {ReactElement} The section
     */
    render () {
        return (
            <CardRow>
                <CardRowContainer gridWidth={12}>
                    <ContentCard heading="Tooltips">
                        <Tooltip text="Asynchronous JavaScript And XML" position="top">
                            {(setAnchorRef) => (
                                <PrimaryButton ref={setAnchorRef}>AJAX (top)</PrimaryButton>
                            )}
                        </Tooltip>
                        <Tooltip text="Structured Query Language" position="right">
                            {(setAnchorRef) => (
                                <PrimaryButton ref={setAnchorRef}>SQL (right)</PrimaryButton>
                            )}
                        </Tooltip>
                        <Tooltip text="Cascading Style Sheets" position="bottom">
                            {(setAnchorRef) => (
                                <PrimaryButton ref={setAnchorRef}>CSS (bottom)</PrimaryButton>
                            )}
                        </Tooltip>
                        <Tooltip text="Employee Management Console" position="left">
                            {(setAnchorRef) => (
                                <PrimaryButton ref={setAnchorRef}>EMC (left)</PrimaryButton>
                            )}
                        </Tooltip>
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
