import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import AnimateTextUnderline from "./AnimateTextUnderline";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from './CardSideMenu.module.scss';

class CardSideMenu extends Component {

    static propTypes = {
        links: PropTypes.array.isRequired,
    };

    /**
     * Get a list of class names to be used in styling the link and underline components
     *
     * @return {object} An object of class names
     */
    getClassLists () {
        const linkClass = classNames({
            [styles.menuLink]: true,
            [styles.unbranded]: true,
        });

        const activeLinkClass = classNames({
            [styles.active]: true,
        });

        const underlineComponentClass = {
            underline: styles.underline,
            underlineFill: styles.underlineFill,
        };

        return {
            linkClass,
            activeLinkClass,
            underlineComponentClass,
        };
    }

    /**
     * Render a link
     *
     * @return {ReactElement} The link
     */
    renderLink = () => {
        const { links } = this.props;
        const { linkClass, activeLinkClass, underlineComponentClass } = this.getClassLists();

        const singleLinks = links.map((item) => {
            return (
                <NavLink
                    exact to={item.to}
                    key={item.label}
                    className={linkClass}
                    activeClassName={activeLinkClass}
                >
                    <AnimateTextUnderline classList={underlineComponentClass} accentColour={'#fff'}>
                        <div className={styles.underlineText}>{item.label}</div>
                    </AnimateTextUnderline>
                </NavLink>
            );
        });

        return links ? singleLinks : null;
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        return (
            <div className={styles.cardSideMenu}>
                <div className={styles.linkContainer}>
                    {this.renderLink()}
                </div>
            </div>
        );
    }

}

export default CardSideMenu;
