import React from 'react';
import PropTypes from 'prop-types';
import appState from '../../state/App';
import axios from 'axios';
import api from '../../lib/api';
import { paths } from "../../lib";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import {
    CheckboxInputRow,
    DefaultForm,
    PrimaryButton,
    SecondaryButton,
    TextInputRow,
    TimeInputRow,
    SelectInputRow,
} from '@dataplan/react-components/dist/components/forms';
import _ from "lodash";
import { generatePath, withRouter } from 'react-router-dom';
import classNames from "classnames";
import { defaultAccentColour } from '../../Colours';

import styles from './assets/AddPayroll.module.scss';

class AddPayroll extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);
        const company = this.getCurrentCompany();

        this.state = {
            company,
            name: {
                hasChanged: false,
                valid: false,
                value: '',
            },
            frequency: {
                value: 'Monthly',
            },
            releaseTime: {
                defaultValue: '09:00',
                valid: true,
                value: '09:00',
            },
            fileTypes: {
                hasChanged: false,
                valid: false,
                value: null,
            },
        };
    }

    /**
     * Called when the component first renders
     *
     * @return {void}
     */
    componentDidMount = () => {
        this.getFileTypes();
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Called once the component has loaded, queries the API for available file types
     *
     * @return {void}
     */
    getFileTypes = () => {
        api.get('/payroll/file_types', {
            component: this,
        })
            .then(({ data }) => {
                // Format response data into flat array and add 'active' property to each file type
                const value = Object.values(data).reduce((acc, array) => {
                    array.forEach((fileType) => {
                        fileType.active = false;
                    });
                    acc.push(...array);
                    return acc;
                }, []);

                this.setState({
                    fileTypes: {
                        hasChanged: false,
                        valid: false,
                        value,
                    },
                }, () => {
                    // If there is only one file type, select it
                    if (value.length === 1) {
                        this.handleFileTypeInputChange(value[0].id);
                    }
                });
            })
            .catch((error) => {
                // Will catch if axios request is canceled
                if (axios.isCancel(error)) {
                    return null;
                }

                // Will catch if there are no file types returned
                if (error.response.status === 403) {
                    this.setState({
                        fileTypes: {
                            hasChanged: true,
                            valid: true,
                            value: [],
                        },
                    });
                }

                throw error;
            });
    }

    /**
     * Gets the current company from the url
     *
     * @return {void}
     */
    getCurrentCompany = () => {
        const { companies } = this.props.appState;
        const companyIdFromURL = _.toNumber(this.props.match.params.company_id);

        const company = _.find(companies, {id: companyIdFromURL});

        return company;
    }

    /**
     * Handler for the standard input change events
     *
     * @param {string} field The field that has been changed
     * @param {string} value The input value
     *
     * @return {void}
     */
    handleInputChange = (field, value) => {
        this.setState((prevState) => {
            return {
                [field]: {
                    ...prevState[field],
                    hasChanged: true,
                    valid: this.checkValidity(field, value),
                    value,
                },
            };
        });
    }

    /**
     * Checks given value of a field is valid
     *
     * @param {string} field The field
     * @param {string} value The value
     * @return {boolean} The validity
     */
    checkValidity (field, value) {
        let valid = true;

        if (field === 'name') {
            valid = value.length > 0;
        } else if (field === 'releaseTime') {
            valid = this.checkTimeFormatValidity(value);
        }

        return valid;
    }

    /**
     * Checks given value of time is valid
     *
     * @param {string} value The value
     * @return {boolean} The validity
     */
    checkTimeFormatValidity (value) {
        const validFormat = (/^\d\d:\d\d$/).test(value);
        let validRange = false;

        if (validFormat) {
            const hour = Number(value.slice(0, 2));
            const minute = Number(value.slice(3, 5));
            const hourValid = (hour >= 0 && hour <= 23);
            const minuteValid = (minute === 0) ? true : null;
            validRange = (hourValid && minuteValid);
        }

        return (validRange);
    }

    /**
     * Posts new payroll to api
     */
    postPayroll = () => {
        const { history, match } = this.props;

        const {
            company,
            fileTypes,
            frequency,
            name,
            releaseTime,
        } = this.state;

        // Format time into integer of the selected hour
        const epayNotifTime = parseInt(releaseTime.value.slice(0, 2), 10);

        // Format file types array into an array of ids
        const fileTypesArr = fileTypes.value.reduce((acc, fileType) => {
            if (fileType.active) {
                acc.push(fileType.id);
            }

            return acc;
        }, []);

        api.post('/payroll', {
            company_id: company.id, // eslint-disable-line camelcase
            name: name.value,
            frequency: frequency.value,
            epay_notif_time: epayNotifTime, // eslint-disable-line camelcase
            file_types: fileTypesArr, // eslint-disable-line camelcase
        })
            .then(() => {
                appState.addNotification({
                    text: "Payroll has been posted",
                    type: "success",
                    duration: 5,
                }, () => {
                    // eslint-disable-next-line camelcase
                    history.push(generatePath(paths.singleCompanyPayrolls, match.params));
                });
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to post payroll`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Renders the name input section
     *
     * @return {ReactElement} The name section
     */
    renderNameInput = () => {
        const { hasChanged, valid, value } = this.state.name;
        const classList = classNames({
            [styles.error]: (!valid && hasChanged),
        });
        let nameError = (!valid && hasChanged) ? "Name is required" : "";

        return (
            <TextInputRow
                name="name"
                label="Name"
                type="text"
                onChange={(event) => this.handleInputChange('name', event.target.value)}
                value={value}
                ref={this.nameInput}
                inputClassName={classList}
                errorText={nameError}
                placeholder="My payroll name"
            />
        );
    }

    /**
     * Renders the frequency input section
     *
     * @return {ReactElement} The frequency input section
     */
    renderFrequencyInput = () => {
        const values = [
            'Monthly',
            'Weekly',
            'Fortnightly',
            '4 Weekly',
        ];

        const options = values.map((value) => {
            return (
                <option key={value} value={value}>{value}</option>
            );
        });

        return (
            <SelectInputRow
                key={'Frequency'}
                name={'Frequency'}
                label={'Frequency'}
                onChange={(event) => this.handleInputChange('frequency', event.target.value)}
                value={this.state.frequency.value}
                placeholder="Select"
            >
                {options}
            </SelectInputRow>
        );
    }

    /**
     * Renders the releaseTime input section
     *
     * @return {ReactElement} The releaseTime section
     */
    renderReleaseTimeInput = () => {
        const { defaultValue, hasChanged, valid, value } = this.state.releaseTime;
        const classList = classNames({
            [styles.error]: (!valid && hasChanged),
        });
        let releaseTimeError = "";

        if (valid === null) {
            releaseTimeError = "Payslip Release Time is required to be rounded to a whole hour";
        } else if (!valid && hasChanged) {
            releaseTimeError = "Payslip Release Time is required";
        }

        return (
            <TimeInputRow
                name="Payslip Release Time"
                label="Payslip Release Time"
                onChange={(event) => this.handleInputChange('releaseTime', event.target.value)}
                onBlur={(event) => {
                    const isValid = this.checkTimeFormatValidity(event.target.value);

                    if (!isValid && isValid !== null) {
                        this.handleInputChange('releaseTime', defaultValue);
                    }
                }}
                ref={this.releaseTimeInput}
                inputClassName={classList}
                errorText={releaseTimeError}
                hideSpinner={true}
                value={value}
            />
        );
    }

    /**
     * Renders the file types input section
     *
     * @return {ReactElement} The file types input section
     */
    renderFileTypeInputs = () => {
        const { fileTypes } = this.state;

        if (!fileTypes.value) {
            return <LoadingSpinner className={styles.loadingSpinner} label="Loading file types" />;
        } else if (fileTypes.value.length <= 1) {
            return null;
        }

        let error = (!fileTypes.valid && fileTypes.hasChanged) ? "File type is required" : "";
        const options = fileTypes.value.map((fileType) => {
            return {
                name: fileType.id,
                value: fileType.id,
                label: fileType.name,
            };
        });

        return (
            <CheckboxInputRow
                backgroundColour={defaultAccentColour}
                errorText={error}
                inputClassName={styles.fileTypesInput}
                label="File Types"
                large
                onChange={(event) => this.handleFileTypeInputChange(parseInt(event.target.value, 10))}
                options={options}
            />
        );
    }

    /**
     * Toggles whether a file type is selected
     *
     * @param {number} id The file type id to toggle
     *
     * @return {void}
     */
    handleFileTypeInputChange = (id) => {
        const { fileTypes } = this.state;
        const selected = fileTypes.value.find((fileType) => fileType.id === id);

        selected.active = !selected.active;
        fileTypes.hasChanged = true;
        fileTypes.valid = fileTypes.value.some((fileType) => fileType.active);

        this.setState({
            fileTypes,
        });
    }

    /**
     * Render the form buttons
     *
     * @return {ReactElement} The buttons
     */
    renderButtons = () => {
        const { history, match } = this.props;

        const {
            fileTypes,
            name,
            releaseTime,
        } = this.state;

        const onClick = this.postPayroll;
        const valid = (fileTypes.valid && name.valid && releaseTime.valid);
        const hasChanged = name.hasChanged;
        // eslint-disable-next-line camelcase
        const cancelAction = () => history.push(generatePath(paths.singleCompanyPayrolls, match.params));
        const buttonText = (hasChanged) ? "Cancel" : "Close";

        return (
            <div className={styles.inputRow} >
                <PrimaryButton onClick={onClick} disabled={!valid}>
                    Add
                </PrimaryButton>
                <SecondaryButton
                    className={styles.secondaryButton}
                    onClick={cancelAction}
                    accent={defaultAccentColour}
                    text={buttonText}
                    aria-label={buttonText}
                />
            </div>
        );
    }

    /**
     * Render the component
     *
     * @return {ReactElement} The component
     */
    render = () => {
        const { company } = this.state;

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    heading={{
                        text: `${company.name} / Add Payroll`,
                        size: "h1",
                    }}
                    pageType="boxed"
                    maxWidth={this.props.appState.maxWidth}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                >
                    <DefaultForm>
                        {this.renderNameInput()}
                        {this.renderFrequencyInput()}
                        {this.renderReleaseTimeInput()}
                        {this.renderFileTypeInputs()}
                        {this.renderButtons()}
                    </DefaultForm>
                </PageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(AddPayroll));
