import React from 'react';
import PropTypes from 'prop-types';
import { ArrowDropDownIcon } from "@dataplan/react-components/dist/components/icons";
import _ from 'lodash';
import classNames from 'classnames';

import styles from './SetupSharedStyles.module.scss';

class AuthenticatorCodeDropdown extends React.Component {

    static propTypes = {
        code: PropTypes.string,
    }

    static defaultProps = {
        code: "",
    }

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    /**
     * Toggles / sets if the accordion is open
     *
     * @param {boolean} setOpen Optional argument to set open or not
     */
    toggleOpen = (setOpen) => {
        this.setState((prevState) => {
            const open = (_.isBoolean(setOpen))
                ? setOpen
                : !prevState.open;

            return {
                open,
            };
        });
    }

    /**
     * Renders the code
     *
     * @return {ReactElement} The authentication code
     */
    renderCode () {
        return (
            <>
                <div className={styles.header}>
                    Enter this setup key into your authentication app
                </div>
                <div className={styles.code}>
                    {this.props.code}
                </div>
            </>
        );
    }

    /**
     * Renders the code dropdown
     *
     * @return {ReactElement} The code dropdown
     */
    render () {
        const classList = classNames(styles.icon, {
            [styles.open]: (this.state.open),
        });

        return (
            <>
                <div onClick={this.toggleOpen} className={styles.dropdownHeader}>
                    <ArrowDropDownIcon className={classList} />
                    Having trouble scanning your code?
                </div>
                {this.state.open && this.renderCode()}
            </>
        );
    }

}

export default AuthenticatorCodeDropdown;
