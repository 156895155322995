import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { paths } from "../../../lib";

import { EditAnnouncementContext } from "../";
import { DatedPageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { PrimaryButton, SecondaryButton } from '@dataplan/react-components/dist/components/forms';
import PageLayoutContext from '@dataplan/react-components/dist/components/ui/page_layout/PageLayoutContext';
import styles from "./AnnouncementAdd.module.scss";

import { defaultAccentColour } from '../../../Colours';

class AnnouncementEditPreview extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        context: PropTypes.object.isRequired,
    }

    /**
     * Render the buttons at the bottom of the page
     * Changes depending on the state of parent component
     * @param {object} context pageLayout context
     *
     * @return {ReactElement} A collection of buttons
     */
    renderButtons = (context) => {
        const { history } = this.props;
        const cancelAction = () => history.push(paths.announcements);

        return (
            <div>
                <PrimaryButton
                    onClick={(event) => {
                        event.preventDefault();
                        context.setCurrentTab(1);
                    }}
                >
                    Edit
                </PrimaryButton>
                <SecondaryButton
                    className={styles.secondaryButton}
                    onClick={cancelAction}
                    accent={defaultAccentColour}
                    text={"Close"}
                    aria-label={"Close"}
                />
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { announcement } = this.props.context;

        return (
            <>
                <DatedPageLayout
                    maxWidth={'800px'}
                    pageType={null}
                    text={announcement.subject}
                    date={announcement.datestart}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: true,
                        hasGutter: true,
                        hasTopShadow: true,
                    }}
                >
                    <div className={styles.message} >
                        {announcement.message}
                    </div>
                </DatedPageLayout>
                <PageLayoutContext.Consumer>
                    {(context) => (
                        this.renderButtons(context)
                    )}
                </PageLayoutContext.Consumer>
            </>
        );
    }

}

/* eslint-disable react/no-multi-comp */
// https://github.com/yannickcr/eslint-plugin-react/issues/2172
const component = React.forwardRef((props, ref) => (
    <EditAnnouncementContext.Consumer>
        {(context) => (
            <AnnouncementEditPreview forwardedRef={ref} context={context} {...props} />
        )}
    </EditAnnouncementContext.Consumer>
));

export default withRouter(component);
/* eslint-enable */
