import api from '../api';
import appState from '../../state/App';
import noEmployeesSelected from './noEmployeesSelected';
import { StringHelper } from '../StringHelper';

/**
 * Handles resetting a single account (quick action)
 *
 * @param {number} employeeId The employees ID
 * @param {?object} employeeData The employees data for a single action to customise response
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 *
 * @return {void}
 */
const handleSingleReset = (employeeId, employeeData, redrawCallback) => {
    const employeeName = new StringHelper(employeeData.employeeName.text).parseName();

    api.put(`/employee/${employeeId}/reset`)
        .then(() => {
            appState.addNotification({
                text: `${employeeName}'s account reset`,
                type: "success",
                duration: 5,
            }, redrawCallback);
        }).catch(() => {
            appState.addNotification({
                text: `Failed to reset account for ${employeeName}`,
                type: "error",
                duration: 0,
            });
        });
};

/**
 * Handles resetting multiple accounts (bulk action)
 *
 * @param {number[]} employeeIds Array of employee IDs
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 *
 * @return {void}
 */
const handleMultipleReset = (employeeIds, redrawCallback) => {
    api.put(`/employees/bulk/reset`, {
        'employee_ids': employeeIds,
    }).then(({ data }) => {
        if (data.successful) {
            appState.addNotification({
                text: `${data.successful.length} accounts have been reset`,
                type: "success",
                duration: 5,
            }, redrawCallback);
        }

        if (data.failed) {
            appState.addNotification({
                text: `Failed to reset ${data.failed.length} accounts`,
                type: "error",
                duration: 0,
            });
        }
    }).catch((err) => {
        appState.addNotification({
            text: `Failed to reset accounts`,
            type: "error",
            duration: 0,
        });
    });
};

/**
 * Prompts the user to confirm resetting multiple accounts
 *
 * @param {number} employeeId The employees ID
 * @param {?object} employeeData The employees data for a single action to customise response
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 */
const confirmSingleReset = (employeeId, employeeData, redrawCallback, createSnackBarConfirmation) => {
    const employeeName = new StringHelper(employeeData.employeeName.text).parseName();

    createSnackBarConfirmation({
        type: "warn",
        heading: `${employeeName}'s account will be reset`,
        message: "Are you sure you want to reset this user account?",
        onConfirm: () => handleSingleReset(employeeId, employeeData, redrawCallback),
    });
};

/**
 * Prompts the user to confirm resetting a single account
 *
 * @param {number[]} employeeIds Array of employee IDs
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 */
const confirmMultipleReset = (employeeIds, redrawCallback, createSnackBarConfirmation) => {
    const selectedCount = employeeIds.length;

    createSnackBarConfirmation({
        type: "warn",
        heading: `Accounts for ${selectedCount} selected employees will be reset`,
        message: "Are you sure you want to reset the selected user accounts?",
        onConfirm: () => handleMultipleReset(employeeIds, redrawCallback),
    });
};

/**
 * Handles resetting an employees account
 *
 * @param {number[]} employeeIds Array of employee IDs
 * @param {?object} employeeData The employees data for a single action to customise response
 * @param {?function} redrawCallback The callback from the HOC to redraw page after change to data
 * @param {?function} createSnackBarConfirmation The callback from the HOC to create a snack bar
 *
 * @return {void}
 */
const handleResetEmployee = (
    employeeIds,
    employeeData = null,
    redrawCallback = null,
    createSnackBarConfirmation = null,
) => {
    if (employeeIds.length < 1) {
        noEmployeesSelected();

        return;
    }

    if (employeeIds.length === 1 && employeeData) {
        confirmSingleReset(employeeIds[0], employeeData, redrawCallback, createSnackBarConfirmation);
    } else {
        confirmMultipleReset(employeeIds, redrawCallback, createSnackBarConfirmation);
    }
};

export default handleResetEmployee;
