import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'utils/PrivateRoute';
import { paths } from "../../lib";

import {
    ViewEmployeeList,
} from './';
import { ViewEmployeeProfile } from "../Employees/index";

class Employees extends React.Component {

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <Switch>
                <PrivateRoute path={paths.employee} component={ViewEmployeeProfile} />
                <PrivateRoute path={paths.employees} component={ViewEmployeeList} />
            </Switch>
        );
    }

}

export default Employees;
