import React from 'react';
import PropTypes from 'prop-types';
import {
    generatePath,
    Link,
    Redirect,
    Route,
    Switch,
    withRouter,
} from 'react-router-dom';
import axios from "axios";
import api from 'lib/api';
import { paths } from "../../lib";
import appState from 'state/App';

import SingleMessageReply from "./routes/SingleMessageReply.js";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import { DatedPageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { PrimaryButton, SecondaryButton } from '@dataplan/react-components/dist/components/forms';
import Icon from "../../components/icons/Icon.js";
import Attachment from "lib/downloaders/Attachment";

import { defaultAccentColour } from '../../Colours';

import styles from "./assets/SingleMessage.module.scss";

class SingleMessage extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the message
     *
     * @param {object} props The component properties
     */
    constructor (props) {
        super(props);

        this.messageId = props.match.params.message_id;

        this.state = {
            message: null,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        this.getMessage();
    }

    /**
     * Called before the component will be unmounted
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Gets message from api
     *
     * @return {void}
     */
    getMessage = () => {
        axios.all([
            api.get(`/legacy_message/${this.messageId}`, {
                component: this,
            }).catch(() => {
                // Will catch if axios request is canceled
            }),
            api.get(`/legacy_message/${this.messageId}/attachments`, {
                component: this,
            }).catch(() => {
                // Will catch if axios request is canceled
            }),
        ]).then(axios.spread(({ data: message }, { data: attachments }) => {
            const { employees } = this.props.appState;
            const employee = employees.filter((value) => value.id === message.employee_id)[0];

            message.attachments = attachments;

            this.setState({
                employee,
                message,
            });
        }));
    }

    /**
     * Renders the From section
     *
     * @return {void}
     */
    renderFromSection = () => {
        const { employee, message } = this.state;
        const subheading = (message.recipient === "employee")
            ? "To"
            : "From";

        return (
            <div className={styles.section} >
                <span className={styles.subheading}>
                    {subheading}
                </span>
                <Link className={styles.messageLink} to={generatePath(paths.employee, message)}>
                    {employee.name}
                </Link>
            </div>
        );
    }

    /**
     * Renders the Message section
     *
     * @return {void}
     */
    renderMessageSection = () => {
        const { message } = this.state;

        return (
            <Switch>
                <Route exact path={paths.singleMessage} >
                    {this.renderViewMessage()}
                    {this.renderButtons()}
                </Route>
                <Route exact path={paths.singleMessageReply} >
                    <SingleMessageReply prevMessage={message} />
                </Route>
                <Route>
                    <Redirect
                        to={generatePath(paths.singleMessage, {
                            message_id: message.id, // eslint-disable-line camelcase
                        })}
                    />
                </Route>
            </Switch>
        );
    }

    /**
     * Renders the message
     *
     * @return {void}
     */
    renderViewMessage = () => {
        const { message } = this.state.message;

        return (
            <div className={styles.section} >
                <span className={styles.subheading} >Message</span>
                <p className={styles.content} >{message}</p>
            </div>
        );
    }

    /**
     * Renders the buttons
     *
     * @return {void}
     */
    renderButtons = () => {
        const { history } = this.props;
        const { message } = this.state;
        const canReply = (message.recipient === "company");
        // eslint-disable-next-line camelcase
        const replyAction = () => history.push(generatePath(paths.singleMessageReply, { message_id: message.id }));

        return (
            <div className={styles.buttons} >
                {canReply && (
                    <PrimaryButton
                        aria-label={"Reply"}
                        onClick={replyAction}
                        text={"Reply"}
                    />
                )}
                <SecondaryButton
                    accent={defaultAccentColour}
                    aria-label={"Back"}
                    className={styles.secondaryButton}
                    onClick={() => history.push(paths.messages)}
                    text={"Back"}
                />
            </div>
        );
    }

    /**
     * Renders the attachments
     *
     * @return {void}
     */
    renderAttachments = () => {
        const { attachments } = this.state.message;

        if (!attachments || !attachments.length) {
            return null;
        }

        return (
            <div className={styles.attachmentSection}>
                {attachments.map((attachment) => {
                    const attachmentHandler = new Attachment(attachment);

                    return (
                        <div key={attachment.id} className={styles.attachment}>
                            <Icon className={styles.icon} icon={"Attachment"} />
                            <button
                                type="button"
                                className={styles.downloadButton}
                                onClick={() => attachmentHandler.download()}
                            >
                                {attachment.filename}
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render = () => {
        const { message } = this.state;

        if (!message) {
            return <LoadingSpinner className={styles.loadingSpinner} label="Loading" />;
        }

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <DatedPageLayout
                    maxWidth={this.props.appState.maxWidth}
                    text={message.subject}
                    date={message.time_sent}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                >
                    {this.renderFromSection()}
                    {this.renderMessageSection()}
                    {this.renderAttachments()}
                </DatedPageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(SingleMessage));
