import React from 'react';
import PropTypes from 'prop-types';

import { CheckboxInput } from '@dataplan/react-components/dist/components/forms';
import { defaultAccentColour } from '../../../../Colours';

import styles from './FilterValue.module.scss';

export default class FilterValue extends React.Component {

    static propTypes = {
        filterKey: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onFilterSelect: PropTypes.func,
        checked: PropTypes.bool,
    }

    static defaultProps = {
        onFilterSelect: null,
        checked: false,
    }

    /**
     * Handle the user selecting a value from the filter
     *
     * @param {event} event The input change event
     *
     * @return {void}
     */
    handleFilterSelect = (event) => {
        const { filterKey, value } = this.props;

        this.props.onFilterSelect(value, filterKey, event.target.checked);
    }

    /**
     * Renders the filter value to the DOM
     *
     * @return {ReactElement} The filter value
     */
    render () {
        const { filterKey, value, checked } = this.props;

        return (
            <div className={styles.filterCheckBox}>
                <CheckboxInput
                    name={`${filterKey}-${value}`}
                    value={value}
                    label={value}
                    checked={checked}
                    submitOnEnter={false}
                    onChange={this.handleFilterSelect}
                    backgroundColour={defaultAccentColour}
                />
            </div>
        );
    }

}
