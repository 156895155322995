import React from 'react';
import PropTypes from 'prop-types';
import api from "lib/api";
import ReactTimeout from "react-timeout";

import createToasts from 'components/toasts/createToasts';
import { DocumentUploadForm } from 'pages/Documents';

class DocumentUpload extends React.Component {

    static propTypes = {
        drawer: PropTypes.object,
        employeeDetails: PropTypes.object.isRequired,
        documentType: PropTypes.oneOf([
            "personalcompany",
            "personal",
            "pension",
            "eP60",
            "P11d",
            "company",
        ]).isRequired,
        reLoadTableDocs: PropTypes.func,
        setTimeout: PropTypes.func.isRequired,
        visibilityOption: PropTypes.bool,
    }

    static defaultProps = {
        reLoadTableDocs: null,
        drawer: null,
        visibilityOption: true,
    }

    /**
     * Helper method to open the drawer
     *
     * @return {void} Changes state of drawer
     */
    closeDrawer = () => {
        const { drawer } = this.props;

        drawer.current.setState({
            visible: false,
        });
    }

    /**
     * Helper method to alter the portal loading state
     *
     * @return {void} Alters the loading state
     */
    uploading = () => {
        const { drawer } = this.props;

        drawer.current.setState((prevState) => {
            return {
                ...prevState,
                loading: !prevState.loading,
            };
        });
    }

    /**
     * Helper method to handle the success of a file upload.
     * Closes drawer, removes uploading state and displays a
     * success toast
     *
     * @return {void} Displays toast/Closes portal
     *
     */
    successHandler = () => {
        const { reLoadTableDocs, employeeDetails } = this.props;
        // eslint-disable-next-line camelcase
        const { forename_1, surname } = employeeDetails;
        reLoadTableDocs();
        this.closeDrawer();

        this.props.setTimeout(() => {
            this.uploading();
            createToasts({
                // eslint-disable-next-line camelcase
                message: `Document added for ${forename_1} ${surname}`,
            }, 'success');
        }, 1000);
    }

    /**
     * Helper method to show any errors in a toast notification
     *
     * @return {void} Cancels upload process and displays errors
     */
    catchHandler = () => {
        this.uploading();
        createToasts('Something went wrong');
    }

    /**
     * Handles the saving of changes once validated
     *
     * @param {object} form The form data
     *
     * @return {void} submits the form if valid
     */
    handleSubmit = (form) => {
        const { employeeDetails, documentType } = this.props;

        let data = new FormData(form);
        data.append('employee', employeeDetails.id);

        this.uploading();
        api.post(`/file/${documentType}`, data)
            .then(this.successHandler)
            .catch(this.catchHandler);
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <div aria-label="upload form">
                <DocumentUploadForm
                    handleSubmit={this.handleSubmit}
                    closeDrawer={this.closeDrawer}
                    visibilityOption={this.props.visibilityOption}
                />
            </div>
        );
    }

}

export default ReactTimeout(DocumentUpload); // eslint-disable-line new-cap
