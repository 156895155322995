import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './DefaultButton.scss';

class DefaultButton extends React.Component {

    static propTypes = {
        children: PropTypes.string.isRequired,
        className: PropTypes.string,
        forwardedRef: PropTypes.any,
    }

    static defaultProps = {
        className: null,
        forwardedRef: null,
    }

    /**
     * Renders the button
     *
     * @return {ReactElement} The button
     */
    render () {
        const { forwardedRef, className, ...otherProps } = this.props;

        return (
            <button
                type="button"
                ref={forwardedRef}
                className={classNames(["default-button", className])}
                {...otherProps}
            >
                <span>{this.props.children}</span>
            </button>
        );
    }

}

// eslint-disable-next-line react/no-multi-comp
export default React.forwardRef((props, ref) => {
    return <DefaultButton forwardedRef={ref} {...props} />;
});
