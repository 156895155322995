import React from 'react';

import ActionsSection from './ActionsSection';
import PayrollStatsSection from './PayrollStatsSection';
import PayRunsSection from './PayRunsSection';
import UploadSection from './UploadSection';
import DashboardSection from './DashboardSection';
import appState from '../../../state/App';
import { getPayrollName, getFullPayrollName } from '../../../lib/companyUtils';
import moment from 'moment';
import api from '../../../lib/api';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

import styles from './PayrollDashboard.module.scss';

class PayrollDashboard extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Creates an instance of the dashboard content
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            actions: [],
            payRuns: [],
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleApiCall();
    }

    /**
     * Invoked before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
      * Handles the state change once the api has returned the data
      *
      * @param {string} key The key of the data we're setting
      * @param {object} apiResponse The response object from the API
      *
      * @return {void}
      */
    handleStateChange (key, apiResponse) {
        let responseData = [];

        apiResponse.forEach((response) => {
            responseData.push(response.data);
        });

        this.setState({
            [key]: responseData.reduce((a, b) => a.concat(b), []),
        });
    }

    /**
     * The api promise for the pay runs data
     *
     * @param {array} payrollIds An array of the payroll Ids to use
     *
     * @return {Promise} The promise of pay runs
     */
    getPayRuns (payrollIds) {
        const start = moment();
        const end = moment().subtract(45, 'days');

        return payrollIds.map((payrollId) => {
            return api.get(`/payroll/${payrollId}/payruns`, {
                component: this,
                params: {
                    start_date: start.format("DD-MM-Y"), /* eslint-disable-line camelcase */
                    end_date: end.format("DD-MM-Y"), /* eslint-disable-line camelcase */
                    include_future: true, /* eslint-disable-line camelcase */
                },
            });
        });
    }

    /**
     * Called once the component has loaded
     *
     * @return {void}
     */
    handleApiCall = () => {
        let payrollIds = [];

        this.props.appState.payrolls.forEach((payroll) => {
            payrollIds.push(payroll.id);
        });

        // Run payruns query first as this takes less time to resolve
        axios.all(this.getPayRuns(payrollIds))
            .then((payRuns) => {
                this.handleStateChange('payRuns', payRuns);
            })
            .catch(() => {
                // Will catch if axios request is canceled
            });
    }

    /**
     * Renders the actions section
     *
     * @return {ReactElement} The section content
     */
    renderActionsSection () {
        if (this.state.actions.length === 0) {
            return null;
        }

        return (
            <DashboardSection heading="Actions">
                <ActionsSection actions={this.state.actions} />
            </DashboardSection>
        );
    }

    /**
     * Renders the stats and upload sections
     *
     * @return {ReactElement} The section content
     */
    renderStatsAndUploadSection () {
        const { employees } = this.props.appState;
        const classList = classNames(styles.statsUploadSection, {
            [styles.noActions]: (this.state.actions.length < 1),
        });

        return (
            <div className={classList}>
                <DashboardSection heading="Employees" className={styles.statsSection}>
                    <AnimationContainer
                        animationStyle="animationContainer"
                        appearTimeout={400}
                        enterTimeout={1000}
                        exitTimeout={100}
                    >
                        <PayrollStatsSection employees={employees} />
                    </AnimationContainer>
                </DashboardSection>
                <DashboardSection heading="Upload Pay Data" className={styles.uploadSection}>
                    <AnimationContainer
                        animationStyle="animationContainerUpload"
                        appearTimeout={800}
                        enterTimeout={1000}
                        exitTimeout={100}
                    >
                        <UploadSection />
                    </AnimationContainer>
                </DashboardSection>
            </div>
        );
    }

    /**
     * Helper function to map out the pay run details for each period
     *
     * @param {array} payRuns Pay run information for a given payroll
     * @return {array} The pay run details
     */
    payRunDetails (payRuns) {
        const { payrolls, companies } = this.props.appState;

        return payRuns.reduce((collection, payRun) => {
            let payDates = payRun.paydates;
            let period = payRun.period;

            if (payDates.length) {
                let payrollName = getPayrollName(period.payroll_id, payrolls, companies);
                let payrollFullName = getFullPayrollName(period.payroll_id, payrolls, companies);

                let max = payDates.reduce((prev, current) => {
                    return (prev.total > current.total) ? prev : current;
                });

                let totalPayslips = payDates.reduce((sum, payslip) => {
                    return sum + payslip.total;
                }, 0);

                collection.push({
                    payDate: max.paydate,
                    paySlipTotal: totalPayslips,
                    period,
                    payrollName,
                    payrollFullName,
                });
            }

            return collection;
        }, []);
    }

    /**
     * Renders the pay runs section
     *
     * @return {ReactElement} The section content
     */
    renderPayRunsSection () {
        if (this.state.payRuns.length === 0) {
            return null;
        }

        const payRuns = this.payRunDetails(this.state.payRuns);

        return (
            <DashboardSection heading="Recent Pay Runs">
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={1000}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    <PayRunsSection payRuns={payRuns} />
                </AnimationContainer>
            </DashboardSection>
        );
    }

    /**
     * Renders the dashboard for a payroll
     *
     * @return {ReactElement} The page content
     */
    render () {
        return (
            <div>
                {this.renderActionsSection()}
                {this.renderStatsAndUploadSection()}
                {this.renderPayRunsSection()}
            </div>
        );
    }

}

export default appState.attachState(PayrollDashboard);
