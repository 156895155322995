import React from 'react';
import PropTypes from 'prop-types';

import styles from './Action.module.scss';

export default class Action extends React.Component {

    static propTypes = {
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
        handleCloseMenu: PropTypes.func,
    }

    static defaultProps = {
        handleCloseMenu: null,
    }

    /**
     * Click handle for the action button.
     *
     * @param {ClickEvent} event The click event
     *
     * @return {void}
     */
    handleClick = (event) => {
        event.preventDefault();

        if (this.props.handleCloseMenu) {
            this.props.handleCloseMenu();
        }

        this.props.action();
    }

    /**
     * Renders the action button
     *
     * @return {ReactElement} The button element
     */
    render () {
        return (
            <button
                type="button"
                onClick={this.handleClick}
                className={styles.action}
                tabIndex="1"
            >
                {this.props.label}
            </button>
        );
    }

}
