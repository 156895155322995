import React from 'react';
import PropTypes from 'prop-types';
import api from 'lib/api';
import axios from 'axios';
import appState from 'state/App';
import { withRouter } from 'react-router-dom';
import { paths } from "../../lib";
import _ from 'lodash';

import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { AddCompanyForm } from '.';

class CompaniesAdd extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    }

    /**
     * instace of companiesAdd
     *
     * @param {object} props The component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            logo: "",
        };
    }

    /**
     * Handles the submission of the adding a new company form
     *
     * @param {object} name company name
     * @param {object} logo companyLogo
     * @param {object} address companyAddress
     *
     * @return {void}
     */
    handleSubmit = (name, logo, address) => {
        const { history } = this.props;
        const apiRequests = [];
        const addLogo = logo.value ? (response) => this.handleFileUpload(response) : _.noop;

        this.setState({
            logo,
        });

        apiRequests.push(api.post('/company/add', {
            name: name.value,
            address1: address.address1.value,
            address2: address.address2.value,
            address3: address.address3.value,
            town: address.town.value,
            postcode: address.postcode.value,
        }).then(addLogo));

        axios.all(apiRequests)
            .then(() => {
                appState.unblockNavigation();
                appState.addNotification({
                    text: `Company successfully added`,
                    type: "success",
                    duration: 5,
                }, () => {
                    history.push(paths.companies);
                });
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to add company`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Handles the logo upload
     *
     * @param {object} response response from the push request
     *
     * @return {PostRequest} posts the updated logo to the relevant company
     */
    handleFileUpload = (response) => {
        const formData = new FormData();
        formData.append('logo', this.state.logo.value);
        return api.post(`/branding/company/${response.data.id}/update_logo`, formData);
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    pageType="boxed"
                    maxWidth={this.props.appState.maxWidth}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                    heading={{
                        text: "Add a company",
                        size: "h1",
                    }}
                >
                    <AddCompanyForm
                        handleSubmit={this.handleSubmit}
                    />
                </PageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(CompaniesAdd));
