import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { paths } from "../../lib";
import EntryPage from './EntryPage';
import ComponentGuide from './ComponentGuide';
import PageLayoutGuide from './PageLayoutGuide';
import ToastDemo from './ToastDemo';

import './Styleguide.scss';

export default class Styleguide extends React.Component {

    /**
     * Renders the styleguide page
     *
     * @return {ReactElement} The page component
     */
    render () {
        return (
            <Switch>
                <Route path={paths.styleguideComponents} component={ComponentGuide} />
                <Route path={paths.styleguideLayout} component={PageLayoutGuide} />
                <Route path={paths.styleguideToastDemo} component={ToastDemo} />
                <Route path={paths.styleguide} component={EntryPage} />
            </Switch>
        );
    }

}
