import React from 'react';
import styles from "./ArrowForward.module.scss";
import { FullArrow } from "@dataplan/react-components/dist/components/icons";

export default class ArrowForward extends React.Component {

    /**
     * Renders the icon component
     *
     * @return {ReactElement} The icon element
     */
    render () {
        return (
            <FullArrow width={24} height={24} classlist={styles.forwardArrow} {...this.props} />
        );
    }

}
