import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dataTableSort from '../../../lib/dataTableSort';
import dataSetSearch from '../../../lib/dataSetSearch';
import dataSetFilter from '../../../lib/dataSetFilter';

import styles from './DataTable.module.scss';
import {
    Row,
    Column,
    DataTableHeader,
    DataTableRow,
    DataTableSearch,
    DataTableFilter,
    DataTableBulkActions,
    TableBody,
    Icon,
    SnackBar,
} from '../../';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import { DataTableContext } from "@dataplan/react-components/dist/components/ui/page_layout";
import { Drawer } from '@dataplan/react-components/dist/components/ui/drawer';

export default class DataTable extends React.Component {

    static propTypes = {
        colOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
        data: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
        ]).isRequired,
        dataLoaded: PropTypes.bool,
        headings: PropTypes.object,
        hidden: PropTypes.object,
        showCheckBoxes: PropTypes.bool,
        searchable: PropTypes.bool,
        sortable: PropTypes.bool,
        actions: PropTypes.object,
        bulkActions: PropTypes.object,
        filterable: PropTypes.object,
        emptySearch: PropTypes.string,
        rowHeight: PropTypes.string,
        advancedSearch: PropTypes.bool,
        advancedSearchComponent: PropTypes.func,
    }

    static defaultProps = {
        dataLoaded: null,
        headings: {},
        hidden: {},
        showCheckBoxes: false,
        searchable: false,
        sortable: false,
        actions: null,
        bulkActions: {},
        filterable: {},
        emptySearch: "results",
        rowHeight: "70px",
        advancedSearch: false,
        advancedSearchComponent: null,
    }

    /**
     * Creates an instance of the data table
     *
     * This is the single source of truth (SSoT) for the state of the data table,
     * unless it's required the SSOT is set in a HOC.
     * The exception is data, which is set in the component calling the data table.
     *
     * @param {object} props The data table properties
     */
    constructor (props) {
        super(props);

        this.nativeDrawer = React.createRef();

        this.state = {
            data: [],
            queryString: '',
            tableSort: {},
            checkedRows: {},
            allChecked: false,
            filtersSelected: {},
            // This is used by within a setState method so ESLint is displaying this as a false-positive.
            // eslint-disable-next-line react/no-unused-state
            visibleRows: [],
            snackBarConfig: null,
        };
    }

    /**
     * Invoked immediately after the component mounts
     * Populates the table, used if the table has previously been
     * populated but unmounted (tabbed pages)
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleComponentUpdate(this.props.data);
    }

    /**
     * Invoked immediately after updating occurs
     * Used to populate the table once the API call has been returned
     *
     * @param {object} prevProps The components previous properties
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        if (this.props.data !== prevProps.data) {
            this.handleComponentUpdate(this.props.data);
        }
    }

    /**
     * Called if/when the components data prop is changed
     * Sets defaults for sorting and checkboxes
     *
     * @param {object} data The data to be rendered in the table
     *
     * @return {void}
     */
    handleComponentUpdate = (data) => {
        if (this.props.sortable && !_.isEmpty(this.props.headings)) {
            this.setSortDefaults(this.props.headings, data);
        } else {
            this.setState({
                data,
            });
        }

        if (this.props.showCheckBoxes) {
            this.setCheckboxDefaults(data);
        }
    }

    /**
     * Helper method to get the drawer top action
     *
     * @return {Object} The drawer top action
     */
    getTopAction = () => {
        return {
            icon: <Icon icon="ArrowBack" aria-label="ArrowBack" className={styles.icon} />,
            action: this.closeDrawer,
        };
    }

    /**
     * Helper method to get the drawer component config
     *
     * @return {ReactElement} The drawer component
     */
    getSearchDrawer () {
        const header = {
            size: 'h2',
            text: 'Advanced Search',
        };

        return (
            <Drawer
                targetNode={document.body}
                ref={this.nativeDrawer}
                header={header}
                loadingLabel="Uploading..."
                onClickOutside={this.closeDrawer}
                topAction={this.getTopAction()}
            >
                {this.props.advancedSearchComponent ? this.props.advancedSearchComponent() : null}
            </Drawer>
        );
    }

    /**
     * Helper method to open the drawer
     *
     * @return {void} Changes state of drawer
     */
    openDrawer = () => {
        const { nativeDrawer } = this;

        if (this.props.advancedSearch) {
            window.scrollTo(0, 0);
        }

        nativeDrawer.current.setState({
            visible: true,
        });
    }

    /**
     * Helper method to open the drawer
     *
     * @return {void} Changes state of drawer
     */
    closeDrawer = () => {
        const { nativeDrawer } = this;

        nativeDrawer.current.setState({
            visible: false,
        });
    }

    /**
     * Sets the default status of the checkboxes to not be checked in state
     *
     * @param {object} data The data to be rendered in the table
     *
     * @return {void}
     */
    setCheckboxDefaults = (data) => {
        this.setState((prevState) => {
            let initialcheckedRows = {};

            _.forEach(data, (row, key) => {
                let rowKey = row.id || key;
                initialcheckedRows[rowKey] = false;
            });

            return {
                checkedRows: initialcheckedRows,
            };
        }, () => this.setVisibleRows());
    }

    /**
     * Sets the default sort for the table (1st column)
     * Only called if sortable prop is set to true
     *
     * @param {object} headings The headings for the table
     * @param {object} data The data to be rendered in the table
     *
     * @return {void}
     */
    setSortDefaults = (headings, data) => {
        let defaultSort = {};
        let i = 0;
        let sortedData = data;

        _.forEach(headings, (heading, key) => {
            defaultSort[key] = {
                order: (i === 0) ? "asc" : null,
                fieldName: key,
            };

            if (i === 0) {
                sortedData = dataTableSort(data, key, "asc");
            }

            i++;
        });

        this.setState({
            tableSort: defaultSort,
            data: sortedData,
        });
    }

    /**
     * Sets the table sort for a given column when that columns heading has been clicked
     * and sorts the provided data accordingly
     *
     * @param {Event} colHeader The column to sort
     */
    onSetSort = (colHeader) => {
        const svgCheck = (colHeader.target.nodeName === "svg")
            ? colHeader.target.parentElement.value
            : colHeader.target.parentElement.parentElement.value;

        const targetCol = (colHeader.target.type === "button") ? colHeader.target.value : svgCheck;
        const sortValues = ["asc", "desc"];

        this.setState((prevState) => {
            const currentSort = prevState.tableSort[targetCol].order;
            const fieldName = prevState.tableSort[targetCol].fieldName;
            const data = prevState.data;

            let currentIndex = _.findIndex(sortValues, (val) => {
                return val === currentSort;
            });
            const newSort = sortValues[(++currentIndex) % (sortValues.length)];

            const sortedData = dataTableSort(data, fieldName, newSort);

            return {
                data: sortedData,
                tableSort: {
                    ...prevState.tableSort,
                    [targetCol]: {
                        order: newSort,
                        fieldName: prevState.tableSort[targetCol].fieldName,
                    },
                },
            };
        });
    }

    /**
     * Handles users typing in the search input
     *
     * @param {Event} event The onChange event for the input
     *
     * @return {void}
     */
    handleSearchInput = (event) => {
        this.setState({
            queryString: event.target.value,
        });
    }

    /**
     * Handles user selecting a filter
     *
     * @param {string} value The filter value selected
     * @param {string} filterKey The filter the value was selected from
     * @param {boolean} checked If the checkbox is checked or un-checked
     */
    handleFilterSelect = (value, filterKey, checked) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                filtersSelected: {
                    ...prevState.filtersSelected,
                    [filterKey]: {
                        ...prevState.filtersSelected[filterKey],
                        [value]: checked,
                    },
                },
                allChecked: false,
            };
        }, () => this.setVisibleRows(true));
    }

    /**
     * Stores the visible rows (from ID) in the table
     *
     * @param {boolean} [uncheckHiddenRows=false] Flag for handling un-checking hidden rows
     *
     * @return {void}
     */
    setVisibleRows = (uncheckHiddenRows = false) => {
        this.setState(({ data }) => {
            let rowIds = [];

            let tableData = this.handleFilterSearchData(data);

            // Assign row IDs
            Object.keys(tableData).forEach((key) => {
                rowIds.push(tableData[key].id);
            });

            return {
                visibleRows: rowIds,
            };
        }, () => {
            if (uncheckHiddenRows) {
                this.uncheckHiddenRows();
            }
        });
    }

    /**
     * Sets hidden rows as un-checked
     *
     * @return {void}
     */
    uncheckHiddenRows = () => {
        this.setState(({checkedRows, visibleRows}) => {
            let checked = checkedRows;

            Object.keys(checkedRows).forEach((key) => {
                if (visibleRows.indexOf(Number(key)) < 0) {
                    checked[Number(key)] = false;
                }
            });

            return {
                checkedRows: checked,
            };
        });
    }

    /**
     * Handles user clicking the clear filter button
     *
     * @param {string} filterKey The filter to clear
     */
    handleFiltersClear = (filterKey) => {
        if (filterKey === "all") {
            this.setState({
                filtersSelected: {},
                allChecked: false,
            }, () => this.setVisibleRows());
        } else {
            this.setState((prevState) => {
                const prevFilterValues = prevState.filtersSelected;
                let reducedFilterSelection = _.reduce(prevFilterValues, (newSelection, value, key) => {
                    if (key !== filterKey) {
                        newSelection[key] = value;
                    }

                    return newSelection;
                }, {});

                return {
                    ...prevState,
                    allChecked: false,
                    filtersSelected: reducedFilterSelection,
                };
            }, () => this.setVisibleRows());
        }
    }

    /**
     * Manipulates the input table data to show only values matching
     * user search or filter selection
     *
     * @param {object} data The table data fed from the API into the data table
     *
     * @return {object} The filtered data
     */
    handleFilterSearchData = (data) => {
        let queriedData = data;
        const { queryString, filtersSelected } = this.state;
        const { searchable, filterable } = this.props;
        if (searchable && queryString !== '') {
            queriedData = _.filter(queriedData, (row) => {
                return dataSetSearch(row, queryString);
            });
        }

        if (filterable && !_.isEmpty(filtersSelected)) {
            queriedData = _.filter(queriedData, (row) => {
                return dataSetFilter(row, filtersSelected);
            });
        }

        return queriedData;
    }

    /**
     * Sets the snackbar config in state
     *
     * @param {object} snackBarConfig The snackbar config
     */
    setSnackBarConfig = (snackBarConfig) => {
        this.setState({
            snackBarConfig,
        });
    }

    /**
     * Renders the table rows to display
     *
     * @param {object} data The data to display in the table
     *
     * @return {array} An array of the rows to render
     */
    renderTableRows = (data) => {
        const {
            actions,
            colOrder,
            dataLoaded,
            hidden,
            rowHeight,
            showCheckBoxes,
        } = this.props;

        const tableData = this.handleFilterSearchData(data);

        if (actions) {
            actions.actions.forEach((action) => {
                if (action.getSnackBarConfig) {
                    action.callback = (key) => this.setSnackBarConfig(action.getSnackBarConfig(key));
                }
            });
        }

        const tableRows = _.map(tableData, (row, index) => {
            const rowKey = row.id || index;

            return (
                <DataTableRow
                    colOrder={colOrder}
                    id={rowKey}
                    key={rowKey}
                    dataRow={row}
                    hidden={hidden}
                    showCheckBoxes={showCheckBoxes}
                    checked={this.state.checkedRows[rowKey]}
                    onCheckRow={this.onCheckRow}
                    actions={actions}
                    rowHeight={rowHeight}
                />
            );
        });

        if (!dataLoaded && dataLoaded !== null) {
            return null;
        }

        if (tableRows.length < 1) {
            return this.renderNoResults();
        }

        return tableRows;
    }

    /**
     * Shows a notice if there are no results (either from the API request or search criteria)
     *
     * @return {ReactElement} The no results notice
     */
    renderNoResults = () => {
        let colSpan = Object.keys(this.props.headings).length || 1;

        if (this.props.showCheckBoxes) {
            colSpan++;
        }

        return (
            <tr>
                <td colSpan={colSpan}>
                    No {this.props.emptySearch} found
                </td>
            </tr>
        );
    }

    /**
     * Checks/unchecks all checkboxes if the checkbox in header has been selected
     *
     * @param {event} event When the header checkbox is checked
     *
     * @return {void}
     */
    onCheckAll = ({ target }) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                allChecked: (prevState.allChecked !== true),
                checkedRows: this.setAllChecked(prevState, target),
            };
        });
    }

    /**
     * Creates an object for the component state for handling select all
     *
     * @param {object} prevState The components previous state before update
     * @param {element} target The checkbox
     *
     * @return {object} The states of each rows checkbox
     */
    setAllChecked = ({ visibleRows, checkedRows }, target) => {
        let newState = {};

        Object.keys(checkedRows).forEach((value) => {
            // Set items as checked if they are visible.
            newState[value] = (visibleRows.indexOf(Number(value)) >= 0)
                ? target.checked
                : false;
        });

        return newState;
    }

    /**
     * Checks/unchecks a given checkbox when clicked (clears allChecked status)
     *
     * @param {Event} event When the row's checkbox is checked
     *
     * @return {void}
     */
    onCheckRow = (event) => {
        const target = event.target;

        this.setState((prevState) => {
            return {
                allChecked: false,
                checkedRows: {
                    ...prevState.checkedRows,
                    [target.value]: target.checked,
                },
            };
        });
    }

    /**
     * Returns the content for the table header
     *
     * @return {ReactElement} The table header component
     */
    getTableHeader = () => {
        const { allChecked, tableSort } = this.state;
        const {
            colOrder,
            headings,
            hidden,
            showCheckBoxes,
            sortable,
        } = this.props;

        if (_.isEmpty(headings)) {
            return null;
        }

        return (
            <DataTableHeader
                colOrder={colOrder}
                headings={headings}
                hidden={hidden}
                showCheckBoxes={showCheckBoxes}
                onCheckAll={this.onCheckAll}
                isChecked={allChecked}
                sortable={sortable}
                tableSort={tableSort}
                onSetSort={this.onSetSort}
            />
        );
    }

    /**
     * Renders loading spinner if data is loading
     *
     * @return {ReactElement} The loading spinner
     */
    renderDataLoading = () => {
        const { dataLoaded } = this.props;

        if (dataLoaded !== null && !dataLoaded) {
            return <LoadingSpinner label="Loading" className={styles.dataTableLoading} />;
        }

        return null;
    }

    /**
     * Renders the snackbar
     *
     * @return {ReactElement} The snackbar
     */
    renderSnackbar = () => {
        const { snackBarConfig } = this.state;

        if (!snackBarConfig) {
            return null;
        }

        return (
            <SnackBar
                type={snackBarConfig.type}
                heading={snackBarConfig.heading}
                message={snackBarConfig.message}
                onConfirm={() => this.setState({ snackBarConfig: null }, snackBarConfig.onConfirm)}
                onCancel={() => this.setState({ snackBarConfig: null })}
            />
        );
    }

    /**
     * Renders the search input row if content is searchable
     *
     * @return {ReactElement} The search input row
     */
    renderSearchRow = () => {
        const { queryString, checkedRows } = this.state;
        const { searchable, bulkActions, advancedSearch } = this.props;

        if (!searchable && !advancedSearch) {
            return null;
        }

        return (
            <Row>
                <Column>
                    { searchable
                        && (
                            <DataTableSearch
                                onSearchInput={this.handleSearchInput}
                                queryString={queryString}
                            />
                        )
                    }
                </Column>
                <Column align="right">
                    <div className={styles.actionsContainer}>
                        <DataTableBulkActions
                            checked={checkedRows}
                            actions={bulkActions}
                        />
                        { advancedSearch
                            && (
                                <button
                                    className={styles.advancedSearch}
                                    type="button"
                                    onClick={this.openDrawer}
                                >
                                    Advanced search
                                </button>
                            )
                        }
                    </div>
                </Column>
            </Row>
        );
    }

    /**
     * Renders a standard data table
     *
     * @return {ReactElement} The table element
     */
    render () {
        const { data, filtersSelected } = this.state;
        const { filterable } = this.props;

        return (
            <DataTableContext.Consumer>
                {(context) => (
                    <>
                        {this.renderSearchRow()}
                        { !_.isEmpty(filterable) && !_.isNull(context.filterRenderContainer.current)
                            && (
                                ReactDOM.createPortal(
                                    (
                                        <DataTableFilter
                                            data={data}
                                            filterable={filterable}
                                            filtersSelected={filtersSelected}
                                            onFilterSelect={this.handleFilterSelect}
                                            onFiltersClear={this.handleFiltersClear}
                                        />
                                    ), context.filterRenderContainer.current
                                )
                            )
                        }
                        <table className={styles.dataTable}>
                            {this.getTableHeader()}
                            <TableBody>
                                {this.renderTableRows(data)}
                            </TableBody>
                        </table>
                        {this.getSearchDrawer()}
                        {this.renderDataLoading()}
                        {this.renderSnackbar()}
                    </>
                )}
            </DataTableContext.Consumer>
        );
    }

}
