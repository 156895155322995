import React from 'react';
import PropTypes from "prop-types";
import Easing from 'easing-js';

export default class LeftToRightGradient extends React.Component {

    static propTypes = {
        animation: PropTypes.shape({
            stopColor: PropTypes.string.isRequired,
            startColor: PropTypes.string.isRequired,
            ease: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
        }).isRequired,
        offset: PropTypes.number.isRequired,
        gradientKey: PropTypes.string.isRequired,
    }

    /**
     * Creates an instance of the linear gradient
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.nativeGradient = React.createRef();
    }

    /**
     * Animates an SVG offset
     *
     * @param {number} timestamp The time the animation frame was triggered
     * @param {object} animation The animation details
     */
    animate (timestamp, animation) {
        let currentTime = (new Date()).getTime();

        let value = Easing[animation.ease](
            (currentTime - animation.startTime),
            animation.start,
            animation.end,
            animation.duration
        );

        if (this.nativeGradient.current === null) {
            return;
        }

        let stops = this.nativeGradient.current.getElementsByTagName('stop');

        for (let i = 0; i < stops.length; ++i) {
            stops[i].setAttribute('offset', `${Math.min(value, animation.end)}%`);
        }

        if (value <= 100 && value > -100){
            requestAnimationFrame(() => {
                this.animate(timestamp, animation);
            });
        }
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        const { offset, animation } = this.props;
        const startTime = (new Date()).getTime();
        const ease = animation.ease;
        const duration = animation.duration;

        requestAnimationFrame((timestamp) => {
            this.animate(timestamp, {
                startTime,
                start: 0,
                end: offset,
                ease,
                duration,
            });
        });
    }

    /**
     * Sets the native checkbox element reference. This is needed
     * so that we can submit the form when enter key is pressed
     *
     * @param {HTMLElement} element The native checkbox element
     *
     * @return {void}
     */
    setGradientRef = (element) => {
        this.nativeGradient = element;
    }

    /**
     * Renders the component inside an SVG
     *
     * @return {ReactElement} The linear gradient animation - left to right
     */
    render () {
        const { animation, gradientKey } = this.props;
        const stopColour = animation.stopColor;
        const startColour = animation.startColor;

        return (
            <linearGradient id={gradientKey} ref={this.nativeGradient}>
                <stop offset="0%" stopColor={startColour} />
                <stop offset="0%" stopColor={stopColour} />
            </linearGradient>
        );
    }

}
