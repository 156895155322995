import React from 'react';
import PropTypes from 'prop-types';

import './Badge.scss';

export default class Badge extends React.Component {

    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        theme: PropTypes.oneOf(["dark", "accent"]),
    }

    static defaultProps = {
        theme: "accent",
    }

    /**
     * Renders the badge
     *
     * @return {ReactElement} The badge
     */
    render () {
        return (
            <div className={`badge badge-${this.props.theme}`}>
                <div className="badge-value">{this.props.value}</div>
                <div className="badge-label">{this.props.label}</div>
            </div>
        );
    }

}
