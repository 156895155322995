import React from 'react';
import PropTpes from 'prop-types';

export default class DefaultForm extends React.Component {

    static propTypes = {
        action: PropTpes.string,
        method: PropTpes.oneOf(["get", "post"]),
        children: PropTpes.any,
        onSubmit: PropTpes.func,
    }

    static defaultProps = {
        action: "",
        method: "post",
        children: null,
        onSubmit: null,
    }

    /**
     * Default submit handler to prevent accidental page reloads
     *
     * @param {SubmitEvent} event The form submit event
     *
     * @return {void}
     */
    voidSubmitHandler = (event) => {
        event.preventDefault();
    }

    /**
     * Renders the form
     *
     * @return {ReactElement} The form component
     */
    render () {
        const submitHandler = this.props.onSubmit || this.voidSubmitHandler;

        return (
            <form
                action={this.props.action}
                method={this.props.method}
                encType="mulipart/form-data"
                onSubmit={submitHandler}
            >
                {this.props.children}
            </form>
        );
    }

}
