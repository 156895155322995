import React from 'react';
import DefaultForm from '../../../components/forms/DefaultForm';

class Devices extends React.Component {

    /**
     * Renders the Device tab
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <DefaultForm />
        );
    }

}

export default Devices;
