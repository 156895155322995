import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import styles from './PayrollStat.module.scss';

export default class PayrollStat extends React.Component {

    static propTypes = {
        onClick: PropTypes.func,
        term: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }

    static defaultProps = {
        onClick: null,
    }

    /**
     * Renders the stat container
     *
     * @return {ReactElement} The component
     */
    render () {
        const { term, value } = this.props;
        const containerClasses = classNames(styles.container, {
            [styles.isLink]: value !== "0",
        });
        const onClick = (value !== "0") ? () => this.props.onClick(term) : _.noop;

        return (
            <div tabIndex="0" className={containerClasses} onClick={onClick}>
                <dt className={styles.term}>{term}</dt>
                <dd className={styles.value}>{value}</dd>
            </div>
        );
    }

}
