import React from 'react';
import PropTypes from 'prop-types';

import './CardRow.scss';

export default class CardRow extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
    }

    /**
     * Renders the row of cards
     *
     * @return {ReactElement} The row element
     */
    render () {
        return (
            <div className="card-row">
                {this.props.children}
            </div>
        );
    }

}
