import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    Icon,
} from '../';

import styles from './DocumentPdfPagination.module.scss';
import sharedStyles from './DocumentPdfSharedStyles.module.scss';

class DocumentPdfPagination extends React.Component {

    static propTypes = {
        total: PropTypes.number.isRequired,
        defaultPage: PropTypes.number,
        maxButtons: PropTypes.number,
        onPageChange: PropTypes.func.isRequired,
    }

    static defaultProps = {
        defaultPage: 1,
        maxButtons: 5,
    }

    /**
     * Creates an instance of the page control
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            currentPage: props.defaultPage,
        };
    }

    /**
     * Updates the tracked page number and passes an event up to the parent
     *
     * @param {integer} newPage The new page number
     */
    setNewPage (newPage) {
        this.setState({ currentPage: newPage });
        this.props.onPageChange(newPage);
    }

    /**
     * Called when one of the number buttons is clicked.
     *
     * @param {integer} newPage The new page number
     *
     * @return {void}
     */
    handleNumberClick = (newPage) => {
        this.setNewPage(newPage);
    }

    /**
     * Handles the next button being clicked
     * Note that this will do nothing if there is no following page.
     *
     * @return {void}
     */
    handleNextClick = () => {
        if (this.state.currentPage < this.props.total) {
            this.setNewPage(this.state.currentPage + 1);
        }
    }

    /**
     * Handles the previous button being clicked
     * Note that this will do nothing if there is no previous page.
     *
     * @return {void}
     */
    handlePreviousClick = () => {
        if (this.state.currentPage > 1) {
            this.setNewPage(this.state.currentPage - 1);
        }
    }

    /**
     * Gets a list of all page buttons
     *
     * @return {array} The button list
     */
    getPageButtons = () => {
        let buttons = [];

        for (let page = 1; page <= this.props.total; ++page) {
            const classList = classNames(
                styles.paginationNumberButton,
                { [styles.current]: page === this.state.currentPage },
            );

            buttons.push((
                <button
                    type="button"
                    key={page}
                    className={classList}
                    onClick={() => this.handleNumberClick(page)}
                >
                    <span>{page}</span>
                </button>
            ));
        }

        return buttons;
    }

    /**
     * Renders the prev arrow button
     *
     * @return {ReactElement} The button
     */
    renderPrevArrow = () => {
        let classList = classNames(styles.arrowButton, [
            styles.left,
            (this.state.currentPage > 1)
                ? styles.enabled
                : styles.disabled,
        ]);

        return (
            <button
                type="button"
                className={classList}
                onClick={this.handlePreviousClick}
            >
                <Icon icon="ArrowBack" className={styles.paginationPreviousIcon} />
            </button>
        );
    }

    /**
     * Renders the next arrow button
     *
     * @return {ReactElement} The button
     */
    renderNextArrow = () => {
        let classList = classNames(styles.arrowButton, [
            styles.right,
            (this.state.currentPage < this.props.total)
                ? styles.enabled
                : styles.disabled,
        ]);

        return (
            <button
                type="button"
                className={classList}
                onClick={this.handleNextClick}
            >
                <Icon icon="ArrowForward" className={styles.paginationNextIcon} />
            </button>
        );
    }

    /**
     * Renders the page number navigation
     *
     * @return {ReactElement} The page number navigation
     */
    renderPageNumbers = () => {
        const buttons = this.getPageButtons();
        const classList = classNames([
            styles.pageButtons,
            sharedStyles.pageButtons,
        ]);

        const start = Math.max(0, this.state.currentPage - Math.ceil(this.props.maxButtons / 2));
        const visibleButtons = buttons.slice(start, start + this.props.maxButtons);

        return (
            <div className={classList}>
                {visibleButtons}
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        if (this.props.total <= 1) {
            return null;
        }

        return (
            <div>
                {this.renderPrevArrow()}
                {this.renderNextArrow()}
                {this.renderPageNumbers()}
            </div>
        );
    }

}

export default DocumentPdfPagination;
