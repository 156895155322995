import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';

import Pagination from '../../../components/Pagination';

export default class PaginationSection extends React.Component {

    /**
     * Creates an instance of the pagination container
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.defaultPage = 1;
        this.state = {
            currentPage: this.defaultPage,
        };
    }

    /**
     * Handles page number changes
     *
     * @param {integer} newPage The new page number
     *
     * @return {void}
     */
    handlePageChange = (newPage) => {
        this.setState({ currentPage: newPage });
    }

    /**
     * Renders the pagination styleguide section
     *
     * @return {ReactElement} The section element
     */
    render () {
        return (
            <CardRow>
                <CardRowContainer gridWidth={12}>
                    <ContentCard heading="Pagination">
                        <Pagination
                            total={12}
                            defaultPage={this.defaultPage}
                            onPageChange={this.handlePageChange}
                        />
                        <br />
                        <div>Current page: {this.state.currentPage}</div>
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
