import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './CardFrame.module.scss';

export default class CardFrame extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        theme: PropTypes.oneOf(["light", "dark", "accent"]),
        className: PropTypes.string,
        action: PropTypes.func,
    }

    static defaultProps = {
        children: null,
        theme: null,
        className: null,
        action: null,
    }

    /**
     * Renders the card frame
     *
     * @return {ReactElement} The container element
     */
    render () {
        const className = classNames([
            styles.frame,
            this.props.className,
        ], {
            [styles[this.props.theme]]: this.props.theme,
        });

        return (
            <div
                className={className}
                onClick={this.props.action}
            >
                {this.props.children}
            </div>
        );
    }

}
