import StateAccessor from './StateAccessor';
import PropTypes from 'prop-types';

class User extends StateAccessor {

    /**
     * Creates an instance of the app state container
     */
    constructor () {
        super("User");
    }

    /**
     * Gets the intial state
     *
     * @return {object} The state object
     */
    getInitialState () {
        return {
            loggedIn: false,
            username: null,
            forename: null,
            surname: null,
            mfa_totp: null, // eslint-disable-line camelcase
        };
    }

    /**
     * Gets the propTypes description of the state
     *
     * @return {object} The description
     */
    getPropTypes () {
        return {
            loggedIn: PropTypes.bool,
            username: PropTypes.string,
            forename: PropTypes.string,
            surname: PropTypes.string,
            mfa_totp: PropTypes.bool, // eslint-disable-line camelcase

        };
    }

    /**
     * Sets the current user details
     *
     * @param {string} username The display name of the user
     */
    setLoggedIn (username) {
        this.setState((prevState) => {
            return {
                ...prevState,
                loggedIn: true,
                username,
            };
        });
    }

    /**
     * Sets the name of the current user
     *
     * @param {string} forename Users forename
     * @param {string} surname Users surname
     */
    setName (forename, surname) {
        this.setState((prevState) => {
            return {
                ...prevState,
                forename,
                surname,
            };
        });
    }

    /**
     * Sets the status of the users mfa
     *
     * @param {bool} mfa whether mfa is enabled or disabled
     */
    setMFA (mfa) {
        this.setState((prevState) => {
            return {
                ...prevState,
                mfa_totp: mfa, // eslint-disable-line camelcase
            };
        });
    }

}

export default new User();
