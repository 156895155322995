import PropTypes from 'prop-types';

export const payslipPropTypes = PropTypes.shape({
    "id": PropTypes.number.isRequired,
    "period_number": PropTypes.number.isRequired,
    "net": PropTypes.number.isRequired,
    "gross": PropTypes.number.isRequired,
    "total_deductions": PropTypes.number.isRequired,
    "year": PropTypes.number.isRequired,
    "marked_as_read": PropTypes.bool.isRequired,
    "paydate": PropTypes.string.isRequired,
    "additions": PropTypes.object.isRequired,
    "deductions": PropTypes.object.isRequired,
    "extras": PropTypes.object.isRequired,
});

export const payslipIdPropTypes = PropTypes.number;

export const payslipPdfPropTypes = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
]);

export const payslipsDefaultProps = {
    "id": Number(),
    "period_number": Number(),
    "net": Number(),
    "gross": Number(),
    "total_deductions": Number(),
    "year": Number(),
    "marked_as_read": false,
    "paydate": "",
    "additions": {},
    "deductions": {},
    "extras": {},
};

export const payslipPdfDefaultProps = null;

export default payslipPropTypes;
