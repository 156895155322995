import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';

import Badge from '../../../components/badges/Badge';

export default class BadgesSection extends React.Component {

    /**
     * Renders the badges section of the styleguide
     *
     * @return {ReactElement} The section
     */
    render () {
        return (
            <CardRow>
                <CardRowContainer gridWidth={6}>
                    <ContentCard heading="Badges">
                        <div className="styleguide-badge-list">
                            <Badge label="Need email validating" value={2} />
                            <Badge label="Need email validating" value={2} theme="dark" />
                            <Badge label="Need email validating" value={999999} />
                        </div>
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
