import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TextInputRow } from "@dataplan/react-components/dist/components/forms";

import styles from "./AddCompanyForm.module.scss";

class AddressForm extends React.Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        formContent: PropTypes.object.isRequired,
    }

    /**
     * Renders the address input rows
     *
     * @return {ReactElement} text input rows
     */
    render () {
        const { formContent } = this.props;
        return _.map(this.props.formContent, (address) => {
            const { label, name, required } = address;
            const changeCheck = !formContent[name].valid && formContent[name].hasChanged && required;
            const nameError = (changeCheck)
                ? `${label} is required`
                : "";

            const classList = (nameError)
                ? styles.error
                : "";

            return (
                <TextInputRow
                    key={name}
                    name={name}
                    label={label}
                    placeholder={label}
                    onChange={(event) => this.props.onChange(event, name)}
                    errorText={nameError}
                    inputClassName={classList}
                />
            );
        });
    }

}

export default AddressForm;
