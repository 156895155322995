import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, withRouter } from "react-router-dom";
import { paths } from "../../../lib";
import {
    latestPayslipPropTypes,
    latestPayslipDefaultProps,
} from '../../../lib/props-schema/payslipsSchema';

import DocumentPdfView from '../../../components/document-pdf/DocumentPdfView';
import { formatLongDate } from '../../../lib/formattingHelpers';

import styles from './LatestPayslipSection.module.scss';
import sections from '../assets/ProfileSections.module.scss';

class LatestPayslipSection extends React.Component {

    static propTypes = {
        classNames: PropTypes.string,
        latestPayslip: latestPayslipPropTypes,
        match: PropTypes.object.isRequired,
    }

    static defaultProps = {
        classNames: '',
        latestPayslip: latestPayslipDefaultProps,
    }

    /**
     * Gets the payslip preview component if the API call was successful
     *
     * @param {number} payslip The latest payslip
     *
     * @return {ReactElement|String} The payslip preview
     */
    getPayslipPreview = (payslip) => {
        const { match } = this.props;

        if (!payslip) {
            return "No payslips to display";
        }

        // eslint-disable-next-line camelcase
        const employeePath = generatePath(paths.employee, match.params);
        const viewMore = `${employeePath}?payslipId=${payslip.id}`;

        return (
            <DocumentPdfView
                className={styles.payslipPreviewCanvas}
                key={formatLongDate(payslip.paydate)}
                url={`/payslip/${payslip.id}/pdf`}
                viewMore={viewMore}
                responseType="json"
                jsonPdfKey="payslip"
                title={formatLongDate(payslip.paydate)}
                scale={0.65}
            />
        );
    }

    /**
     * Renders a preview of the employees latest payslip
     *
     * @return {ReactElement} The employees latest payslip preview
     */
    render () {
        const { latestPayslip, classNames } = this.props;

        return (
            <div className={classNames}>
                <h3 className={sections.title}>Latest Payslip</h3>
                {this.getPayslipPreview(latestPayslip)}
            </div>
        );
    }

}

export default withRouter(LatestPayslipSection);
