import React from "react";
import { employeePropTypes } from "../../../lib/props-schema/employeeDetailsSchema";
import api from "../../../lib/api";
import appState from "../../../state/App";
import {
    TextInputRow,
    InputLabel,
    InputRow,
} from '@dataplan/react-components/dist/components/forms';

import {
    Toggle,
    PrimaryButton,
    SnackBar,
} from "../../../components";
import { formatShortDate, StringHelper } from "../../../lib";

export default class AccountAccess extends React.Component {

    static propTypes = {
        employeeDetails: employeePropTypes.isRequired,
    }

    /**
     * Creates the Account > Access page
     *
     * @param {object} props List of properties
     */
    constructor (props) {
        super(props);

        this.state = {
            enabled: props.employeeDetails.enabled,
            leave_date: props.employeeDetails.leave_date || "", /* eslint-disable-line camelcase */
            active: !(props.employeeDetails.account_status === "Not Activated"),
            showSnackBar: false,
            snackBarType: "",
            snackBarMessage: "",
            snackBarHeading: "",
            snackBarConfirmed: null,
        };
    }

    /**
     * Updates a user account status (enabled/disabled)
     * @private
     *
     * @return {void}
     */
    handleArchiveUser = () => {
        const { employeeDetails } = this.props;

        const name = (employeeDetails.name === undefined)
            ? `${employeeDetails.forename_1} ${employeeDetails.surname}`
            : employeeDetails.name;

        const employeeName = new StringHelper(name).parseName();
        const payload = {
            enabled: this.state.enabled,
        };

        api.put(`/employee/${this.props.employeeDetails.id}`, payload)
            .then(() => {
                appState.addNotification({
                    text: `${employeeName}'s account ${(payload.enabled) ? 'enabled' : 'archived'}`,
                    type: "success",
                    duration: 5,
                });
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to ${(payload.enabled) ? 'enabled' : 'archive'} account for ${employeeName}`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Handles the state of the toggles
     *
     * @param {string} stateName The name of the state property
     * @param {any} value The Value of the state to change
     *
     * @return {void}
     */
    toggleInput = (stateName, value) => {
        this.setState({
            [stateName]: value,
        }, () => this.handleArchiveUser());
    }

    /**
     * Handles the API action to reset account once confirmation has been made
     *
     * @param {object} employeeDetails The details of the employee we are resetting
     * @param {string} employeeName The name of the employee, used in the toast confirmation
     *
     * @return {void}
     */
    handleConfirmResetAccount = (employeeDetails, employeeName) => {
        api.put(`/employee/${employeeDetails.id}/reset`)
            .then(({data, status}) => {
                this.setState({
                    active: status !== 200,
                }, () => {
                    appState.addNotification({
                        text: `${employeeName}'s account reset`,
                        type: "success",
                        duration: 5,
                    });
                });
            })
            .catch(() => {
                appState.addNotification({
                    text: `Failed to reset account for ${employeeName}`,
                    type: "error",
                    duration: 0,
                });
            });
    }

    /**
     * Handles resetting the account
     *
     * @return {void}
     */
    handleClickResetAccount = () => {
        const { employeeDetails } = this.props;
        const employeeName = new StringHelper(employeeDetails.name).parseName();

        this.setState({
            showSnackBar: true,
            snackBarType: "warn",
            snackBarHeading: `${employeeName}'s account will be reset`,
            snackBarMessage: "Are you sure you want to reset this user account?",
            snackBarConfirmed: () => this.handleConfirmResetAccount(employeeDetails, employeeName),
        });
    };

    /**
     * Renders this component
     *
     * @return {ReactElement} The account access settings page
     */
    render () {
        const leaveDate = !(["0000-00-00", ""].includes(this.state.leave_date))
            ? formatShortDate(this.state.leave_date)
            : "-";

        const {
            showSnackBar,
            snackBarType,
            snackBarMessage,
            snackBarHeading,
            snackBarConfirmed,
        } = this.state;

        return (
            <div>
                <InputRow>
                    <InputLabel value={"Account"} />
                    <Toggle
                        label={"Enabled"}
                        initialValue={this.state.enabled}
                        handleChange={(value) => this.toggleInput("enabled", value)}
                    />
                </InputRow>

                <InputRow>
                    <TextInputRow
                        name="leave_date"
                        label="Leave Date"
                        value={leaveDate}
                        readOnly
                    />
                </InputRow>

                <InputRow>
                    <PrimaryButton
                        onClick={() => this.handleClickResetAccount()}
                        disabled={!this.state.active}
                    >
                        Reset Account
                    </PrimaryButton>
                </InputRow>

                { showSnackBar && (
                    <SnackBar
                        type={snackBarType}
                        message={snackBarMessage}
                        heading={snackBarHeading}
                        onConfirm={snackBarConfirmed}
                        onCancel={() => this.setState({showSnackBar: false})}
                    />
                )}
            </div>
        );
    }

}
