import React from 'react';
import { withRouter } from 'react-router-dom';
import appState from '../../state/App';
import PropTypes from 'prop-types';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import {
    ChangePassword,
    ChangeEmailAddress,
    ChangeSecurityQuestions,
    ChangeUsername,
    MultiFactorAuthentication,
    Devices,
} from './';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

class MyAccountPage extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Returns the tab content for the page layout
     *
     * @return {array} The tab content
     */
    getTabContent = () => {
        return [
            {
                text: "Username",
                component: <ChangeUsername />,
            },
            {
                text: "Password",
                component: <ChangePassword />,
            },
            {
                text: "Security questions",
                component: <ChangeSecurityQuestions />,
            },
            {
                text: "Email address",
                component: <ChangeEmailAddress />,
            },
            {
                text: "Multi-Factor authentication",
                component: <MultiFactorAuthentication />,
            },
            {
                text: "Devices",
                component: <Devices />,
            },
        ];
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    heading={{
                        text: "My Account",
                        size: "h1",
                    }}
                    maxWidth={this.props.appState.maxWidth}
                    tabContent={this.getTabContent()}
                    isRouted={true}
                />
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(MyAccountPage));
