import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { paths } from "../lib";
import classNames from 'classnames';
import _ from 'lodash';

import ProfileButton from './ProfileButton';
import MenuButton from './MenuButton';
import MoreButton from './MoreButton';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import { ButtonMenu } from "@dataplan/react-components/dist/components/ui/floating_menu";
import { Action } from "@dataplan/react-components/dist/components/ui/action_menu";

import appState from '../state/App';

import styles from './HeaderBar.module.scss';

class HeaderBar extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the header bar
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.headerRef = React.createRef();
        this.profileButtonRef = React.createRef();
    }

    /**
     * Stored the position of the profile menu icon
     *
     * This is needed for the login form animation which
     * moves a placeholder avatar over this position.
     *
     * @return {void}
     */
    setProfilePosition () {
        const header = this.headerRef.current.getBoundingClientRect();
        const button = this.profileButtonRef.current.getBoundingClientRect();
        // each icon is 40px but adds 2.5px for the spacing needed with padding. Rounded to remove bugs with decimals.
        const iconSpacing = 43;
        // the profile icon's svg is inset within the profileButton
        const profileSpacing = 1;

        appState.setProfileMenuPosition((button.top + header.height) + profileSpacing, button.left - iconSpacing);
    }

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        if (this.headerRef.current && this.profileButtonRef.current) {
            this.setProfilePosition();
        }
    }

    /**
     * Called when the main menu button is clicked
     *
     * @return {void}
     */
    handleMainMenuClick = () => {
        const { mainMenuOpen } = this.props.appState;

        appState.setMainMenuOpen(!mainMenuOpen);
    }

    /**
     * Renders the main hamburger menu
     *
     * @return {ReactElement} The menu item container
     */
    renderMainMenu () {
        const { mainMenuOpen } = this.props.appState;

        return (
            <div className={styles.hamburgerContainer} ref={this.menuRef}>
                <MenuButton onClick={this.handleMainMenuClick} isOpen={mainMenuOpen} />
            </div>
        );
    }

    /**
     * Renders the user profile menu
     *
     * @return {ReactElement} The menu item container
     */
    renderProfileMenu () {
        const { history } = this.props;

        const classList = classNames(styles.optionContainer, {
            [styles.optionsLoaded]: this.props.appState.loaded && !this.props.appState.entered,
        });

        return (
            <div className={classList} ref={this.profileButtonRef}>
                <ButtonMenu
                    className={styles.buttonMenu}
                    button={(clickHandler, parentRef) => {
                        return <ProfileButton onClick={clickHandler} ref={parentRef} />;
                    }}
                >
                    <Action
                        label="My Account"
                        action={() => history.push(paths.myAccountPage)}
                        className={styles.action}
                    />
                    <Action
                        label="Logout"
                        action={() => history.push(paths.logout)}
                        className={styles.action}
                    />
                </ButtonMenu>
            </div>
        );
    }

    /**
     * Renders the link to administration page
     *
     * @return {ReactElement} The menu item container
     */
    renderAdminCog () {
        const { history } = this.props;

        const classList = classNames(styles.optionContainer, {
            [styles.optionsLoaded]: this.props.appState.loaded && !this.props.appState.entered,
        });

        return (
            <div className={classList} ref={this.profileButtonRef}>
                <ButtonMenu
                    className={styles.buttonMenu}
                    button={(clickHandler, parentRef) => {
                        return <MoreButton onClick={clickHandler} ref={parentRef} />;
                    }}
                >
                    <Action
                        label="Companies"
                        className={styles.adminAction}
                        action={() => history.push(paths.companies)}
                    />
                    <Action
                        label="User Management"
                        className={styles.adminAction}
                        action={_.noop}
                    />
                </ButtonMenu>
            </div>
        );
    }

    /**
     * Render the breadcrumb
     *
     * @return {ReactElement} The breadcrumb
     */
    renderBreadcrumb = () => {
        return (
            <Breadcrumbs {...this.props} />
        );
    }


    /**
     * Renders the main header bar
     *
     * @return {ReactElement} The header element
     */
    render () {
        const classList = classNames(styles.bar, {
            [styles.barLoaded]: this.props.appState.loaded && !this.props.appState.entered,
        });

        return (
            <div aria-label="Header Bar" role="navigation">
                <div className={classList} ref={this.headerRef}>
                    <div className={styles.globalOptions}>
                        {this.renderMainMenu()}
                    </div>
                    <div className={styles.accountOptions}>
                        {this.renderProfileMenu()}
                        {this.renderAdminCog()}
                    </div>
                </div>
                {this.renderBreadcrumb()}
            </div>
        );
    }

}

export default withRouter(appState.attachState(HeaderBar));
