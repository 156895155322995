import _ from 'lodash';
import moment from 'moment';

/**
 * Returns a concatenated string based on an array of name parts
 *
 * @param {array} fragments The array of name parts
 *
 * @return {string} The concatenated name
 */
export const formatFullName = (...fragments) => {
    return fragments
        .filter((part) => !_.isEmpty(part))
        .join(' ')
        .trim();
};

/**
 * Returns the employees name, based on if the payroll uses Smartforms 1 or 2
 *
 * @param {object} employee An object of user data for a given employee
 * @param {bool} hasDataTemplate If the payroll is SF 1 or 2
 *
 * @return {string} A formatted string of the users name
 */
export const formatFormalName = (employee) => {
    if (_.isEmpty(employee)) {
        return "-";
    }

    let { forename_1: forename1, forename_2: forename2, surname, name } = employee;

    if (name) {
        return name;
    }

    return [
        `${surname},`,
        forename1,
        forename2,
    ].join(' ').trim();
};

/**
 * Returns true or false if the date  string is empty
 *
 * @param {string} date The string to check
 *
 * @return {bool} If the date is empty or not
 */
const isDateEmpty = (date) => {
    return !date || _.includes(date, "0000-00-00");
};

/**
 * Returns a formatted string of a timestamp in 01 Jun 2018 format
 *
 * @param {string} date The timestamp
 *
 * @return {string} The formatted date
 */
export const formatLongDate = (date) => {
    if (isDateEmpty(date)) {
        return "-";
    }

    return moment(date).format("DD MMM YYYY");
};

/**
 * Returns a formatted string of a timestamp in 01-06-2018 format
 *
 * @param {string} date The timestamp
 *
 * @return {string} The formatted date
 */
export const formatShortDate = (date) => {
    if (isDateEmpty(date)) {
        return "-";
    }

    return moment(date, "YYYY-MM-DD HH:mm:ss").format('DD-MM-YYYY');
};

/**
 * Returns a formatted string of a timestamp as a Date/Time format
 *
 * @param {string} date The timestamp
 *
 * @return {string} The formatted date
 */
export const formatDateTime = (date) => {
    if (isDateEmpty(date)) {
        return "-";
    }

    return moment(date, "YYYY-MM-DD HH:mm:ss").format('ha, DD-MM-YYYY');
};

/**
 * Returns a formatted string for currency with option to show currency symbol
 *
 * @param {string|number} value The value to parse
 * @param {bool} showCurrency To show the currency symbol or not
 *
 * @return {string} The formatted string
 */
export const formatMonies = (value, showCurrency = false) => {
    const decimalValue = _.toNumber(value).toFixed(2);

    return showCurrency
        ? `£${decimalValue}`
        : decimalValue;
};

/**
 * Gets the employees email address, returning home email as first priority
 *
 * @param {object} emails The employees email addresses
 *
 * @return {string} The email address to display
 */
export const getFullFirstEmail = (emails) => {
    if (_.isEmpty(emails)) {
        return "-";
    }

    return emails.home || emails.work;
};

/**
 * Truncates a string to a certain length, if longer than the max set
 *
 * @param {string} string The string to truncate
 * @param {number} maxLength The length to truncate to
 * @param {string} suffix The suffix to add to the end, defaults to ellipsis
 *
 * @return {string} The truncated string
 */
export const truncateString = (string, maxLength = 15, suffix = `\u2026`) => {
    if (string.length <= maxLength) {
        return string;
    }

    return `${string.slice(0, (maxLength - 1)).trim()}${suffix}`;
};

/**
 * Gets the employees email address, returning home email as first priority, and shortens it
 *
 * @param {object} emails The employees email addresses
 *
 * @return {string} The email address to display
 */
export const getFirstEmail = (emails) => {
    if (_.isEmpty(emails)) {
        return "-";
    }

    return truncateString(emails.home || emails.work);
};
