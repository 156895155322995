import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import api from '../../lib/api';
import _ from 'lodash';
import moment from 'moment';
import { payslipsDefaultProps } from '../../lib/props-schema/payslipSchema';
import { formatLongDate } from '../../lib/formattingHelpers';
import appState from '../../state/App';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import DocumentPdfView from '../../components/document-pdf/DocumentPdfView';
import PayslipOverviewTab from './assets/PayslipOverviewTab';
import PayslipDetailsTab from './assets/PayslipDetailsTab';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

class ViewPayslip extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        payslipId: PropTypes.string.isRequired,
        unsetPayslipId: PropTypes.func,
    }

    static defaultProps = {
        unsetPayslipId: _.noop,
    }

    /**
     * Creates an instance of the view payslip page
     *
     * @param {object} props Payslip page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            dataLoaded: false,
            payslipDetails: payslipsDefaultProps,
            payslipPdf: {},
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        const { payslipId } = this.props;

        this.handleApiCall(payslipId);
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        const { unsetPayslipId } = this.props;

        if (unsetPayslipId) {
            unsetPayslipId();
        }
    }

    /**
     * Called once the component has loaded, queries the API for the payslip
     *
     * @param {number} payslipId The ID of the payslip we're looking at
     *
     * @return {void}
     */
    handleApiCall = (payslipId) => {
        const { unsetPayslipId } = this.props;

        axios.all([
            api.get(`/payslip/${payslipId}`).catch(this.handleApiCatch),
            api.get(`/payslip/${payslipId}/pdf?download=true`, { responseType: 'blob' }).catch(this.handleApiCatch),
        ]).then(axios.spread(({ data: payslipDetails }, { data: payslipPdf }) => {
            if (!payslipDetails || !payslipPdf) {
                unsetPayslipId();
            } else {
                this.setState({
                    dataLoaded: true,
                    payslipDetails,
                    payslipPdf,
                });
            }
        }));
    }

    /**
     * Handles an error response from the api
     *
     * @param {object} error The error response
     *
     * @return {void}
     */
    handleApiCatch = (error) => {
        if (error.response && error.response.status === 403) {
            return {
                data: null,
            };
        } else {
            // catch any other JS related errors
            throw new Error(error.message);
        }
    }

    /**
     * Returns the tab content for the page layout
     *
     * @return {array} The tab content
     */
    getTabContent = () => {
        const { payslipId } = this.props;
        const { dataLoaded, payslipDetails, payslipPdf } = this.state;

        if (!dataLoaded) {
            return null;
        }

        const title = formatLongDate(payslipDetails.paydate);

        return [
            {
                text: "Preview",
                component: (
                    <DocumentPdfView
                        key={title}
                        url={`/payslip/${payslipId}/pdf`}
                        responseType="json"
                        jsonPdfKey="payslip"
                        title={title}
                    />
                ),
                url: "/preview",
            },
            {
                text: "Overview",
                component: (
                    <PayslipOverviewTab
                        payslipPdf={payslipPdf}
                        payslipTitle={title}
                        payslipDetails={payslipDetails}
                    />
                ),
                url: "/overview",
            },
            {
                text: "Details",
                component: (
                    <PayslipDetailsTab
                        payslipDetails={payslipDetails}
                    />
                ),
                url: "/details",
            },
        ];
    }

    /**
     * Returns the page title, appending the payslip date once returned from the API
     *
     * @return {string} The page title
     */
    getPageTitle = () => {
        const payslipDetails = this.state.payslipDetails;

        if (_.isEmpty(payslipDetails.paydate)) {
            return "Payslip";
        }

        return `Payslip for ${moment(payslipDetails.paydate).format("DD MMMM YYYY")}`;
    }

    /**
     * Renders the view payslip page
     *
     * @return {ReactElement} The payslip view for a given ID
     */
    render () {
        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    heading={{
                        text: this.getPageTitle(),
                        size: "h1",
                    }}
                    pageType="boxed"
                    maxWidth={this.props.appState.maxWidth}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                    tabContent={this.getTabContent()}
                    isRouted={true}
                    showBackArrow={true}
                    role="main"
                />
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(ViewPayslip));
