import React from 'react';

export default class Warning extends React.Component {

    /**
     * Renders the icon component
     *
     * @return {ReactElement} The icon element
     */
    render () {
        return (
            /* eslint-disable max-len */
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 20.462" {...this.props}>
                <path d="M11.5 2.34a2.195 2.195 0 0 1 1.807 1.243l6.917 11.53c1 1.656.227 3.01-1.7 3.01H4.48c-1.931 0-2.7-1.356-1.7-3.01l6.917-11.53a2.195 2.195 0 0 1 1.807-1.243m0-2.34a4.49 4.49 0 0 0-3.817 2.376L.77 13.908a4.375 4.375 0 0 0-.275 4.481 4.38 4.38 0 0 0 3.985 2.073h14.04a4.378 4.378 0 0 0 3.986-2.073 4.377 4.377 0 0 0-.277-4.48L15.313 2.38A4.486 4.486 0 0 0 11.5 0z" />
                <circle cx="11.5" cy="14.612" r="1.521" />
                <path d="M13.255 7.592a1.755 1.755 0 1 0-3.38.658c.648 1.61 1.625 4.023 1.625 4.023l1.628-4.023a1.8 1.8 0 0 0 .127-.658z" />
            </svg>
            /* eslint-enable */
        );
    }

}
