import api from './api';
import fileDownload from 'js-file-download';

/**
 * Called to download the file in the browser from the API
 *
 * @param {string} url The API url to query against
 * @param {string} fileName The filename to use when saving the file
 * @param {string} responseType The API response type, defaults to blob
 * @param {?string} responseKey The array key to use if the API response is a JSON array
 *
 * @return {void}
 */
const getFileDownload = (url, fileName = 'untitled', responseType = 'blob', responseKey = null) => {
    api.get(url, { responseType: `${responseType}` })
        .then((response) => {
            const fileData = (responseKey) ? response.data[responseKey] : response.data;

            fileDownload(fileData, fileName);
        });
};

export default getFileDownload;
