import React from 'react';
import PropTypes from 'prop-types';
import appState from '../../state/App';

import {
    SnackBar,
} from './';

class GlobalSnackBar extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    }

    /**
     * Called when the close button on the snack bar is clicked
     *
     * @param {object} snackBar The current snack bar being displayed
     *
     * @return {void}
     */
    handleCloseSnackBar = (snackBar) => {
        appState.closeSnackBar(snackBar.id);
    }

    /**
     * Renders an instance of the snack bar based on the first instance of global snack bars
     * in the appState
     *
     * @param {object} snackBar The current snack bar being displayed
     *
     * @return {ReactElement} The snack bar
     */
    renderSnackBar = (snackBar) => {
        return (
            <SnackBar
                key={snackBar.id}
                type={snackBar.type}
                heading={snackBar.heading}
                message={snackBar.message}
                onClose={() => this.handleCloseSnackBar(snackBar)}
            />
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { globalSnackBars: snackBars } = this.props.appState;

        if (snackBars.length < 1) {
            return null;
        }

        return this.renderSnackBar(snackBars[0]);
    }

}

export default appState.attachState(GlobalSnackBar);
