import React from 'react';
import PropTypes from 'prop-types';

import './TableHeader.scss';

export default class TableHeader extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
    }

    /**
     * Renders a table header containing one row of headings
     *
     * @return {ReactElement} The header element
     */
    render () {
        return (
            <thead className="table-header">
                <tr>
                    {this.props.children}
                </tr>
            </thead>
        );
    }

}
