import React from 'react';
import PropTypes from 'prop-types';

import { FileInputRow } from "@dataplan/react-components/dist/components/forms";

class LogoForm extends React.Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        errorCheck: PropTypes.object.isRequired,
    }

    /**
     * Renders the file upload
     *
     * @return {ReactElement} The file upload selector
     */
    render () {
        const { valid, hasChanged } = this.props.errorCheck;
        const fileError = (!valid && hasChanged) ? "Logo is required" : "";

        return (
            <FileInputRow
                name="logo"
                label="Upload Logo"
                onChange={this.props.onChange}
                errorText={fileError}
            />
        );
    }

}

export default LogoForm;
