import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { store } from '../../redux-store';
import api from '../../lib/api';
import { paths } from "../../lib";

class Logout extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    /**
     * Clears local auth information and redirects to the login page
     *
     * @return {void}
     */
    clearAuthDetails () {
        const { history } = this.props;

        store.dispatch({ type: "RESET" });
        sessionStorage.removeItem("apiToken");
        history.push(paths.dashboard);
    }

    /**
     * Called after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        api.delete('/auth').then(() => {
            this.clearAuthDetails();
        }).catch((error) => {
            this.clearAuthDetails();

            // Prevents error if user clicks "Logout" after their API token has expired
            if (error.response.status !== 401) {
                throw new Error(error.message);
            }
        });
    }

    /**
     * Renders nothing, this componenet only does a redirect
     *
     * @return {null} Always null
     */
    render () {
        return null;
    }

}

export default withRouter(Logout);
