import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { checkValidity } from 'lib/validationHelpers';
import appState from 'state/App';
import _ from 'lodash';

import {
    DefaultForm,
    FileInputRow,
    TextInputRow,
    CheckboxInputRow,
    PrimaryButton,
    SecondaryButton,
    SelectInputRow,
} from "@dataplan/react-components/dist/components/forms";

import { defaultAccentColour } from '../../../Colours';

import styles from "./DocumentUploadForm.module.scss";

class DocumentUploadForm extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        closeDrawer: PropTypes.func,
        showSendNotifications: PropTypes.bool,
        visibilityOption: PropTypes.bool,
    }

    static defaultProps = {
        closeDrawer: _.noop,
        showSendNotifications: true,
        visibilityOption: true,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            title: {
                valid: false,
                hasChanged: false,
            },
            file: {
                valid: false,
                hasChanged: false,
            },
            visibility: {
                selectedCompany: null,
                selectedPayrolls: {
                    values: [],
                    hasChanged: false,
                },
            },
        };
    }

    /**
     * Called upon mounting of component
     *
     * @return {void}
     */
    componentDidMount () {
        const { companies } = this.props.appState;

        this.handleCompanySelect(companies[0].id);
    }

    /**
     * Helper method to cancel the changes
     *
     * @return {void}
     */
    handleCancel = () => {
        const { closeDrawer } = this.props;

        closeDrawer();
    }

    /**
     * Helper method to change the state of a given input key's validation
     *
     * @param {object} valid The validation handler for a given type
     * @param {string} key The state key validated
     *
     * @return {void} Triggers a state change for the given key
     */
    changeValidationStateofInput (valid, key) {
        this.setState({
            [key]: {
                hasChanged: true,
                valid: valid.isValid,
            },
        });
    }

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void} Triggers state change
     */
    handleDescriptionChange = (event) => {
        const title = event.target.value;
        appState.blockNavigation();
        const valid = checkValidity(title, 'requiredInput', 'Title');
        this.changeValidationStateofInput(valid, 'title');
    }

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handleFileInput = (event) => {
        const file = event.target.value;
        appState.blockNavigation();
        const valid = checkValidity(file, 'requiredInput', 'File');
        this.changeValidationStateofInput(valid, 'file');
    }

    /**
     * Called when a company is selected
     *
     * @param {string} companyId The company id
     *
     * @return {void}
     */
    handleCompanySelect = (companyId) => {
        const prevCompany = this.state.visibility.selectedCompany;
        if (prevCompany && companyId === prevCompany.id) {
            return null;
        }

        const company = _.find(this.props.appState.companies, (item) => {
            return item.id === Number(companyId);
        });
        const payrolls = _.filter(this.props.appState.payrolls, (payroll) => payroll.company_id === company.id)
            .map((payroll) => {
                return {
                    company_id: payroll.company_id, /* eslint-disable-line camelcase */
                    id: payroll.id,
                    name: payroll.name,
                };
            });

        const selectedCompany = {
            id: company.id,
            name: company.name,
            payrolls,
        };

        this.setState({
            visibility: {
                selectedCompany,
                selectedPayrolls: {
                    values: [],
                    hasChanged: false,
                },
            },
        }, () => {
            this.getEmployeeCounts(selectedCompany.id, selectedCompany.payrolls);

            if (selectedCompany.payrolls.length === 1) {
                this.handlePayrollSelect(selectedCompany.payrolls[0].id, true);
            }
        });

        return selectedCompany;
    }

    /**
     * Gets count of employees on each payroll
     *
     * @param {string} companyId The company id
     * @param {array} payrolls The company payrolls
     *
     * @return {void}
     */
    getEmployeeCounts = (companyId, payrolls) => {
        const { employees } = this.props.appState;
        const { selectedCompany } = this.state.visibility;

        const employeeCountRef = employees.reduce((ref, employee) => {
            if (!ref[employee.payroll_id]) {
                ref[employee.payroll_id] = 1;
            } else {
                ref[employee.payroll_id]++;
            }

            return ref;
        }, {});

        payrolls.forEach((payroll) => {
            payroll.employeeCount = employeeCountRef[payroll.id];
        });

        if (companyId === selectedCompany.id) {
            selectedCompany.payrolls = payrolls;

            this.setState((prevState) => {
                return {
                    visibility: {
                        ...prevState.visibility,
                        selectedCompany,
                    },
                };
            });
        }
    }

    /**
     * Called when a payroll checkbox is ticked/unticked
     *
     * @param {string} id The payroll id
     * @param {boolean} isChecked If the checkbox has been checked
     *
     * @return {void}
     */
    handlePayrollSelect = (id, isChecked) => {
        const { payrolls } = this.state.visibility.selectedCompany;

        this.setState((prevState) => {
            const { values } = prevState.visibility.selectedPayrolls;

            if (isChecked) {
                const payroll = _.find(payrolls, (item) => {
                    return (item.id === Number(id));
                });

                values.push(payroll);
            } else {
                const payrollIndex = values.indexOf(values.find((payroll) => payroll.id === Number(id)));

                values.splice(payrollIndex, 1);
            }

            return {
                visibility: {
                    ...prevState.visibility,
                    selectedPayrolls: {
                        values,
                        hasChanged: true,
                    },
                },
            };
        });
    }

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void} submits the form if valid
     */
    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const { file, title, visibility: { selectedPayrolls } } = this.state;
        const payrollsValid = selectedPayrolls.values.length > 0 || !this.props.visibilityOption;

        if (!file.valid || !title.valid || !payrollsValid) {
            return;
        }

        appState.unblockNavigation();

        this.props.handleSubmit(form, selectedPayrolls.values.map((payroll) => payroll.id));
    }

    /**
     * Renders the file input
     *
     * @return {ReactElement} The file input row
     */
    renderFileInput () {
        const { valid, hasChanged } = this.state.file;
        const fileError = (!valid && hasChanged) ? "File is required" : "";

        return (
            <FileInputRow
                name="file"
                label="File"
                onChange={this.handleFileInput}
                errorText={fileError}
                rowClassName={styles.fileRow}
            />
        );
    }

    /**
     * Renders the title input
     *
     * @return {ReactElement} The text input row
     */
    renderTitleInput () {
        const { valid, hasChanged } = this.state.title;
        const classList = (!valid && hasChanged) ? styles.error : '';
        const titleError = (!valid && hasChanged) ? "Title is required" : "";

        return (
            <TextInputRow
                name="description"
                label="Title"
                onChange={this.handleDescriptionChange}
                inputClassName={classList}
                errorText={titleError}
                rowClassName={styles.fileRow}
            />
        );
    }

    /**
     * Renders the send notification input
     *
     * @return {ReactElement} The input row
     */
    renderSendNotifications () {
        if (!this.props.showSendNotifications) {
            return null;
        }

        const notificationRows = [{
            name: 'send_notification',
            value: "1",
            label: 'Send Notification (Immediately)',
        }].map((option) => {
            return {
                name: option.name,
                value: option.value,
                label: option.label,
            };
        });

        return (
            <div className={styles.formSection}>
                <CheckboxInputRow
                    options={notificationRows}
                    label="Notifications"
                    large
                    rowClassName={styles.notificationRow}
                    backgroundColour={defaultAccentColour}
                />
            </div>
        );
    }

    /**
     * Renders the visibility section
     *
     * @return {ReactElement} The section
     */
    renderVisibilitySection () {
        return (
            <div className={styles.formSection}>
                <div className={styles.visibilitySectionHeader} >
                    <h3 className={styles.title}>Visibility</h3>
                    {this.renderEmployeeCountLabel()}
                </div>
                {this.renderCompanySelection()}
                {this.renderPayrollSelection()}
            </div>
        );
    }

    /**
     * Renders the employee count label
     *
     * @return {ReactElement} The label
     */
    renderEmployeeCountLabel () {
        const { selectedPayrolls } = this.state.visibility;

        const employeeCount = selectedPayrolls.values.reduce((acc, payroll) => {
            return acc + (payroll.employeeCount || 0);
        }, 0);

        return (
            <div className={styles.employeeCountLabel}>
                <div className={styles.employeeCount} >
                    {employeeCount}
                </div>
                <span>Employees</span>
            </div>
        );
    }

    /**
     * Renders the company selection input
     *
     * @return {ReactElement} The input row
     */
    renderCompanySelection () {
        const { selectedCompany } = this.state.visibility;
        const { companies } = this.props.appState;

        if (!selectedCompany || companies.length === 1) {
            return null;
        }

        const options = companies.map((option) => <option value={option.id} key={option.id}>{option.name}</option>);

        return (
            <SelectInputRow
                name="companySelect"
                label={"Company"}
                onChange={(event) => this.handleCompanySelect(event.target.value)}
                placeholder={"Please select"}
                rowClassName={styles.fileRow}
                value={selectedCompany.id.toString()}
            >
                {options}
            </SelectInputRow>
        );
    }

    /**
     * Renders the payroll selection input
     *
     * @return {ReactElement} The input row
     */
    renderPayrollSelection () {
        const { selectedCompany, selectedPayrolls: { values, hasChanged } } = this.state.visibility;
        const { companies } = this.props.appState;

        if (!selectedCompany || selectedCompany.payrolls.length === 1) {
            return null;
        }

        const payrollError = (values.length < 1 && hasChanged) ? "Please select at least one payroll" : "";

        const payrollRows = selectedCompany.payrolls.reduce((arr, payroll) => {
            arr.push({
                name: "payrolls[]",
                value: `${payroll.id}`,
                label: (companies.length > 1) ? `${payroll.name} (${selectedCompany.name})` : payroll.name,
            });

            return arr;
        }, []);

        return (
            <CheckboxInputRow
                label="Payrolls"
                options={payrollRows}
                large
                inputClassName={styles.payrollInput}
                rowClassName={styles.payrollsRow}
                onChange={(event) => this.handlePayrollSelect(event.target.value, event.target.checked)}
                errorText={payrollError}
                backgroundColour={defaultAccentColour}
            />
        );
    }

    /**
     * Renders the button section
     *
     * @return {ReactElement} The button row
     */
    renderButtonsSection () {
        const { file, title } = this.state;

        return (
            <div className={styles.upload}>
                <PrimaryButton
                    type="submit"
                    disabled={!(file.valid && title.valid)}
                    className={styles.button}
                >
                    Publish
                </PrimaryButton>
                <SecondaryButton
                    onClick={this.handleCancel}
                    className={styles.secondaryButton}
                    accent={defaultAccentColour}
                    text={"Cancel"}
                    aria-label={"Cancel"}
                />
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const visibilityToggle = this.props.visibilityOption ? this.renderVisibilitySection() : null;

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                <div className={styles.formSection}>
                    <h3 className={styles.title}>Document Details</h3>
                    {this.renderFileInput()}
                    {this.renderTitleInput()}
                </div>
                {visibilityToggle}
                {this.renderSendNotifications()}
                {this.renderButtonsSection()}
            </DefaultForm>
        );
    }

}

export default withRouter(appState.attachState(DocumentUploadForm));
