import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import payslipPropTypes, {
    payslipsDefaultProps,
    payslipPdfPropTypes,
} from '../../../lib/props-schema/payslipSchema';

import { formatLongDate, formatMonies } from '../../../lib/formattingHelpers';
import DocumentPdfDownload from '../../../components/document-pdf/DocumentPdfDownload';

import styles from './PayslipOverviewTab.module.scss';

export default class PayslipOverviewTab extends React.Component {

    static propTypes = {
        payslipPdf: payslipPdfPropTypes.isRequired,
        payslipTitle: PropTypes.string.isRequired,
        payslipDetails: payslipPropTypes,
    }

    static defaultProps = {
        payslipDetails: payslipsDefaultProps,
    }

    /**
     * Renders the payslip overview tab
     *
     * @return {ReactElement} The payslip overview tab
     */
    render () {
        const { payslipPdf, payslipDetails, payslipTitle } = this.props;
        const alignRightClasses = classNames(styles.column, styles.alignRight);
        const { employee } = payslipDetails;

        const {
            "Tax Code": taxCode,
            "NI Code": niCode,
        } = employee;

        return (
            <div>
                <div className={styles.container}>
                    <div className={styles.innerContainer}>
                        <div className={styles.column}>
                            <h4>Date</h4>
                            {formatLongDate(payslipDetails.paydate)}
                        </div>
                        <div className={styles.column}>
                            <h4>Period</h4>
                            {payslipDetails.period_number}
                        </div>
                        <div className={styles.column}>
                            <h4>Tax Code</h4>
                            {taxCode}
                        </div>
                        <div className={styles.column}>
                            <h4>NI Code</h4>
                            {niCode}
                        </div>
                    </div>
                    <div className={styles.innerContainer}>
                        <div className={alignRightClasses}>
                            <h4>Gross</h4>
                            {formatMonies(payslipDetails.gross)}
                        </div>
                        <div className={alignRightClasses}>
                            <h4>Deductions</h4>
                            {formatMonies(payslipDetails.total_deductions)}
                        </div>
                        <div className={alignRightClasses}>
                            <h4>Net</h4>
                            {formatMonies(payslipDetails.net)}
                        </div>
                    </div>
                </div>
                <DocumentPdfDownload
                    documentPdf={payslipPdf}
                    title={payslipTitle}
                />
            </div>
        );
    }

}
