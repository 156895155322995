import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import styles from './SearchButton.module.scss';

export default class SearchButton extends React.Component {

    static propTypes = {
        onClick: PropTypes.func,
    }

    static defaultProps = {
        onClick: _.noop,
    }

    /**
     * Gets the search icon SVG.
     *
     * This is wrapped in a method so we can ignore the long line.
     *
     * @return {ReactElement} The SVG element
     */
    getSeachIcon () {
        /* eslint-disable max-len */
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={styles.icon}>
                <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </svg>
        );
        /* eslint-enable */
    }

    /**
     * Renders the hamburger nenu button
     *
     * @return {ReactElement} The menu button
     */
    render () {
        return (
            <button
                type="button"
                className={styles.button}
                onClick={this.props.onClick}
            >
                {this.getSeachIcon()}
            </button>
        );
    }

}
