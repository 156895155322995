/**
 * Handles print activating for an employee
 *
 * @param {number[]} employeeIds Array of employee IDs.
 *
 * @return {void}
 */
const handlePrintActivation = (employeeIds) => {
    // NYI
    console.log("Needs implementing", {employeeIds}); // eslint-disable-line
};

export default handlePrintActivation;
