import React from 'react';
import { withRouter } from 'react-router-dom';
import documentsPropTypes, { documentsDefaultProps } from '../../lib/props-schema/documentsSchema';
import PropTypes from 'prop-types';
import appState from '../../state/App';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import DocumentsListTab from './documents/DocumentsListTab';
import DocumentUpload from './documents/DocumentUpload.js';
import { Drawer } from '@dataplan/react-components/dist/components/ui/drawer';
import { AddButton } from "@dataplan/react-components/dist/components/forms";
import { defaultAccentColour } from '../../Colours';
import employeePropTypes from "../../lib/props-schema/employeeDetailsSchema";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import {Icon} from "../../components";
import styles from './DocumentsTab.module.scss';

class DocumentsTab extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        employeeDocuments: documentsPropTypes,
        employeeDetails: employeePropTypes.isRequired,
        reLoadTableDocs: PropTypes.func,
    }

    static defaultProps = {
        employeeDocuments: documentsDefaultProps,
        reLoadTableDocs: null,
    }

    /**
     * Creates an instance of the portal example
     *
     * @param {object} props The portal example properties
     */
    constructor (props) {
        super(props);

        this.nativeDrawer = React.createRef();
    }

    /**
     * Helper method to get the drawer top action
     *
     * @return {Object} The drawer top action
     */
    getTopAction = () => {
        let topAction;

        topAction = {
            icon: <Icon icon="Close" aria-label="Close" className={styles.icon} />,
            action: this.closeDrawer,
        };

        return topAction;
    }

    /**
     * Helper method to get the drawer component config
     *
     * @return {ReactElement} The drawer component
     */
    getNewUploadPortal () {
        const { employeeDetails, reLoadTableDocs } = this.props;

        const header = {
            size: 'h2',
            text: 'Upload a document',
        };

        return (
            <Drawer
                targetNode={document.body}
                ref={this.nativeDrawer}
                header={header}
                loadingLabel={"Uploading..."}
                onClickOutside={this.closeDrawer}
                topAction={this.getTopAction()}
            >
                <DocumentUpload
                    drawer={this.nativeDrawer}
                    employeeDetails={employeeDetails}
                    reLoadTableDocs={reLoadTableDocs}
                    documentType={"personalcompany"}
                    visibilityOption={false}
                />
            </Drawer>
        );
    }

    /**
     * Helper method to open the drawer
     *
     * @return {void} Changes state of drawer
     */
    openDrawer = () => {
        const { nativeDrawer } = this;

        nativeDrawer.current.setState({
            visible: true,
        });
    }

    /**
     * Helper method to open the drawer
     *
     * @return {void} Changes state of drawer
     */
    closeDrawer = () => {
        const { nativeDrawer } = this;

        nativeDrawer.current.setState({
            visible: false,
        });
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { employeeDocuments, reLoadTableDocs } = this.props;
        const headerAction = {
            drawer: this.getNewUploadPortal(),
            button: (
                <AddButton colour={defaultAccentColour} onClick={this.openDrawer} />
            ),
        };

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageLayout
                    heading={{
                        text: "Documents",
                        size: "h2",
                    }}
                    headerAction={headerAction}
                    maxWidth={this.props.appState.maxWidth}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        isNested: true,
                        hasBackground: true,
                        hasGutter: true,
                    }}
                >
                    <DocumentsListTab
                        key="all"
                        employeeDocuments={employeeDocuments}
                        reLoadTableDocs={reLoadTableDocs}
                    />
                </PageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(DocumentsTab));
