import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import appState from '../../state/App';
import { KEY_SPACE, KEY_ENTER, KEY_RETURN } from 'keycode-js';
import _ from 'lodash';
import classNames from 'classnames';

import {
    ActionMenu,
    Action,
} from '../';

import styles from './Tabs.module.scss';

class Tabs extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        tabContent: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            component: PropTypes.element,
            url: PropTypes.string,
        })),
        isRouted: PropTypes.bool,
        isResponsive: PropTypes.bool,
        onTabChange: PropTypes.func.isRequired,
        defaultColor: PropTypes.string,
        selectedColor: PropTypes.string,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
    };

    static defaultProps = {
        tabContent: null,
        isRouted: false,
        isResponsive: false,
        defaultColor: "#7e7e7e",
        selectedColor: "#b5b5b5",
    }

    /**
     * Creates an instance of the page tabs
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.underlineRef = null;
        this.menuRef = null;

        this.state = {
            currentTabIndex: 0,
        };
    }

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        if (this.props.isRouted) {
            this.getTabIndexFromUrl();
        } else {
            this.positionUnderline();
        }
    }

    /**
     * Called when the component recieves updates
     *
     * @param {object} prevProps The components props before update
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.getTabIndexFromUrl();
        }
    }

    /**
     * Get the current tab based on URL compared to tab content object
     * Used to position tab underline on page load
     *
     * @return {void}
     */
    getTabIndexFromUrl = () => {
        const { tabContent, location } = this.props;

        let indexFromUrl = _.findIndex(tabContent, (tab) => {
            return location.pathname.indexOf(tab.url) > 0;
        });

        this.setState({
            currentTabIndex: (indexFromUrl > 0) ? indexFromUrl : 0,
        }, () => {
            this.positionUnderline();
        });
    }

    /**
     * Sets the position and width of the underline based on the current selection
     *
     * @return {void}
     */
    positionUnderline () {
        const currentTab = this.state.currentTabIndex;
        const menuItems = this.menuRef.children;

        // Match the width of the menu item that was clicked on
        let width = menuItems[currentTab].getBoundingClientRect().width;
        // Left offset is the width of all menu items before the current one
        let left = 0;

        for (let i = 0; i < currentTab; ++i) {
            left += menuItems[i].getBoundingClientRect().width;
        }

        // Adjust to be within the padding of the tab
        width -= 40;
        left += 20;

        this.underlineRef.style.width = `${width}px`;
        this.underlineRef.style.left = `${left}px`;
    }

    /**
     * Sets the currently visible tab
     *
     * @param {integer} index The index of the new tab
     *
     * @return {void}
     */
    setCurrentTab (index) {
        // Quick sanity check on the selected index, user would only be able to do
        // this by doing something weird like editing the markup in dev tools.
        if (index < 0 || index > this.props.tabContent.length) {
            throw new Error("Invalid tab selected");
        }

        this.setState({
            currentTabIndex: index,
        }, () => {
            // Reposition the underline once the state has updated
            this.positionUnderline();
        });
    }

    /**
     * Sets the native reference to the menu container
     *
     * @param {Element} element The native element
     *
     * @return {void}
     */
    setMenuRef = (element) => {
        this.menuRef = element;
    }

    /**
     * Called when one of the tab list items is clicked
     *
     * @param {integer} index The new tab to be active
     *
     * @return {void}
     */
    handleClick = (index) => {
        const { history, isRouted, onTabChange, tabContent} = this.props;

        if (!this.props.appState.navigationBlocked) {
            this.setCurrentTab(index);
            onTabChange(index);
        }

        if (isRouted) {
            history.push(tabContent[index].url);
        }
    }

    /**
     * Called when a key is released with a list item focused
     *
     * @param {Event} event The event
     * @param {integer} index The tabs index
     *
     * @return {void}
     */
    handleKeyUp = (event, index) => {
        // List of keys that will trigger the tab to be changed
        const activatingKeys = [KEY_SPACE, KEY_ENTER, KEY_RETURN];

        if (activatingKeys.indexOf(event.keyCode) !== -1) {
            this.setCurrentTab(index);

            // The default action for space and enter it to scroll down
            event.preventDefault();
        }
    }

    /**
     * Called when a key is pressed with a list item focused. The space key
     * will scroll the window by default so we have to prevent that here.
     *
     * @param {Event} event The keyboard event
     *
     * @return {void}
     */
    handleKeyDown = (event) => {
        if (event.keyCode === KEY_SPACE) {
            event.preventDefault();
        }
    }

    /**
     * Sets the native underline element reference
     *
     * @param {Element} element The native element
     */
    setUnderlineRef = (element) => {
        this.underlineRef = element;
    }

    /**
     * Renders the responsive dropdown version of the tabs if required
     *
     * @return {?ReactElement} The responsive dropdown
     */
    renderResponsiveDropdown = () => {
        if (!this.props.isResponsive) {
            return null;
        }

        const { tabContent } = this.props;
        const { currentTabIndex } = this.state;

        return (
            <div className={styles.dropdownMenu}>
                <ActionMenu
                    label={tabContent[currentTabIndex].text}
                    anchorPoint="left"
                >
                    {tabContent.map((item, index) => {
                        return (
                            <Action
                                key={item.text}
                                label={item.text}
                                action={() => this.handleClick(index)}
                            />
                        );
                    })}
                </ActionMenu>
            </div>
        );
    }

    /**
     * Renders the tabs
     *
     * @return {ReactElement} The page tabs
     */
    render () {
        const classList = classNames(styles.menu, {
            [styles.responsiveMenu]: this.props.isResponsive,
        });

        return (
            <>
                {this.renderResponsiveDropdown()}
                <ol className={classList} ref={this.setMenuRef}>
                    {this.props.tabContent.map((item, index) => {
                        const textColor = (index === this.state.currentTabIndex)
                            ? this.props.defaultColor
                            : this.props.selectedColor;

                        return (
                            <li
                                key={`${index}-${item.text}`} // eslint-disable-line react/no-array-index-key
                                className={styles.menuItem}
                                style={{ color: textColor }}
                                tabIndex="0"
                                onClick={() => this.handleClick(index)}
                                onKeyUp={(event) => this.handleKeyUp(event, index)}
                                onKeyDown={(event) => this.handleKeyDown(event)}
                            >
                                {item.text}
                            </li>
                        );
                    })}
                    <div className={styles.selectionUnderline} ref={this.setUnderlineRef} />
                </ol>
            </>
        );
    }

}

export default withRouter(appState.attachState(Tabs));
