import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { KEY_TAB } from 'keycode-js';
import { PrivateRoute, RouterNavigationBlocking } from './utils';
import appState from './state/App';
import { paths } from "./lib";

import {
    HeaderBar,
    MainMenu,
    NotificationToast,
    GlobalSnackBar,
} from './components';

import {
    AddPayroll,
    Authentication,
    Companies,
    Dashboard,
    Employees,
    Login,
    Logout,
    MyAccountPage,
    Upload,
    Payruns,
    SingleCompany,
    SinglePayroll,
    NotFound,
    Forbidden,
    Announcements,
    Messages,
    SingleMessage,
    CompanyDocuments,
    TemplatesViewEdit,
} from './pages';

import Styleguide from './pages/Styleguide/Styleguide';
import styles from './Emc.module.scss';

class Emc extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Called when a key is pressed or held down
     *
     * @param {Event} event The keyboard event
     *
     * @return {void}
     */
    handleKeyDown = (event) => {
        if (event.keyCode === KEY_TAB) {
            document.body.classList.add("__flag-user-is-tabbing");
            document.body.classList.remove("__flag-user-is-not-tabbing");
        }
    }

    /**
     * Called when the mouse is clicked
     *
     * @return {void}
     */
    handleMouseDown = () => {
        document.body.classList.remove("__flag-user-is-tabbing");
        document.body.classList.add("__flag-user-is-not-tabbing");
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.unblockNavigation();
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('mousedown', this.handleMouseDown);
    }

    /**
     * Called just before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('mousedown', this.handleMouseDown);
    }

    /**
     * Renders the main application
     *
     * @return {ReactElement} The root element content
     */
    render () {
        const allPaths = Object.values(paths);
        const employeesPaths = [paths.employee, paths.employees];
        const announcementsPaths = [paths.announcementAdd, paths.announcementViewEdit, paths.announcements];
        const companydocumentsPaths = [paths.companyDocumentsReports, paths.companyDocuments];
        const companiesPaths = [paths.companiesAdd, paths.companies];

        const styleguidePaths = [
            paths.styleguideComponents,
            paths.styleguideLayout,
            paths.styleguideToastDemo,
            paths.styleguide,
        ];

        return (
            <>
                <a className={styles.skipLink} href="#main">Skip to content</a>
                {this.props.appState.loaded && <HeaderBar />}
                {this.props.appState.loaded && <MainMenu />}

                <RouterNavigationBlocking />

                <main role="main" id="main">
                    <Switch>
                        <Route exact path={allPaths} >
                            <Switch>
                                <Route exact path={employeesPaths} component={Employees} />
                                <Route exact path={announcementsPaths} component={Announcements} />
                                <Route exact path={companydocumentsPaths} component={CompanyDocuments} />
                                <Route exact path={companiesPaths} component={Companies} />
                                <Route exact path={styleguidePaths} component={Styleguide} />

                                <PrivateRoute exact path={paths.dashboard} component={Dashboard} />

                                <PrivateRoute path={paths.logout} component={Logout} />
                                <PrivateRoute path={paths.myAccountPage} component={MyAccountPage} />
                                <PrivateRoute path={paths.authentication} component={Authentication} />
                                <PrivateRoute path={paths.upload} component={Upload} />
                                <PrivateRoute path={paths.payruns} component={Payruns} />

                                <PrivateRoute path={paths.singleMessage} component={SingleMessage} />
                                <PrivateRoute path={paths.messages} component={Messages} />

                                <PrivateRoute path={paths.templatesViewEdit} component={TemplatesViewEdit} />
                                <PrivateRoute path={paths.singlePayroll} component={SinglePayroll} />
                                <PrivateRoute path={paths.addPayroll} component={AddPayroll} />
                                <PrivateRoute path={paths.singleCompany} component={SingleCompany} />

                                <Route path={paths.login} component={Login} />

                                <Route path={paths.forbidden} component={Forbidden} />
                            </Switch>
                        </Route>
                        <Route component={NotFound} />
                    </Switch>
                </main>
                {this.props.appState.loaded && <NotificationToast />}
                {this.props.appState.loaded && <GlobalSnackBar />}
            </>
        );
    }

}

export default withRouter(appState.attachState(Emc));
