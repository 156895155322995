import naturalCompare from 'string-natural-compare';

/**
 * Helper function to sort data array naturally
 *
 * @param {array} data The data object to sort
 * @param {string} key The column to sort
 * @param {string} order The order in which to sort (asc/desc)
 *
 * @return {array} The data object sorted by the criteria
 */
const dataTableSort = (data, key, order) => {
    if (!order) {
        return null;
    }

    return data.sort((a, b) => {
        let ValueA = (a[key].sortValue) ? a[key].sortValue : a[key].text;
        let valueB = (b[key].sortValue) ? b[key].sortValue : b[key].text;

        if (order === "desc") {
            return naturalCompare(
                `${valueB}`,
                `${ValueA}`,
                {
                    caseInsensitive: true,
                }
            );
        } else {
            return naturalCompare(
                `${ValueA}`,
                `${valueB}`,
                {
                    caseInsensitive: true,
                }
            );
        }
    });
};

export default dataTableSort;
