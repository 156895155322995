import React from 'react';
import PropTypes from 'prop-types';

import { SelectInputRow } from "@dataplan/react-components/dist/components/forms";

import styles from './TemplateSelect.module.scss';

class TemplateSelect extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })),
        selected: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        isPreview: PropTypes.bool,
    };

    static defaultProps = {
        name: "",
        label: "",
        values: [],
        selected: "0",
        isPreview: false,
    }

    /**
     * Create an array collection of options for the select
     *
     * @param {array} values The array of values for the select
     *
     * @return {ReactElement[]} An array of the option elements
     */
    getSelectValues (values) {
        if (!values) {
            return null;
        }

        return values.map((option) => {
            return (
                <option value={option.value} key={option.value}>
                    {option.text}
                </option>
            );
        });
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { values, name, label, selected, isPreview, onChange } = this.props;

        if (isPreview) {
            return null;
        }

        return (
            <SelectInputRow
                name={name}
                label={label}
                value={selected}
                placeholder="Please select"
                rowClassName={styles.inputRow}
                onChange={onChange}
            >
                {this.getSelectValues(values)}
            </SelectInputRow>
        );
    }

}

export default TemplateSelect;
