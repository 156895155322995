import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './StatusIndicator.module.scss';

export default class StatusIndicator extends React.Component {

    static propTypes = {
        type: PropTypes.oneOf(["warn", "success", "error", "info"]).isRequired,
        className: PropTypes.string,
    }

    static defaultProps = {
        className: '',
    }

    /**
     * Renders the status indicator component
     *
     * @return {ReactElement} The status indicator
     */
    render () {
        const classNameList = classNames([
            styles.indicatorIcon,
            styles[this.props.type],
            styles[this.props.className],
        ]);

        return (
            <div
                className={classNameList}
            >
                <div className={styles.indicatorIconInner} />
            </div>
        );
    }

}
