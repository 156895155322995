import React from 'react';
import PropTypes from 'prop-types';
import appState from '../../state/App';
import classNames from "classnames";
import api from "../../lib/api";
import _ from "lodash";
import { generatePath, withRouter } from 'react-router-dom';
import { paths } from "../../lib";
import Routes from './routes';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { AddButton } from "@dataplan/react-components/dist/components/forms";
import { defaultAccentColour } from '../../Colours';
import CardSideMenu from "../../components/CardSideMenu";

import styles from './assets/SingleCompany.module.scss';

class SingleCompany extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            company: null,
            payrolls: [],
        };
    }

    /**
     * Called when the component first renders
     *
     * @return {void}
     */
    componentDidMount () {
        this.setCompanyPayrolls();
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = "EMC";

        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Handles updating the component
     *
     * @param {object} prevProps previous props
     */
    componentDidUpdate (prevProps) {
        const companyId = this.props.match.params.company_id;
        const prevId = prevProps.match.params.company_id;

        if (companyId !== prevId) {
            this.setCompanyPayrolls();
        }
    }

    /**
     * Sets the state for company and payrolls
     */
    setCompanyPayrolls () {
        const company = this.getCurrentCompany();
        this.setPageName(company);

        api.get(`/company/${company.id}/payrolls`)
            .then(({ data }) => {
                this.setState({
                    company,
                    payrolls: data,
                });
            });
    }

    /**
     * Set page name
     *
     * @param {object} company the company data
     *
     * @return {void}
     */
    setPageName = (company) => {
        const pageName = `${company.name}`;
        document.title = pageName;
    }

    /**
     * Gets the current company from the url
     *
     * @return {void}
     */
    getCurrentCompany () {
        const { companies } = this.props.appState;
        const companyIdFromURL = _.toNumber(this.props.match.params.company_id);

        return _.find(companies, {id: companyIdFromURL});
    }

    /**
     * The api promise for the employees data
     *
     * @param {object} payrolls details of the payroll
     *
     * @return {array} The promise of employees
     */
    getEmployees (payrolls) {
        return payrolls.map((payroll) => {
            return api.get('/employees', {
                params: {
                    payroll_id: payroll.id, /* eslint-disable-line camelcase */
                },
            });
        });
    }

    /**
     * Gets the links for the side menu
     *
     * @returns {object} the links for the side menu
     */
    getLinkList () {
        const { match } = this.props;

        return [
            {
                label: "Payrolls",
                to: generatePath(paths.singleCompanyPayrolls, match.params),
            },
            {
                label: "About",
                // eslint-disable-next-line camelcase
                to: generatePath(paths.singleCompanyAbout, match.params),
            },
        ];
    }

    /**
     * Gets the page content to render
     *
     * @return {ReactElement} The page content
     */
    getPageContent = () => {
        const { history, match } = this.props;
        const { company, payrolls } = this.state;

        if (!company) {
            return <LoadingSpinner className={styles.loadingSpinner} label="Loading" />;
        }

        const headerAction = {
            drawer: null,
            // eslint-disable-next-line camelcase
            button: (
                <AddButton
                    colour={defaultAccentColour}
                    onClick={() => history.push(generatePath(paths.addPayroll, match.params))}
                />
            ),
        };

        return (
            <>
                <CardSideMenu links={this.getLinkList()} />
                <PageLayout
                    heading={{
                        text: `${company.name}`,
                        size: "h1",
                    }}
                    headerAction={headerAction}
                    pageType="full"
                    maxWidth={this.props.appState.maxWidth}
                    display={{
                        isDetached: false,
                        isPadded: true,
                        hasBackground: false,
                        hasGutter: true,
                    }}
                >
                    <Routes
                        company={company}
                        payrolls={payrolls}
                    />
                </PageLayout>
            </>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const classList = classNames(
            styles.card,
            styles.shadow,
        );

        return (
            <>
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={200}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    <div
                        className={classList}
                    >
                        {this.getPageContent()}
                    </div>
                </AnimationContainer>
            </>
        );
    }

}

export default withRouter(appState.attachState(SingleCompany));
