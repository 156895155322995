import React from 'react';
import PropTypes from 'prop-types';
import { defaultAccentColour } from '../Colours';

import styles from './UserAvatar.module.scss';

export default class UserAvatar extends React.Component {

    static propTypes = {
        forename: PropTypes.string,
        surname: PropTypes.string,
        color: PropTypes.string,
        size: PropTypes.number,
    }

    static defaultProps = {
        forename: "",
        surname: "",
        color: defaultAccentColour,
        size: 40,
    }

    /**
     * Gets the content that should be used for the avatar
     *
     * @return {ReactElement} The avatar content
     */
    getAvatarContent () {
        if (!this.props.forename && !this.props.surname) {
            /* eslint-disable max-len */
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.749 24.71" className={styles.icon}>
                    <ellipse cx="12.375" cy="7.413" rx="6.178" ry="7.413" />
                    <path d="M24.483 21.127a11.26 11.26 0 0 0-5.93-5.189 2.766 2.766 0 0 0-2.224.124 7.585 7.585 0 0 1-7.907 0 2.78 2.78 0 0 0-2.224 0 11.26 11.26 0 0 0-5.93 5.189 2.439 2.439 0 0 0 2.348 3.454h19.519a2.5 2.5 0 0 0 2.348-3.578z" />
                </svg>
            );
            /* eslint-enable */
        } else {
            const initials = [
                this.props.forename && this.props.forename.substr(0, 1).toUpperCase(),
                this.props.surname && this.props.surname.substr(0, 1).toUpperCase(),
            ];

            return <span className={styles.initials}>{initials.join('')}</span>;
        }
    }

    /**
     * Renders the user avatar
     *
     * @return {ReactElement} The avatar component
     */
    render () {
        return (
            <div
                className={styles.avatar}
                style={{
                    backgroundColor: this.props.color,
                    width: this.props.size,
                    height: this.props.size,
                    fontSize: `${(this.props.size / 2)}px`,
                }}
            >
                {this.getAvatarContent()}
            </div>
        );
    }

}
