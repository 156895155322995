import React from 'react';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';

import DefaultForm from '../../../components/forms/DefaultForm';
import {
    CheckboxInputRow,
    SelectInputRow,
    TimeInputRow,
    FileInputRow,
    InputRow,
    TextInputRow,
    NumberInputRow,
    RadioInputRow,
    RangeInputRow,
} from '@dataplan/react-components/dist/components/forms';
import PrimaryButton from '../../../components/forms/controls/PrimaryButton';

import Toggle from '../../../components/Toggle';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import ProgressBar from '../../../components/ProgressBar';
import ActionMenu from '../../../components/ActionMenu';
import Action from '../../../components/Action';

export default class FormControlsSection extends React.Component {

    /**
     * Creates an instance of the section
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.testOptions = [
            {
                value: "1",
                label: "One",
            },
            {
                value: "2",
                label: "Two",
            },
            {
                value: "3",
                label: "Three",
            },
        ];
    }

    /**
     * Renders the form and input controls section
     *
     * @return {ReactElement} The controls section
     */
    render () {
        return (
            <CardRow>
                <CardRowContainer gridWidth={6}>
                    <ContentCard heading="Form Controls">
                        <DefaultForm>
                            <TextInputRow
                                name="text_input"
                                label="Text Input"
                                placeholder="Placeholder text"
                                helpText="Password must contain an uppercase character"
                            />
                            <TextInputRow
                                name="invalid_text_input"
                                label="Invalid Text Input"
                                required
                                pattern="[0-9]{10}"
                                errorText="Something bad happened with this input"
                            />
                            <NumberInputRow name="number_input" label="Number Input" min="0" />
                            <TimeInputRow name="time_input" label="Time Input" />
                            <TextInputRow
                                name="readonly_input"
                                label="Readonly text input"
                                value="A sample value"
                                readOnly
                            />
                            <TextInputRow name="password_input" label="Password Input" type="password" />
                            <SelectInputRow name="select_input" label="Select Input">
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                <option value="4">Four</option>
                            </SelectInputRow>
                            <FileInputRow name="file_input" label="File Input" />
                            <CheckboxInputRow
                                label="Checkbox options"
                                name="checkbox_options"
                                options={this.testOptions}
                            />
                            <RadioInputRow
                                label="Radio options"
                                name="radio_options"
                                options={this.testOptions}
                            />
                            <RangeInputRow name="range_input" label="Range Input" min={0} max={10} />
                            <InputRow>
                                <PrimaryButton type="submit">Save</PrimaryButton>
                            </InputRow>
                        </DefaultForm>
                    </ContentCard>
                </CardRowContainer>
                <CardRowContainer gridWidth={6}>
                    <ContentCard heading="General Controls">
                        <InputRow>
                            <Toggle label="Enabled" />
                        </InputRow>
                        <InputRow>
                            <Toggle label="Enabled" initialValue={true} />
                        </InputRow>
                        <InputRow>
                            <LoadingSpinner label="Loading something" />
                        </InputRow>
                        <InputRow>
                            <ProgressBar value={0.65} />
                        </InputRow>
                        <InputRow>
                            <ActionMenu label="Actions">
                                <Action label="Upload Doc" action={() => null} />
                                <Action label="View Latest Payslip" action={() => null} />
                                <Action label="Deactivate Account" action={() => null} />
                            </ActionMenu>
                        </InputRow>
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
