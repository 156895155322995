import React from 'react';

import CardsSection from './assets/CardsSection';
import ButtonSection from './assets/ButtonSection';
import FiltersSection from './assets/FiltersSection';
import BadgesSection from './assets/BadgesSection';
import FormControlsSection from './assets/FormControlsSection';
import TablesSection from './assets/TablesSection';
import TooltipSection from './assets/TooltipSection';
import PaginationSection from './assets/PaginationSection';
import AvatarSection from './assets/AvatarSection';
import StepsSection from './assets/StepsSection';
import TabsSection from './assets/TabsSection';
import AlertsSection from './assets/AlertsSection';

import './Styleguide.scss';

export default class ComponentGuide extends React.Component {

    /**
     * Renders the styleguide page
     *
     * @return {ReactElement} The page component
     */
    render () {
        return (
            <div>
                <CardsSection />
                <ButtonSection />
                <FiltersSection />
                <BadgesSection />
                <FormControlsSection />
                <TablesSection />
                <TooltipSection />
                <PaginationSection />
                <AvatarSection />
                <StepsSection />
                <TabsSection />
                <AlertsSection />
            </div>
        );
    }

}
