import React from "react";

export const addAnnouncementContextDefault = {
    announcement: {
        subject: "",
        message: "",
        datestart: null,
        dateend: null,
        visibility: [],
        isSubmitting: false,
        backToStepOne: false,
        nextToStepTwo: false,
    },
};

export const AddAnnouncementContext = React.createContext(addAnnouncementContextDefault);
