import React from 'react';
import Pdf from 'react-pdf-js';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PdfDownloader } from "../../lib";
import {
    DocumentPdfNav,
    DocumentPdfPagination,
} from '../';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";

import styles from './DocumentPdfView.module.scss';
import sharedStyles from './DocumentPdfSharedStyles.module.scss';

class DocumentPdfView extends React.Component {

    static propTypes = {
        url: PropTypes.string.isRequired,
        responseType: PropTypes.oneOf([
            'blob', 'json',
        ]),
        jsonPdfKey: PropTypes.string,
        title: PropTypes.string.isRequired,
        showDownload: PropTypes.bool,
        viewMore: PropTypes.string,
        className: PropTypes.string,
        responsive: PropTypes.bool,
        scale: PropTypes.number,
    }

    static defaultProps = {
        responseType: 'blob',
        jsonPdfKey: null,
        showDownload: true,
        viewMore: null,
        className: '',
        responsive: false,
        scale: 1,
    }

    /**
     * Creates an instance of the document preview
     *
     * @param {object} props Preview properties
     */
    constructor (props) {
        super(props);
        const { jsonPdfKey, responseType, title, url } = this.props;

        this.pdfDownloader = new PdfDownloader({ jsonPdfKey, responseType, title, url });

        this.state = {
            pdfBlob: null,
            pdfObjectURL: null,
            pdfTotalPages: 0,
            pdfCurrentPage: 1,
        };
    }

    /**
     * Called once the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.getPdf();
    }

    /**
     * Called once the component has been torn down
     *
     * @return {void}
     */
    componentWillUnmount () {
        const { pdfObjectURL } = this.state;

        if (this.pdfDownloader.cancelRequests) {
            this.pdfDownloader.cancelRequests();
        }

        if (pdfObjectURL) {
            this.pdfDownloader.revokeObjectURL();
        }
    }

    /**
     * Called once the component has loaded, queries the API for the document
     *
     * @return {void}
     */
    getPdf = () => {
        Promise.all([
            this.pdfDownloader.blob,
            this.pdfDownloader.createObjectURL(),
        ])
            .then(([pdfBlob, pdfObjectURL]) => {
                this.setState({
                    pdfBlob,
                    pdfObjectURL,
                });
            })
            .catch(() => {
                // Will catch if axios request is canceled
            });
    }

    /**
     * Called once the PDF has been generated
     *
     * @param {number} totalPages The total number of pages in the PDF
     *
     * @return {void}
     */
    onDocumentComplete = (totalPages) => {
        this.setState({
            pdfTotalPages: totalPages,
        });
    }

    /**
     * Changes the current page number in the PDF being viewed
     *
     * @param {number} pageNumber The page number to view
     *
     * @return {void}
     */
    onPdfPageChange = (pageNumber) => {
        this.setState({
            pdfCurrentPage: pageNumber,
        });
    }

    /**
     * Renders the pagination component if the PDF has multiple pages
     *
     * @return {?ReactElement} The pagination component
     */
    renderPdfPagination = () => {
        const { pdfTotalPages } = this.state;

        if (pdfTotalPages <= 1) {
            return null;
        }

        return (
            <DocumentPdfPagination
                total={pdfTotalPages}
                onPageChange={this.onPdfPageChange}
            />
        );
    }

    /**
     * Renders the document PDF
     *
     * @return {ReactElement} The document PDF component
     */
    renderPdf = () => {
        const { viewMore } = this.props;
        const { pdfObjectURL, pdfCurrentPage } = this.state;

        const pdfComponent = (
            <Pdf
                scale={this.props.scale}
                file={pdfObjectURL}
                page={pdfCurrentPage}
                onDocumentComplete={this.onDocumentComplete}
            />
        );

        if (viewMore !== null) {
            return (
                <Link aria-label="Payslip Pdf" className={styles.canvasLink} to={viewMore}>
                    {pdfComponent}
                </Link>
            );
        }

        return pdfComponent;
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { className, title, showDownload, viewMore, responsive } = this.props;
        const { pdfBlob } = this.state;

        const classList = classNames(
            styles.documentPreviewCanvas,
            sharedStyles.documentPreviewCanvas,
            {
                [styles.responsive]: responsive,
            }
        );

        if (!pdfBlob) {
            return <LoadingSpinner label="Loading" />;
        }

        return (
            <div className={className}>
                <div className={classList}>
                    {this.renderPdf()}
                    {this.renderPdfPagination()}
                </div>
                <DocumentPdfNav
                    title={title}
                    showDownload={showDownload}
                    viewMore={viewMore}
                    documentPdf={pdfBlob}
                />
            </div>
        );
    }

}

export default DocumentPdfView;
