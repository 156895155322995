import React from 'react';
import appState from '../../../state/App';
import createToasts from '../../../components/toasts/createToasts';
import { checkValidity } from '../../../lib/validationHelpers';
import api from '../../../lib/api';
import classNames from 'classnames';

import DefaultForm from '../../../components/forms/DefaultForm';
import {
    TextInputRow,
    ReadOnlyRow,
    InputRow,
} from '@dataplan/react-components/dist/components/forms';
import PrimaryButton from '../../../components/forms/controls/PrimaryButton';
import InlineValidationErrors from '../../../components/validation/InlineValidationErrors';

import sharedStyles from '../SharedStyles.module.scss';

class ChangeEmailAddress extends React.Component {

    /**
     * Creates an instance of the change email address page
     *
     * @param {object} props Change email address page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            currentEmail: 'loading...',
            password: '',
            emails: {
                newEmail: '',
                confirmNewEmail: '',
            },
            hasChanged: false,
            error: {
                isValid: true,
                messages: [],
                invalidInputs: [],
            },
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.setInitialState();
    }

    /**
     * Sets the component default state
     *
     * @return {void}
     */
    setInitialState = () => {
        this.setState({
            password: '',
            emails: {
                newEmail: '',
                confirmNewEmail: '',
            },
            hasChanged: false,
        }, () => {
            this.getCurrentEmail();
        });
    }

    /**
     * Gets the users current email address
     *
     * @return {void}
     */
    getCurrentEmail = () => {
        api.get(`/user/profile`)
            .then((response) => {
                this.setState({
                    currentEmail: response.data.email,
                });
            });
    }

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handlePasswordInput = (event) => {
        let password = event.target.value;

        this.setState({
            password,
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    }

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     * @param {string} field Which email field has changed
     *
     * @return {void}
     */
    handleEmailInput = (event, field) => {
        let email = event.target.value;

        this.setState((prevState) => {
            return {
                emails: {
                    ...prevState.emails,
                    [field]: email,
                },
                hasChanged: Boolean(email),
            };
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    }

    /**
     * Checks which inputs are invalid
     *
     * @return {string[]} the invalid inputs
     */
    invalidInputChecker () {
        const { password, emails } = this.state;
        let passwordEntered = checkValidity(password, 'passwordEntered');
        let emailsValidation = checkValidity([emails.newEmail, emails.confirmNewEmail], 'newEmail');

        return [
            !passwordEntered.isValid ? 'currentPassword' : null,
            !emailsValidation.isValid ? 'newEmail' : null,
            !emailsValidation.isValid ? 'confirmNewEmail' : null,
        ].filter((input) => input !== null);
    }

    /**
     * Checks the password field in completed and the email address is valid
     *
     * @return {void}
     */
    handleValidation = () => {
        const { password, emails } = this.state;
        let passwordEntered = checkValidity(password, 'passwordEntered');
        let emailsValidation = checkValidity([emails.newEmail, emails.confirmNewEmail], 'newEmail');
        let errors = [];

        [passwordEntered, emailsValidation].forEach((validation) => {
            if (!validation.isValid) {
                errors.push(validation.message);
            }
        });

        if (emails.confirmNewEmail) {
            this.setState({
                error: {
                    isValid: (passwordEntered.isValid && emailsValidation.isValid),
                    messages: errors,
                    invalidInputs: this.invalidInputChecker(),
                },
            });
        }
    }

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        event.preventDefault();

        const { password, emails, hasChanged, error } = this.state;

        if (!hasChanged || !error.isValid) {
            return;
        }

        appState.unblockNavigation();

        api.patch('/security/email', {
            password,
            "email": emails.newEmail,
            "email_confirm": emails.confirmNewEmail,
        }).then(() => {
            appState.addNotification({
                text: "Email address successfully updated.",
                type: "success",
                duration: 5,
            });
            this.setInitialState();
        }).catch((apiError) => {
            createToasts(apiError.response.data, 'error');
        });
    }

    /**
     * Renders the password field
     *
     * @return {ReactElement} The password input
     */
    renderPasswordField = () => {
        const { invalidInputs } = this.state.error;
        const classList = classNames([
            sharedStyles.currentPassword,
            (invalidInputs.indexOf('currentPassword') >= 0) ? sharedStyles.error : '',
        ]);

        return (
            <TextInputRow
                name="currentPassword"
                label="Current Password"
                type="password"
                onChange={this.handlePasswordInput}
                value={this.state.password}
                inputClassName={classList}
            />
        );
    }

    /**
     * Renders a email input field
     *
     * @param {string} field Which email field to render
     * @param {string} display The textual label to display
     *
     * @return {ReactElement} The email input component
     */
    renderEmailField = (field, display) => {
        const { invalidInputs } = this.state.error;
        const classList = (invalidInputs.indexOf(field) >= 0) ? sharedStyles.error : '';
        const invalidConfirm = (field === "confirmNewEmail");

        return (
            <div className={sharedStyles.inputPair}>
                <TextInputRow
                    name={field}
                    label={display}
                    type="email"
                    onChange={(event) => this.handleEmailInput(event, field)}
                    value={this.state.emails[field]}
                    placeholder={this.state.currentEmail}
                    inputClassName={classList}
                />
                {invalidConfirm ? <div className={sharedStyles.description}>Email addresses must match</div> : null}
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { hasChanged, error, currentEmail } = this.state;

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                <InlineValidationErrors errors={error} />
                <ReadOnlyRow
                    label="Current Email:"
                    value={currentEmail}
                />
                {this.renderPasswordField()}
                <InputRow className={sharedStyles.flexRow}>
                    {this.renderEmailField("newEmail", "New email address")}
                    {this.renderEmailField("confirmNewEmail", "Confirm new email address")}
                </InputRow>
                <PrimaryButton
                    type="submit"
                    disabled={!hasChanged || !error.isValid}
                    className={sharedStyles.saveButton}
                >
                    Save
                </PrimaryButton>
            </DefaultForm>
        );
    }

}

export default ChangeEmailAddress;
