import React from 'react';
import _ from 'lodash';

import CardRow from '../../../components/cards/CardRow';
import CardRowContainer from '../../../components/cards/CardRowContainer';
import ContentCard from '../../../components/cards/ContentCard';
import PrimaryButton from '../../../components/forms/controls/PrimaryButton';

import { StepList, Step } from '@dataplan/react-components/dist/components/ui/steplist';

export default class StepsSection extends React.Component {

    /**
     * Creates an instance of the styleguide section
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            currentStep: 1,
        };
    }

    /**
     * Called when the next button is clicked
     *
     * @return {void}
     */
    handleClick = () => {
        this.setState((prevState) => {
            let nextStep = (prevState.currentStep + 1);

            if (nextStep > 4) {
                nextStep = 1;
            }

            return {
                currentStep: nextStep,
            };
        });
    }

    /**
     * Renders the tooltip section
     *
     * @return {ReactElement} The section
     */
    render () {
        const stepList = {
            1: "Upload Files",
            2: "Employee Moderation",
            3: "Release",
            4: "Summary &amp; Confirmation",
        };

        const stepComponents = [];

        _.forOwn(stepList, (label, number) => {
            const numberVal = parseInt(number, 10);

            stepComponents.push((
                <Step
                    key={numberVal}
                    number={numberVal}
                    label={label}
                    completed={this.state.currentStep >= numberVal}
                />
            ));
        });

        return (
            <CardRow>
                <CardRowContainer gridWidth={12}>
                    <ContentCard heading="Steps">
                        <StepList>
                            {stepComponents}
                        </StepList>
                        <br />
                        <PrimaryButton onClick={this.handleClick}>Next</PrimaryButton>
                    </ContentCard>
                </CardRowContainer>
            </CardRow>
        );
    }

}
