import React from 'react';
import PropTypes from 'prop-types';
import appState from '../../../state/App';

import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import {
    TemplatePreview,
    TemplateEdit,
} from '../';

class TemplateLayout extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        dataLoaded: PropTypes.bool.isRequired,
        title: PropTypes.string,
        preview: PropTypes.array.isRequired,
        content: PropTypes.array.isRequired,
        settings: PropTypes.array.isRequired,
        rawValues: PropTypes.object,
        canSubmit: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.object,
        ]),
        onComponentUnmount: PropTypes.func.isRequired,
    };

    static defaultProps = {
        rawValues: {},
        canSubmit: false,
        title: "Template",
    }

    /**
     * Get the tab content for the page layout
     *
     * @return {array} The tabs
     */
    getTabContent () {
        const {
            dataLoaded,
            preview,
            content,
            settings,
            rawValues,
            canSubmit,
            onComponentUnmount,
        } = this.props;

        if (!dataLoaded) {
            return null;
        }

        return [
            {
                text: "Preview",
                component: (
                    <TemplatePreview>
                        {preview}
                    </TemplatePreview>
                ),
                url: "/preview",
            },
            {
                text: "Edit",
                component: (
                    <TemplateEdit
                        rawValues={rawValues}
                        canSubmit={canSubmit.content}
                        onComponentUnmount={onComponentUnmount}
                        previewCard
                    >
                        {content}
                    </TemplateEdit>
                ),
                url: "/edit",
            },
            {
                text: "Settings",
                component: (
                    <TemplateEdit
                        rawValues={rawValues}
                        canSubmit={canSubmit.settings}
                        onComponentUnmount={onComponentUnmount}
                    >
                        {settings}
                    </TemplateEdit>
                ),
                url: "/settings",
            },
        ];
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { dataLoaded, title } = this.props;

        return (
            <PageLayout
                heading={{
                    text: title,
                    size: "h1",
                }}
                pageType="boxed"
                maxWidth={this.props.appState.maxWidth}
                display={{
                    isDetached: false,
                    isPadded: true,
                    hasBackground: true,
                    hasGutter: true,
                }}
                tabContent={this.getTabContent()}
                isRouted
            >
                {(!dataLoaded) && (
                    <LoadingSpinner label="Loading" />
                )}
            </PageLayout>
        );
    }

}

export default (appState.attachState(TemplateLayout));
