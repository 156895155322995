import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../icons/Icon';

import styles from './ValidationIcon.module.scss';

class ValidationIcon extends React.Component {

    static propTypes = {
        isValid: PropTypes.bool,
        label: PropTypes.string,
    }

    static defaultProps = {
        isValid: false,
        label: '',
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const iconProps = (this.props.isValid)
            ? {
                isValid: 'passed',
                icon: 'Tick',
                viewBox: '2.5 5 18.64 13.98',
            }
            : {
                isValid: 'failed',
                icon: 'Close',
                viewBox: '5 5 15 15',
            };
        const classList = classNames([
            styles.tickIcon,
            styles[iconProps.isValid],
        ]);

        return (
            <div className={styles.validationIconRow}>
                <Icon
                    icon={iconProps.icon}
                    className={classList}
                    viewBox={iconProps.viewBox}
                />
                {this.props.label}
            </div>
        );
    }

}

export default ValidationIcon;
