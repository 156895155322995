import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../utils/PrivateRoute';
import { paths } from "../../lib";

import { CompanyDocumentReports } from '.';
import CompanyDocumentsPage from "./CompanyDocumentsPage";

class CompanyDocuments extends React.Component {

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <Switch>
                <PrivateRoute path={paths.companyDocumentsReports} component={CompanyDocumentReports} />
                <PrivateRoute path={paths.companyDocuments} component={CompanyDocumentsPage} />
            </Switch>
        );
    }

}

export default CompanyDocuments;
